import React, { forwardRef, ReactNode, useCallback, useMemo, useState, } from 'react';
import { Card, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './Editors.module.scss';
import useKeyPressListener from 'hooks/useKeyPressListener';
import { Account } from 'types/statutory.types';
import { getDisplayName } from 'utils/common.utils';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import Button from 'components/elements/button/Button';
import { FieldErrors } from 'react-hook-form';
import { useAppSelector } from 'store/hooks/hooks';

import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import clsx from 'clsx';

const APPLY_KEY_BINDING = { code: 'Enter' };
type AccountSelectValue = { primary: number; counter: number };

interface SearchableSelectProps {
  value: AccountSelectValue;
  field: string;
  icon?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  errors: FieldErrors;
  setValue: (value: AccountSelectValue) => void;
  formatValue?: (value: Value | string) => string;
  onBlur?: () => void;
}

type SelectValue = Value | number | string;

type Value = {
  id: number | string;
  name: number | string;
};

interface ValuesProps {
  values: SelectValue[];
}

const AccountCellEditor = forwardRef((
  props: SearchableSelectProps & ValuesProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const value = props.value;

  const accountMap = useAppSelector(state => state.breakdowns.accountMap);

  const [ primaryOpen, setPrimaryOpen ] = useState(false);
  const [ primaryValue, setPrimaryValue ] = useState<number>(value?.primary);
  const [ counterOpen, setCounterOpen ] = useState(false);
  const [ counterValue, setCounterValue ] = useState<number>(value?.counter);
  const [ t ] = useTranslation('common');

  const options = useMemo(() => {
    return props.values.map((_value: Account) => ({
      value: _value.id,
      label: props.formatValue ? props.formatValue(_value) : typeof _value === 'object' ?
        getDisplayName(_value?.name) : _value,
    }));
  }, [ props.values, props.formatValue ]);

  const onChange = useCallback((key: number, type: 'primary' | 'counter') => {
    if (type === 'primary') {
      setPrimaryValue(key);
    }
    if (type === 'counter') {
      setCounterValue(key);
    }
  }, [ props.values ]);

  const applyOnEnter = useCallback(() => {
    if (!primaryOpen && !counterOpen) {
      props.setValue({ primary: primaryValue, counter: counterValue });
    }
  }, [ primaryOpen, counterOpen, primaryValue, counterValue ]);

  useKeyPressListener({ keyBinding: APPLY_KEY_BINDING, cb: applyOnEnter });

  const onOpenChange = useCallback((_open: boolean, type: 'primary' | 'counter') => {
    if (type === 'primary') {
      setPrimaryOpen(_open);
    }
    if (type === 'counter') {
      setCounterOpen(_open);
    }
  }, []);

  const onApply = () => {
    props.setValue({ primary: primaryValue, counter: counterValue });
    setIsOpen(false);
  };

  const inputs = [
    {
      label: t('account-editor.primary.label'),
      open: primaryOpen,
      onOpenChange: (open: boolean) => onOpenChange(open, 'primary'),
      value: primaryValue,
      onChange: (key: number) => onChange(key, 'primary')
    },
    {
      label: t('account-editor.counter.label'),
      open: counterOpen,
      onOpenChange: (open: boolean) => onOpenChange(open, 'counter'),
      value: counterValue,
      onChange: (key: number) => onChange(key, 'counter')
    }
  ];

  const primaryAccount = accountMap[ props.value?.primary ];

  return (
    <Dropdown
      open={ isOpen }
      onOpenChange={ setIsOpen }
      trigger={ [ 'click' ] }
      dropdownRender={ (() => <div className={ styles.accountEditor }>
        <Card
          title={ t('account-editor.title') }
          extra={
            <CloseIcon
              className={ styles.closeIcon }
              onClick={ () => setIsOpen(false) }/> }>
          {
            inputs.map(
              (input) => (
                <div className={ styles.inputGroup } key={ input.label }>
                  <label>{ input.label }</label>  
                  <SearchableSelect
                    open={ input.open }
                    onDropdownVisibleChange={ input.onOpenChange }
                    dropdownMatchSelectWidth={ 350 }
                    value={ input.value }
                    options={ options }
                    popupClassName='ag-custom-component-popup'
                    className={ clsx(styles.fullCellInput, 'withBorder') }
                    onChange={ input.onChange }
                  />
                </div>
              )
            )
          }
        </Card>
        <div className={ styles.footer }>
          <Button
            type='text'
            onClick={ () => setIsOpen(false) }
          >
            { t('account-editor.cancel') }
          </Button>
          <Button autoFocus onClick={ onApply }>{ t('account-editor.apply') }</Button>
        </div>
      </div>) }>
      <button type='button' className={ styles.accountValue }>
        <span className={ styles.value }>
          {
            typeof props.value === 'string' ?
              props.value :
              `${ primaryAccount?.number } ${ getDisplayName(primaryAccount?.name) }`
          }
        </span>
        <span className={ styles.icon }>
          <ChevronDown />
        </span>
      </button>
    </Dropdown>
  );
});

AccountCellEditor.displayName = 'AccountCellEditor';
export default AccountCellEditor;

import { PaginatedResponse } from 'types/app.types';
import { Cadence } from 'types/form.types';
import {
  BudgetItemAiCompletion,
  PlanSettingRequest,
  PlanSettingsResponse
} from 'types/planning.types';
import { StatutoryRowCategory, UUID } from 'types/templates.types';
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';
import {
  Budget,
  BudgetClearDimensionsRequest,
  BudgetItem,
  BudgetItemParams,
  BudgetItemType,
  BudgetItemUpdateRequest,
} from '../types/budget.types';

export type BudgetItemTableRequestFilters = {
  templateRowId?: string;
  dimensionItemIds?: string[];
  transactionLines?: number[];
  unassignedDimensionIds?: number[];
  contractId?: number;
  startDate?: string;
  endDate?: string;
  planStartDate?: string;
  planEndDate?: string;
  cadence?: Cadence;
  userTemplateId?: number;
  templateCategory?: StatutoryRowCategory;
  breakdownIds?: number[];
  excludedUnassignedDimensionIds?: number[];
  excludedDimensionItemIds?: number[];
  formulaIds?: number[];
};

const getPlanning = () =>
  http.get<PaginatedResponse<PlanSettingsResponse>>(endpoints.planning.settings);

const setPlanning = (data: PlanSettingRequest) =>
  http.post<PlanSettingsResponse>(endpoints.planning.settings, data);

const restoreDefaultPlanning = () =>
  http.post<PlanSettingsResponse>(endpoints.planning.restoreDefaults);

const getBudgets = () =>
  http.get<PaginatedResponse<Budget>>(endpoints.planning.budgets.list);

const getBudgetItemTypes = () =>
  http.get<PaginatedResponse<BudgetItemType>>(endpoints.planning.budgetItemTypes);

const getBudgetItems = (params: BudgetItemParams) =>
  http.get<PaginatedResponse<BudgetItem>>(endpoints.planning.budgetItems.index, { params });

const getTableBudgetItems = (data?: BudgetItemTableRequestFilters) =>
  http.post<BudgetItem[]>(endpoints.planning.budgetItems.table, data);

const bulkDeleteBudgetItem = (ids: number[]) =>
  http.post(endpoints.planning.budgetItems.bulkDelete, ids);

const batchUpdateBudgetItems = (data: BudgetItemUpdateRequest[]) =>
  http.post<BudgetItem[]>(endpoints.planning.budgetItems.batchUpdate, data);

const regenerateBudgetItems = (data: { ids: number[] })=>
  http.post<void>(endpoints.planning.budgetItems.regenerate, data);

const clearDimensions = (data: BudgetClearDimensionsRequest) =>
  http.post<void>(endpoints.planning.budgetItems.clearDimensions, data);

const generatePayments = () =>
  http.post(endpoints.planning.generatePayments);

const promptAiCompletion = (prompt: string, sessionUuid: UUID) =>
  http.post<BudgetItemAiCompletion>(
    endpoints.planning.budgetItems.aiCompletion,
    { prompt, sessionUuid }
  );

const refreshPlan = () =>
  http.post(endpoints.planning.refreshPlan);

export const planningService = {
  getPlanning,
  setPlanning,
  restoreDefaultPlanning,
  getBudgets,
  getBudgetItemTypes,
  getBudgetItems,
  getTableBudgetItems,
  bulkDeleteBudgetItem,
  batchUpdateBudgetItems,
  regenerateBudgetItems,
  clearDimensions,
  generatePayments,
  promptAiCompletion,
  refreshPlan,
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LabelingRules.module.scss';
import useRulesGrid from './rulesList/hooks/useRulesGrid';
import { ColumnState } from 'ag-grid-community';
import labelingService from 'services/labeling.service';
import { CounterpartyLabelingRuleElement, LabelingStatus, TData } from 'types/labeling.types';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { getDisplayName } from 'utils/common.utils';
import Loader from 'components/elements/loader/Loader';
import { notifyError } from 'utils/notifications.utils';
import Modal from 'components/elements/modal/Modal';
import { ReactComponent as Bin } from 'assets/icons/bin.svg';
import Pill from 'components/elements/pill/Pill';
import Card from 'components/elements/card/Card';
import ModalTransactionLines
  from '../elements/tableWrapper/table/modalTransactionLines/ModalTransactionLines';
import { Action } from '../elements/tableWrapper/types/table.types';
import Table from '../elements/tableWrapper/table/Table';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { useAppSelector } from '../../store/hooks/hooks';
import { useAppDispatch } from '../../store/hooks/hooks';
import { topBarSlice } from 'store/topBar.slice';
import { AgGridReact } from 'ag-grid-react';
import useLabelingRulesCommandBar from './hooks/useLabelingRulesCommandBar';
import RulesLeftPanel from './panels/InputsLeftPanel';
import { TableContextProvider } from 'context/TableContext';
import PanelProvider from 'components/panels/panelsContext';

const LabelingRules = () => {
  const [ t ] = useTranslation('labeling');
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const [ rulesToDelete, setRulesToDelete ] = useState<TData[]>([]);
  const [ isDeleteRuleModalVisible, setIsDeleteRuleModalVisible ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ rows, setRows ] = useState<TData[]>([]);

  const [ search, setSearch ] = useState('');

  const gridRef = useRef<AgGridReact>(null);

  const [ transactionsModalVisible, setTransactionsModalVisible ] = useState(false);
  const [ ruleId, setRuleId ] = useState(null);

  const dispatch = useAppDispatch();

  useLabelingRulesCommandBar();

  const setTransactionListClicked = (id: number) => {
    setTransactionsModalVisible(true);
    setRuleId(id);
  };

  const getModalTitle = () => {
    return (
      <div className={ styles.modalTitle }>
        <Bin height={ 25 }/>
        { t('delete-modal.title') }
      </div>
    );
  };

  const onRuleDeleteConfirm = useCallback(async () => {
    setIsDeleting(true);
    try {
      const idsToDelete = rulesToDelete.map(({ id }) => id);
      await labelingService.deleteBulkRule(idsToDelete);
      setRows(rows.filter((row) => !idsToDelete.includes(row.id)));
      gridRef.current?.api?.applyTransaction({ remove: rulesToDelete });
      setIsDeleteRuleModalVisible(false);
    } catch (e) {
      notifyError(t('delete-modal.delete-error.message'));
    }
    setIsDeleting(false);
  }, [ rulesToDelete ]);

  const { columnDefs } = useRulesGrid({ setTransactionListClicked, search });

  const mapResults = useCallback((results: CounterpartyLabelingRuleElement[]): TData[] => {
    const today = dayjs();
    const counterparty = dimensions.find((dimension) => dimension.relation === 'COUNTERPARTY');

    return results.map((item) => {
      const targetDimension = dimensions.find(
        (dimension) => dimension.id === item.dimensionItem.dimension.id
      );
      const targetDimensionItem = targetDimension?.items.find(
        (dimensionItem) => dimensionItem.id === item.dimensionItem.id
      );

      return {
        id: item.id,
        startDate: dayjs(item.startDate),
        endDate: dayjs(item.endDate),
        relation: 'arrow',
        source: getDisplayName(counterparty.customName),
        sourceDimension: capitalize(item.counterparty.name),
        target: getDisplayName(targetDimension.customName),
        targetDimension: getDisplayName(targetDimensionItem.customName),
        applied: item.applied,
        lastApplied: item.lastApplied,
        ruleType: item.ruleType,
        status: dayjs(item.endDate).isBefore(today) ? LabelingStatus.ENDED : LabelingStatus.ACTIVE,
      };
    });
  }, [ dimensions ]);

  const getRows = useCallback(async () => {
    const response = await labelingService.getRules({ limit: 999999 });
    const mapRows = mapResults(response.data.results);
    setRows(mapRows);
  }, [ mapResults ]);

  useEffect(() => {
    if (!dimensions.length) return;

    setLoading(true);
    getRows().finally(() => {
      setLoading(false);
    });
  }, [ dimensions ]);

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle(t('navigation:links.manage.labeling-rules')));

    return () => {
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  const deleteRules = useCallback(async () => {
    const elementsToDelete = gridRef?.current.api.getSelectedRows().slice();
    setRulesToDelete(elementsToDelete);
    setIsDeleteRuleModalVisible(true);
  }, []);

  const actions: Action[] = useMemo(() => ([
    {
      label: t('common:delete'),
      onClick: deleteRules,
      icon: <BinIcon width={ 17 } height={ 17 }/>
    }
  ]), []);

  const defaultSort: ColumnState[] = useMemo(() => ([
    {
      colId: 'applied',
      sort: 'desc',
      sortIndex: 0
    }
  ]), []);

  return (
    <div className={ styles.labelingRules }>
      <ModalTransactionLines
        isVisible={ transactionsModalVisible }
        onClose={ () => setTransactionsModalVisible(false) }
        onConfirm={ () => setTransactionsModalVisible(false) }
        ruleId={ ruleId }
      />
      <Modal
        onConfirm={ onRuleDeleteConfirm }
        onClose={ () => setIsDeleteRuleModalVisible(false) }
        okText={ t('delete-modal.ok-text') }
        okButtonProps={ { danger: true } }
        width='auto'
        confirmLoading={ isDeleting }
        closeText={ t('delete-modal.close-text') }
        isVisible={ isDeleteRuleModalVisible } title={ getModalTitle() }>
        <div className={ styles.modalContent }>
          <div>
            <span>{ t('delete-modal.description') }</span>
          </div>
          <div className={ styles.rulesToDelete }>
            { rulesToDelete && rulesToDelete.map(
              ruleToDelete => <RenderRuleToDeleteLine
                key={ ruleToDelete.id } 
                ruleToDelete={ ruleToDelete }
              />) }
          </div>
        </div>

      </Modal>
      {
        loading ? (
          <Loader isActive={ loading }/>
        ) : (
          <div className={ styles.viewWrapper }>
            <TableContextProvider defaultSorting={ defaultSort }>
              <PanelProvider>
                <RulesLeftPanel gridRef={ gridRef } />
                <Card className={ styles.container }>
                  <Table
                    gridRef={ gridRef }
                    name={ t('links.manage.labeling-rules', { ns: 'navigation' }) }
                    tableKey='labeling-rules'
                    columnDefs={ columnDefs }
                    rowData={ rows }
                    tableStyle={ styles.table }
                    onSearch={ setSearch }
                    showSearch={ true }
                    actions={ actions }
                    defaultSort={ defaultSort }
                  />
                </Card>
              </PanelProvider>
            </TableContextProvider>
          </div>
        )
      }
    </div>
  );
};
export default LabelingRules;

const RenderRuleToDeleteLine = ({ ruleToDelete }: { ruleToDelete: TData }) => {
  return (
    <>
      <div className={ styles.pillWrapper }>
        <Pill className={ styles.pillFirst }>
          <strong>
            { ruleToDelete?.source }
          </strong>
          &nbsp;
          <span>
            { ruleToDelete?.sourceDimension }
          </span>
        </Pill>
      </div>
      <div className={ styles.dash }>
        &ndash;
      </div>
      <div className={ styles.pillWrapper }>
        <Pill className={ styles.pillSecond }>
          <strong>
            { ruleToDelete?.target }
          </strong>
          &nbsp;
          <span>
            { ruleToDelete?.targetDimension }
          </span>
        </Pill>
      </div>
    </>
  );
};
import { Budget, BudgetGeneration, BudgetItemType } from './../types/budget.types';
import { arrayToMap } from 'utils/mapping.utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/store';
import { BudgetStore } from '../types/budget.types';
import { setActiveBudgetIdCookie } from '../utils/auth.utils';
import { inputsApi } from './api/inputs.api';
import { DisplayName } from '../types/app.types';
import { reportsApi } from './api/reports.api';

const defaultBudgetGeneration: BudgetGeneration = {
  status: 'not_started',
  step: 0,
  error: false,
  totalSteps: 0,
};

const initialState: BudgetStore = {
  budgets: [],
  activeBudgetId: null,
  budgetItemTypes: [],
  budgetItemCategories: [],
  budgetItemCategoryInputs: {}, // we don't have id, so en name to list of input ids
  budgetItemTypesMap: {},
  budgetSettings: null,
  budgetGeneration: defaultBudgetGeneration,
};

export const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setBudgets: (state, action: PayloadAction<BudgetStore['budgets']>) => {
      state.budgets = action.payload;
    },
    setActiveBudgetId: (state, action: PayloadAction<BudgetStore['activeBudgetId']>) => {
      state.activeBudgetId = action.payload;
    },
    setBudgetItemTypes: (state, action: PayloadAction<BudgetStore['budgetItemTypes']>) => {
      state.budgetItemTypes = action.payload;
      const categories: DisplayName[] = [];
      const categoriesInputs: { [key: string]: number[] } = {};
      const seen = {};
      for (const item of action.payload) {
        const en = item.category[ 'en' ];
        if (!seen[ en ]) {
          categories.push(item.category);
          categoriesInputs[ en ] = item.relatedInputs;
        }
        seen[ en ] = true;
      }

      state.budgetItemCategories = categories;
      state.budgetItemCategoryInputs = categoriesInputs;
      state.budgetItemTypesMap = arrayToMap<BudgetItemType>(action.payload, item => item.id);
    },
    setBudgetSettings: (state, action: PayloadAction<BudgetStore['budgetSettings']>) => {
      state.budgetSettings = action.payload;
    },
    setBudgetGeneration: (
      state,
      action: PayloadAction<Partial<BudgetStore['budgetGeneration']>>
    ) => {
      state.budgetGeneration = {
        ...state.budgetGeneration,
        ...action.payload,
      };
    },
  }
});

export const endBudgetGeneration = () => (dispatch: AppDispatch) => {
  dispatch(budgetSlice.actions.setBudgetGeneration({ status: 'ended' }));
};

export const updateBudgetGeneration = (payload: Partial<BudgetStore['budgetGeneration']>) =>
  (dispatch: AppDispatch) => {
    dispatch(budgetSlice.actions.setBudgetGeneration({
      ...payload,
    }));
  };

export const clearBudgetGeneration = () => (dispatch: AppDispatch) => {
  dispatch(budgetSlice.actions.setBudgetGeneration(defaultBudgetGeneration));
};
export const fetchAndSetInputs = () => async (dispatch: AppDispatch) => {
  dispatch(inputsApi.endpoints.getInputs.initiate({}));
};

export const setActiveBudget = (budget: Budget) => async (
  dispatch: AppDispatch
) => {
  setActiveBudgetIdCookie(budget.id);
  dispatch(budgetSlice.actions.setActiveBudgetId(budget.id));
  dispatch(reportsApi.util.invalidateTags([ 'Report', 'StreamReport' ]));
};

export const budgetReducer = budgetSlice.reducer;
export const selectBudgetItemTypes = (state: RootState) => state.budget.budgetItemTypes;
export const selectBudgetSettings = (state: RootState) => state.budget.budgetSettings;

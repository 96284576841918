import { useMemo } from 'react';
import { useGetBudgetsQuery } from 'store/api/planning.api';
import { Budget } from 'types/budget.types';

const useBudgets = () => {
  const query = useGetBudgetsQuery();

  const budgets = useMemo(() => {
    if (!query.data?.results) return []; 

    return [ ...query.data.results ]?.sort(sortByDate);
  }, [ query.data ]);

  return {
    ...query,
    budgets,
  };
};

export default useBudgets;

function sortByDate(a: Budget, b: Budget) {
  return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
}

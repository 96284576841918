import React from 'react';
import { Integration } from './settings.types';

export type NotificationType = 'info' | 'error' | 'warning' | 'success';

export type ActionNotification = {
  id: string;
  message: string;
  description?: string;
  type: NotificationType;
  button: {
    url: string;
    name: string;
  };
};

export type CustomViewTemplates = {
  type: 'table' | 'inputs';
  isShown: boolean;
  order: number;
  templateId: number;
};

export type CustomViewSection = {
  id: number;
  icon: null | string;
  name: DisplayName;
  views: CustomViewNavigate[];
  warning?: boolean;
  warningTooltip?: string;
};

export type CustomViewNavigate = {
  icon: null | string;
  id: number;
  section: string;
  default: boolean;
  name: DisplayName;
  url: null | string;
  planning: boolean;
  templates: CustomViewTemplates[];
  visibleTemplates: CustomViewTemplates[];
};

export type AppStore = {
  actionNotifications: ActionNotification[];
  navigationList: CustomViewSection[];
  anyModalOpened: boolean;
  navbarOpen: boolean;
  integrations: Integration[];
  initialIntegrationsFetched: boolean;
  organizationChangeWarning: boolean;
  integrationsCode: string;
  accountsSynchronized: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leftPanelData: Record<string, any>;
  leftPanel: string | null;
  rightPanel: string | null;
  websocketConnected: boolean;
};

export enum EnvironmentType {
  local = 'local',
  dev = 'dev',
  production = 'production'
}

export enum LocalDisplayName {
  en = 'en',
  fi = 'fi',
  sv = 'sv'
}

export type LocalDisplayNameType = keyof typeof LocalDisplayName;

export type DisplayName = string | {
  [key in LocalDisplayNameType]?: string
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type PaginatedResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type ImportedStyles = {
  readonly [key: string]: string;
};

export type ContextType<State, Dispatch> = {
  state: State;
  dispatch: React.Dispatch<Dispatch>;
};

export const LeftPanelOptions = {
  SORT: 'sort',
  GROUP: 'group',
  COLUMNS: 'columns',
  LABEL: 'label',
  FILTER: 'filter',
  LAYOUT: 'layout',
  SETTINGS: 'settings',
} as const;

export const PanelTypeOptions = {
  ...LeftPanelOptions,
  BUDGET: 'budget',
  CHAT: 'chat'
} as const;

export type LeftPanelType = typeof LeftPanelOptions[keyof typeof LeftPanelOptions];
export type PanelType = typeof PanelTypeOptions[keyof typeof PanelTypeOptions];

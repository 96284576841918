import React, { forwardRef, useCallback, useMemo } from 'react';
import {
  ColDef,
  EditableCallback,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import styles from '../table/Table.module.scss';
import useTableRenderer from './useTableRenderer';
import { Account, Counterparty, DimensionItemRelation } from 'types/statutory.types';
import { useTranslation } from 'react-i18next';
import useTableFormatter from './useTableFormatter';
import { useAppSelector } from 'store/hooks/hooks';
import { getDisplayName } from 'utils/common.utils';
import {
  UNASSIGNED_ROW_NAME
} from '../../../singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import { displayNameComparator } from 'utils/sorting.utils';
import { getDimensionViewName } from 'utils/financials.utils';
import {
  DimensionCellEditor
} from 'components/elements/tableWrapper/table/editors/DimensionCellEditor';
import useTableEditableProps from 'components/elements/tableWrapper/hooks/useTableEditableProps';
import { valueIsString } from '../utils/table.utils';
import { Dimension } from 'types/filterTable.types';

interface Props {
  search: string;
}

const useTableColumns = ({ search }: Props) => {
  const [ t ] = useTranslation('tableWrapper');
  const { defaultCellRenderer, renderField, redirectCellRenderer } = useTableRenderer({ search });
  const { accountFormatter, dateFormatter, counterpartyFormatter } = useTableFormatter();
  const { editableColumnDef } = useTableEditableProps();
  const { dimensions, dimensionItemMap } = useAppSelector(state => state.breakdowns);
  const accountDimension = useMemo(() => {
    return dimensions.find(dimension => dimension.relation === 'ACCOUNT');
  }, [ dimensions ]);
  const counterpartyDimension = useMemo(() => {
    return dimensions.find(dimension => dimension.relation === 'COUNTERPARTY');
  }, [ dimensions ]);

  const defaultColDef: ColDef = useMemo(() => ({
    suppressMovable: false,
    resizable: true,
    filter: 'agTextColumnFilter',
    getQuickFilterText: (params) => params.value,
    cellRenderer: defaultCellRenderer,
    flex: 1,
    sortable: true,
    suppressHeaderMenuButton: true,
    headerClass: styles.header,
    sortingOrder: [ 'desc', 'asc', null ],
    icons: {
      sortAscending:
        `<img
          src='/icons/chevron-down.svg'
          alt='sort-asc'
          class="${ styles.sortAsc }"
        />
        `,
      sortDescending:
        `<img 
          src='/icons/chevron-down.svg'
          alt='sort-desc'
          class="${ styles.sortDesc }"
        />
        `,
    }
  }), []);

  const numberColumnDef: ColDef = useMemo(() => ({
    headerClass: [ styles.header, styles.rightAlignedHeader ],
    cellClass: styles.rightAlignedCell,
    comparator: (valueA, valueB) => {
      return valueA - valueB;
    },
  }), []);

  /**
   * In transaction data, ids of dimensionItems are returned, but when grouped by dimension in
   * server side row model, we get something like this:
   * { dimension.1: 123 }. This getter handles that.
   * @param dimension
   */
  const dimensionValueGetterFactory = (dimension: Dimension) => (params: ValueGetterParams) => {
    const valueField = 'dimension.' + dimension?.id.toString();
    if (!params.data) {
      return null;
    }
    const simpleValue = params.data[ valueField ];
    if (simpleValue) {
      return simpleValue;
    }
    const dimensionItem = dimension?.items.find(
      item => params.data.dimensionItemIds?.includes(item.id));
    return dimensionItem?.id;
  };

  const accountColumnDef: ColDef = useMemo(() => ({
    ...renderField(
      'dimension.' + accountDimension?.id.toString(),
      accountDimension ?
        getDisplayName(accountDimension.customName) : t('transactions-table.column.account'),
      200
    ),
    flex: 0,
    valueFormatter: accountFormatter,
    valueGetter: (params: ValueGetterParams) => {
      const getter = dimensionValueGetterFactory(accountDimension);
      return getter(params);
    },
    keyCreator: params => params.value.id,
    getQuickFilterText: accountFormatter,
    suppressHide: true,
    comparator: (valueA: Account, valueB: Account) => {
      if (valueIsString(valueA) && valueIsString(valueB)) {
        return valueA.localeCompare(valueB);
      } else if (valueA !== undefined) {
        return valueA.number - valueB.number;
      }
    },
    group: t('common:dimensions'),
  }), [ renderField, accountDimension ]);

  const counterpartyColDef: ColDef = useMemo(() => ({
    ...renderField(
      'dimension.' + counterpartyDimension?.id.toString(),
      counterpartyDimension ? getDisplayName(counterpartyDimension.customName) :
        t('transactions-table.column.counterparty')
    ),
    valueFormatter: counterpartyFormatter,
    valueGetter: (params: ValueGetterParams) => {
      const getter = dimensionValueGetterFactory(counterpartyDimension);
      return getter(params);
    },
    keyCreator: counterpartyFormatter,
    getQuickFilterText: (e) => e.value,
    filterValueGetter: (params) => {
      return params.data.counterparty?.name ?? UNASSIGNED_ROW_NAME;
    },
    flex: 0,
    group: t('common:dimensions'),
    comparator: (valueA: Counterparty, valueB: Counterparty) => {
      if (valueIsString(valueA) && valueIsString(valueB)) {
        return valueB.localeCompare(valueA);
      } else if (!valueA || !valueB) {
        return valueA != valueB ? -1 : 1;
      } else {
        return valueB.name.localeCompare(valueA.name);
      }

    },
  }), [ renderField, counterpartyDimension ]);

  const getDateColDef = useCallback((
    field: string,
    title: string,
    defaultWidth?: number
  ): ColDef => {
    return {
      ...renderField(field, title, defaultWidth),
      flex: 0,
      valueFormatter: dateFormatter,
      getQuickFilterText: (params) => dateFormatter(params),
    };
  }, [ renderField ]);

  const dimensionItemValueFormatter = (
    params: ValueFormatterParams
  ) => {
    const value = params.value;
    if (params.node.rowPinned === 'bottom') {
      return undefined;
    }
    if (!value) {
      return '';
    }
    const dimensionItem = dimensionItemMap[ +value ];
    if (dimensionItem) {
      return getDimensionViewName(dimensionItem);
    }
    return value || '';
  };

  const defaultCanBeAssigned = useCallback((
    _: EditableCallbackParams,
    dimension: Dimension
  ) => {
    return dimension.canBeAssigned;
  }, []);

  const defaultCanBeUnassigned = useCallback((
    _: ICellEditorParams,
    dimension: Dimension
  ) => {
    return dimension.canBeUnassigned;
  }, []);

  type GetDimensionColumnsProps<T> = {
    onChangeDimensionItem: (node: IRowNode<T>, id: number, dimension: number) => Promise<void>;
    canBeAssigned?: typeof defaultCanBeAssigned;
    canBeUnassigned?: typeof defaultCanBeUnassigned;
    removeRelationColumns?: boolean;
  };

  const getDimensionColumns = useCallback(<T extends DimensionItemRelation, >(
    {
      onChangeDimensionItem,
      canBeAssigned = defaultCanBeAssigned,
      canBeUnassigned = defaultCanBeUnassigned,
      removeRelationColumns
    }: GetDimensionColumnsProps<T>,
  ): TableColDef[] => {
    let filteredDimensions = dimensions;
    if (removeRelationColumns) {
      filteredDimensions = filteredDimensions.filter(
        (dim) => dim.relation !== 'ACCOUNT' && dim.relation !== 'COUNTERPARTY');
    }
    return filteredDimensions
      .slice()
      .sort((a, b) => displayNameComparator(a.customName, b.customName))
      .map(dim => {
        const name = getDimensionViewName(dim);
        const field = 'dimension.' + dim.id.toString();
        return {
          ...renderField(field, name),
          ...editableColumnDef,
          editable: (params: EditableCallbackParams) => {
            const editable = editableColumnDef.editable as EditableCallback;
            return editable(params) && canBeAssigned(params, dim);
          },
          cellEditorParams: () => {
            return {
              values: dim.items,
              cellEditorPopup: true,
            };
          },
          suppressKeyboardEvent: function (params) {
            return params.editing && params.event.key === 'Enter';
          },
          /* eslint-disable react/display-name */
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          cellEditor: forwardRef((props: ICellEditorParams, _) => {
            return (
              <DimensionCellEditor
                props={ props }
                onChangeDimensionItem={ (node, id) => onChangeDimensionItem(node, id, dim.id) }
                canBeUnassigned={ canBeUnassigned(props, dim) }
              />
            );
          }),
          flex: 0,
          group: t('common:dimensions'),
          valueGetter: (params: ValueGetterParams<T>) => {
            const getter = dimensionValueGetterFactory(dim);
            return getter(params);
          },
          valueFormatter: (params) => dimensionItemValueFormatter(params),
          keyCreator: (params) => dimensionItemValueFormatter(params),
          cellDataType: 'text',
        };
      });
  }, [ dimensions ]);

  const getRedirectColDef = useCallback((
    field: string,
    onClick: (node: IRowNode) => void
  ): TableColDef => ({
    ...renderField(field, ''),
    flex: 0,
    width: 44,
    minWidth: 44,
    resizable: false,
    sortable: false,
    cellStyle: { padding: 0 },
    lockPosition: 'left' as const,
    headerComponent: () => null,
    cellRenderer: (_params: ICellRendererParams) => redirectCellRenderer(_params, onClick),
    cellDataType: 'text',
  }), []);

  return {
    defaultColDef,
    numberColumnDef,
    accountColumnDef,
    counterpartyColDef,
    getDateColDef,
    getDimensionColumns,
    getRedirectColDef
  };
};

export default useTableColumns;

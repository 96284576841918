import React, { MutableRefObject, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Card } from 'antd';
import clsx from 'clsx';

import { GroupOption, GroupSettings } from 'components/elements/tableWrapper/types/table.types';
import { appSlice } from 'store/app.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import ColumnSelection from 'components/panels/columnSelection/ColumnSelection';
import PanelWrapper from 'components/panels/wrappers/PanelWrapper';
import SearchBar from 'components/elements/searchBar/SearchBar';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './InputsLeftPanel.module.scss';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import SortSelection,
{ convertColumnsStateToSortForm } from 'components/panels/sortSelection/SortSelection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import { useTableContext } from 'context/TableContext';
import RegisterPanel from 'components/panels/registerPanel/RegisterPanel';
import { sideTabs } from 'components/panels/panel.constants';
import { usePanelControl } from 'components/panels/panelsContext';
import LayoutControl from 'components/financials/panels/layout/LayoutControl';
import LayoutSidePanelHeader from 'components/panels/layoutSidePanelHeader/LayoutSidePanelHeader';

interface Props {
  gridRef: MutableRefObject<AgGridReact>;
  groupOptions?: GroupOption[];
}
const RulesLeftPanel = ({ gridRef, groupOptions }: Props) => {

  const dispatch = useAppDispatch();
  const activePanelType = useAppSelector(store => store.app.leftPanel);

  const { setLeftActivePanel } = usePanelControl();

  const isActive = activePanelType != null;

  const onChange = (key: string | null) => {
    dispatch(appSlice.actions.setLeftPanel(key));
  };

  const onClose = () => {
    dispatch(appSlice.actions.setLeftPanel(null));
  };

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  useEffect(() => {
    setLeftActivePanel(activePanelType);
  }, [ activePanelType ]);

  const { sortState, defaultSorting } = useTableContext();

  if (!gridRef.current) {
    return null;
  }

  return <PanelWrapper
    orientation='left'
    isActive={ isActive }
  >
    <Card bordered={ false } className={ clsx(panelStyles.card, styles.card) }>
      <LayoutSidePanelHeader
        onChange={ onChange }
        side='left'
        onClose={ onClose }
      />

      <RegisterPanel { ...sideTabs[ 'columns' ] } >

        <SearchBar
          className={ panelStyles.searchBox }
          showSeparator={ false }
          showResults={ false }
        />
        <div className={ clsx(panelStyles.panelBody) }>
          <ColumnSelection
            isGridReady
            gridRef={ gridRef }
            sectionKey='labelingRules'
            tableKey='labelingRules'
          />
        </div>
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'sort' ] } >
        <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
          <SortSelection
            gridRef={ gridRef }
            sortState={ convertColumnsStateToSortForm(sortState) }
            defaults={ convertColumnsStateToSortForm(defaultSorting) }
          />
        </div>
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'group' ] } >
        <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
          <GroupSelection
            onSelect={ onGroupSelect }
            gridRef={ gridRef }
            options={ groupOptions }
          />
        </div>
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'layout' ] } >
        <LayoutControl />
      </RegisterPanel>

    </Card>
  </PanelWrapper>;
};

const ProviderWrapper = (props: Props) => {
  return <RulesLeftPanel { ...props } />;
};

export default ProviderWrapper;

import React from 'react';
import styles from './DimensionCapsule.module.scss';
import clsx from 'clsx';
import { ReactComponent as RemoveIcon } from 'assets/icons/close.svg';

interface Props {
  icon?: React.ReactNode;
  className?: string;
  dimension: string;
  dimensionItem: string;
  height?: number;
  showRemoveIcon?: boolean;
  onRemove?: () => void;
}

const DimensionCapsule = (
  {
    icon,
    className,
    dimension,
    dimensionItem,
    height = 24,
    showRemoveIcon = false,
    onRemove
  }: Props) => {
  return <div
    style={ { height } }
    className={ clsx(styles.capsule, { [ styles.hasIcon ]: icon }, className) }>
    <div>
      <span className={ styles.icon }>{ icon }</span>
      <b>{ dimension?.toLowerCase() }</b>
      { dimensionItem }
    </div>
    
    { showRemoveIcon && <div className={ styles.removeIconContainer } onClick={ onRemove }>
      <RemoveIcon className={ styles.removeIcon }/>
    </div>
    }
  </div>
  ;
};

export default DimensionCapsule;

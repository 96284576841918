import React from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { labelingSlice } from 'store/labeling.slice';
import { FILTERS_LIST_VIEW } from 'utils/storage.utils';
import SettingsSection from '../common/settingsSection/SettingsSection';
import SwitchSetting from '../common/switchSetting/SwitchSetting';

interface Props {
  disableSettings?: string[];
}

const LabelSettings = ({ disableSettings = [] }: Props) => {
  const [ t ] = useTranslation('financials');

  const dispatch = useAppDispatch();

  const [ view, setView ] = useLocalStorage(FILTERS_LIST_VIEW, true);

  const skipConfirmation = useAppSelector(state => state.labeling.settings.skipConfirmation);
  const setSkipConfirmation = (value: boolean) => {
    dispatch(labelingSlice.actions.setSkipConfirmation(value));
  };

  const delayRefresh = useAppSelector(state => state.labeling.settings.delayRefresh);
  const setDelayLabelingRefresh = (value: boolean) => {
    dispatch(labelingSlice.actions.setDelayRefresh(value));
  };

  return <SettingsSection title={ t('actions.labeling-settings-header') }>
    <SwitchSetting
      label={ t('left-panel.list-view') }
      tooltip={ t('left-panel.list-view-warning') }
      active={ !!view }
      disabled={ disableSettings.includes('list-view') }
      onChange={ (value) => setView(value) }
    />
    <SwitchSetting
      label={ t('left-panel.skip-confirmation') }
      active={ !!skipConfirmation }
      disabled={ disableSettings.includes('skip-confirmation') }
      onChange={ (value) => setSkipConfirmation(value) }
    />
    <SwitchSetting
      label={ t('left-panel.delay-refresh') }
      active={ !!delayRefresh }
      disabled={ disableSettings.includes('delay-refresh') }
      onChange={ (value) => setDelayLabelingRefresh(value) }
    />
  </SettingsSection>;
};

export default LabelSettings;

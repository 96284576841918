/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { TemplateRequest, TemplateResponse, TemplatesListResponse, UserReport }
  from 'types/templates.types';
import { endpoints } from 'utils/config.utils';
import http, { getActiveReportMode } from 'utils/http.utils';
import { Period } from '../types/financials.types';
import { LimitOffsetParams } from './params/pagination';
import dayjs from 'dayjs';
import {
  getActiveBudgetIdCookie,
  getActiveOrganizationIdCookie,
  getToken
} from '../utils/auth.utils';

const toggleReportVisible = (id: number, data) =>
  http.post<TemplateRequest>
  (endpoints.reports.toggleVisibility.replace('{id}', id.toString()), data);

const getSystemTemplates = () =>
  http.get<TemplatesListResponse>(endpoints.reports.systemTemplates);

const getSystemTemplate = (templateId: number) =>
  http.get<TemplateRequest>(endpoints.reports.systemTemplate
    .replace('{id}', templateId.toString()));

const getUserTemplates = (params: LimitOffsetParams) =>
  http.get<TemplatesListResponse>(endpoints.reports.userTemplates, { params });

const getPresetTemplates = (params: LimitOffsetParams) =>
  http.get<TemplatesListResponse>(endpoints.reports.presets, { params });

const getUserTemplate = (templateId: number) =>
  http.get<TemplateResponse>(endpoints.reports.userTemplate
    .replace('{id}', templateId.toString()));

const getPresetTemplate = (presetId: number) =>
  http.get<TemplateResponse>(endpoints.reports.preset.replace('{id}', presetId.toString()));

const addUserTemplate = (data: TemplateRequest) =>
  http.post<TemplateResponse>(endpoints.reports.userTemplates, data
  );

const updateUserTemplate = (data: TemplateRequest, templateId: number) =>
  http.put<TemplateRequest>(endpoints.reports.userTemplate
    .replace('{id}', templateId.toString()), data
  );

const saveUserTemplate = (data: TemplateRequest, templateId?: number) =>
  templateId ?
    updateUserTemplate(data, templateId) :
    addUserTemplate(data);

const deleteUserTemplate = (templateId: number) =>
  http.delete(endpoints.reports.userTemplate
    .replace('{id}', templateId.toString())
  );

interface UserReportParams {
  reportId: number;
  period: Period;
  signal?: AbortSignal;
}

const getUserReport = (
  {
    reportId,
    period,
    signal
  }: UserReportParams) => {
  const params = {};
  if (period) {
    params[ 'cadence' ] = period.cadence;
    if (period.startDate) {
      params[ 'startDate' ] = dayjs.unix(period.startDate).utcOffset(0, true).format();
    }
    if (period.endDate) {
      params[ 'endDate' ] = dayjs.unix(period.endDate).utcOffset(0, true).format();
    }
    if (period.startDatePlan) {
      params[ 'planStartDate' ] = dayjs.unix(period.startDatePlan).utcOffset(0, true).format();
    }
    if (period.endDatePlan) {
      params[ 'planEndDate' ] = dayjs.unix(period.endDatePlan).utcOffset(0, true).format();
    }
  }
  return http.get<UserReport>(
    endpoints.reports.data.replace('{id}', reportId.toString()),
    {
      params,
      signal,
    }
  );
};

const streamUserReport = (
  {
    reportId,
    period,
    signal
  }: UserReportParams) => {
  const params = new URLSearchParams();
  if (period.startDate) {
    params.set('startDate', dayjs.unix(period.startDate).utcOffset(0, true).format());
  }
  if (period.endDate) {
    params.set('endDate', dayjs.unix(period.endDate).utcOffset(0, true).format());
  }
  if (period.startDatePlan) {
    params.set('planStartDate', dayjs.unix(period.startDatePlan).utcOffset(0, true).format());
  }
  if (period.endDatePlan) {
    params.set('planEndDate', dayjs.unix(period.endDatePlan).utcOffset(0, true).format());
  }
  if (period.cadence) {
    params.set('cadence', period.cadence);
  }

  return fetch(
    process.env.REACT_APP_API_URL + endpoints.reports.stream.replace(
      '{id}', reportId.toString()) + '?' + params.toString(),
    {
      headers: {
        Authorization: `Bearer ${ getToken() }`,
        'Content-Type': 'application/json',
        'X-Organization-ID': getActiveOrganizationIdCookie(),
        'X-Budget-ID': getActiveBudgetIdCookie(),
        'X-Report-Mode': getActiveReportMode(),
      },
      signal,
    },
  );
};

const toggleFavoriteTemplate = (templateId: number) =>
  http.put(endpoints.reports.favorites.replace('{id}', templateId.toString()));

export const reportsService = {
  getUserTemplates, getUserTemplate, deleteUserTemplate,
  addUserTemplate, updateUserTemplate, saveUserTemplate, getUserReport,
  getPresetTemplates, getPresetTemplate,
  getSystemTemplates, getSystemTemplate, toggleReportVisible,
  toggleFavoriteTemplate, streamUserReport
};

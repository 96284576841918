import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styles from './Editors.module.scss';
import useKeyPressListener from 'hooks/useKeyPressListener';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';

const SELECT_KEY_BINDING = { code: 'Enter' };

type SelectValue = Value | number | string;

interface SearchableSelectProps {
  value: SelectValue;
  field: string;
  icon?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  setValue: (value: SelectValue) => void;
  formatValue?: (value: Value | string) => string;
  onBlur?: () => void;
}

type Value = {
  id: number | string;
  name: number | string;
};
interface ValuesProps {
  values: SelectValue[];
}

const SelectCellEditor = (props: SearchableSelectProps & ValuesProps) => {
  const value = props.value;
  const column = props.field;
  const [ open, setOpen ] = useState(false);

  const options = useMemo(() => {
    return props.values.map((_value: Value | string) => ({
      value: typeof _value === 'object' ? _value?.id : _value,
      label: props.formatValue ? props.formatValue(_value) :
        typeof _value === 'object' ? _value?.name : _value,
    }));
  }, [ props.values, props.formatValue ]);

  const updateValue = useCallback((val) => {
    props.setValue(val);
  }, [ column ]);

  const isObjectArray = (array: SelectValue[]): array is Value[] => {
    return typeof array[ 0 ] === 'object';
  };

  const onChange = useCallback((key: number) => {
    if (isObjectArray(props.values)) {
      const _value = props.values.find((el) => el.id === key);
      updateValue(_value);
    } else {
      updateValue(key);
    }
  }, [ props.values, updateValue ]);

  const openOnEnter = useCallback(() => setOpen(true), []); 

  useKeyPressListener({ keyBinding: SELECT_KEY_BINDING, cb: openOnEnter, enabled: open });

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  return (
    <SearchableSelect
      open={ open }
      onBlur={ props.onBlur }
      disabled={ props.disabled }
      placeholder={ props.placeholder }
      getPopupContainer={ (triggerNode) => triggerNode }
      onDropdownVisibleChange={ onOpenChange }
      dropdownMatchSelectWidth={ 450 }
      onFocus={ () => setOpen(true) }
      value={ typeof value === 'object' ? value?.id : value }
      options={ options }
      popupClassName='ag-custom-component-popup'
      className={ styles.searchableSelect }   
      onChange={ onChange }
    />
  );
};

SelectCellEditor.displayName = 'SelectCellEditor';
export default SelectCellEditor;

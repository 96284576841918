import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import SearchableSelect from '../../../../../elements/searchableSelect/SearchableSelect';
import styles from './Editors.module.scss';
import useKeyPressListener from 'hooks/useKeyPressListener';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { MonthInput } from 'components/elements/tableWrapper/table/editors/FrequencyCellEditor';
import { FrequencyUnit } from 'types/financials.types';

const SELECT_KEY_BINDING = { code: 'Enter' };

type Value = { amount: number; unit: FrequencyUnit };

interface ValuesProps {
  field: string;
  values: { name: string; label: string; amount: number; unit: FrequencyUnit }[];
  value: Value;
  icon?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  setValue: (value: Value) => void;
  formatValue: (value: Value | string) => string;
  onBlur?: () => void;
}

const FrequencyCellEditor = (props: ValuesProps) => {

  const [ t ] = useTranslation('common');
  const value = getNameFromValue(props.value, props.values);

  const [ open, setOpen ] = useState(false);

  const [ customOption, setCustomOption ] = useState(props.value?.amount);

  const options = useMemo(() => {
    return props.values.map((option) => {

      return {
        value: option.name,
        val: option,
        name: option.label,
        label: option.label as ReactNode,
      };
    })
      .concat({
        value: 'custom',
        val: { unit: 'month' as const, amount: customOption, name: 'custom', label: '' },
        name: t('frequency.every_count_months', { count: customOption }),
        label: <MonthInput
          value={ customOption }
          onChange={ (newCustomValue: number) => {
            setCustomOption(newCustomValue);
            onChange(newCustomValue);
          } } />
      });
  }, [ props.values, props.formatValue, customOption ]);

  const onChange = useCallback((key: string | number) => {
    const selectedValue = props.values.find((val) => val.name === key);
    if (selectedValue) {
      props.setValue(selectedValue);
    } else {
      if (typeof key === 'number') {
        props.setValue({ amount: key, unit: 'month' });
      } else {
        props.setValue({ amount: customOption, unit: 'month' });
      }
    }
  }, [ customOption ]);

  const openDropdown = useCallback(() => setOpen(true), []);

  useKeyPressListener({ keyBinding: SELECT_KEY_BINDING, cb: openDropdown, enabled: open });

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  return (
    <SearchableSelect
      open={ open }
      onBlur={ props.onBlur }
      disabled={ props.disabled }
      placeholder={ props.placeholder }
      onDropdownVisibleChange={ onOpenChange }
      dropdownMatchSelectWidth={ 450 }
      getPopupContainer={ (triggerNode) => triggerNode }
      onFocus={ () => setOpen(true) }
      searchBy={ (obj) => obj.name }
      optionLabelProp='name'
      value={ value }
      autoFocus={ false }
      options={ options }
      popupClassName='ag-custom-component-popup'
      className={ clsx(styles.searchableSelect) }
      onChange={ onChange }
    />
  );
};

export default FrequencyCellEditor;

function getNameFromValue (value: Value, values: ValuesProps['values']) {
  if (value == null) return null;
  if (!value) return values.at(0)?.name;

  const selectedValue = values
    .find((val) => val.amount === value.amount && val.unit === value.unit);
  return selectedValue?.name ?? 'custom';
}

import React, { useMemo } from 'react';

import { InputRowWithValues } from '../../types/inputs.types';
import { IRowNode } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { getDisplayName } from '../../../../utils/common.utils';
import styles from '../../../elements/dimensionLeftPanel/addEditDimensionModal/Modals.module.scss';
import Input from '../../../elements/input/Input';
import { useForm } from 'react-hook-form';
import Modal from '../../../elements/modal/Modal';
import { useGetInputsQuery } from '../../../../store/api/inputs.api';

interface AddInputAliasModalProps {
  // eslint-disable-next-line no-undef
  node: IRowNode<InputRowWithValues>;
  onClose: () => void;
  open: boolean;
  onConfirm: (node: IRowNode<InputRowWithValues>, alias: string) => void;
  disabled?: boolean;
}

const AddInputAliasModal = (
  {
    node,
    onClose,
    open,
    onConfirm,
    disabled
  }: AddInputAliasModalProps) => {

  const [ t ] = useTranslation('inputs');

  const {
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm<{ alias: string }>({ mode: 'onChange' });
  const alias = watch('alias');

  const { data: inputsResponseData } = useGetInputsQuery({});

  const existingAliases = useMemo(() => {
    return new Set(inputsResponseData?.results?.map(input => input.alias));
  }, [ inputsResponseData ]);

  const rules = {
    required: {
      value: true,
      message: t('common:validation.requiredField', { field: 'Alias' })
    },
    maxLength: {
      value: 250,
      message: t('common:validation.maxLength', { field: 'Alias', length: 250 })
    },
    validate: {
      unique: (value: string) =>
        !existingAliases.has(value) || t('add-alias-modal.errors.unique')
    }
  };

  return (
    <Modal
      title={ t('add-alias-modal.title', { inputName: getDisplayName(node?.data?.name) }) }
      open={ open }
      onConfirm={ () => {
        onConfirm(node, alias);
      } }
      onClose={ () => {
        onClose();
        reset();
      } }
      isVisible={ open }
      confirmDisabled={ disabled || !!errors.alias }
      okText={ t('add-alias-modal.create') }
    >
      <div className={ styles.content }>
        <Input
          name='alias'
          errors={ errors }
          control={ control }
          label={ t('add-alias-modal.label') }
          rules={ rules }
          displayErrorMessage={ true }
          labelTooltip={ t('add-alias-modal.label-tooltip') }
        />
      </div>
    </Modal>
  );
};

export default AddInputAliasModal;

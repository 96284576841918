import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loader from 'components/elements/loader/Loader';
import dashboardService from 'services/dashboard.service';
import {
  dashboardSlice,
  saveElements,
  selectElements,
  selectMode,
} from 'store/dashboard.slice';
import { formatUnixDate } from 'utils/period.utils';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DashboardModeType } from 'types/dashboard.types';
import DashboardGrid from '../grid/DashboardGrid';
import DashboardPanel from '../panel/DashboardPanel';
import styles from './DashboardPage.module.scss';
import { fetchAndSetSystemTemplate, fetchAndSetTemplateList } from 'store/templates.slice';
import { selectViewPeriod, topBarSlice } from 'store/topBar.slice';
import { useTranslation } from 'react-i18next';
import {
  clearDetailedViewSettings,
  clearPanels,
  clearRightPanel,
  financialsSlice
} from 'store/financials.slice';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';
import useFinancialsCommandBar from 'components/financials/hooks/useFinancialsCommandBar';
import useDashboardCommandBar from 'components/dashboard/hooks/useDashboardCommandBar';
import {
  defaultCustomSettings,
  FinancialTableContextProvider
} from 'context/FinancialTableContext';
import FinancialTableModal from 'components/modals/financialTable/FinancialTableModal';
import DynamicTableWrapper from 'components/wrappers/dynamicTableWrapper/DynamicTableWrapper';
import useAbortSignal from 'hooks/useAbort';
import {
  DASHBOARD_PERIOD_ID,
  getDateParams,
  getLatestDashboardIdCookie,
  setLatestDashboardIdCookie
} from 'utils/dashboard.utils';
import useFloatingDetailedViewResizer from
  'components/financials/detailedView/hooks/useFloatingDetailedViewResizer';
import { FLOATING_PANEL_PORTAL_ID } from
  'components/financials/detailedView/utils/detailedView.utils';
import PanelProvider from '../../panels/panelsContext';

const DashboardPage = () => {
  const [ t ] = useTranslation('dashboard');

  const dispatch = useAppDispatch();
  const period = useAppSelector(selectViewPeriod);
  const tableIds = useAppSelector(state => Object.keys(state.financials.tables).map(Number));
  const panel = useAppSelector(state => state.app.leftPanel);
  const activeTable = useAppSelector(state => state.financials.active?.templateId);
  const elements = useAppSelector(selectElements);
  const { signal, controller } = useAbortSignal();
  const dashboardLoaded = useRef(false);
  const mode = useAppSelector(selectMode);
  const { activeDashboardId } = useAppSelector((state) => state.dashboard);
  const [ loading, setLoading ] = useState(true);
  const viewId = useAppSelector((state) => state.topBar.viewId);
  const { ref } = useFloatingDetailedViewResizer({ type: 'workspaces' });

  useCommandBarDefaults({ keys: [ 'cta', 'group', 'layout', 'columns' ], mode: 'hide' });
  useDashboardCommandBar();
  useFinancialsCommandBar({
    disabled: mode === DashboardModeType.EDIT,
    disabledCommands: [ 'layout', 'overviewDetails' ]
  });
  useEffect(() => {
    dispatch(fetchAndSetTemplateList());
    dispatch(fetchAndSetSystemTemplate());
    dispatch(topBarSlice.actions.setViewId(DASHBOARD_PERIOD_ID));

    return () => {
      dispatch(dashboardSlice.actions.clear());
      dispatch(financialsSlice.actions.removeTables(tableIds));
    };
  }, []);

  useEffect(() => {
    const getDashboard = async () => {
      setLoading(true);
      const dashboards = await dashboardService.getDashboards();
      dispatch(dashboardSlice.actions.setDashboards(dashboards.data.results));

      const latestDashboardId = getLatestDashboardIdCookie();
      const dashboardId = +latestDashboardId || dashboards.data.results[ 0 ].id;
      if (!latestDashboardId) {
        setLatestDashboardIdCookie(dashboardId);
      }

      dashboardService.getDashboard(dashboardId, {
        startDate: formatUnixDate(period.startDate),
        endDate: formatUnixDate(period.endDate),
        cadence: period.cadence
      }).then((res) => {
        dispatch(dashboardSlice.actions.setActiveDashboard(res.data));
        const _elements = res.data.elements || [];
        dispatch(saveElements(_elements));
        if (!dashboardLoaded.current) {
          const isStatic = !!_elements.length;
          dispatch(dashboardSlice.actions.setMode(
            isStatic ? DashboardModeType.VIEW : DashboardModeType.EDIT
          ));
        }
        dashboardLoaded.current = true;
      }).finally(() => {
        setLoading(false);
      });
    };

    if (viewId && period) {
      getDashboard();
    }
  }, [ viewId, period ]);

  const updateDashboard = useCallback(() => {
    if (activeDashboardId == null) return;
    dispatch(dashboardSlice.actions.setSaving(true));

    dashboardService.updateDashboard(
      activeDashboardId,
      elements,
      getDateParams(period),
      signal
    ).finally(() => {
      dispatch(dashboardSlice.actions.setSaving(false));
    });
  }, [ elements, activeDashboardId ]);

  useEffect(() => {
    if (mode === DashboardModeType.VIEW && dashboardLoaded.current) {
      updateDashboard();
    }
    if (mode === DashboardModeType.EDIT) {
      dispatch(clearDetailedViewSettings());
      dispatch(clearRightPanel());
    }
  }, [ mode ]);

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle(t('page.title')));
    dispatch(topBarSlice.actions.setViewPeriodVisible(true));
    dispatch(financialsSlice.actions.clearActiveTable());
    return () => {
      dispatch(topBarSlice.actions.clear());
      dispatch(financialsSlice.actions.clear());
      controller.abort();
    };
  }, []);

  return (
    <div className={ styles.dashboard } id={ FLOATING_PANEL_PORTAL_ID }>
      <PanelProvider>
        <Loader className={ styles.loader } isActive={ loading } />

        <DashboardPanel />
        <DashboardGrid widthRef={ ref } />
        {
          panel ? <FinancialTableContextProvider
            customSettings={ {
              ...defaultCustomSettings,
              adjustHeight: false,
              disableStickyHeader: true,
              panelSettings: {
                ...defaultCustomSettings.panelSettings,
                chart: {
                  ...defaultCustomSettings.panelSettings.chart,
                  disabled: true,
                },
                filterLabel: {
                  ...defaultCustomSettings.panelSettings.filterLabel,
                  className: styles.filterLabel
                }
              },
              detailedViewSettings: {
                disabled: true,
                floatingPanelDisabled: true,
              }
            } }
            templateId={ activeTable }
            onClose={ () => dispatch(clearPanels()) }
          >
            <DynamicTableWrapper templateId={ activeTable }>
              <FinancialTableModal isVisible={ panel != null } />
            </DynamicTableWrapper>
          </FinancialTableContextProvider> : null
        }
      </PanelProvider>
    </div>
  );
};

export default DashboardPage;

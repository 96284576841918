import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OperatorsType
} from 'components/templates/customTemplate/formulaBuilder/utils/formulaBuilder.utils';
import { cloneDeep } from 'lodash';
import { AppDispatch, RootState } from 'store/store';
import { setTemplateNodes } from 'store/template.slice';
import {
  CustomFormulaFormat,
  FormulaItem,
  FormulaNodeRowData,
  FormulaPeriod,
  isFormulaNode,
  TemplateNodeId
} from 'types/templates.types';
import { mapFormulaToNode } from 'utils/template.utils';

type FormulaState = {
  customFormula: FormulaItem[] | null;
  customFormulaFormat: CustomFormulaFormat;
  calculateDynamicTotals: boolean;
  calculateRowTotals: boolean;
  reverseChangesFormatting: boolean;
  selectCustomFormulaId: number | string;
};

const initialState: FormulaState = {
  selectCustomFormulaId: null,
  customFormula: null,
  calculateDynamicTotals: false,
  calculateRowTotals: false,
  reverseChangesFormatting: false,
  customFormulaFormat: {
    formatting: 'NOMINAL',
    decimals: 0,
  },
};

export const formulaSlice = createSlice({
  name: 'formula',
  initialState,
  reducers: {
    setSelectCustomFormulaId: (state, action: PayloadAction<number | string>) => {
      state.selectCustomFormulaId = action.payload;
    },
    setCustomFormulaFormat: (state, action: PayloadAction<CustomFormulaFormat>) => {
      state.customFormulaFormat = action.payload;
    },
    updateCustomFormula: (state, action: PayloadAction<FormulaItem[] | null>) => {
      state.customFormula = action.payload;
    },
    setCustomFormula: (state, action: PayloadAction<FormulaItem[]>) => {
      state.customFormula = action.payload;
    },
    setCalculateDynamicTotals: (state, action: PayloadAction<boolean>) => {
      state.calculateDynamicTotals = action.payload;
    },
    setCalculateRowTotals: (state, action: PayloadAction<boolean>) => {
      state.calculateRowTotals = action.payload;
    },
    setReverseChangesFormatting: (state, action: PayloadAction<boolean>) => {
      state.reverseChangesFormatting = action.payload;
    },
  }
});

export const actions = formulaSlice.actions;

export const clearCustomFormula = () => (dispatch: AppDispatch) => {
  dispatch(actions.updateCustomFormula(null));
  dispatch(actions.setSelectCustomFormulaId(null));
};

export const changeSelectCustomFormulaId = (data: number | string) => (dispatch: AppDispatch) => {
  dispatch(actions.setSelectCustomFormulaId(data));
};

export const changeCustomFormulaFormat = (data: CustomFormulaFormat) => (dispatch: AppDispatch) => {
  dispatch(actions.setCustomFormulaFormat(data));
};

export const updateCustomFormula = (data: FormulaItem[]) => (dispatch: AppDispatch) => {
  dispatch(actions.updateCustomFormula(data));
};

export const updateFormula = (
  formula: FormulaItem[],
  nodeId: TemplateNodeId,
  config: Partial<FormulaNodeRowData> = {}
) => (dispatch: AppDispatch, getState: () => RootState) => {
  const customFormula = mapFormulaToNode(formula);
  const template = getState().template.present.template;
  const nodes = cloneDeep(template.nodes);
  const node = nodes[ nodeId ];
  if (isFormulaNode(node)) {
    node.rowData = {
      ...node.rowData,
      ...config,
      formulaElements: customFormula,
      formulaType: undefined
    };
  }
  dispatch(setTemplateNodes({ ...template, nodes }));
  dispatch(updateCustomFormula(null));
  dispatch(changeSelectCustomFormulaId(null));
};

export const swapOperator = (
  data: FormulaItem[],
  chooseElement: FormulaItem,
  operator: OperatorsType
) => (dispatch: AppDispatch) => {
  const editFormula = data.map(item => {
    if (item.key === chooseElement.key)
      return ({
        ...item, value: operator.value, id: operator.value
      });
    else return item;
  });
  dispatch(updateCustomFormula(editFormula));
};

export const deleteCustomFormulaElement = (data: FormulaItem[], chooseElement: FormulaItem) =>
  (dispatch: AppDispatch) => {
    if (data.length > 0) {
      const filterElements = data.filter(el => el !== chooseElement);
      const updatedFormula = filterElements.map((el, index) => {
        return {
          ...el,
          prev: index === 0 ? null : filterElements[ index - 1 ].type,
          next: filterElements.length === index + 1
            ? null
            : filterElements[ index + 1 ].type,
        };
      });

      dispatch(updateCustomFormula(updatedFormula));
    }
  };

export const setCustomFormulaOffset = (key: string, offset: FormulaPeriod | null) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const customFormula = getState().formula.customFormula;
    if (!customFormula) {
      return;
    }
    const tmpCustomFormula = customFormula.map(el => {
      if (el.key !== key) {
        return el;
      }
      return { ...el, offset };
    });
    dispatch(actions.setCustomFormula(tmpCustomFormula));
  };

export const setFormulaItemRollingAverage = (key: string, value: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const customFormula = getState().formula.customFormula;
    if (!customFormula) {
      return;
    }
    const tmpCustomFormula = customFormula.map(el => {
      if (el.key !== key) {
        return el;
      }
      return { ...el, rollingAverage: value };
    });
    dispatch(actions.setCustomFormula(tmpCustomFormula));
  };

export const toggleCalculateDynamicTotals = () =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const currentState = getState().formula.calculateDynamicTotals;
    dispatch(actions.setCalculateDynamicTotals(!currentState));
  };

export const toggleRowTotals = () =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const currentState = getState().formula.calculateRowTotals;
    dispatch(actions.setCalculateRowTotals(!currentState));
  };

export const toggleReverseChangesFormatting = () =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const currentState = getState().formula.reverseChangesFormatting;
    dispatch(actions.setReverseChangesFormatting(!currentState));
  };

export const assignPeriodToFormula = (key: string, period: FormulaPeriod | null) =>
  (dispatch: AppDispatch) => {
    dispatch(setCustomFormulaOffset(key, period));
  };

export const selectCustomFormulaId = (state: RootState) => state.formula.selectCustomFormulaId;
export const selectCustomFormulaFormat = (state: RootState) => state.formula.customFormulaFormat;
export const selectCalculateDynamicTotals =
    (state: RootState) => state.formula.calculateDynamicTotals;
export const selectCalculateRowTotals =
    (state: RootState) => state.formula.calculateRowTotals;
export const selectReverseChangesFormatting =
    (state: RootState) => state.formula.reverseChangesFormatting;

export const selectCustomFormula = (state: RootState) => state.formula.customFormula;

export const formulaReducer = formulaSlice.reducer;

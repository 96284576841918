import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import generatePicker from 'antd/lib/date-picker/generatePicker';
import i18n from 'locales/i18n';
import enGB from 'antd/lib/locale/en_GB';
import fi from 'antd/lib/locale/fi_FI';
import { DATE_FORMATS } from 'utils/date.utils';

import styles from './InstancesCalendar.module.scss';

interface Instance {
  date: string;
}

interface Props {
  instances?: Instance[];
}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const InstancesCalendar = ({ instances }: Props) => {
  const locale = useMemo(() => 
    i18n.language === 'en' ? enGB.DatePicker : fi.DatePicker ,[ i18n.language ]);

  const [ selectedValue, setSelectedValue ] = React.useState<Dayjs | null>(
    instances.at(0)?.date ? dayjs(instances.at(0)?.date) : dayjs()
  );

  useEffect(() => {
    setSelectedValue(instances.at(0)?.date ? dayjs(instances.at(0)?.date) : dayjs());
  }, [ instances ]);

  const dateFullCellRender = useCallback((value: Dayjs) => {
    const isInstance = instances.some(instance => value.isSame(instance.date, 'day'));
    return (
      <div className={ isInstance ? styles.instance : styles.cell }>
        { value.date() }
      </div>
    );
  }, [ instances ]);

  return <DatePicker
    className={ styles.datePicker }
    getPopupContainer={ target => target }
    format={ (val) => val.format(DATE_FORMATS.at(1)) }
    locale={ locale }
    open={ true }
    showToday={ false }
    inputRender={ () => null }
    bordered={ false }
    dateRender={ dateFullCellRender }
    value={ selectedValue }
    size='small'
  />;
};

export default InstancesCalendar;

import React from 'react';
import { useCommandBar } from 'components/commandBar/context/CommandBarContext';
import { useRegistryControl } from '../panelsContext';
import { SideHeaderProps, SidePanelHeader } from '../sidePanelHeader/SidePanelHeader';

interface ControlledSidePanelHeaderProps extends Omit<SideHeaderProps, 'tabs' | 'activeTabId'> {
  side: 'left' | 'right';
  includeTabs?: string[];
}

const LayoutSidePanelHeader = ({
  onChange,
  onClose,
  side,
  showOnlyTabs,
  hideTabs,
  includeTabs = []
}: ControlledSidePanelHeaderProps) => {
  const { state: { utils } } = useCommandBar();
  const { panels, activeLeftPanel, activeRightPanel } = useRegistryControl();

  const commandBarTabs = utils.filter(u => !u.hidden && !u.disabled).map((u) => u.key);
  const tabs = panels
    .filter(el => el.side === side)
    .filter(el => (commandBarTabs as ReadonlyArray<string>).includes(el.id));

  if (includeTabs.length) {
    includeTabs.forEach(tab => {
      if (!tabs.find(el => el.id === tab)) {
        const tabPanel = panels.find(el => el.id === tab);
        if (tabPanel) {
          tabs.push(tabPanel);
        }
      }
    });
  }

  return <SidePanelHeader
    activeTabId={ (side === 'right' ? activeRightPanel : activeLeftPanel) }
    onChange={ onChange }
    onClose={ onClose }
    tabs={ tabs }
    showOnlyTabs={ showOnlyTabs }
    hideTabs={ hideTabs }
  />;
};

export default LayoutSidePanelHeader;

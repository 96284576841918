import { Card, CardProps } from 'antd';
import React from 'react';

import styles from './LabelCard.module.scss';

interface LabelCardProps extends CardProps {
  children: React.ReactNode;
  count?: number;
}

const LabelCard = (props: LabelCardProps) => {

  const titleElement = (
    <div className={ styles.labelCardTitle }>
      <span>{ props.title }</span>
      { props.count ? <span className={ styles.labelCardCount }>{ props.count }</span> : null }
    </div>
  );

  return (
    <Card
      { ...props }
      className={ styles.labelCardHeader }
      title={ titleElement }
      bodyStyle={ { padding: '15px 10px' } }
    >
      { props.children }
    </Card>
  );
};

export default LabelCard;

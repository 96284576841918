import { isChartStacked } from './../chart/utils/chart.utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChartNode,
  ChartReport,
  ChartSettings,
} from 'types/chart.types';
import { groupRowsByTotal } from 'utils/chart.utils';
import useGrid from '../../../hooks/useGrid';
import { FinancialRow, Period, RowType } from 'types/financials.types';
import { useAppSelector } from 'store/hooks/hooks';
import useChartConfig from '../../../hooks/useChartConfig';
import { ReportDataValues, ReportNode } from 'types/templates.types';
import _, { isNumber } from 'lodash';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { useChartContext } from '../context/ChartContext';

interface Props {
  settings: ChartSettings;
  templateId: number;
  period: Period;
  cumulative: boolean;
}
const FILE_PATH_PARENT_INDEX = -2;

type BreakdownReportNode = ReportNode & {type: RowType.BREAKDOWN};

const useChartData = ({ settings, templateId, period, cumulative }: Props) => {
  const datasetsCount = settings.datasets.length;
  const { state: { templateNodeUuids } } = useChartContext();
  const { data: report } = useReportQuery({ templateId, period, templateNodeUuids });
  const dimensionLabels =
    useAppSelector(state => state.financials.options.dimensionLabels);
  const grid = useGrid({
    templateId,
    showDynamicColumns: false,
    period
  }, null);
  const [ rowData, setRowData ] = useState<FinancialRow[]>([]);
  const { getChartData } = useChartConfig();

  useEffect(() => {
    if (!report) return undefined;

    const res = grid.getRows(report);
    setRowData(res.rows);
  }, [ report ]);

  const getFinancialItemBreakdownChildren = useCallback((
    node: ReportNode,
    stacks: number
  ) => {
    const breakdownNodeId = dimensionLabels ? node.children[ 0 ] : node.id;
    const breakdownChildren = getChildrenRowData(+breakdownNodeId);

    return groupRowsByTotal(breakdownChildren, stacks);
  }, [ dimensionLabels, rowData ]);

  const getChildrenRowData = useCallback((
    nodeId: number,
    index = FILE_PATH_PARENT_INDEX
  ) => {
    return rowData.filter(r => {
      const parentId = +r.filePath.at(index);
      return parentId === nodeId;
    });
  }, [ rowData ]);

  const getBreakdownIdsFromData = useCallback((data: ReportDataValues) => {
    const ids = [];
    Object.keys(data).forEach(key => {
      if (isNumber(+key) && !isNaN(+key)) {
        ids.push(+key);
      }
    });
    return ids;
  }, []);

  const getChildrenRowDataFromBreakdown = useCallback((hiddenNode: BreakdownReportNode) => {
    const children = [];
    const data = {
      ...hiddenNode.data.actual,
      ...hiddenNode.data.plan,
    };
    const breakdownIds = getBreakdownIdsFromData(data);
    const dimension = hiddenNode.rowData.id;
    breakdownIds.forEach(id => {
      children.push(...getChildrenRowData(id, dimension));
    });

    return children;
  }, [ getChildrenRowData ]);

  const getBreakdownItemChildren = useCallback((
    node: BreakdownReportNode,
    stacks: number
  ) => {
    const breakdownNodeId = +node.id;
    if (dimensionLabels) {
      const breakdownChildren = getChildrenRowData(breakdownNodeId);
      return groupRowsByTotal(breakdownChildren, stacks);
    }

    const breakdownChildren = getChildrenRowDataFromBreakdown(node);
    return groupRowsByTotal(breakdownChildren, stacks);
  }, [ dimensionLabels, rowData ]);

  const getNodes = useCallback((index: number): ChartNode[] => {
    if (!settings.datasets[ index ].active || !report?.nodes) return [];

    const node = report.nodes.find(
      n => n.uuid === settings.datasets[ index ].templateNode);
    if (!node) return [];

    const nodes: ChartNode[] = [ node ];

    const isBreakdownActive =
      settings.datasets[ index ].style !== 'bar' &&
      !settings.datasets[ index ].style.startsWith('line');
    if (isBreakdownActive) {
      if (isChartStacked(settings.datasets[ index ].style)) {
        nodes.pop();
      }
      const stacks = settings.datasets[ index ].stacks;
      if (node.type === RowType.BREAKDOWN) {
        nodes.push(...getBreakdownItemChildren(node, stacks));
      } else {
        nodes.push(...getFinancialItemBreakdownChildren(node, stacks));
      }
    }
    return nodes;
  }, [ templateId, report, settings, rowData, dimensionLabels ]);

  const data: ChartReport = useMemo(() => {
    const _data = Array.from({ length: datasetsCount }).map(
      (_d, index) => getChartData(
        getNodes(index), settings.datasets[ index ].datePickerSource)
    );
    if (cumulative) {
      return _data.map((dataset) => {
        const grouped = _.groupBy(dataset, 'type');
        const flatGroupData = [];
        for (const key in grouped) {
          const cumulated = [];
          let total = 0;
          for (const value of grouped[ key ]) {
            total += value.value;
            cumulated.push({ ...value, value: total });
          }
          flatGroupData.push(...cumulated);
        }
        return flatGroupData;
      });
    }

    return _data;

  }, [ getNodes, settings, cumulative ]);

  return {
    data
  };
};

export default useChartData;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'locales/i18n';

import { ErrorMessage } from 'types/form.types';
import { getValidationRules } from 'utils/form.utils';
import { routes } from 'utils/config.utils';
import { resetPassword } from 'services/auth.service';

import Input from 'components/elements/input/Input';
import Button from 'components/elements/button/Button';
import AuthCard from 'components/auth/authCard/AuthCard';
import Loader from 'components/elements/loader/Loader';

import styles from './ForgotPassword.module.scss';

const inputs = [
  {
    name: 'email',
    placeholder: 'name@company.com',
    label: i18n.t('input-labels.email', { ns: 'auth' }),
    rules: getValidationRules(
      { required: true, maxLength: 255, isEmail: true },
      i18n.t('input-labels.email', { ns: 'auth' }),
    ),
  }
];

const ForgotPassword = () => {
  const { control, handleSubmit, formState: { errors } } = useForm( { mode: 'all' } );
  const [ errorMessage, setErrorMessage ] = useState<ErrorMessage>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ t ] = useTranslation( 'auth');

  const onSubmit = (values) => {
    setIsLoading(true);
    resetPassword(values)
      .then(() => {
        setErrorMessage({ 
          message:  t('notifications.info', { ns: 'common' }),
          description: t('notifications.forgot-password.message'),
          type: 'info' 
        });
      })
      .catch(({ response }) => {
        if (response?.data?.email?.[ 0 ]) 
          setErrorMessage({ 
            message: response.data.email[ 0 ], 
            type: 'error' 
          });
        else 
          setErrorMessage({ 
            message: t('notifications.unexpected-error.message', { ns: 'common' }), 
            type: 'error' 
          });
      })
      .finally(() => setIsLoading(false));
  };
  
  useEffect(() => {
    const error = Object.values(errors)?.[ 0 ];
    if (error) {
      setErrorMessage({ message: error.message as string, type: 'error' });
    } else {
      errorMessage && setErrorMessage(null);
    }
  }, [ errors.email ]);
  
  return (
    <AuthCard containerClassName={ styles.container }>

      <h1 className={ styles.header }>
        { t('page.forgot-password.title') }
      </h1>
      <p className={ styles.text }>
        { t('page.forgot-password.description') }
      </p>
      <form 
        onSubmit={ handleSubmit(onSubmit) }
        className={ styles.form }
      >
        { errorMessage && (
          <Alert
            message={ errorMessage.message }
            description={ errorMessage.description }
            type={ errorMessage.type }
            showIcon
            className={ styles.alert }
          />
        ) }
        { inputs.map(input => (
          <Input 
            key={ input.name }
            control={ control }
            errors={ errors }
            className={ styles.input }
            { ...input }
          />
        )) }
        <Button 
          name= { t('buttons.reset-password') }
          className={ styles.button }
          htmlType='submit'
        />
        <Link to={ routes.login } className={ styles.link }>
          { t('buttons.login') }
        </Link>
      </form>
      <Link to='/' className={ `${ styles.link } ${ styles.bottom }` }>
        { t('buttons.contact') }
      </Link>
      <Loader isActive={ isLoading }/>
    </AuthCard>
  );
};

export default ForgotPassword;

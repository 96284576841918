import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { ContextMenuItem, ContextMenuType } from './contextMenu.types';
import { ReactComponent as NextIcon } from 'assets/icons/small-arrow.svg';
import { ReactComponent as ActiveIcon } from 'assets/icons/check.svg';
import styles from './ContextMenu.module.scss';

const DEFAULT_TYPE = ContextMenuType.FORMULA_ITEM;

type BaseProps = {
  items: ContextMenuItem[];
  children: React.ReactNode;
  overlayClassName?: string;
  itemClassName?: string;
  offset?: number[];
};

type MenuProps = BaseProps & {
  isVisible?: boolean;
  onVisibleChange?: (isVisible: boolean) => void;
  type?: ContextMenuType;
  trigger?: 'contextMenu' | 'click';
};

type SubMenuProps = BaseProps & {
  onHide: () => void;
};

type ContentProps = Omit<SubMenuProps, 'children'> & {
  type?: ContextMenuType;
};

const content = ({
  items,
  onHide,
  type = DEFAULT_TYPE,
  overlayClassName='',
  itemClassName=''
}: ContentProps) => {
  return items.map((item) => (
    <ContextSubMenu
      overlayClassName={ overlayClassName }
      itemClassName={ itemClassName }
      items={ item?.subMenu }
      key={ item.name }
      onHide={ onHide }
    >
      <div
        className={ `
        ${ type === ContextMenuType.CAPSULE ?
      styles.contextMenuItemCapsule : styles.contextMenuItem }
        ${ item.active ? styles.active : '' }
        ${ item.disabled ? styles.inactive : '' }
        ${ item.suppressHide ? styles.suppressHide : '' }
        ${ itemClassName }` }
        onClick={ () => {
          if (item.disabled) return;

          item.action && item.action(item);
          !item.suppressHide && onHide();
        } }>
        <div className={ styles.name }>
          { item.icon ? item.icon : null }
          { item.name }
        </div>
        {
          item.render ? item.render() : <div>
            { item.subMenu && item.subMenu.length > 0 ?
              <NextIcon className={ styles.nextIcon } /> : null }
            { item.active ? <ActiveIcon className={ styles.activeIcon } /> : null }
          </div>
        }
      </div>
    </ContextSubMenu>
  )
  );
};

const ContextSubMenu = ({
  items,
  children,
  onHide,
  overlayClassName,
  itemClassName,
}: SubMenuProps) => {
  if (!items?.length) return <>{ children }</>;

  return <Popover
    align={ {
      offset: [ 5, 0 ],
      ignoreShake: true
    } }
    placement='rightTop'
    trigger='hover'
    content={ content({ items, onHide, overlayClassName, itemClassName }) }
    destroyTooltipOnHide={ true }
    overlayClassName={ `${ styles.contextMenu } ${ styles.contextSubMenu } ${ overlayClassName }` }
  >
    { children }
  </Popover>;
};

const ContextMenu = ({ items,
  isVisible,
  onVisibleChange,
  children,
  type,
  trigger='contextMenu',
  overlayClassName='',
  itemClassName='',
  offset=[ 20, 2 ]
}: MenuProps) => {
  const [ open, setOpen ] = useState(isVisible);

  useEffect(() => {
    setOpen(isVisible);
  }, [ isVisible ]);

  return (
    <Popover
      align={ {
        offset,
        ignoreShake: true
      } }
      open={ open }
      onOpenChange={ () => {
        onVisibleChange?.(!isVisible);
        setOpen(!open);
      } }
      placement='bottomLeft'
      content={ content({
        items,
        onHide: () => {
          onVisibleChange?.(false);
          setOpen(false);
        },
        type,
        overlayClassName,
        itemClassName
      }) }
      trigger={ trigger }
      destroyTooltipOnHide={ true }
      overlayClassName={ `
          ${ styles.contextMenu }
          ${ !items.length ? styles.contextMenuEmpty : '' }
          ${ overlayClassName }
          ` }>
      { children }
    </Popover>
  );
};

export default ContextMenu;

import React from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/group.svg';
import { ReactComponent as ColumnsIcon } from 'assets/icons/column.svg';
import { ReactComponent as LayoutIcon } from 'assets/icons/layout.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/magic.svg';
import { ReactComponent as Budget } from 'assets/icons/budget.svg';
import { ReactComponent as OverviewIcon } from 'assets/icons/text-lens.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/filter.svg';

import i18n from 'i18next';

export const LEFT_PANEL_PORTAL_ID = 'left-panel-portal';
export const RIGHT_PANEL_PORTAL_ID = 'right-panel-portal';

export const sideTabs = ({
  filter: {
    id: 'filter',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.filter'),
    tags: [ 'filter' ],
    icon: <FilterIcon height={ 24 } width={ 24 } />
  },
  label: {
    id: 'label',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.label'),
    tags: [ 'label' ],
    icon: <LabelIcon height={ 24 } width={ 24 } />
  },
  sort: {
    id: 'sort',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.sort'),
    tags: [ 'sort' ],
    icon: <SortIcon height={ 24 } width={ 24 } />
  },
  group: {
    id: 'group',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.group'),
    tags: [ 'group' ],
    icon: <GroupIcon height={ 24 } width={ 24 } />
  },
  columns: {
    id: 'columns',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.columns'),
    tags: [ 'columns' ],
    icon: <ColumnsIcon height={ 24 } width={ 24 }/>
  },
  layout:{
    id: 'layout',
    side: 'left',
    title: i18n.t('financials:left-panel.layout-control.title'),
    tags: [ 'layout' ],
    icon: <LayoutIcon height={ 24 } width={ 24 } />
  },
  overviewDetails: {
    id: 'overviewDetails',
    side: 'right',
    title: i18n.t('common:left-panel.navigation.overview'),
    tags: [ 'overview' ],
    icon: <OverviewIcon height={ 24 } width={ 24 }/>
  },
  chat: {
    id: 'chat',
    side: 'right',
    title: i18n.t('common:left-panel.navigation.chat'),
    tags: [ 'chat' ],
    icon: <ChatIcon height={ 24 } width={ 24 }/>
  },
  budget: {
    id: 'budget',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.budget'),
    tags: [ 'budget' ],
    icon: <Budget height={ 24 } width={ 24 }/>
  },
  settings: {
    id: 'settings',
    side: 'left',
    title: i18n.t('common:left-panel.navigation.settings'),
    tags: [ 'settings' ],
    icon: <SettingsIcon height={ 24 } width={ 24 } />
  }
} as const);

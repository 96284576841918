import {
  Dispatch,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { SelectionChangedEvent, ValueSetterParams, } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import type {
  Action,
  HeaderTool,
  Redirect,
  TableColDef,
  TableHookProps,
} from 'components/elements/tableWrapper/types/table.types';
import type { Period } from 'types/financials.types';
import type { BudgetItem, BudgetItemType } from 'types/budget.types';
import { planningService } from 'services/planning.service';
import { updateDetailedViewSettings } from 'store/financials.slice';
import { updateBudgetGeneration } from 'store/budget.slice';
import { useAppDispatch } from 'store/hooks/hooks';
import useBudgetItemTable from 'components/budget/budgetItemTable/hooks/useBudgetItemTable';
import useBudgetItems, {
  InternalBudgetItem
} from 'components/budget/budgetItemTable/hooks/useBudgetItems';
import BudgetItemTypeDropdown
  from 'components/budget/budgetItemTypeDropdown/BudgetItemTypeDropdown';
import styles from 'components/financials/detailedView/table/DetailedViewTable.module.scss';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';
import { ReactComponent as AllVisible } from 'assets/icons/all-visible.svg';
import { ReactComponent as Regenerate } from 'assets/icons/refresh.svg';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import useDetailedView from '../../../financials/financialTable/hooks/useDetailedView';
import useMasterDetailSplits from './useMasterDetailSplits';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-bin.svg';
import Modal from '../../../elements/modal/Modal';

interface Props {
  gridRef: RefObject<AgGridReact>;
  period: Period;
  search: string;
  budgetItemType: BudgetItemType;
  setRedirect: Dispatch<Redirect>;
  setIsAnySelected: MutableRefObject<() => void>;
  onAddBudgetItem: (budgetItem: BudgetItem) => void;
  onBudgetItemsUpdated?: () => void;
}

interface BudgetItemTableProps {
  canApply: boolean;
  onClose: () => void;
  update: (budgetItem: BudgetItem) => void;
  clearChanges: () => void;
}

const useBudgetItemTableProps = ({
  gridRef,
  period,
  search,
  budgetItemType,
  setRedirect,
  setIsAnySelected,
  onAddBudgetItem,
  onBudgetItemsUpdated,
}: Props): TableHookProps<InternalBudgetItem, BudgetItemTableProps> => {
  const [ t ] = useTranslation('tableWrapper');
  const dispatch = useAppDispatch();
  const [ canDuplicate, setCanDuplicate ] = useState(false);
  const [ canDelete, setCanDelete ] = useState(false);
  const [ isDeleteModalVisible, setIsDeleteModalVisible ] = useState(false);
  const [ selectedCount, setSelectedCount ] = useState(0);

  const onDelete = useCallback(() => {
    setIsDeleteModalVisible(true);
  }, []);

  const budgetItemDeleteName = useMemo(() => {
    return t('common:terms.budgetItem', { count: selectedCount });
  }, [ selectedCount ]);

  const masterDetailProps = useMasterDetailSplits({ search });

  const {
    items,
    setItems,
    loading,
    fetchBudgetItems,
    fetchBudgetItemsByItemType,
    isDataFetched,
    update,
    remove,
    clear,
    duplicate,
    addItem,
    updating,
    onUpdate,
    canApply,
    clearChanges
  } = useBudgetItems({ period, onBudgetItemsUpdated });

  useDetailedView({
    fetchBudgetItems: fetchBudgetItems,
    fetchBudgetItemsByItemType: fetchBudgetItemsByItemType,
    setBudgetItems: setItems,
  });

  useEffect(() => {
    // Slight timeout to ensure modal is fully open before focusing on the first empty cell
    setTimeout(
      () =>{
        if (isDataFetched && budgetItemType) {
          onSelectBudgetItemType(budgetItemType.id);
        }
      }, 200);

  }, [ isDataFetched, budgetItemType ]);

  const {
    budgetColumnDefs,
    dimensionColumnDefs,
    budgetSettingsColumnDefs,
    redirectColDef,
  } = useBudgetItemTable({
    search,
    updateBudgetItem: update,
    setRedirect
  });

  const focusFirstEmptyCell = useCallback(() => {
    if (!gridRef.current) {
      return;
    }
    const columns = gridRef.current.api.getAllDisplayedColumns();
    const newRow = gridRef.current.api.getRenderedNodes()[ 0 ];
    if (!newRow) {
      return;
    }
    const firstEmptyCell = columns.find(col => {
      return !newRow.data?.[ col.getColId() ] &&
        !col.isSuppressNavigable(newRow) &&
        col.getColDef().headerName;
    });
    const cellToStartEdit = firstEmptyCell || columns[ 0 ];
    gridRef.current.api.setFocusedCell(0, cellToStartEdit.getColId());
    gridRef.current.api.startEditingCell({
      rowIndex: 0,
      colKey: cellToStartEdit.getColId(),
    });
  }, []);

  const onSelectBudgetItemType = useCallback((id: number) => {
    const newItem = addItem(id);
    onAddBudgetItem(newItem);
    setTimeout(() => {
      focusFirstEmptyCell();
    }, 300);
  }, [ addItem ]);

  const budgetValueSetter = useCallback((params: ValueSetterParams<BudgetItem>) => {
    if (!params.colDef.field) return false;

    const budgetItem: BudgetItem = {
      ...params.data,
      [ params.colDef.field ]: params.newValue,
    };
    update(budgetItem);

    return params.newValue !== params.oldValue;
  }, []);

  const onClose = useCallback(() => {
    clear();
  }, []);

  const onDuplicate = useCallback(() => {
    const selectedRow = gridRef.current?.api.getSelectedNodes()[ 0 ];
    if (!selectedRow) {
      return;
    }

    duplicate(selectedRow.data);
    selectedRow.setSelected(false);
  }, []);

  const onDeleteClose = useCallback(() => {
    setTimeout(() => setIsDeleteModalVisible(false));
  }, []);

  const onBudgetItemDelete = useCallback(async () => {
    const nodes = gridRef.current?.api.getSelectedNodes();
    if (!nodes?.length) {
      return;
    }
    nodes.forEach(async (node) => {
      if (node.data.internalId) {
        return remove(node.data.internalId);
      }
      remove(node.data.id);
    }, []);
    const nodeIds = nodes.flatMap(node => node.data.id ? [ node.data.id ] : []);

    if (!nodeIds.length) {
      return;
    }

    dispatch(updateBudgetGeneration({
      status: 'queued',
    }));
    await planningService.bulkDeleteBudgetItem(nodeIds);
    setIsDeleteModalVisible(false);
  }, []);

  const budgetHeaderTools: HeaderTool[] = useMemo(() => {
    return [
      {
        key: 'duplicate',
        button: <GhostIconButton
          size='middle'
          disabled={ !canDuplicate }
          className={ styles.editableTool }
          onClick={ onDuplicate }
          tooltip={ t('common:form.duplicate') }
          type='text'
        >
          <DuplicateIcon />
        </GhostIconButton>,
        buttonPosition: 6,
      },
      {
        key: 'delete-row',
        button: <GhostIconButton
          size='middle'
          disabled={ !canDelete }
          className={ styles.editableTool }
          onClick={ onDelete }
          tooltip={ t('financials:context-menu.delete-row') }
          type='text'
        >
          <DeleteIcon />
          <Modal
            title={ t('common:delete-thing-question', { thing: budgetItemDeleteName }) }
            isVisible={ isDeleteModalVisible }
            onConfirm={ onBudgetItemDelete }
            onClose={ onDeleteClose }
            okText={ t('common:delete') }
            okButtonProps={ { danger: true } }
          />
        </GhostIconButton>,
        buttonPosition: 7,
      },
      {
        key: 'add-input-row',
        button: <BudgetItemTypeDropdown onSelect={ onSelectBudgetItemType } />,
        buttonPosition: 8,
      }
    ];
  }, [ onUpdate, canApply, updating, onSelectBudgetItemType, canDuplicate, onBudgetItemDelete,
    canDelete, isDeleteModalVisible, onDeleteClose ]);

  const columnDefs = useMemo(() => {
    return [
      redirectColDef,
      ...budgetColumnDefs,
      ...budgetSettingsColumnDefs.map(col => ({ ...col, initialHide: true })),
      ...dimensionColumnDefs.map(col => ({ ...col, initialHide: true }))
    ];
  }, [ dimensionColumnDefs, budgetColumnDefs, budgetSettingsColumnDefs ]);

  const defaultColDef: TableColDef = useMemo(() => {
    return { valueSetter: budgetValueSetter };
  }, [ budgetValueSetter ]);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    setSelectedCount(selectedRows.length);
    setCanDuplicate(selectedRows.length === 1);
    setCanDelete(selectedRows.length > 0);
    setIsAnySelected?.current?.();
  }, []);

  const setPeriodToFetchAllBudgetItems = useCallback(() => {
    dispatch(updateDetailedViewSettings({ period: {
      ...period,
      fullRange: true,
    } }));
  }, [ period ]);

  const regenerateBudgetItems = useCallback(async () => {
    dispatch(updateBudgetGeneration({
      status: 'queued',
    }));
    const nodes = gridRef.current.api.getSelectedNodes();
    if (!nodes.length) {
      return;
    }
    // In order to regenerate budget items we just need to run update without any changed data
    const nodeIds = nodes.flatMap(node => node.data.id ? [ node.data.id ] : []);
    await planningService.regenerateBudgetItems({ ids: nodeIds });

  }, []);

  const actions: Action[] = useMemo(() => [
    {
      label: t('common:form.apply'),
      onClick: () => {
        // Prevents weird behaviour when clicking apply button while still editing a cell.
        gridRef.current?.api.stopEditing();
        onUpdate();
      },
      disabled: !canApply,
      icon: null,
    },
    {
      label: t('budget-table.actions.list-all'),
      onClick: setPeriodToFetchAllBudgetItems,
      icon: <AllVisible />
    },
    {
      label: t('budget-table.actions.regenerate'),
      onClick: regenerateBudgetItems,
      disabled: selectedCount === 0,
      icon: <Regenerate/>,
    }
  ], [ canApply, selectedCount, onUpdate ]);

  return useMemo(() => ({
    table: {
      rowData: items,
      isLoading: loading,
      headerTools: budgetHeaderTools,
      defaultColDef: defaultColDef as TableColDef<InternalBudgetItem>,
      columnDefs: columnDefs as TableColDef<InternalBudgetItem>[],
      onSelectionChanged: onSelectionChanged,
      tableKey: 'detailed-view-budget-items',
      actions,
      rowModelType: 'clientSide',
      ...masterDetailProps
    },
    other: {
      onClose,
      canApply,
      update,
      clearChanges
    }
  }), [
    items,
    loading,
    budgetHeaderTools,
    columnDefs,
  ]);
};

export default useBudgetItemTableProps;

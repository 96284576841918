import { isEqual } from 'lodash';
import React from 'react';
import { RowType } from 'types/financials.types';
import {
  FormulaNodeRowData,
  isBreakdownNode,
  isDimensionItemNode,
  isFinancialsNode,
  Template,
  TemplateActionImmutableType,
  TemplateActionType,
  TemplateNode,
  TemplateRequest,
  UUID,
} from 'types/templates.types';
import { getDisplayName } from './common.utils';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'i18next';
import { Dimension } from '../types/filterTable.types';

export const newTemplate: Template = {
  id: undefined,
  title: '',
  subtitle: '',
  roots: [],
  nodes: {}
};

export const getTemplateStyle = (node: TemplateNode): React.CSSProperties => {
  if (isFinancialsNode(node)) {
    return {
      fontWeight: `${ node.rowData?.bold ? '500' : 'normal' }`,
      textTransform: `${ node.rowData?.capitalized ? 'uppercase' : 'none' }`
    };
  }

  return {
    fontWeight: 'normal',
    textTransform: 'none'
  };
};

export const parseTemplateRequestData = ({ nodes, ...rest }: Template): TemplateRequest => {
  const isUnassignedNode = (n: TemplateNode):
    n is TemplateNode & { type: RowType.DIMENSION_ITEM } => {
    return isDimensionItemNode(n) && n.rowData.dimensionId && !n.rowData.id;
  };

  const processedNodes = Object.values(nodes).map(n => {
    if (isBreakdownNode(n)) {
      return {
        ...n,
        rowData: {
          ...n.rowData,
          items: []
        }
      };
    }
    // We parse-back the unassigned dimension item we created locally to
    // proper unassigned node type backend stores.
    if (isUnassignedNode(n)) {

      return {
        ...n,
        type: RowType.UNASSIGNED as const,
        rowData: {
          name: 'Unassigned',
          id: n.rowData.dimensionId
        } as Dimension
      };
    }

    return n;
  });
  return {
    ...rest,
    nodes: processedNodes
  };
};

export const isActionType = (type: RowType) => type in TemplateActionType;

export const isImmutableActionType = (type: RowType) => type in TemplateActionImmutableType;

export const isOtherTag = (type: string) => layoutTags.find(
  tag => tag.type === type) != null;

export const tagNamesComparator = (tag: TemplateNode, searchValue: string) => {
  let name = getDisplayName(tag.rowData.name);
  if (isDimensionItemNode(tag)) {
    if (tag.rowData.relation === 'ACCOUNT') {
      name = `${ tag.rowData.account.number } ${ tag.rowData.customName }`;
    } else {
      name = tag.rowData.customName;
    }
  }
  return name.toLowerCase().includes(searchValue);
};

export const deepTagComparator = (tag: TemplateNode, searchValue: string) => {
  let results = 0;
  if (tagNamesComparator(tag, searchValue) && tag.type !== RowType.BREAKDOWN_GROUP) {
    results = 1;
  }
  if (tag.childrenNodes?.length) {
    tag.childrenNodes.forEach((childTag) => {
      results += deepTagComparator(childTag, searchValue);
    });
  }
  return results;
};

export const isTemplateClear = (template: Template) => {
  return isEqual(template, newTemplate) && !template.title;
};

export const getUUID = uuidv4 as () => UUID;

const defaultFormulaRowData: FormulaNodeRowData = {
  id: null,
  name: '',
  formulaElements: [],
  formatting: 'NOMINAL',
  calculateDynamicTotals: false,
  calculateRowTotals: false,
  reverseChangesFormatting: false,
  decimals: 0,
};

export const getDefaultRowName = (type: RowType) => {
  switch (type) {
    case RowType.TOTAL:
      return t('templates.default-total-name', { ns: 'financials' });
    case RowType.SUBTOTAL:
      return t('templates.default-subtotal-name', { ns: 'financials' });
    case RowType.FORMULA:
      return t('templates.default-formula-name', { ns: 'financials' });
    case RowType.GROUP:
      return t('templates.default-group-name', { ns: 'financials' });
    case RowType.TITLE:
      return t('templates.default-title-name', { ns: 'financials' });
    case RowType.SPACER:
      return t('templates.default-spacer-name', { ns: 'financials' });
    default:
      return '';
  }
};

export const formulasTags: TemplateNode[] = [
  {
    id: 1,
    uuid: getUUID(),
    children: [],
    childrenNodes: [],
    rowData: {
      ...defaultFormulaRowData,
      name: getDefaultRowName(RowType.TOTAL),
      formulaType: 'total',
    },
    type: RowType.FORMULA,
  },
  {
    id: 2,
    uuid: getUUID(),
    children: [],
    childrenNodes: [],
    rowData: {
      ...defaultFormulaRowData,
      name: getDefaultRowName(RowType.SUBTOTAL),
      formulaType: 'subtotal',
    },
    type: RowType.FORMULA,
  },
  {
    id: 3,
    uuid: getUUID(),
    children: [],
    childrenNodes: [],
    rowData: {
      ...defaultFormulaRowData,
      name: getDefaultRowName(RowType.FORMULA),
    },
    type: RowType.FORMULA,
  },
];

export const oldFormulasTags: TemplateNode[] = [
  {
    ...formulasTags[ 0 ],
    type: RowType.TOTAL,
    rowData: {
      name: getDefaultRowName(RowType.TOTAL),
    }
  },
  {
    ...formulasTags[ 1 ],
    type: RowType.SUBTOTAL,
    rowData: {
      name: getDefaultRowName(RowType.SUBTOTAL),
    }
  },
  {
    ...formulasTags[ 2 ],
    type: RowType.FORMULA,
    rowData: {
      ...defaultFormulaRowData,
      name: getDefaultRowName(RowType.FORMULA),
    }
  },
];

export const layoutTags: TemplateNode[] = [
  {
    id: 21,
    uuid: getUUID(),
    children: [],
    childrenNodes: [],
    rowData: {
      name: getDefaultRowName(RowType.TITLE),
    },
    type: RowType.TITLE,
  },
  {
    id: 22,
    uuid: getUUID() as UUID,
    children: [],
    childrenNodes: [],
    rowData: {
      name: getDefaultRowName(RowType.GROUP),
    },
    type: RowType.GROUP,
  },
  {
    id: 23,
    uuid: getUUID() as UUID,
    children: [],
    childrenNodes: [],
    rowData: {
      name: getDefaultRowName(RowType.SPACER),
    },
    type: RowType.SPACER,
  },
];

import { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';

import generatePicker from 'antd/lib/date-picker/generatePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import Label from 'components/elements/form/label/Label';
import { Budget } from 'types/budget.types';
import Modal from 'components/elements/modal/Modal';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  useCreateBudgetMutation,
  useDeleteBudgetMutation,
  useDuplicateBudgetMutation,
  usePatchBudgetMutation
} from 'store/api/planning.api';
import Button from 'components/elements/button/Button';
import BurgerDropdown, { BurgerOption } from 'components/elements/burgerDropdown/BurgerDropdown';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-bin.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate-file.svg';

import styles from './BudgetDetails.module.scss';
import { setActiveBudget } from 'store/budget.slice';
import { useTranslation } from 'react-i18next';
import FormMessage from 'components/elements/form/formMessage/FormMessage';
import { ErrorMessage } from '@hookform/error-message';
import { DATE_FORMATS } from 'utils/date.utils';
import useDatePickerLocale from 'hooks/datePickerLocale';
import { handleError } from 'components/budget/utils/budget.utils';

interface BudgetForm {
  name: string;
  startDate: Dayjs;
  endDate: Dayjs;
  status: string;
}

interface Props {
  budget: Budget | undefined;
  budgets: Budget[];
  onBudgetSelect: (budgetId: number | null) => void;
}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const BudgetDetails = ({ budgets, budget, onBudgetSelect }: Props) => {
  const [ t ] = useTranslation('budget');

  const [ deletingBudget, setDeletingBudget ] = useState(false);
  const activeBudget = useAppSelector((state) => state.budget.activeBudgetId);
  const appDispatch = useAppDispatch();

  const [ createBudget, { isLoading: isCreating } ] = useCreateBudgetMutation();
  const [ deleteBudget, { isLoading: isDeleting } ] = useDeleteBudgetMutation();
  const [ patchBudget, { isLoading: isPatchLoading } ] = usePatchBudgetMutation();
  const [ duplicateBudget ] = useDuplicateBudgetMutation();

  const isLoading = isCreating || isPatchLoading;

  const locale = useDatePickerLocale();

  const isActive = budget?.id === activeBudget;
  const areOtherExisting = budgets?.length > 1;

  const confirmDelete = async () => {
    if (!budget) return;
    try {
      await deleteBudget(budget.id);

      setDeletingBudget(false);
      onBudgetSelect(null);
    } catch (error) {
      handleError(error);
    }
  };

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<BudgetForm>({
    defaultValues: {
      name: budget?.name,
      startDate: budget?.startDate ?? dayjs().startOf('month'),
      endDate: budget?.startDate ?
        dayjs(budget.startDate).add(budget.durationMonths ?? 1, 'months').endOf('month') :
        dayjs().endOf('month').add(1, 'months'),
      status: budget?.status ?? 'approved'
    }
  });

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const setBudgetAsActive = () => {
    if (!budget) return;
    appDispatch(setActiveBudget(budget));
  };

  const createBudgetDuplicate = async () => {
    if (!budget) return;
    try {
      const result = await duplicateBudget(budget.id).unwrap();

      onBudgetSelect(result.id);
    } catch (error) {
      handleError(error);
    }
      
  };

  const menu: BurgerOption[] = [
    {
      title: t('panel.setAsActive'),
      icon: <CheckIcon />,
      onClick: () => setBudgetAsActive(),
      disabled: isActive
    },
    {
      title: t('common:delete'),
      icon: <DeleteIcon />,
      onClick: () => setDeletingBudget(true),
      disabled: !areOtherExisting || isActive
    },
    {
      title: t('common:form.duplicate'),
      icon: <DuplicateIcon />,
      onClick: () => createBudgetDuplicate(),
    }
  ];

  useEffect(() => {
    reset({
      name: budget?.name,
      startDate: budget?.startDate ?? dayjs().startOf('month'),
      endDate: budget?.startDate ?
        dayjs(budget.startDate).add(budget.durationMonths ?? 1, 'months').endOf('month') :
        dayjs().endOf('month').add(1, 'months'),
      status: budget?.status ?? 'approved'
    });
  }, [ budget ]);

  const submit = async (data: BudgetForm) => {
    if (!budget) return;
    if (budget.id === 0) {
      try {
        const result = await createBudget({
          name: data.name,
          startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
          durationMonths: dayjs(data.endDate).diff(data.startDate, 'month'),
          status: data.status as 'draft' | 'approved'
        }).unwrap();

        onBudgetSelect(result.id);
        
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        await patchBudget({ id: budget.id, data: {
          name: data.name,
          startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
          durationMonths: dayjs(data.endDate).diff(data.startDate, 'month'),
          status: data.status as 'draft' | 'approved'
        } }).unwrap();
        
      } catch (error) {
        handleError(error);
      }
    }
  };

  const statusOptions = useMemo(() => {
    return [
      { value: 'draft', label: t('panel.form.statusOptions.draft') },
      { value: 'approved', label: t('panel.form.statusOptions.approved') },
    ];
  }, []);

  return <div className={ styles.container }>
    <div className={ styles.navigation }>
      <Button
        type='link'
        icon={ <ChevronRight className={ styles.backChevron } /> }
        onClick={ () => onBudgetSelect(null) }
      >
        { t('common:form.back') }
      </Button>
    </div>
    <div className={ styles.panelHeader }>
      <span className={ styles.budgetName }>
        { budget?.name }
      </span>

      <BurgerDropdown
        contentClassName={ styles.burgerDropdownContent }
        options={ menu }
      />
    </div>
    <Form className={ styles.form } onFinish={ handleSubmit(submit) }>
      <div>
        <Controller
          name='name'
          rules={ {
            required: t('common:validation.required') as string,
          } }
          control={ control }
          render={ ({ field }) => (
            <Label label={ t('panel.form.name') }>
              <Input { ...field } status={ errors?.[ 'name' ] ? 'error' : undefined } />
            </Label>
          ) }
        />
      </div>
      <FormMessage className={ styles.formMessage }>
        <ErrorMessage errors={ errors } name='name' />
      </FormMessage>
      <div className={ styles.dates }>
        <Controller
          name='startDate'
          rules={ {
            validate: {
              validDate: (v) => validateDate(v)
                || `${ t('financials:datePicker.errors.valid-date') }`,
              validStartDate: v => validStartDate(v, endDate)
                || `${ t('financials:datePicker.errors.start-date-is-later') }`
            }
          } }
          control={ control }
          render={ ({ field: { value, onChange } }) => (
            <Label label={ t('panel.form.startDate') } className={ styles.halfWidth }>
              <DatePicker
                picker='month'
                value={ dayjs(value) ?? undefined }
                locale={ locale }
                format={ DATE_FORMATS.at(1) }
                status={ errors?.[ 'startDate' ] ? 'error' : undefined }
                onChange={ (newValue) => {
                  if (!newValue) return undefined;
                  onChange(dayjs(newValue).startOf('month'));
                } }
              />
            </Label>
          ) }
        />
        <Controller
          name='endDate'
          rules={ {
            validate: {
              validDate: (v) => validateDate(v)
                || `${ t('financials:datePicker.errors.valid-date') }`,
              validEndDate: v => validEndDate(v, startDate)
                || `${ t('financials:datePicker.errors.end-date-is-before') }`
            }
          } }
          control={ control }
          render={ ({ field: { value, onChange } }) => (
            <Label label={ t('panel.form.endDate') } className={ styles.halfWidth }>
              <DatePicker
                locale={ locale }
                picker='month'
                format={ DATE_FORMATS.at(1) }
                value={ dayjs(value) ?? undefined }
                status={ errors?.[ 'endDate' ] ? 'error' : undefined }
                onChange={ (newValue) => {
                  if (!newValue) return undefined;
                  onChange(dayjs(newValue).endOf('month'));
                } }
              />
            </Label>
          ) }
        /> 
      </div>
      <FormMessage className={ styles.formMessage }>
        <ErrorMessage errors={ errors } name='startDate' />
      </FormMessage>
      <div>
        <Controller
          name='status'
          control={ control }
          render={ ({ field: { value, onChange } }) => (
            <Label label={ t('panel.form.status') }>
              <Select
                value={ value }
                className={ styles.select }
                popupClassName={ styles.selectPopup }
                options={ statusOptions }
                onSelect={ onChange }
              />
            </Label>
          ) }
        />
      </div>
      <div>
        <Button
          loading={ isDeleting }
          onClick={ () => setDeletingBudget(true) }
          disabled={ !areOtherExisting || isActive }
          type='link'
          danger
        >
          { t('common:delete') }
        </Button>
        <Button
          loading={ isLoading }
          htmlType='submit'
          type='primary'
        >
          { t('common:form.apply') }
        </Button>
      </div>

    </Form>
    <Modal
      title={ t('deleteBudgetModal.title') }
      isVisible={ deletingBudget }
      isDangerous
      okText={ t('common:delete') as string }
      description={ t('deleteBudgetModal.description', { name: budget?.name }) as string }
      onCancel={ () => setDeletingBudget(false) }
      onClose={ () => setDeletingBudget(false) }
      onConfirm={ confirmDelete }
    />
  </div>;
};

export default BudgetDetails;

const validateDate = (value: dayjs.Dayjs) => {
  return dayjs(value).isValid();
};

const validStartDate = (value: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  if (!value || !endDate) return true;

  return dayjs(value).isBefore(dayjs(endDate));
};

const validEndDate = (value: dayjs.Dayjs, startDate: dayjs.Dayjs) => {
  if (!value || !startDate) return true;
  return dayjs(value).isAfter(dayjs(startDate));
};

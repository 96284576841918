import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider
} from 'react-router-dom';
import { saveUser, selectAuthentication, setUserOrganization } from 'store/auth.slice';
import { getUserData } from 'services/auth.service';
import { routes } from 'utils/config.utils';
import {
  getActiveBudgetIdCookie,
  getRefreshToken,
  getToken,
  setActiveOrganizationIdCookie
} from 'utils/auth.utils';
import { notifyOrganizationChanged, notifyUnexpectedError } from 'utils/notifications.utils';
import Login from 'components/auth/login/Login';
import ForgotPassword from 'components/auth/forgotPassword/ForgotPassword';
import ChangePassword from 'components/auth/changePassword/ChangePassword';
import Layout from 'components/layout/Layout';
import Settings from 'components/settings/Settings';
import Integrations from 'components/settings/integrations/Integrations';
import Global from 'components/settings/global/Global';
import AccountsMapping from 'components/accountsMapping/AccountsMapping';
import Financials from 'components/financials/Financials';
import RevenueRecognition from 'components/revenueRecognition/RevenueRecognition';
import SingleRevenueRecognition from 'components/singleRevenueRecognition/SingleRevenueRecognition';
import { toggleAltKey, toggleBackspaceKey } from 'store/events.slice';
import Redirect from '../auth/redirect/Redirect';
import ComingSoonView from '../comingSoonView/ComingSoonView';
import Contracts from 'components/contracts/Contracts';
import { connectAnalytics } from 'analytics/analytics';
import ContractDetails from 'components/contracts/details/ContractDetails';
import DocumentTitle from 'components/elements/documentTitle/DocumentTitle';
import { connectHotjar } from 'utils/hotjar.utils';
import { setUnassigned } from 'store/accountMapping.slice';

import { fetchAndSetCountTransactionLines, fetchAndSetDimensions } from 'store/breakdowns.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import LabelingRules from '../labelingRules/LabelingRules';
import dayjs from 'dayjs';
import i18n from 'locales/i18n';
import DashboardPage from 'components/dashboard/page/DashboardPage';
import { planningService } from 'services/planning.service';
import { budgetSlice, setActiveBudget } from 'store/budget.slice';
import InputsPage from 'components/inputs/page/InputsPage';
import { connectMicrosoftClarity } from '../../analytics/microsoftClarity';
import { fetchAndSetIntegrations } from 'store/app.slice';
import TemplateView from 'components/templates/TemplateView';
import RouterErrorBoundary
  from 'components/elements/errorBoundary/routerErrorBoundary/RouterErrorBoundary';
import ProtectAuth from 'components/auth/routeProtection/ProtectAuth';
import ProtectAnonymous from 'components/auth/routeProtection/ProtectAnonymous';
import { useGetBudgetsQuery } from 'store/api/planning.api';

const router = createBrowserRouter(
  createRoutesFromElements(<>
    <Route element={ <ProtectAnonymous /> } errorElement={ <RouterErrorBoundary /> }>
      <Route path={ routes.login } element={ <Login/> }/>
      <Route path={ routes.forgotPassword } element={ <ForgotPassword/> }/>
      <Route path={ routes.changePassword } element={ <ChangePassword/> }/>
    </Route>
    <Route element={ <ProtectAuth /> }>
      <Route element={
        <Layout>
          <Outlet />
        </Layout>
      }>
        <Route errorElement={ <RouterErrorBoundary /> }>        
          <Route
            path='view'
          >
            <Route path=':_viewId' element={
              <DocumentTitle title={ null }>
                <Financials/>
              </DocumentTitle> }
            />
            <Route path='' element={
              <DocumentTitle title={ null }>
                <Financials/>
              </DocumentTitle> }
            />
          </Route>
          <Route path=':section/:view'>
            <Route path={ routes.comingSoon } element={
              <DocumentTitle title={ i18n.t('common:document-title.coming-soon') }>
                <ComingSoonView/>
              </DocumentTitle>
            }
            />
          </Route>

          <Route path={ routes.settings.accountsMapping } element={
            <DocumentTitle title={ i18n.t('common:document-title.accounts-mapping') }>
              <AccountsMapping/>
            </DocumentTitle>
          }
          />
          <Route path={ routes.report.templateBuilder }>
            <Route path=':id' element={ <TemplateView/> }/>
            <Route path='' element={ <TemplateView/> }/>
          </Route>
          <Route path={ routes.manage.revenueRecognition }>
            <Route path=':id' element={
              <DocumentTitle title={ i18n.t('common:document-title.revenue-recognition.detail') }>
                <SingleRevenueRecognition/>
              </DocumentTitle>
            }/>
            <Route path='' element={
              <DocumentTitle
                title={ i18n.t('common:document-title.revenue-recognition.overview') }
              >
                <RevenueRecognition/>
              </DocumentTitle>
            }/>
          </Route>
          <Route path={ routes.manage.labelingRules }>
            <Route path='' element={
              <DocumentTitle title={ i18n.t('common:document-title.labeling-rules') }>
                <LabelingRules/>
              </DocumentTitle>
            }
            />
          </Route>

          <Route path={ routes.settings.index } element={ <Settings/> }>
            <Route path={ routes.settings.global } element={
              <DocumentTitle title={ i18n.t('common:document-title.global-settings') }>
                <Global/>
              </DocumentTitle>
            }/>
            <Route path={ routes.settings.integrations } element={
              <DocumentTitle title={ i18n.t('common:document-title.integrations') }>
                <Integrations/>
              </DocumentTitle>
            }
            />
          </Route>
          <Route path={ routes.manage.contracts }>
            <Route path=':id' element={ <ContractDetails/> }/>
            <Route path='' element={ <Contracts/> }/>
          </Route>
          <Route
            path={ routes.dashboard }
            element={ <DashboardPage/> }
          />
          <Route path={ routes.inputs } element={ <InputsPage /> } />
          <Route path='*' element={ <Redirect/> }/>

        </Route>
        <Route path='*' element={ <Redirect/> }/>
      </Route>
    </Route>
    <Route path='*' element={ <Navigate to={ routes.login }/> }/>
  </>)
);

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const { user, organization } = useAppSelector(selectAuthentication);
  const { organizationChangeWarning } = useAppSelector((state) => state.app);
  const [ loaded, setLoaded ] = useState(false);

  const locales = {
    en: import('dayjs/locale/en'),
    fi: import('dayjs/locale/fi'),
  };

  const setDayJsLocale = (language) => {
    locales[ language ].then(() => {
      const altLang = language === 'en' ? 'en' : language;
      dayjs.locale(altLang);
    });
  };

  useEffect(() => setDayJsLocale(i18n.language), [ i18n.language ]);

  useEffect(() => {
    if (organizationChangeWarning) {
      notifyOrganizationChanged();
    }
  }, [ organizationChangeWarning ]);

  useEffect(() => {
    if (user) {
      dispatch(fetchAndSetCountTransactionLines());
      dispatch(fetchAndSetDimensions());
      dispatch(setUnassigned());
      if (organization) {
        dispatch(fetchAndSetIntegrations(organization.id));
      } else {
        setActiveOrganizationIdCookie('');
        dispatch(setUserOrganization(user));
      }
    }
  }, [ user ]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const { REACT_APP_ENABLE_ANALYTICS } = process.env;
    const enableAnalytics = REACT_APP_ENABLE_ANALYTICS !== undefined ?
      REACT_APP_ENABLE_ANALYTICS : 'false';
    if (user && enableAnalytics) {
      connectAnalytics(user);
      connectHotjar(user);
      connectMicrosoftClarity(user);
    }
  }, [ user ]);

  useEffect(() => {
    if (getToken() || getRefreshToken()) {
      getUserData()
        .then((data) => {
          dispatch(saveUser(data?.data));
          dispatch(setUserOrganization(data?.data));
        })
        .catch((e) => {
          notifyUnexpectedError(e);
        })
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    const keyboardListener = (e) => {
      if (e.key === 'Alt') {
        dispatch(toggleAltKey(e.type === 'keydown'));
      }
      if (e.key === 'Backspace') {
        if (e.repeat) return;
        dispatch(toggleBackspaceKey(e.type === 'keyup'));
      }
    };

    document.addEventListener('keydown', keyboardListener);
    document.addEventListener('keyup', keyboardListener);

    return () => {
      document.removeEventListener('keydown', keyboardListener);
      document.addEventListener('keyup', keyboardListener);
    };
  }, []);

  useGetBudgetsQuery(null, { skip: !user });

  useEffect(() => {
    if (!user) return;
    const budgetId = Number.parseInt(getActiveBudgetIdCookie());
    dispatch(budgetSlice.actions.setActiveBudgetId(budgetId));
    planningService.getBudgets().then(res => {
      if (res.data.results.length === 0) return;
      dispatch(budgetSlice.actions.setBudgets(res.data.results));
      if (budgetId) {
        dispatch(setActiveBudget(res.data.results.find((b) => b.id === budgetId)));
      } else {
        dispatch(setActiveBudget(res.data.results[ 0 ]));
      }
    });

    planningService.getBudgetItemTypes().then((res) => {
      dispatch(budgetSlice.actions.setBudgetItemTypes(res.data.results));
    });
    planningService.getPlanning().then((res) => {
      const data = res.data.results[ 0 ];
      dispatch(budgetSlice.actions.setBudgetSettings(data.categories));
    });
  }, [ user ]);

  if (!loaded) {
    return null;
  }

  return <RouterProvider router={ router } />;
};

export default App;

import React from 'react';
import { Switch, Tooltip } from 'antd';
import clsx from 'clsx';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import styles from './SwitchSetting.module.scss';

interface SettingProps {
  label: string;
  active: boolean;
  onChange: (active: boolean) => void;
  disabled?: boolean;
  tooltip?: string | null;
  className?: string;
}

const SwitchSetting = ({
  label,
  active,
  tooltip,
  onChange,
  disabled,
  className
}: SettingProps) => {
  return <div
    className={ clsx(
      styles.option,
      styles.toggle,
      { [ styles.disabled ]: disabled },
      className
    ) }
  >
    <label className={ styles.label }>
      <Switch
        className={ styles.switch }
        size='default'
        checked={ active }
        onChange={ onChange }
        disabled={ disabled }
      />
      <span className={ styles.optionLabel }>
        { label }
      </span>
    </label>
    { tooltip && (
      <Tooltip title={ tooltip }>
        <InfoIcon />
      </Tooltip>
    ) }

  </div>;
};

export default SwitchSetting;

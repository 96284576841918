/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { ColDef, Column, ColumnGroup, GridApi, ICellRendererParams } from 'ag-grid-community';
import dayjs, { Dayjs, QUnitType } from 'dayjs';
import { Entries } from 'types/app.types';
import { ColumnHeaderOptions, TableColumns } from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { getColumnWidth } from 'utils/grid.utils';
import { RowType } from 'types/revenueRecognition.types';
import { ReactComponent as GrayCircle } from 'assets/icons/gray-circle.svg';
import { ReactComponent as CheckGreenCircle } from 'assets/icons/check-green-circle.svg';
import DealTypeSelect from 'components/singleRevenueRecognition/dealTypeSelect/DealTypeSelect';
import { useAppSelector } from '../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { DurationUnitType } from 'dayjs/plugin/duration';

const columnHeadersOptions: ColumnHeaderOptions[] = [
  {
    cadence: Cadence.month,
    groupId: (date: Dayjs) => date.format('MMM-YYYY'),
    name: (date: Dayjs) => date.format('MMM'),
  },
  {
    cadence: Cadence.year,
    groupId: (date: Dayjs) => `Y${ date.format('YYYY') }`,
    name: (date: Dayjs) => date.format('YYYY'),
  },
];

const useRevenueGrid = (
  styles,
  isInvoiceTable=false,
  gridRef) => {

  const period = useAppSelector(state => state.revenueRecognition.table.period);
  const [ t ] = useTranslation('revenueRecognition');

  const headerGroupRenderer = (
    props: ({
      displayName: string;
      columnGroup: ColumnGroup;
      cadence: string;
      column: Column;
      api: GridApi;
    })) => {
    const isGroupHeader = props.columnGroup;
    const isCompleteArray = [];

    if (!isInvoiceTable) {
      props.api?.forEachNode(node => {
        const recognitionData = node.data.recognitionData;
        if (recognitionData && recognitionData[ props.column?.getColId() ] !== undefined) {
          isCompleteArray.push(node.data.recognitionData[ props.column?.getColId() ]);
        }
      } );
    }

    return props.displayName == null ?
      null : (
        <div
          style={ isGroupHeader ? { width: `${ getColumnWidth(props.columnGroup) }px` } : {} }
          className={ isGroupHeader ? styles.columnGroupHeader : styles.headerCell }
        >
          <span className={ styles.headerTitle }>{ props.displayName }</span>
          {
            !isInvoiceTable
            && !isGroupHeader
            && <span className={ styles.icon }>
              { isCompleteArray.every(el => el ) ? <CheckGreenCircle/> : <GrayCircle/> }
            </span>
          }
        </div>
      );
  };

  const getHeaderGroup = (
    parents: Entries<TableColumns>, columnOption: ColumnHeaderOptions
  ) => {
    const object = {};
    parents.forEach(([ key, val ]) => {
      const date = dayjs(key).startOf(columnOption.cadence as QUnitType);
      if (object[ date.toString() ]) {
        object[ date.toString() ].children.push(val);
      } else
        object[ date.toString() ] =
          {
            groupId: columnOption.groupId(date),
            headerName: columnOption.name(date),
            children: [ val ],
            type: 'valueColumn',
            headerGroupComponentParams: {
              cadence: columnOption.cadence
            },
            headerGroupComponent: headerGroupRenderer,
          };
    });
    return object;
  };

  const typeHeaderRenderer = () => {
    return <div
      style={ { minWidth: '50px' } }
      className={ styles.customHeaderCell }
    >
      <span className={ styles.customHeaderTitle }>
        { t('deal-type') }
      </span>
    </div>;
  };

  const typeCellRenderer = (params: ICellRendererParams) => {
    if (params.data?.type !== RowType.INVOICE) {
      return null;
    }

    return <div className={ styles.selectCell }>
      <DealTypeSelect data={ params.data } gridRef={ gridRef } />
    </div>;
  };
  const typeField = useMemo(() => 'TypeColumn', []);

  const getTypeColumn = () => {
    const typeColumn = {
      field: typeField,
      headerName: typeField,
      type: 'valueColumn',
      width: 160,
      pinned: 'left',
      headerGroupComponentParams: {
        cadence: Cadence.year,
      },
      headerComponent: typeHeaderRenderer,
      cellRenderer: typeCellRenderer
    };
    return typeColumn;
  };

  const getColumnDefs = () => {
    let columns: ColDef;
    const startIndex =
      columnHeadersOptions.findIndex(item => item.cadence === period.cadence);

    const columnFields = [];

    for (let i = startIndex; i < columnHeadersOptions.length; i++) {
      const columnOption = columnHeadersOptions[ i ];
      if (columns) {
        columns = getHeaderGroup(Object.entries(columns), columnOption);
      } else {
        columns = {};
        let start = dayjs.unix(period.startDate);
        start = start.startOf(columnOption.cadence as QUnitType);
        while (start.diff(dayjs.unix(period.endDate)) <= 0) {
          const field = `${ start.format('YYYY-MM-DD') }T00:00:00`;
          columnFields.push(field);
          columns[ start.toString() ] = {
            field: field,
            headerName: columnOption.name(start),
            type: 'valueColumn',
            filter:  'agNumberColumnFilter',
            headerComponentParams: {
              cadence: columnOption.cadence
            },
            headerComponent: headerGroupRenderer
          };
          start = start.add(1, columnOption.cadence as DurationUnitType);
        }
      }
    }
    columnFields.push('name');
    columnFields.push('totalRevenue');
    columns[ 'name' ] = { field: 'name', hide: true };
    columns[ 'totalRevenue' ] = { field: 'totalRevenue', hide: true };
    if (isInvoiceTable) {
      columnFields.push(typeField);
      columns[ typeField ] = getTypeColumn();
    }
    const columnDefs = Object.values(columns);

    columnDefs.unshift(columnDefs.splice(columnDefs.findIndex(e => e.field === typeField), 1)[ 0 ]);

    return {
      columnDefs: columnDefs,
      columnFields: columnFields
    };
  };

  return {
    getColumnDefs
  };
};

export default useRevenueGrid;

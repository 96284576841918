import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import Tooltip from 'components/elements/tooltip/Tooltip';
import styles from './ExpandButtons.module.scss';
import { RowType } from 'types/financials.types';
import { AgGridReact } from 'ag-grid-react';
import { IRowNode } from 'ag-grid-community';
import { useAppSelector } from 'store/hooks/hooks';
import { isProductProxy } from 'utils/financials.utils';

interface Props {
  gridRef: RefObject<AgGridReact>;
  maxRowDepth: number;
}

const ExpandButtons = ({ gridRef, maxRowDepth }: Props) => {
  const [ t ] = useTranslation('financials');
  const dimensionLabels = useAppSelector((state) => state.financials.options.dimensionLabels);

  const isRowExpandable = (row: IRowNode) => {
    return row.childrenAfterFilter.at(0)?.data?.type !== RowType.TRANSACTION &&
      !isProductProxy(row);
  };

  const getRowsToDisplay = () => {
    const rowsToDisplay: IRowNode[] = [];
    gridRef?.current?.api?.forEachNode(node => rowsToDisplay.push(node));
    return rowsToDisplay;
  };

  const handleRowsExpand = () => {
    const rowsToDisplay: IRowNode[] = getRowsToDisplay();
    let foundNotExpanded = false;
    for (let i = 0; i < maxRowDepth; i++) {
      const levelRows = rowsToDisplay.filter(row =>
        row.level === i && isRowExpandable(row));

      for (const row of levelRows) {
        if (!row.expanded) {
          foundNotExpanded = true;
          break;
        }
      }
      if (foundNotExpanded) {
        levelRows.forEach(row => {
          if (isRowExpandable(row)) {
            row.setExpanded(true);
            dimensionLabels &&
              row.childrenAfterGroup.forEach((leaf) => {
                leaf.data?.type === RowType.BREAKDOWN &&
                    leaf.setExpanded(true);
              }
              );
          }
        });
        break;
      }
    }
  };

  const handleRowsCollapse = () => {
    const rowsToDisplay = getRowsToDisplay();

    let foundExpanded = false;
    for (let i = maxRowDepth; i >= 0; i--) {
      const levelRows = rowsToDisplay.filter(row => row.level === i && !isProductProxy(row));
      for (const row of levelRows) {
        if (
          row.childrenAfterGroup.length &&
          row.expanded &&
          row?.data?.type !== RowType.BREAKDOWN
        ) {
          foundExpanded = true;
          break;
        }
      }
      if (foundExpanded) {
        levelRows.forEach(row => {
          row.setExpanded(false);
        });
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        placement='topLeft'
        title={ t('table.expand-indentation') }
        mouseEnterDelay={ 3 }
      >
        <button
          className={ styles.foldButton }
          onClick={ handleRowsExpand }>
          <Plus/>
        </button>
      </Tooltip>
      <Tooltip
        placement='topLeft'
        title={ t('table.collapse-indentation') }
        mouseEnterDelay={ 3 }
      >
        <button
          className={ styles.foldButton }
          onClick={ handleRowsCollapse }>
          <Minus />
        </button>
      </Tooltip>
    </React.Fragment>
  );
};

export default ExpandButtons;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import type { IRowNode } from 'ag-grid-community';
import type { Account } from 'types/statutory.types';

import { ReactComponent as TrashIcon } from 'assets/icons/trash-bin.svg';

import Modal from 'components/elements/modal/Modal';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { getDisplayName } from '../../../../utils/common.utils';
import { getValueCellsInRowsSet } from 'components/financials/utils/selection.utils';
import { DATE_FORMATS } from 'utils/date.utils';
import { ReportType } from 'types/templates.types';

import styles from './ConfirmCellsDelete.module.scss';
import { TableCoordinates } from 'components/financials/panels/overviewPanel/overviewPanel.types';

interface Props {
  isVisible: boolean;
  nodesToRemove: TableCoordinates[] | IRowNode[];
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmRowsDelete = ({ nodesToRemove, isVisible, onClose, onConfirm }: Props) => {
  const [ t ] = useTranslation('common');

  const elementsToRemove = useMemo(() => {
    if (!nodesToRemove) return [];

    if (areRowNodes(nodesToRemove)) {
      return getValueCellsInRowsSet(nodesToRemove, ReportType.PLAN);
    }

    return nodesToRemove;
  }, [ nodesToRemove ]);

  const description = useDeleteDescription(elementsToRemove);

  const accountMap = useAppSelector((state) => state.breakdowns.accountMap);

  const getAccountDisplay = (id: Account[ 'id' ]) => {
    const account = accountMap[ id ];
    if (!account) return '';
    return `${ account?.number } ${ getDisplayName(account?.name) }`;
  };

  return <Modal
    isVisible={ isVisible }
    onClose={ onClose }
    onConfirm={ onConfirm }
    okText={ t('delete') }
    isDangerous
    destroyOnClose
    title={ <div className={ styles.title }>
      <TrashIcon />
      {
        areRowNodes(nodesToRemove)
          ? t('warnings.delete-rows-title', { count: nodesToRemove?.length }) 
          : t('warnings.delete-cells-title', { count: elementsToRemove.length })
      }
    </div> }
    description={ description }
  >
    <div className={ styles.modalContent }>
      <table className={ styles.table }>
        <thead>
          <tr>
            <th>{ t('date') }</th>
            <th>{ t('financials:inline-edit.fields.account') }</th>
            <th>{ t('financials:inline-edit.fields.counterparty') }</th>
            <th>{ t('financials:inline-edit.fields.memo') }</th>
          </tr>
        </thead>
        <tbody>
          {
            (
              areRowNodes(nodesToRemove) ?
                (nodesToRemove ?? []).map((node) => ({ node, column: null })) :
                elementsToRemove
            )
              .map(({ node, column }) => (
                <tr key={ `${ node.id }__${ column }` }>
                  <td>
                    { column ?
                      getDateFromColumn(column) :
                      formatDate(node.data.rowData.startDate)
                    }
                  </td>
                  <td>{ getAccountDisplay(node.data.rowData?.accounts?.primary) }</td>
                  <td>{ node.data.rowData.counterparty?.name }</td>
                  <td>{ node.data.rowData.memo }</td>
                </tr>
              ))
          }
        </tbody>
      </table>
    </div>
  </Modal>;
};

export default ConfirmRowsDelete;

function getDateFromColumn (column: string) {
  return formatDate(column.split('__').at(-1));
}

function formatDate (date: string) {
  if (!date) return '';

  return dayjs(date).format(DATE_FORMATS.at(0));
}

function useDeleteDescription (
  elementsToRemove: { node: IRowNode; column: string }[] | IRowNode[],
) {
  const [ t ] = useTranslation('common');

  return useMemo(() => {
    if (!elementsToRemove?.length) return '';

    // Removing range selection
    if (!areRowNodes(elementsToRemove)) {
      if (elementsToRemove.length === 1) {
        return t(
          'warnings.delete-cell',
          { memo: elementsToRemove.at(0).node.data.rowData.memo ?? '...' }
        );
      }

      return t('warnings.delete-cells-multiple', { count: elementsToRemove.length });
    }

    // Removing rows
    if (elementsToRemove.length === 1) {
      return t(
        'warnings.delete-row',
        { memo: elementsToRemove.at(0).data.rowData.memo ?? '...' }
      );
    }

    return t('warnings.delete-rows-multiple', { count: elementsToRemove.length });
  }, [ elementsToRemove, t ]);
}

function areRowNodes (node: unknown): node is IRowNode[] {
  return (node as IRowNode[])?.at(0)?.id !== undefined;
}

import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import styles from './Editors.module.scss';
import { DATE_FORMATS } from 'utils/date.utils';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface ValidationProps {
  comparison: string;
  type: 'after' | 'before';
}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const DatePickerCellEditor = forwardRef((props: ICellEditorParams & ValidationProps, ref) => {
  const [ t ] = useTranslation('common');
  const [ value, setValue ] = useState<Dayjs>(props.value ? dayjs(props.value) : null);
  const [ valid, setValid ] = useState(true);
  const refContainer = useRef<HTMLDivElement>(null);
  const [ open, setOpen ] = useState(true);
  const columnToCompare = useMemo(() => {
    return props.api.getColumn(props.comparison);
  }, []);

  const getDateInFormat = useCallback((date: Dayjs): string => {
    return date?.format('YYYY-MM-DD');
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return getDateInFormat(value);
    }
  }));

  const onClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      props.api.stopEditing(false);
    });
  }, []);

  const isValidDate = useCallback((date: Dayjs) => {
    if (!columnToCompare) {
      return true;
    }
    const row = props.node.data;
    const val = row[ columnToCompare.getColId() ];
    if (!val) {
      return true;
    }
    if (props.type === 'after') {
      return date.isAfter(dayjs(val));
    }
    return date.isBefore(dayjs(val));
  }, [ columnToCompare ]);

  const onSelect = useCallback((date: Dayjs) => {
    if (date == null) {
      setValue(null);
      return;
    }
    if (!isValidDate(date)) {
      setValid(false);
      return;
    }
    setValid(true);
    setValue(date);
    props.node.setDataValue(props.column.getColId(), getDateInFormat(date));
    onClose();
  }, []);

  const onBlur = useCallback((e) => {
    const inputValue = e.target.value;
    if (!inputValue) {
      setValue(null);
      props.node.setDataValue(props.column.getColId(), null);
      onClose();
    }
  }, []);

  const validationTitle = useMemo(() => {
    if (props.type === 'after') {
      return t('common:validation.date.after', { field: columnToCompare.getColDef().headerName });
    }
    return t('common:validation.date.before', { field: columnToCompare.getColDef().headerName });
  }, [ props.type ]);

  return (
    <div ref={ refContainer }>
      <Tooltip open={ !valid } title={ validationTitle }>
        <DatePicker
          autoFocus={ true }
          open={ open }
          value={ value }
          format={ DATE_FORMATS }
          status={ valid ? null : 'error' }
          getPopupContainer={ undefined }
          onSelect={ onSelect }
          onBlur={ onBlur }
          onChange={ onSelect }
          dropdownClassName='ag-custom-component-popup'
          className={ styles.datePicker }
        />
      </Tooltip>
    </div>
  );
});

DatePickerCellEditor.displayName = 'DatePickerCellEditor';
export default DatePickerCellEditor;

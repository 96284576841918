import { useCallback, useMemo } from 'react';
import { InputRowWithValues, } from 'components/inputs/types/inputs.types';
import useInputsTable from 'components/inputs/table/hooks/useInputsTable';
import dayjs from 'dayjs';
import { Cadence } from 'types/form.types';
import { ColDef, GridReadyEvent, IRowNode } from 'ag-grid-community';
import useInputs from './useInputs';
import { INPUT_TEMPLATE_ID, isInputValid } from 'components/inputs/utils/inputs.utils';

import useTablePeriodWithStorage
  from 'components/elements/tableWrapper/hooks/useTablePeriodWithStorage';

interface Props {
  search: string;
  onAliasCellEdit: (node: IRowNode<InputRowWithValues>) => void;
}

const useInputsTableProps = ({ search, onAliasCellEdit }: Props) => {
  
  const { period, setPeriod } = useTablePeriodWithStorage({
    tableId: INPUT_TEMPLATE_ID,
    defaultPeriod: {
      startDate: dayjs().subtract(2, 'year').startOf(Cadence.month).unix(),
      endDate: dayjs().subtract(1, 'year').endOf(Cadence.month).unix(),
      startDatePlan: dayjs().subtract(1, 'year').startOf(Cadence.month).unix(),
      endDatePlan: dayjs().endOf(Cadence.month).unix(),
      cadence: Cadence.month,
      actualsOpen: false,
      planOpen: true,
      isManuallySet: false,
    },
    settings: { plan: true, actuals: true }
  });

  const {
    inputs,
    categories,
    loading,
    update,
    canUpdate,
    onAddInput,
    isAutoSaved,
    duplicate
  } = useInputs({ period });

  const {
    columnDefs,
    groupByOptions,
    dimensionColumns
  } = useInputsTable({ search, period, update, categories, onAliasCellEdit });

  const defaultColDef: ColDef<InputRowWithValues> = {
    valueSetter: (params) => {
      const newInputRow: InputRowWithValues = {
        ...params.data,
        [ params.colDef.field ]: params.newValue,
      };
      update(newInputRow);
      return true;
    }
  };

  const onGridColumnsChanged = useCallback((e: GridReadyEvent) => {
    const colIdsToHide = dimensionColumns.map(col => col.field);
    e.api.setColumnsVisible(colIdsToHide, false);
  }, [ dimensionColumns ]);

  const columnProperties = useMemo(() => {
    return {
      columnDefs,
      defaultColDef,
    };
  }, [ dimensionColumns, period, categories ]);
  
  const otherTableProps ={
    table: {
      rowData: inputs,
      onGridColumnsChanged,
      period,
      onPeriodChange: setPeriod,
      disableCadence: true,
      groupByOptions,
      groupHeaderHeight: 30,
      headerHeight: 40,
      isLoading: loading,
      isRowValid: isInputValid,
      tableKey: INPUT_TEMPLATE_ID.toString(),
      isAutoSaved,
    },
    other: {
      canUpdate
    }
  };

  return {
    otherTableProps,
    columnProperties,
    duplicate,
    onAddInput
  };

};

export default useInputsTableProps;

import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from '../Form.module.scss';

interface Props {
  type?: 'warning' | 'error' | 'info';
  className?: string;
}

const FormMessage = ({ children, type = 'error', className }: PropsWithChildren<Props>) => {
  if (!children) return null;
  return <span className={ clsx(styles.formMessage, styles[ type ], className) }>
    { children }
  </span>;
};

export default FormMessage;

import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import styles from './ScrollUpButton.module.scss';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import debounce from 'lodash/debounce';

interface Props {
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>;
}

const ScrollUpButton = ({ scrollContainerRef }: Props ) => {
  const [ isVisible, setIsVisible ] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(scrollContainerRef.current.scrollTop > 100);
  };

  const debouncedChangeHandler = useCallback(
    debounce(
      () => scrollContainerRef.current.addEventListener('scroll',toggleVisibility), 500
    ), []);

  const scrollToTop = () =>{
    scrollContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    debouncedChangeHandler();

    return () => {
      scrollContainerRef?.current?.removeEventListener('scroll', toggleVisibility);
    };

  },[]);

  return (
    <div className={ `${ styles.scrollButton } ${ isVisible ? styles.visible : '' }` }>
      <div
        onClick={ scrollToTop }
        className={ styles.button }
      >
        <Chevron className={ styles.arrow }/>
      </div>
    </div>
  );
};

export default ScrollUpButton;

import { TransactionLineBulkActionParams, TransactionLinesBulkEdit } from 'types/templates.types';
/* eslint-disable camelcase */
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';
import { Account, Counterparty, RequiredRoleAccount, Transaction } from '../types/statutory.types';
import {
  CountTransactionLines,
  Dimension,
  DimensionItem,
  DimensionLabelingProgress
} from '../types/filterTable.types';
import { Cadence } from 'types/form.types';
import { CounterpartyType, Product } from 'types/revenueRecognition.types';
import { CustomViewNavigate, CustomViewSection, PaginatedResponse } from 'types/app.types';
import { TransactionLinePayloadParams, TransactionLineRequestParams } from './statutory.service';
import { LimitOffsetParams } from './params/pagination';
import { IServerSideGetRowsRequest } from 'ag-grid-community';

const getAccounts = () => http.get<Account[]>(endpoints.organizations.accounts);

const getCountTransactionLines = (params?: TransactionLineRequestParams) =>
  http.get<CountTransactionLines>(endpoints.organizations.transactions.count, { params });

const getDimensions = () =>
  http.get<Dimension[]>(endpoints.organizations.dimensions);

const getDimensionsLabelingProgress = () =>
  http.get<DimensionLabelingProgress[]>(endpoints.organizations.dimensionsLabelingProgress);

const getDimension = (id: number) =>
  http.get<Dimension>(endpoints.organizations.dimension.replace('{id}', id.toString()));

const addDimensionItem = (id, payload) =>
  http.post<DimensionItem>(
    endpoints.organizations.dimensionItems.replace('{id}', id.toString()),
    payload
  );

const editDimension = (id, payload) =>
  http.patch(
    endpoints.organizations.dimension.replace('{id}', id.toString()),
    payload
  );

const markDimensionAsFavourite = (id: Dimension['id']) =>
  http.post<void>(
    endpoints.organizations.dimensionToggleFavourite.replace('{id}', id.toString()),
  );

const unmarkDimensionAsFavourite = (id: Dimension['id']) =>
  http.delete<void>(
    endpoints.organizations.dimensionToggleFavourite.replace('{id}', id.toString()),
  );

const deleteDimension = (id) =>
  http.delete(
    endpoints.organizations.dimension.replace('{id}', id.toString()),
  );

const deleteDimensionItem = (id) =>
  http.delete(
    endpoints.organizations.deleteDimensionItem.replace('{id}', id.toString())
  );

const addDimensions = (payload) =>
  http.post<Dimension>(
    endpoints.organizations.dimensions,
    payload
  );

const editDimensionsItem = (id, payload) =>
  http.patch<DimensionItem>(
    endpoints.organizations.deleteDimensionItem.replace('{id}', id.toString()),
    payload
  );

const getInvoice = ( id ) =>
  http.get(endpoints.organizations.transactions.getInvoice
    .replace('{id}', id.toString()));

type AddDimensionToTransactionPayload = TransactionLinePayloadParams & {
  dimensionItem?: number;
  excludedTransactionLineIds?: number[];
};

const addDimensionToTransaction = (payload: AddDimensionToTransactionPayload) =>
  http.post(
    endpoints.organizations.transactions.addDimension,
    payload
  );

const addProductToTransaction = (payload) =>
  http.post(
    endpoints.organizations.transactions.addProduct,
    payload
  );

interface ClearDimensionsPayload extends TransactionLinePayloadParams {
  excludedTransactionLineIds?: number[];
}

const clearDimensions = (payload: ClearDimensionsPayload, dimension?: number) =>
  http.post<void>(endpoints.organizations.transactions.clearDimensions, {
    ...payload,
    dimension,
  });

const getCustomViewsSection = () => http.get<CustomViewSection[]>(endpoints.customView.sections);

const getCustomView = (id: number) =>
  http.get<CustomViewNavigate>(endpoints.customView.view.replace('{id}', id.toString()));

interface CounterpartyListRequestParams extends LimitOffsetParams {
  startDate?: string;
  endDate?: string;
  cadence?: Cadence;
  counterpartyType?: CounterpartyType;
}

interface CounterpartyRecognition extends Counterparty {
  recognitionData: {[key: string]: boolean} | null;
}

const getCounterpartiesRecognitionList = (params: CounterpartyListRequestParams) =>
  http.get<PaginatedResponse<CounterpartyRecognition>>(
    endpoints.contracts.revenueRecognitionList, { params });

const getCounterpartiesList = (params?: CounterpartyListRequestParams) =>
  http.get(endpoints.organizations.counterparty.counterpartiesList, { params });

export enum TransactionType {
  SYSTEM_ADJUSTMENT = 'system_adjustment',
  JOURNAL = 'journal',
  ACCRUAL = 'accrual',
  SALES_INVOICE = 'sales_invoice',
  SALES_PAYMENT = 'sales_payment',
  PURCHASE_INVOICE = 'purchase_invoice',
  PAYROLL = 'payroll',
  EXPENSE_CLAIM = 'expense_claim',
  BANK_STATEMENT = 'bank_statement',
  OPENING_BALANCE = 'opening_balance',
  VAT_STATEMENT = 'vat_statement',
  OTHER = 'other',
}

export enum ProfitLossType {
  INCOME = 'income',
  EXPENSE = 'expense'
}

type TransactionListRequestParams = {
  startDate?: string;
  endDate?: string;
  counterpartyId?: number | null;
  excludedTransactionTypes: TransactionType[];
  plTypes: ProfitLossType[];
};

const getRecognizedTransactionList = (params: TransactionListRequestParams) =>
  http.get(endpoints.organizations.transactions.recognizedList, {
    params: params
  });

const getTransaction = (id: string | number) => 
  http
    .get(endpoints.organizations.transactions.getSingleTransaction.replace('{id}', id.toString()));

const getProducts = () =>
  http.get(endpoints.organizations.products.index);

const removeProduct = (id: string | number) =>
  http.delete(endpoints.organizations.products.delete
    .replace('{id}', id.toString())
  );

const addProduct = (name: string) =>
  http.post<Product>(endpoints.organizations.products.add, { name });

const editProduct = ( data ) =>
  http.put<Product>(endpoints.organizations.products.edit
    .replace('{id}', data.id.toString()), { name: data.name }
  );

const getCounterparty = ( id: number ) =>
  http.get(endpoints.organizations.counterparty.detail
    .replace('{id}', id.toString())
  );

const clearTransactionsProduct = (payload: TransactionLinePayloadParams) =>
  http.post(endpoints.organizations.transactions.clearProduct, payload);

const createCounterparty = (name: string) =>
  http.post<Counterparty>(endpoints.organizations.counterparty.list, {
    name: name
  });

const editCounterparty = (counterpartyId: number, name: string) =>
  http.patch<Counterparty>(endpoints.organizations.counterparty.detail
    .replace('{id}', counterpartyId.toString()), {
    name: name
  });

const deleteCounterparty = (counterpartyId: number) =>
  http.delete(endpoints.organizations.counterparty.detail
    .replace('{id}', counterpartyId.toString()));

const assignTransactionLines = (data: TransactionLinesBulkEdit) => {
  return http.post(endpoints.organizations.transactions.bulkLabel, data);
};

const getRequiredRoles = () => 
  http.get(endpoints.organizations.accountsRoles);

const setRequiredRoles = (data: RequiredRoleAccount) => 
  http.post(endpoints.organizations.accountsRoles, data);

export type SSRMParams = Partial<IServerSideGetRowsRequest> & {
  cadence?: Cadence;
};

interface SSRMResponse {
  groupRows: string[];
  transactionLines: Transaction[];
  total: number;
  count: number;
}

const getTransactionLinesSSRM = (params: SSRMParams, signal: AbortSignal) =>
  http.post<SSRMResponse>(
    endpoints.organizations.transactions.ssrm, params, { signal }
  );

const getTransactionLineSSRMSum = (params: SSRMParams) =>
  http.post<CountTransactionLines>(endpoints.organizations.transactions.ssrmSum, params);

interface DimensionSplitState {
  dimensionItem: number;
  dimension: number;
  percentage: number;
  state: 'common' | 'deviated';
}

interface DimensionSplitStateResponse {
  splits: DimensionSplitState[];
}

const getDimensionSplitState = (params: TransactionLineBulkActionParams) => {
  return http.post<DimensionSplitStateResponse>(
    endpoints.organizations.transactions.getDimensionSplitState, params);
};

const chatbotConsent = (consent: boolean) =>
  http.post(endpoints.organizations.chatbotConsent, { chatbotConsent: consent });

/* eslint-disable camelcase */
const organizationsService = {
  getDimensions, getDimensionsLabelingProgress, getDimension, addDimensionToTransaction,
  addProductToTransaction, clearDimensions,
  getCounterpartiesRecognitionList, getRecognizedTransactionList,editProduct, addProduct,
  getProducts, getCounterparty, clearTransactionsProduct, createCounterparty,
  editCounterparty, deleteCounterparty, removeProduct, getInvoice,
  getCustomView, getAccounts, getCustomViewsSection,
  getCounterpartiesList, assignTransactionLines, addDimensions, addDimensionItem,
  deleteDimensionItem, getCountTransactionLines, editDimension, deleteDimension, editDimensionsItem,
  getTransaction, getRequiredRoles, setRequiredRoles, markDimensionAsFavourite,
  unmarkDimensionAsFavourite, getTransactionLinesSSRM, chatbotConsent, getTransactionLineSSRMSum,
  getDimensionSplitState
};

export default organizationsService;

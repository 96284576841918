import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { FieldErrors } from 'react-hook-form';
import clsx from 'clsx';

import styles from './Editors.module.scss';

interface Props {
  value: string;
  field: string;
  headerName: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  errors: FieldErrors;
  className?: string;
  onBlur?: () => void;
}

const TextCellEditor = (props: Props) => {
  const [ value, setValue ] = useState(props.value);

  useEffect(() => {
    props.setValue(value);
  }, [ value ]);

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  return (
    <Input
      autoFocus={ false }
      value={ value }
      name={ props.field }
      disabled={ props.disabled }
      placeholder={ props.placeholder }
      onChange={ (e) => setValue(e.target.value) }
      onBlur={ props.onBlur }
      status={ props.errors[ props.field ] ? 'error' : null }
      className={ clsx(styles.textInput, props.className) }
    />
  );
};

TextCellEditor.displayName = 'TextCellEditor';
export default TextCellEditor;

import { Column, GetContextMenuItemsParams, IRowNode } from 'ag-grid-community';

import type { ImportedStyles } from 'types/app.types';
import PlusIcon from 'assets/icons/plus.svg';
import DeleteIcon from 'assets/icons/trash-bin.svg';
import LensIcon from 'assets/icons/text-lens.svg';
import DetailedViewIcon from 'assets/icons/detailed-view.svg';
import { useTranslation } from 'react-i18next';
import { ReportType } from 'types/templates.types';
import { RowType } from 'types/financials.types';
import { NO_ADD_BUTTON_ROW_TYPES } from 'components/financials/utils/addRow.utils';
import { AG_GRID_AUTO_COLUMN } from 'utils/grid.utils';
import { canOpenOverview } from 'utils/financials.utils';

type CallbacksType = {
  addRow: (node: IRowNode) => void;
  openOverview: (node: IRowNode, colDef: Column) => void;
  openDetailedView: (node: IRowNode, columnName: string) => void;
  removeRow: (nodes: IRowNode[]) => void;
};

type Props = {
  styles: ImportedStyles;
  callbacks: CallbacksType;
};

const useGridContextMenu = ({ styles, callbacks }: Props) => {
  const [ t ] = useTranslation([ 'financials', 'sorting' ]);

  const {
    addRow,
    removeRow,
    openOverview,
    openDetailedView
  } = callbacks;

  const canAddNewRow = (params: GetContextMenuItemsParams) => {
    if (
      !(
        params.column?.getColId().startsWith(ReportType.PLAN) ||
        (
          params.column?.getColId() === AG_GRID_AUTO_COLUMN &&
          params.api?.getColumns().some((col) => col?.getColId().startsWith(ReportType.PLAN))
        )
      )
    ) {
      return false;
    }
    return !NO_ADD_BUTTON_ROW_TYPES.includes(params.node.data?.type);
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (!params.node) {
      return [];
    }

    let selectedNodes = params.api.getSelectedNodes();
    if (!selectedNodes.some(n => n.id === params.node.id)) {
      // AG GRID is not deselecting nodes when opening context menu on item outside of selection
      selectedNodes.forEach(n => n.setSelected(false));
      selectedNodes = [ params.node ];
    }
    if (selectedNodes[ 0 ]?.data?.type !== params.node?.data?.type) {
      selectedNodes.forEach(n => n.setSelected(false));
      selectedNodes = [];
    }
    if (!selectedNodes.includes(params.node)) {
      selectedNodes.forEach(n => n.setSelected(false));
    }
    params.node.setSelected(true);
    params.api.clearRangeSelection();

    return [
      ...(canAddNewRow(params) ? [ {
        name: t('context-menu.addNewRow'),
        cssClasses: [ styles.showRevenueRecognition ],
        icon: `<img src=${ PlusIcon } alt="add"/>`,
        action: () => addRow(params.node)
      } ] : []),
      ...(canOpenOverview(params.node, params.column.getColId()) ? [
        {
          name: t('context-menu.open-overview-dropdown'),
          action: () => openOverview(params.node, params.column),
          icon: `<img src=${ LensIcon } class="${ styles.contextMenuIcon }" alt="lens"/>`
        },
        {
          name: t('context-menu.delete-row'),
          action: () => removeRow(
            params.api.getSelectedNodes()
              .filter((node) => node.data?.type === RowType.NEW_BUDGET_ITEM)
          ),
          icon: `<img src=${ DeleteIcon } class="${ styles.contextMenuIcon }" alt="delete"/>`
        },
        {
          name: t('context-menu.open-details-view'),
          action: () => openDetailedView(params.node, params.column.getColId()),
          icon: `<img src=${ DetailedViewIcon } class="${ styles.contextMenuIcon }" alt="details"/>`
        }
      ] : []),
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'separator',
      'export',
    ];
  };

  return {
    getContextMenuItems
  };
};

export default useGridContextMenu;

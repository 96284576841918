import React from 'react';
import styles from './Chat.module.scss';
import MessageHistory from 'components/collaboration/chat/messages/history/MessageHistory';
import PromptInput from 'components/collaboration/chat/prompt/PromptInput';
import { useAppSelector } from 'store/hooks/hooks';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';

const Chat = () => {
  const history = useAppSelector(state => state.chat.history);

  return <div className={ styles.chat }>
    <DynamicScrollableContainer>
      <MessageHistory messages={ history } />
    </DynamicScrollableContainer>
    <PromptInput />
  </div>;
};

export default Chat;
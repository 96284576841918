import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import { ErrorOption, FieldErrors } from 'react-hook-form';
import { useFormulaRow } from 'components/formulaRow/FormulaRowContext';

import styles from './Editors.module.scss';

interface FieldProps {
  value: number;
  field: string;
  headerName: string;
  disabled?: boolean;
  placeholder?: string;
  errors: FieldErrors;
  setValue: (value: number) => void;
  setError?: (fieldName: string, message: ErrorOption) => void;
  onBlur?: () => void;
}

const NumericCellEditor = (props: FieldProps) => {
  const [ value, setValue ] = useState(+props.value);
  const field = props.headerName;

  const { dispatch, state: { open } } = useFormulaRow();

  const onChange = (val: number) => {
    setValue(val);
    props.setValue(val);
  };

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
      props.setValue(props.value);
    }
  }, [ props.value ]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isStartOfFormula = props.field === 'amount' && e.key === '=';

    if (isStartOfFormula && !open) {
      dispatch({ type: 'OPEN' });
    }
  };

  return (
    <InputNumber
      value={ value }
      autoFocus={ false }
      name={ props.field }
      onBlur={ props.onBlur }
      disabled={ props.disabled }
      className={ styles.numericCell }
      placeholder={ props.placeholder }
      status={ props.errors?.[ field ] ? 'error' : null }
      onChange={ onChange }
      decimalSeparator=','
      controls={ false }
      onKeyPress={ onKeyPress }
    />
  );
};

NumericCellEditor.displayName = 'NumericCellEditor';
export default NumericCellEditor;

import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import SearchableSelect from '../../../searchableSelect/SearchableSelect';
import styles from './Editors.module.scss';
import useKeyPressListener from 'hooks/useKeyPressListener';
import clsx from 'clsx';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { FrequencyUnit } from 'types/financials.types';

const SELECT_KEY_BINDING = { code: 'Enter' };

type Value = {
  amount: number;
  unit: FrequencyUnit;
};
interface ValuesProps {
  values: { name: string; label: string; amount: number; unit: FrequencyUnit }[];
}

const FrequencyCellEditor = forwardRef((
  props: ICellEditorParams & ValuesProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {

  const [ t ] = useTranslation('common');
  const value = getNameFromValue(props.value, props.values);
  const column = props.column.getColId();
  const [ open, setOpen ] = useState(true);

  const [ customOption, setCustomOption ] = useState(props.value?.amount);

  const options = useMemo(() => {
    return props.values.map((option) => {

      return {
        value: option.name,
        val: option,
        name: option.label,
        label: option.label as ReactNode,
      };
    })
      .concat({
        value: 'custom',
        val: { unit: 'month' as const, amount: customOption, name: 'custom', label: '' },
        name: t('frequency.every_count_months', { count: customOption }),
        label: <MonthInput
          value={ customOption }
          onChange={ (newCustomValue) => {
            setCustomOption(newCustomValue);
            onChange(newCustomValue);
          } } />
      });
  }, [ props.values, props.formatValue, customOption ]);

  const updateValue = useCallback((val) => {
    props.node.setDataValue(column, val);
  }, [ column ]);

  const setFocusOnCell = useCallback(() => {
    const focusedCell = props.api.getFocusedCell();
    props.api.clearFocusedCell();
    props.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column, focusedCell.rowPinned);
  }, []);

  const onChange = useCallback((key: string | number) => {
    const selectedValue = props.values.find((val) => val.name === key);
    if (selectedValue) {
      updateValue(selectedValue);
    } else {
      if (typeof key === 'number') {
        updateValue({ amount: key, unit: 'month' });
      } else {
        updateValue({ amount: customOption, unit: 'month' });
      }
    }
  }, [ customOption, props.values, updateValue ]);

  const openDropdown = useCallback(() => setOpen(true), []);

  useKeyPressListener({ keyBinding: SELECT_KEY_BINDING, cb: openDropdown, enabled: open });

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
    if (!_open) {
      setFocusOnCell();
    }
  }, []);

  return (
    <SearchableSelect
      open={ open }
      onDropdownVisibleChange={ onOpenChange }
      dropdownMatchSelectWidth={
        Reflect.has(props.colDef?.cellEditorParams, 'dropdownMatchSelectWidth') ?
          props.colDef.cellEditorParams.dropdownMatchSelectWidth : 450
      }
      autoFocus={ true }
      value={ value }
      options={ options }
      searchBy={ (obj) => obj.name }
      optionLabelProp='name'
      popupClassName='ag-custom-component-popup'
      className={ clsx(styles.fullCellInput, styles.primaryBorder) }
      onChange={ onChange }
      callback={ (e) => {
        if (e.key === 'Tab') {
          e.preventDefault();
          props.api.tabToNextCell();
        }
      } }
    />
  );
});

FrequencyCellEditor.displayName = 'FrequencyCellEditor';
export default FrequencyCellEditor;

function getNameFromValue (value: Value, values: ValuesProps['values']) {
  if (!value) return values.at(0).name;

  const selectedValue = values
    .find((val) => val.amount === value.amount && val.unit === value.unit);
  return selectedValue?.name ?? 'custom';
}

export const MonthInput = ({ value, onChange }) => {
  const [ t ] = useTranslation('common');
  const [ inputValue, setInputValue ] = useState(value);

  const onConfirmInput = useCallback((e) => {
    if (e.code === 'Enter' && e.target.value) {
      setTimeout(() => {
        onChange(inputValue);
      });
    }
  }, [ inputValue ]);

  return (
    <div
      className={ styles.frequencyInputOptionWrapper }
      onClick={ () => {
        setTimeout(() => {
          onChange(inputValue);
        });
      } }
    >
      <span>{ t('frequency.every') }</span>
      <InputNumber
        controls={ false }
        value={ inputValue }
        type='number'
        min={ 0 }
        step={ 1 }
        onChange={ newValue => {
          if (newValue) {
            setInputValue(newValue);
          }
        } }
        onClick={ e => {
          e.preventDefault();
          e.stopPropagation();
          (e.target as HTMLInputElement).focus();
        } }
        onKeyDown={ onConfirmInput }
      />
      <span>
        { t('frequency.months') }
      </span>
    </div>
  );
};

import React, { useCallback, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import enGB from 'antd/lib/locale/en_GB';
import fiFI from 'antd/lib/locale/fi_FI';
import 'dayjs/locale/en-gb';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import styles from './InlineDatePicker.module.scss';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from '../../../utils/date.utils';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
dayjs.extend(isoWeek);

type FormatType = string | ((value: Dayjs) => string);

type Props = {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  disabled: boolean;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  format?: FormatType | FormatType[];
  showToday?: boolean;
  className?: string;
  allowClear?: boolean;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  status?: '' | 'error' | 'warning';
  prefix?: string;
  popupClassName?: string;
  height?: number;
};

const InlineDatePicker = ({
  value,
  onChange,
  disabled,
  getPopupContainer,
  format = DATE_FORMATS,
  showToday = true,
  className = '',
  prefixIcon = null,
  suffixIcon = null,
  allowClear = false,
  status = '',
  prefix,
  popupClassName = '',
  height = 32
}: Props) => {
  const ref = useRef(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const { i18n } = useTranslation();

  const customGetPopupContainer = useCallback(() => {
    if (getPopupContainer) {
      return getPopupContainer;
    }
    return () => ref?.current;
  }, [ getPopupContainer ]);

  const getLocale = useCallback(() => {
    const languageLocale = {
      'en': enGB.DatePicker,
      'fi': fiFI.DatePicker
    };
    return languageLocale[ i18n.language ];
  }, [ i18n.language ]);

  return (
    <div
      style={ { height: `${ height }px` } }
      className={ `${ styles.inlineDatePicker } ${ status === 'error' ? styles.error : '' }` }
      ref={ ref }
    >
      <span
        onClick={ () => !isOpen && setIsOpen(true) }
        className={ styles.prefix }>
        { prefixIcon }
        { prefix }
      </span>
      <DatePicker
        locale={ getLocale() }
        picker='date'
        format={ format }
        open={ isOpen }
        disabled={ disabled }
        onOpenChange={ (open) => setIsOpen(open) }
        bordered={ false }
        value={ value as Dayjs }
        onChange={ (date) => onChange(date) }
        getPopupContainer={ customGetPopupContainer() }
        className={ className }
        suffixIcon={ suffixIcon }
        size='small'
        allowClear={ allowClear }
        showToday={ showToday }
        status={ status }
        popupClassName={ popupClassName }
      />
      <span
        onClick={ () => !isOpen && setIsOpen(true) }
        className={ styles.prefix }>
        { suffixIcon }
      </span>
    </div>
  );
};

export default InlineDatePicker;

import { cloneDeep, uniqueId } from 'lodash';
import { isFinancialsNode, Template, TemplateNode, } from 'types/templates.types';
import { insertAfter } from 'utils/array.utils';
import { PlacementNode } from './TemplatePlacementService';
import { getUUID } from '../../utils/templates.utils';

export const TemplateCreationService = (
  tag: TemplateNode,
  template: Template,
  placement: PlacementNode
) => {
  const { parent, nodeBefore, isRoot, moveChildren, fillMissingNodes, isTop } = placement;
  let updatedTemplate = cloneDeep(template);

  const generateUniqueId = (providedId?: number) => {
    if (providedId) {
      return providedId;
    }
    return Number(uniqueId());
  };

  const createNode = (newTag: TemplateNode, parentId: number | string): TemplateNode => {
    const id = generateUniqueId();
    return {
      ...newTag,
      id,
      uuid: getUUID(),
      children: [],
      parent: parentId,
      negativeMultiplier: false,
    } as TemplateNode;
  };

  const addNodeToTemplate = (node: TemplateNode, templateToAdd: Template): Template => {
    templateToAdd.nodes = {
      ...templateToAdd.nodes,
      [ node.id ]: node,
    };
    return templateToAdd;
  };

  const addNode = () => {
    if (fillMissingNodes) {
      return fillMissingParentNodes(template.nodes[ nodeBefore ], tag.childrenNodes);
    }
    const node = createNode(tag, parent);
    if (isRoot) {
      if (isTop) {
        updatedTemplate.roots = [ node.id, ...updatedTemplate.roots ];
      } else if (nodeBefore) {
        updatedTemplate.roots = insertAfter(updatedTemplate.roots, node.id, nodeBefore);
      } else {
        updatedTemplate.roots.push(node.id);
      }
    } else if (!isRoot && !moveChildren) {
      if (isTop) {
        updatedTemplate.nodes[ node.parent ].children =
          [ node.id, ...updatedTemplate.nodes[ node.parent ].children ];
      } else if (nodeBefore) {
        updatedTemplate.nodes[ node.parent ].children = insertAfter(
          updatedTemplate.nodes[ node.parent ].children,
          node.id,
          nodeBefore
        );
      } else {
        updatedTemplate.nodes[ node.parent ].children.push(node.id);
      }
    }
    updatedTemplate = addNodeToTemplate(node, updatedTemplate);
    updatedTemplate = createChildrenNodes(node, tag.childrenNodes, updatedTemplate);
    if (moveChildren) {
      const children = updatedTemplate.nodes[ parent ].children;
      updatedTemplate.nodes[ parent ].children = [ node.id ];
      updatedTemplate.nodes[ node.id ].children = children;
      children.forEach((childId) => {
        updatedTemplate.nodes[ childId ].parent = node.id;
      });
    }
    return {
      node: updatedTemplate.nodes[ node.id ],
      updatedTemplate
    };
  };

  const createChildrenNodes = (
    node: TemplateNode,
    childrenNodes: TemplateNode[],
    updateTemplate: Template
  ): Template => {
    let clonedTemplate = cloneDeep(updateTemplate);
    childrenNodes?.forEach((child) => {
      const newNode = createNode(child, node.id);
      clonedTemplate = addNodeToTemplate(newNode, clonedTemplate);
      clonedTemplate.nodes[ node.id ].children.push(newNode.id);
      if (child.children.length > 0) {
        clonedTemplate = createChildrenNodes(newNode, child.childrenNodes, clonedTemplate);
      }
    });
    return clonedTemplate;
  };

  const fillMissingParentNodes =
    (nodeToFill: TemplateNode, childrenNodes: TemplateNode[]) => {
      let updateTemplate = cloneDeep(template);

      const missingChildren = childrenNodes.filter(isFinancialsNode).filter(node => {
        const childrenTagIds: (number | string)[] = nodeToFill.children.map(
          (childId) => updateTemplate.nodes[ childId ]
        ).filter(isFinancialsNode).map(childNode => childNode.rowData?.id);
        return !childrenTagIds.includes(node.id);
      });
      updateTemplate = createChildrenNodes(nodeToFill, missingChildren, updateTemplate);
      return {
        updatedTemplate: updateTemplate,
        node: nodeToFill
      };
    };

  return {
    generateUniqueId,
    addNode,
  };
};

import { Popover } from 'antd';
import React, { useState } from 'react';
import clsx from 'clsx';
import { TooltipPlacement } from 'antd/lib/tooltip';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

import styles from './BurgerDropdown.module.scss';

import { ReactComponent as VerticalMenu } from 'assets/icons/vertical-menu.svg';

export type BurgerOption = {
  title: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
};

interface Props {
  options: BurgerOption[];
  optionClassName?: string;
  contentClassName?: string;
  placement?: TooltipPlacement;
}

const BurgerDropdown = ({
  options,
  optionClassName = '',
  contentClassName,
  placement = 'bottomRight'
}: Props) => {
  const [ open, setOpen ] = useState(false);

  return (
    <Popover
      trigger='focus'
      placement={ placement }
      open={ open }
      onOpenChange={ (_open) => setOpen(_open) }
      content={
        <div className={ clsx(styles.burgerOptions, contentClassName) }>
          {
            options.map((option) => (
              <button
                onClick={ option.onClick }
                disabled={ option.disabled }
                className={ `${ styles.option } ${ optionClassName }` }
                key={ option.title }
              >
                { option?.icon }
                { option.title }
              </button>
            ))
          }
        </div>
      }
    >
      <GhostIconButton
        aria-label='dropdown menu'
        onClick={ e => {
          e.stopPropagation();
          e.preventDefault();
        } }
      >
        <VerticalMenu />
      </GhostIconButton>
    </Popover>
  );
};

export default BurgerDropdown;

import React, { PropsWithChildren } from 'react';
import { SearchableContextProvider } from 'context/SearchableContext';
import Portal from 'components/elements/portal/Portal';
import { LEFT_PANEL_PORTAL_ID, RIGHT_PANEL_PORTAL_ID } from '../panel.constants';

interface Props {
  isActive: boolean;
}

interface PropsWithType extends Props {
  isActive: boolean;
  orientation: 'left' | 'right';
  customPortalId?: never;
}
interface PropsWithCustomPortalId extends Props {
  isActive: boolean;
  customPortalId: string;
  type?: never;
}

const PanelWrapper = (props: PropsWithChildren<PropsWithType | PropsWithCustomPortalId>) => {
  let portalId = '';
  if (props.isActive) {
    if (propsWithType(props)) {
      portalId = props.orientation === 'left' ? LEFT_PANEL_PORTAL_ID : RIGHT_PANEL_PORTAL_ID;
    } else {
      portalId = props.customPortalId;
    }
  }

  return (
    <Portal portalId={ portalId }>
      <SearchableContextProvider>
        <div style={
          { display: props.isActive ? 'flex' : 'none', flexFlow: 'column', height: '100%' }
        }>
          { props.children }
        </div>
      </SearchableContextProvider>
    </Portal>
  );
};

export default PanelWrapper;

function propsWithType (props: Props): props is PropsWithType {
  return (props as PropsWithType).orientation !== undefined;
}

import {
  isBreakdownNode,
  ReportData,
  ReportDataValues,
  ReportNode,
  ReportType,
  UserReport
} from '../types/templates.types';
import { FinancialRow, RowData, RowType } from '../types/financials.types';
import get from 'lodash/get';
import { store } from '../store/store';
import { max } from 'lodash';
import { DimensionItem } from '../types/filterTable.types';
import { arrayToMap } from '../utils/mapping.utils';

// These are utility functions that will process backend data and create an
// array of rows for display in the grid
// Used by `useGrid` hook
interface CreateRowParams {
  path: string[];
  node: ReportNode;
  rowData: RowData;
  type?: RowType;
  localRow?: boolean;
  data: ReportData;
  hasTransactions?: boolean;
}

export const handleNodes = (
  data: UserReport,
  templateId: number,
): FinancialRow[] => {

  const nodesMap = arrayToMap(data.nodes, 'id');

  const createRow = ({
    path,
    node,
    rowData,
    type,
    data: reportData = { [ ReportType.ACTUAL ]: {}, [ ReportType.PLAN ]: {} },
    localRow,
    hasTransactions = undefined,
  }: CreateRowParams): FinancialRow => {
    const isFailed = node.isFailed;
    const nodeType = type ? type : node.type;
    const hasChildren = node.children.length > 0;
    const negativeMultiplier = node.negativeMultiplier;

    const hasData = reportData && (
      (reportData.actual && !!Object.keys(reportData.actual).length) ||
      (reportData.plan && !!Object.keys(reportData.plan).length)
    );

    return {
      id: path.join('_') + '-' + rowData.id,
      filePath: path,
      rowData: rowData,
      type: nodeType,
      localRow: localRow || false,
      hasData: hasData,
      hasTransactions: hasTransactions === undefined ? hasData : hasTransactions,
      negativeMultiplier: negativeMultiplier,
      isFailed: isFailed,
      uuid: nodesMap[ +path.at(-1) ]?.uuid,
      asyncStatus: !hasChildren &&
        rowData?.name != 'equity_profit_and_loss' &&
          [
            RowType.FINANCIALS,
            RowType.DIMENSION_ITEM,
            RowType.UNASSIGNED
          ].includes(nodeType) ? 'not_loaded' : null,
      ...reportData
    };
  };

  const state = store.getState();
  const dimensionItemMap = state.breakdowns.dimensionItemMap;
  const dimensionMap = state.breakdowns.dimensionMap;
  const tableId = data.id || templateId;
  if (!state.financials.tables[ tableId ]) {
    return [];
  }
  const showDimensionLabels = state.financials.options.dimensionLabels;

  const handleBreakdownNode = (
    node: ReportNode & ({ type: RowType.BREAKDOWN }),
    path,
    breakdownPath,
    previousNodes: ReportNode[],
    level: number,
  ) => {
    let breakdownRows: FinancialRow[] = [];
    const actualNodeData = node.data?.[ ReportType.ACTUAL ] || {};
    const planNodeData = node.data?.[ ReportType.PLAN ] || {};

    const actualKeys = Object.keys(get(actualNodeData, breakdownPath) || actualNodeData);
    const planKeys = Object.keys(get(planNodeData, breakdownPath) || planNodeData);

    const keys = Array.from(new Set([ ...actualKeys, ...planKeys ]));
    if (isBreakdownNode(node)) {
      // This is just to be able to set "Unassigned" as a fictional DimensionItem id to keep
      // processing uniform for all keys.
      type DimensionItemOrUnassigned = (Omit<DimensionItem, 'id'> & { id: number | string });
      const itemsList: DimensionItemOrUnassigned[] = keys.filter(e => !isNaN(+e)).map(
        e => dimensionItemMap[ +e ]
      );
      itemsList.push({
        name: 'Unassigned',
        id: 'Unassigned',
        dimensionId: null,
        group: null,
        relation: null,
        account: null,
        breakdownType: null,
        contract: null,
        counterparty: null,
        product: null,
        customName: null,
        plType: null
      });

      if (showDimensionLabels || level === 0) {
        const baseName = node.rowData.name;
        breakdownRows.push(
          createRow({
            path,
            node,
            rowData: {
              ...node.rowData,
              name: isBreakdownNode(node) ?
                dimensionMap[ node.rowData.id ]?.customName || baseName : baseName,
            },
            data: {
              [ ReportType.ACTUAL ]:
              get(actualNodeData, [ ...breakdownPath ]) as ReportDataValues ||
              (breakdownPath.length ? {} : actualNodeData),
              [ ReportType.PLAN ]:
              get(planNodeData, [ ...breakdownPath ]) as ReportDataValues ||
                (breakdownPath.length ? {} : planNodeData)
            },
          })
        );
      } else {
        path.pop();
      }
      for (const item of itemsList) {
        if (!item || !keys.includes(item.id?.toString())) continue;
        const dimensionPath = [ ...path, item.id ];

        const rowData = {
          ...item,
          dimensionId: node.rowData.id
        };

        const actualData = get(actualNodeData, [ ...breakdownPath, item.id ]);
        const planData = get(planNodeData, [ ...breakdownPath, item.id ]);
        const hasActualTransactions = actualData ?
          (actualData?.hasTransactions ?? (node.hasTransactions && actualData.count !== 0))
          : false;
        const hasPlanTransactions = planData ?
          (planData?.hasTransactions ?? (node.hasTransactions && planData.count !== 0))
          : false;
        const hasTransactions = hasActualTransactions || hasPlanTransactions;

        if (node.children.length !== 0) {
          breakdownRows.push(
            createRow({
              path: dimensionPath,
              node,
              rowData,
              type: RowType.DIMENSION_ITEM,
              localRow: true,
              data: {
                [ ReportType.ACTUAL ]: actualData,
                [ ReportType.PLAN ]: planData
              },
              hasTransactions,
            })
          );
          if (actualData || planData) {
            for (const childId of node.children) {
              breakdownRows = [
                ...breakdownRows,
                ...handleNode(
                  childId,
                  [ ...dimensionPath ],
                  [ ...breakdownPath, item.id ],
                  [ ...previousNodes, node ],
                  level + 1,
                )
              ];
            }
          }
        } else {
          breakdownRows.push(
            createRow({
              path: dimensionPath,
              rowData,
              node,
              type: RowType.DIMENSION_ITEM,
              localRow: true,
              data: {
                [ ReportType.ACTUAL ]: actualData || {},
                [ ReportType.PLAN ]: planData || {},
              },
              hasTransactions,
            })
          );
        }
      }
    }

    return [ ...breakdownRows ];
  };

  const handleNode = (
    id: number | string,
    filePath?: string[],
    breakdownPath: string[] = [],
    previousNodes: ReportNode[] = [],
    level = 0,
  ): FinancialRow[] => {

    const node = nodesMap[ id ];
    if (!node) return [];
    const rows = [];
    const path = filePath ? [ ...filePath, node.id.toString() ] : [ node.id.toString() ];

    let innerRows = [];

    switch (node.type) {
      case RowType.BREAKDOWN:
        return [
          ...rows,
          ...handleBreakdownNode(node, path, breakdownPath, previousNodes, level)
        ];
      default: {
        let actualData: ReportDataValues;
        let planData: ReportDataValues;
        if (breakdownPath.length) {
          actualData = get(node.data.actual, [ ...breakdownPath ]);
          planData = get(node.data.plan, [ ...breakdownPath ]);
        } else {
          actualData = node.data?.actual || {};
          planData = node.data?.plan || {};
        }
        rows.push(
          createRow({
            path,
            node,
            rowData: node.rowData,
            data: {
              [ ReportType.ACTUAL ]: actualData || {},
              [ ReportType.PLAN ]: planData || {}
            },
            hasTransactions: node.hasTransactions,
          })
        );
        if (actualData || planData) {
          node.children.forEach(childId => {
            innerRows = [
              ...innerRows,
              ...handleNode(
                childId,
                path,
                breakdownPath,
                [ ...previousNodes, node ],
                level + 1
              )
            ];
          });
        }

        return [ ...rows, ...innerRows ];
      }

    }
  };

  let processedNodes = [];
  data.roots.forEach((rootId: number) => {
    processedNodes = [
      ...processedNodes,
      ...handleNode(rootId)
    ];
  });
  return processedNodes;
};

export const getMaxIndentation = (data: UserReport): number => {
  const nodesMap = arrayToMap(data.nodes, 'id');
  const countDepth = (id: number | string, parentDepth = 0): number => {
    const node = nodesMap[ id ];
    if (node && node.children.length) {
      return max(node.children.map(c => countDepth(c, parentDepth + 1)));
    }
    return parentDepth + 1;
  };
  return max(data.roots.map(rootId => countDepth(rootId)));

};

import React from 'react';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarSelectUtil, CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Duplicate } from 'assets/icons/duplicate.svg';
import { ReactComponent as Bin } from 'assets/icons/bin.svg';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useTemplateService from 'components/templates/hooks/useTemplateService';
import { useAppSelector } from 'store/hooks/hooks';
import { selectAllTemplates } from 'store/templates.slice';
import { UserTemplateShort } from 'types/templates.types';
import { selectTemplate } from 'store/template.slice';

interface Props {
  onCreateNewTemplate: () => void;
  onDeleteTemplate: (template: UserTemplateShort) => void;
}
const ctaUtil = defaults.find(u => u.key === 'cta') as CommandBarSelectUtil;

const useTemplateCommandBar = ({ onCreateNewTemplate, onDeleteTemplate }: Props) => {
  const [ t ] = useTranslation('financials');
  const template = useAppSelector(selectTemplate).id;
  const shortTemplate = useAppSelector(state => {
    const templates = selectAllTemplates(state);
    return templates.find(_template => _template.id === template);
  });
  const {
    copyTemplate,
    isSystemTemplate
  } = useTemplateService();

  const utilsToUpdate: CommandBarUtil[] = useMemo(() => ([
    {
      ...ctaUtil,
      hidden: false,
      disabled: false,
      options: [
        {
          label: t('common:form.create-new'),
          type: 'default',
          icon: <Plus />,
          onClick: onCreateNewTemplate,
        },
        ...(template ? [
          {
            label: t('common:form.duplicate'),
            type: 'default' as const,
            icon: <Duplicate />,
            onClick: () => copyTemplate(template),
          },
        ] : []),
        ...(!template || isSystemTemplate(template) ? [] : [
          {
            label: t('common:delete'),
            type: 'default' as const,
            icon: <Bin />,
            onClick: () => onDeleteTemplate(shortTemplate),
          }
        ])
      ]
    }
  ]), [ template, shortTemplate ]);

  return useCommandBarEffect({ utils: utilsToUpdate });
};

export default useTemplateCommandBar;
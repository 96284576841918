import React from 'react';
import { Checkbox, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import type { TableCoordinates } from '../overviewPanel.types';
import { ReactComponent as ArrowIcon } from 'assets/icons/short-arrow.svg';

import styles from './SelectEntries.module.scss';
import clsx from 'clsx';
import dayjs from 'dayjs';
import ItemTypeBadge from 'components/budget/ItemTypeBadge/ItemTypeBadge';
import { CURRENCY_SYMBOL } from 'components/financials/modals/dimensionSplitModal/split.utils';
import { separateThousands, roundTransactionAmount } from 'utils/financials.utils';
import AmountBadge from 'components/elements/badge/amount/AmountBadge';

interface Props {
  entriesList: TableCoordinates[];
  selectedEntries: TableCoordinates[];
  onEntriesSelected: (key: TableCoordinates[]) => void;
}

const SelectEntries = ({ entriesList, selectedEntries, onEntriesSelected }: Props) => {
  const { t } = useTranslation('financials');

  const areAllEntriesSelected = selectedEntries.length === entriesList.length;
  const areSomeEntriesSelected = selectedEntries.length > 0 &&
    selectedEntries.length < entriesList.length;

  const toggleAllEntries = () => {
    if (areAllEntriesSelected) {
      onEntriesSelected([ entriesList.at(0) ]);
    } else {
      onEntriesSelected(entriesList);
    }
  };

  const onCheckboxChange = (entry: TableCoordinates) => {
    if (selectedEntries.some(el => el === entry)) {
      if (selectedEntries.length === 1) return;
      onEntriesSelected(selectedEntries.filter(el => el !== entry));
    } else {
      onEntriesSelected([ ...selectedEntries, entry ]);
    }
  };

  const items = [
    {
      key: 'all',
      label: <div className={ clsx(styles.navigationItem, styles.navigationHeader ) }>
        <Checkbox
          indeterminate={ areSomeEntriesSelected }
          checked={ areAllEntriesSelected }
          onClick={ e => e.stopPropagation() }
          onChange={ e => {
            e.stopPropagation();
            toggleAllEntries();
          } }
        />
        <span
          className={ styles.toggleAllSelection }
          onClick={ (e) => {
            e?.stopPropagation();
            toggleAllEntries();
          } }
        >
          { areAllEntriesSelected ? t('common:select.deselectAll') : t('common:select.selectAll') }
        </span>
      </div>
    }
  ].concat(entriesList.map(el => {
    const key = `${ el.node.id }_${ el.column }`;
    return {
      key,
      onClick: (e) => {
        e?.domEvent?.stopPropagation();
        onEntriesSelected([ el ]);
      },
      label: <div className={ styles.navigationItem } onClick={ e => {
        e.stopPropagation();
        onEntriesSelected([ el ]);
      } }
      >
        <Checkbox
          checked={ selectedEntries.some(e => e === el) }
          onChange={ () => onCheckboxChange(el) }
          onClick={ (e) => {
            e?.stopPropagation();
          } }
        />
        { '  ' }
        <EntryLabel long entry={ el } />
      </div>
    };
  }));

  const switchToNextEntry = () => {
    const currentIndex = entriesList.findIndex(el => el === selectedEntries.at(0));
    const nextIndex = currentIndex + 1 >= entriesList.length ? 0 : currentIndex + 1;
    onEntriesSelected([ entriesList[ nextIndex ] ]);
  };

  const switchToPrevEntry = () => {
    const currentIndex = entriesList.findIndex(el => el === selectedEntries.at(0));
    const prevIndex = currentIndex - 1 < 0 ? entriesList.length - 1 : currentIndex - 1;
    onEntriesSelected([ entriesList[ prevIndex ] ]);
  };

  const canGoNext = selectedEntries.length === 1 && selectedEntries.at(0) !== entriesList.at(-1);
  const canGoPrev = selectedEntries.length === 1 && selectedEntries.at(0) !== entriesList.at(0);

  return (
    <div className={ styles.selectContainer }>
      <button
        type='button'
        onClick={ switchToPrevEntry }
        className={ clsx(
          styles.navigationArrow,
          styles.switchToPrev,
          { [ styles.disabled ]: !canGoPrev }
        ) }
      >
        <ArrowIcon width={ 12 } height={ 12 } />
      </button>
      <Dropdown
        className={ styles.dropdown }
        disabled={ entriesList.length < 2 }
        trigger={ [ 'click' ] }
        arrow={ false }
        overlayClassName={ clsx(styles.dropdownOverlay) }
        menu={ { items } }>
        <div className={ styles.selectedLabel }>
          
          {
            selectedEntries.length > 1 ?
              t('common:form.multiple') :
              <EntryLabel entry={ selectedEntries.at(0) } />
          }

        </div>
      </Dropdown>
      <button
        type='button'
        onClick={ switchToNextEntry }
        className={ clsx(
          styles.navigationArrow,
          styles.switchToNext,
          { [ styles.disabled ]: !canGoNext }
        ) }
      >
        <ArrowIcon width={ 12 } height={ 12 } />
      </button>
    </div>
  );
};

export default SelectEntries;

interface EntryLabelProps {
  entry: TableCoordinates;
  long?: boolean;
}

const EntryLabel = ({ entry, long }: EntryLabelProps) => {
  const { t } = useTranslation('financials');

  const element = entry.node.data.rowData;
  const entryDate = entry.column.split('_').at(-1);
  const amount = element.entries[ entryDate ]?.amountFormula;

  const date = entryDate ? dayjs(entryDate).format('DD.MM.YYYY') : null;

  return <div className={ styles.entryLabel }>
    <div className={ styles.dateAndLabels }>
      <div className={ styles.date }>
        { date }
      </div>
      <div className={ styles.labels }>
        <div className={ styles.tag }>
          { t('budget-short') }
        </div>
        <div>
          <ItemTypeBadge itemType={ element.budgetItemType }/>
        </div>
      </div>
    </div>
    { 
      long && <>
        <div className={ styles.counterparty }>
          { element.counterparty?.name }
        </div>
        <div className={ styles.description }>
          { element.memo }
        </div>
      </>
    }
    <div className={ clsx(styles.amount, { [ styles.long ]: long }) }>
      {
        isAmountNumber(amount) ? <span>
          { long ?
            <AmountBadge amount={ amount } className={ styles.amountBadge } /> :
            <>
              { formatAmount(amount) }
              { ' ' }
              { CURRENCY_SYMBOL }
            </>
          }
        </span> : <span>
          { amount }
        </span>
      }
    </div>
  </div>;
};

function isAmountNumber (amount: string) {
  return !isNaN(parseFloat(amount));
}

function formatAmount (num: number) {
  return separateThousands(roundTransactionAmount(num));
}

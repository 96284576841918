import React, { useCallback, useState } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'components/elements/button/Button';
import { ReactComponent as MenuIcon } from 'assets/icons/vertical-menu.svg';

import styles from './TemplateNodeOptionsPopover.module.scss';
import { TemplateNode } from '../../../../types/templates.types';
import clsx from 'clsx';
// eslint-disable-next-line max-len
import CalculateDynamicTotalsToggle from '../../customTemplate/formulaBuilder/calculateDynamicTotalsToggle/CalculateDynamicTotalsToggle';
import { useAppDispatch } from '../../../../store/hooks/hooks';
import { editTemplateNegativeMultiplier } from '../../../../store/template.slice';

interface Props {
  node: TemplateNode;
  className?: string;
}

const TemplateNodeOptionsPopover = ({ node, className }: Props) => {
  const [ t ] = useTranslation('financials');

  const dispatch = useAppDispatch();

  const [ isVisible, setIsVisible ] = useState(false);

  const onNegativeMultiplierChange = useCallback((value: boolean) => {
    dispatch(editTemplateNegativeMultiplier(node.id, value));
  }, []);

  return <Popover
    placement='bottomRight'
    showArrow={ false }
    open={ isVisible }
    onOpenChange={ (visible) => setIsVisible(visible) }
    overlayClassName={ clsx(styles.popover) }
    trigger='click'
    content={
      <div className={ styles.popoverContent } onClick={ e => e.stopPropagation() }>
        <div className={ styles.popoverActions }>
          <CalculateDynamicTotalsToggle
            value={ node.negativeMultiplier }
            onChange={ onNegativeMultiplierChange }
            labelText={ t('templates.template-node-negative-multiplier-label') }
            helpText={ t('templates.template-node-negative-multiplier-help-text') }
          />
        </div>

      </div>
    }
    mouseEnterDelay={ .3 }
  >
    <Button
      className={ clsx(className, styles.templateNodeOptionButton) }
      type='link'
      onClick={ (e) => e.stopPropagation() }
      tabIndex={ -1 }
    >
      <MenuIcon/>
    </Button>
  </Popover>;
};

export default TemplateNodeOptionsPopover;

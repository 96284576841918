import React, { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { selectTemplate, setTemplateSubtitle, setTemplateTitle } from 'store/template.slice';
import useEscapeListener from 'hooks/useEscapeListener';

import { Tooltip } from 'antd';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';

import styles from './TemplateTitle.module.scss';

import EditText from '../templateRow/editText/EditText';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { selectCustomFormula } from 'store/formula.slice';
import { selectAllTemplates } from '../../../store/templates.slice';
import { notifyError } from '../../../utils/notifications.utils';

type Props = {
  isSubtitle: boolean;
  disable: boolean;
};

const TemplateTitle = ({ isSubtitle, disable }: Props) => {
  const [ t ] = useTranslation('financials');
  const template = useAppSelector(selectTemplate);
  const dispatch = useAppDispatch();
  const editRef = useRef(null);
  const [ isEditing, setIsEditing ] = useState(false);
  useEscapeListener(editRef, () => setIsEditing(false), isEditing);
  const customFormula = useAppSelector(selectCustomFormula);

  const defaultTitle = isSubtitle ? template.subtitle : template.title;
  const titleEditPlaceholder = isSubtitle ? t('templates.edit-subtitle') : t('templates.edit-name');
  const defaultTitlePlaceholder = isSubtitle ?
    t('templates.default-template-subtitle') : t('templates.default-template-name');

  const allTemplates = useAppSelector(selectAllTemplates);

  const allTemplatesNamesSet = useMemo(() => {
    return new Set(allTemplates.map((templ) => templ.title));
  }, [ allTemplates ]);

  return (
    <div
      className={ `${ styles.content } ${ isSubtitle ? styles.contentSubtitle : '' }` }
      ref={ editRef }
    >
      { isEditing ? (
        <EditText
          setEditMode={ setIsEditing }
          editMode={ isEditing }
          disable={ disable }
          unSetError
          name= 'title'
          defaultTitle={ defaultTitle }
          onSave={ (newTitle: string) => {
            if (allTemplatesNamesSet.has(newTitle)) {
              notifyError(t('modals.new-template-modal.error'));
              return;
            }
            if (!isSubtitle) {
              newTitle && dispatch(setTemplateTitle(newTitle));
            } else {
              dispatch(setTemplateSubtitle(newTitle));
            }
          } }
          className={ `${ styles.title } ${ styles.inputTitle }` }
          saveOnEnter={ true }
        />
      ) : (
        <>
          <div className={
            `${ styles.title } ${ customFormula !== null ? styles.openFormula : '' }` }
          >
            { defaultTitle || defaultTitlePlaceholder }
          </div>
          <Tooltip title={ titleEditPlaceholder }>
            <button
              className={
                `${ styles.editButton } ${ customFormula !== null ? styles.openFormula : '' }` }
              onClick={ () => !disable && setIsEditing((prevState) => !prevState) }
            >
              <Edit />
            </button>
          </Tooltip>
        </>
      ) }
    </div>
  );
};

export default TemplateTitle;

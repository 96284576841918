import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './GroupSelection.module.scss';
import { useTranslation } from 'react-i18next';

import { GroupOption, GroupSettings, TableColDef }
  from '../../elements/tableWrapper/types/table.types';
import FlatSelect from '../../elements/flatSelect/FlatSelect';
import { AgGridReact } from 'ag-grid-react';
import { ColumnState } from 'ag-grid-community';
import clsx from 'clsx';
import { groupBy } from 'lodash';

interface Props {
  options?: GroupOption[];
  gridRef: React.MutableRefObject<AgGridReact>;
  isDisabled?: boolean;
  onSelect?: (settings: GroupSettings) => void;
}

interface SelectOption {
  label: string;
  value?: string;
  options?: SelectOption[];
}

const GroupSelection = ({
  options: inputOptions,
  gridRef,
  onSelect,
  isDisabled = false
}: Props) => {
  const [ t ] = useTranslation('common');
  const NONE_GROUPING: GroupOption = {
    field: '', name: t('left-panel.groupBy.options.no-grouping')
  };

  let options;

  if (inputOptions) {
    options = inputOptions;
  } else {
    options = gridRef.current?.api?.getColumns()?.map((column) => {
      const columnDef = column.getColDef() as TableColDef;
      if (!columnDef.headerName) {
        return null;
      }
      if (columnDef?.cellDataType !== 'text') {
        return {
          name: columnDef?.headerName,
          field: columnDef?.field,
        };
      }
      return {
        name: columnDef?.headerName,
        field: columnDef?.field,
        availableChoices: null,
      };
      
    }).filter((el) => el) as GroupOption[] ?? [];
  }

  const [ activeDropdown, setActiveDropdown ] = useState(true);
  const [ currentSettings, setCurrentSettings ] = useState<GroupSettings>({
    primary: NONE_GROUPING,
    secondary: NONE_GROUPING
  });

  const columnStateToOption = useCallback((columnState: ColumnState): GroupOption => {
    const column = gridRef.current?.api?.getColumn(columnState.colId);
    return {
      field: columnState.colId,
      name: column?.getColDef().headerName ?? '-',
    };
  }, [ options ]);

  const defaultGrouping = useMemo(() => {
    const columnState = gridRef.current?.api?.getColumnState();
    if (!columnState) {
      return { primary: NONE_GROUPING, secondary: NONE_GROUPING };
    }
    const primaryGroup = columnState.find(col => col.rowGroupIndex === 0);
    const secondaryGroup = columnState.find(col => col.rowGroupIndex === 1);
    return {
      primary: primaryGroup ? columnStateToOption(primaryGroup) : NONE_GROUPING,
      secondary: secondaryGroup ? columnStateToOption(secondaryGroup) : NONE_GROUPING
    };
  }, [ activeDropdown ]);

  useEffect(() => {
    setCurrentSettings(defaultGrouping);
  }, [ defaultGrouping ]);

  const onGroupSelected = useCallback((settings: GroupSettings) => {
    setActiveDropdown(true);
    if (onSelect) {
      onSelect(settings);
    }
  }, []);

  const onGroupChange = useCallback((section: 'primary' | 'secondary', option: GroupOption) => {
    const newSettings = { ...currentSettings, [ section ]: option };
    setCurrentSettings(newSettings);
    onGroupSelected(newSettings);
  }, [ currentSettings ]);

  const renderSection = useCallback((section: 'primary' | 'secondary', sectionTitle: string) => {
    const otherSettings = section === 'primary' ?
      currentSettings.secondary : currentSettings.primary;
    const optionsWithNone = [ NONE_GROUPING ]
      .concat(options.filter(item => item.field !== otherSettings.field))
      .map(option => ({ label: option.name, value: option.field, groupName: option.groupName }));

    const groupedOptions = groupBy(optionsWithNone, 'groupName');

    const selectOptions: SelectOption[] = [];
    for (const [ group, groupOptions ] of Object.entries(groupedOptions)) {
      if (group !== 'undefined') {
        selectOptions.push({ label: group, options: groupOptions });
      } else {
        selectOptions.push(...groupOptions);
      }
    }

    return <div className={ styles.section }>
      <p>
        { sectionTitle }
      </p>
      <FlatSelect
        options={ selectOptions }
        value={ currentSettings[ section ].field }
        className={ styles.select }
        listHeight={ 400 }
        disabled={ section === 'secondary' && otherSettings.field === '' }
        onSelect={ (value) => onGroupChange(
          section,
          options.find(item => item.field === value) || NONE_GROUPING
        ) }
      />
    </div>;
  }, [ options, currentSettings, onGroupChange ]);

  return (
    <div className={ clsx(styles.container, { [ styles.disabled ]: isDisabled }) }>
      <p className={ styles.title }>{ t('left-panel.groupBy.title') }</p>
      { renderSection('primary', t('left-panel.groupBy.primary.title')) }
      {
        options.length > 1 ?
          renderSection('secondary', t('left-panel.groupBy.secondary.title')) : null
      }
    </div>
  );
};

export default GroupSelection;

import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { notifyUnexpectedError } from '../utils/notifications.utils';

interface Props<T> {
  onSave: (items: T[]) => Promise<void>;
  items: T[];
  timeout: number;
}

const useAutoSaveEffect = <T>({ items, onSave, timeout }: Props<T>) => {
  const [ isAutoSaved, setIsAutoSaved ] = useState(false);

  const debouncedSave = useCallback(debounce(async (_items: T[]) => {
    try {
      await onSave(_items);
      setIsAutoSaved(true);
    } catch (e) {
      notifyUnexpectedError(e);
    }

  }, timeout), [ onSave ]);

  useEffect(() => {
    if (items.length > 0) {
      debouncedSave(items);
    }
  }, [ items ]);

  return { isAutoSaved, clearAutoSave: () => setIsAutoSaved(false) };
};

export default useAutoSaveEffect;

import { AgGridReact } from 'ag-grid-react';
import {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import { FilterList } from 'types/filterTable.types';
import BottomBar from './bottomBar/BottomBar';
import DetailsList from './detailsList/DetailsList';
import styles from './DimensionDetails.module.scss';
import TopBar from './topBar/TopBar';
import useDimension from '../../hooks/useDimension';
import { selectPeriod } from 'store/financials.slice';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { FILTERS_LIST_VIEW } from 'utils/storage.utils';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import { useSearchable } from '../../../../../context/SearchableContext';
import { getDimensionDisplayName } from '../../../../../utils/financials.utils';
import { useTranslation } from 'react-i18next';

interface Props{
  dimensionId: number;
  setDimensionDetails: (value: number | null) => void;
  outerFilters: FilterList | undefined;
  stagingFilter: FilterList | undefined;
  setStagingFilter: (value: FilterList) => void;
  templateId: number;
  gridRef: RefObject<AgGridReact>;
  defaultUnassignNodesCallback: UnassignFunction;
  mode: 'label' | 'filter';
  assignLabels: AssignFunction;
}

const DimensionDetails = ({
  dimensionId,
  setDimensionDetails,
  outerFilters,
  stagingFilter,
  templateId,
  gridRef,
  defaultUnassignNodesCallback,
  setStagingFilter,
  mode,
  assignLabels
}: Props) => {

  const [ t ] = useTranslation('financials');
  const templateFilters = useAppSelector(state => state.financials.tables[ templateId ]?.filter);
  const filters = outerFilters || templateFilters;
  const period = useAppSelector(selectPeriod(templateId));
  const { refetch } = useReportQuery({ templateId, period });
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const findDimension = useMemo(() =>
    dimensions.find(item => item.id === dimensionId), [ dimensions, dimensionId ]);

  const { state: { search } } = useSearchable();

  const searchedItems = useMemo(() => {
    if (!findDimension) return [];
    if (!search) return findDimension.items;
    return findDimension.items.filter(dimensionItem =>
      getDimensionDisplayName(dimensionItem).toLowerCase().includes(search.toLowerCase()));
  }, [ findDimension, search ]);

  const isUnassignedSearched = useMemo(() => {
    if (!search) return true;
    const translatedUnassignText = t('left-panel.unassigned');
    return translatedUnassignText.toLowerCase().includes(search.toLowerCase());
  }, [ search ]);

  const unassignedIsActive = useMemo(() => {
    if (!filters) {
      const stagingDimensionFiltersIndex = stagingFilter?.findIndex(
        (d) => d.dimension === findDimension?.id);
      if (stagingDimensionFiltersIndex === -1) {
        return true;
      }
      return !stagingFilter?.[ stagingDimensionFiltersIndex ]?.excludeUnassigned;
    }
    const dimensionFiltersIndex = filters.findIndex((d) => d.dimension === findDimension?.id);
    if (dimensionFiltersIndex === -1) {
      return true;
    }
    return !filters[ dimensionFiltersIndex ].excludeUnassigned;
  }, [ filters, findDimension ]);

  useEffect(() => {
    if (!filters) return;
    const dimensionFiltersIndex = filters.findIndex((d) => d.dimension === findDimension?.id);
    setActiveUnassigned(!filters[ dimensionFiltersIndex ]?.excludeUnassigned);
  }, [ filters, findDimension ]);

  const [ activeUnassigned, setActiveUnassigned ] = useState(unassignedIsActive);

  const [ isListView ] = useLocalStorage(FILTERS_LIST_VIEW, true);

  const {
    onDimensionEdit,
    onDimensionDelete,
    onDimensionItemAdd
  } = useDimension({ dimension: findDimension });

  const onAddDimensionItem = useCallback(async (name: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: { name: string; contract?: Record<string, any> } = { name };
    await onDimensionItemAdd(payload);
  }, [ dimensions ]);
  return (
    <div className={ styles.container }>
      <TopBar
        templateId={ templateId }
        dimension={ findDimension }
        onBack={ () => setDimensionDetails(null) }
      />
      <div className={ styles.dimensionContainer }>
        <BottomBar
          dimensionType={ findDimension?.type }
          isTemplateBuilder={ false }
          onAddDimensionItem={ onAddDimensionItem }
          onDimensionDelete={ async () => {
            await onDimensionDelete();
            setDimensionDetails(null);
            if (templateId)
              refetch();
          } }
          onDimensionEdit={ onDimensionEdit }
          stagingFilter={ stagingFilter }
          setStagingFilter={ setStagingFilter }
          setActiveUnassigned={ setActiveUnassigned }
          dimension={ findDimension }
          items={ searchedItems }
          isUnassignedSearched={ isUnassignedSearched }
          isLabeling={ mode === 'label' }
        />
        <DynamicScrollableContainer className={ styles.dynamicScroll }>
          <DetailsList
            showUnassignedButton={ isUnassignedSearched }
            gridRef={ gridRef }
            activeUnassigned={ activeUnassigned }
            setActiveUnassigned={ setActiveUnassigned }
            setStagingFilter={ setStagingFilter }
            stagingFilter={ stagingFilter }
            isListView={ isListView }
            templateId={ templateId }
            dimension={ findDimension }
            items={ searchedItems }
            defaultUnassignNodesCallback={ defaultUnassignNodesCallback }
            assignLabels={ assignLabels }
            isLabeling={ mode === 'label' }
          />
        </DynamicScrollableContainer>
      </div>

    </div>
  );
};

export default DimensionDetails;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellClassParams,
  CellRange,
  ColDef,
  Column,
  GetContextMenuItemsParams,
  ICellRendererParams,
  IRowNode,
  MenuItemDef,
  RowDragCallbackParams,
  RowDragEndEvent,
  RowDragMoveEvent,
  RowDropZoneParams,
  ValueFormatterParams,
  ValueParserParams
} from 'ag-grid-community';
import dayjs from 'dayjs';
import { cloneDeep, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import useRevenueGrid from 'hooks/useRevenueGrid';

import { notifyError } from 'utils/notifications.utils';
import { autoGroupColumnDef } from 'utils/gridUtils.utils';
import organizationsService, {
  ProfitLossType,
  TransactionType
} from 'services/organizations.service';
import Loader from 'components/elements/loader/Loader';
import Card from 'components/elements/card/Card';
import TopBar from 'components/revenueRecognition/topBar/TopBar';
import { separateThousands } from 'utils/financials.utils';
import ChevronRight from 'assets/icons/chevron-right-dark.svg';
import Drag from 'assets/icons/drag.svg';
import styles from './InvoicesTable.module.scss';
import productStyles from '../products/Products.module.scss';
import { Product, RowType } from 'types/revenueRecognition.types';
import {
  createProductRows,
  filterInvoiceTable,
  getProductsToUpdate,
  isUnassignedNode,
  mapTransactions,
  transactionColumnField,
  UNASSIGNED_ROW_ID,
  UNASSIGNED_ROW_NAME
} from './invoicesTable.utils';
import {
  addSelectProduct,
  fetchAndSetProductList,
  selectAllProduct,
  selectProduct
} from 'store/revenueRecognition.slice';
import useGridDragStyles from
  'components/singleRevenueRecognition/invoicesTable/hooks/useGridDragStyles';
import {
  deleteRevenueRecognition,
  recognizeRevenue
} from 'services/revenueRecognition.service';
import { ReactComponent as ContainerIcon } from 'assets/icons/container.svg';
import Tooltip from '../../elements/tooltip/Tooltip';
import CustomCellRenderer from './CustomCellRenderer';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import InvoiceModal from 'components/financials/invoiceModal/InvoiceModal';
import useLocationDate from './hooks/useLocationDate';
import { fetchAndSetDimensions } from 'store/breakdowns.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';

type Props = {
  id: number;
};

const InvoicesTable = ({ id }: Props) => {
  const [ t ] = useTranslation('revenueRecognition');
  const gridRef = useRef<AgGridReact>(null);
  const useGrid = useRevenueGrid(styles, true, gridRef);
  const gridDragStyles = useGridDragStyles();
  const dispatch = useAppDispatch();
  useLocationDate();
  const period = useAppSelector(state=> state.revenueRecognition.table.period);
  const selectedProducts = useAppSelector(state =>
    state.revenueRecognition.products.selectProductsList).filter(el => el.id !== -1);
  const allProducts = useAppSelector(selectProduct);
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);
  const selectedProductsIds = useMemo(() => {
    return selectedProducts.filter(el => el.id !== -1).map(product => product.id);
  }, [ selectedProducts ]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ allProductsToSelect, setAllProductsToSelect ] = useState(allProducts);
  const [ invoiceTransaction, setInvoiceTransaction ] = useState<IRowNode>(null);

  const {
    state: { filterProduct },
  } = useRevenueRecognition();

  useEffect(() => {
    if (!allProductsToSelect.length) {
      dispatch(fetchAndSetProductList(
        (data) => setAllProductsToSelect(data)
      ));
    }
    if (!dimensions.length) {
      dispatch(fetchAndSetDimensions());
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getRows(selectedProducts).finally(() => setIsLoading(false));
  }, [ period, allProductsToSelect ]);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [ filterProduct ]);

  useEffect(() => {

    updateSelectedProducts();

    const clickSubmitButton = async (event: KeyboardEvent) => {
      const selectedRanges = gridRef.current?.api.getCellRanges();
      const selectedNodes = gridRef.current?.api.getSelectedNodes();

      if (event.code === 'Enter' && (selectedRanges.length || selectedNodes.length)) {
        await submitSelectedRecognition(!(selectedRanges.length));
        gridRef.current?.api.clearRangeSelection();
        gridRef.current?.api.deselectAll();
      } else if (event.code === 'Escape') {
        gridRef.current?.api.clearRangeSelection();
        gridRef.current?.api.deselectAll();
      }
    };

    document.addEventListener('keydown', clickSubmitButton);
    return () => document.removeEventListener('keydown', clickSubmitButton);
  }, [ selectedProducts ]);

  useEffect(() => {
    if (!allProducts.length) {
      return;
    }
    const dropZonesParams = addRowDropZones();
    return () => {
      dropZonesParams.forEach((zone) => {
        gridRef?.current?.api?.removeRowDropZone(zone);
      });
    };
  }, [ allProducts, selectedProducts ]);

  const getRows = useCallback(async (productsSelected: Product[]) => {
    const unassignedRow =
      createProductRows([ { name: UNASSIGNED_ROW_NAME, id: UNASSIGNED_ROW_ID } ])[ 0 ];

    const selectedProductsRows = createProductRows(productsSelected);

    const res = await organizationsService.getRecognizedTransactionList(
      {
        startDate: dayjs.unix(period.startDate).format(),
        endDate: dayjs.unix(period.endDate).format(),
        counterpartyId: id,
        plTypes: [ ProfitLossType.INCOME ],
        excludedTransactionTypes: [ TransactionType.SYSTEM_ADJUSTMENT ]
      }
    );

    const resultsIsEmpty = res.data.results.length !== 0;
    const mappedData = mapTransactions(res.data.results, unassignedRow.filePath);

    const noEmptyProduct = [ ...Array.from(new Set(mappedData.filter(el => el.product !== null)
      .map(el => el.product.name))) ];
    const filterProd = allProductsToSelect.filter(item => noEmptyProduct.includes(item.name));

    resultsIsEmpty
      ? gridRef?.current?.api.setGridOption(
        'rowData',
        [ ...mappedData, ...selectedProductsRows, unassignedRow ]
      )
      : gridRef?.current?.api.setGridOption('rowData', []);

    dispatch(selectAllProduct(filterProd));

    gridRef?.current?.api.forEachNode(node => {
      node.expanded = true;
    });
    gridRef?.current?.api.onGroupExpandedOrCollapsed();
  }, [ period, allProductsToSelect ]);

  const isGroupColumn = (c: Column): boolean => {
    const colId = c?.getColId();
    return colId === 'ag-Grid-AutoColumn' || colId === 'TypeColumn';
  };

  const addRowDropZones = () => {
    const products = document.querySelectorAll('.collapse');
    const dropZonesParams: RowDropZoneParams[] = [];
    products.forEach(product => {
      if (parseInt(product.id) < 1) {
        return;
      }
      const dropZoneParams: RowDropZoneParams = {
        getContainer: () => product as HTMLElement,
        onDragStop: (params) => {
          let nodes = params.nodes;
          if (params.node.group) {
            nodes = params.node.allLeafChildren;
          }
          assignTransactions(nodes, product.id, true);
          product.classList.remove(productStyles.productHover);
        },
        onDragEnter: () => {
          product.classList.add(productStyles.productHover);
        },
        onDragLeave: () => {
          product.classList.remove(productStyles.productHover);
        }
      };
      dropZonesParams.push(dropZoneParams);
      gridRef?.current?.api?.addRowDropZone(dropZoneParams);
    });
    return dropZonesParams;
  };

  const updateRows = useCallback(async (rowNodes: IRowNode[]) => {
    await getRows(selectedProducts);
    gridRef.current?.api.refreshCells({ force: true, rowNodes });
  }, [ selectedProducts, allProductsToSelect ]);

  const bulkRecognition =
    useCallback(async (rowNodes: IRowNode[], callback: (row: IRowNode) => void) => {
      setIsLoading(true);
      for (const node of rowNodes) {
        await callback(node);
      }
      await updateRows(rowNodes);
      setIsLoading(false);
    }, [ selectedProducts ]);

  const submitSelectedRecognition = async (isAutoRecognition: boolean) => {
    const selectedRanges = gridRef.current?.api.getCellRanges() as CellRange[];
    const selectedRange = selectedRanges[ 0 ];
    const allColumns = gridRef.current?.api.getColumns() as Column[];
    const selectedNodes = gridRef.current?.api.getSelectedNodes() as IRowNode[];

    const submit = async (row: IRowNode) => {
      if (row.data.isRecognized && isAutoRecognition) return;
      const data = [];
      const transactionColumn = allColumns
        .find(c => dayjs(c.getColId()).isSame(row.data.transactionDate, 'month'));
      const selectedColumns = isAutoRecognition ? [ transactionColumn ] : selectedRange?.columns;
      const recognizedValue = -row.data.amount / selectedColumns.length;
      for (const col of selectedColumns) {
        const date = dayjs(col.getColId()).format('YYYY-MM-DD');
        if (date !== 'Invalid Date') {
          data.push({ date: date, amount: recognizedValue });
        }
      }
      if (!data.length) {
        return;
      }
      try {
        await recognizeRevenue(row.data.id, data);
      } catch (e) {
        notifyError(
          t('revenue-recognition.recognition-error.message',
            { ns: 'revenueRecognition' })
        );
        throw e;
      }
    };
    await bulkRecognition(selectedNodes, submit);
  };

  const deleteRecognition = async (rowNodes: IRowNode[]) => {
    const submit = async (row: IRowNode) => {
      if (!row.data.isRecognized) return;
      try {
        await deleteRevenueRecognition(row.data?.id);
      } catch (e) {
        notifyError(
          t('revenue-recognition.recognition-delete-error.message',
            { ns: 'revenueRecognition' })
        );
        throw e;
      }
    };
    bulkRecognition(rowNodes, submit);
  };

  const getIRowNodes = useCallback(() => {
    const rows = [];
    gridRef?.current?.api?.forEachNode(node => rows.push(node));
    return rows;
  }, [ gridRef ]);

  const updateSelectedProducts = () => {
    const nodes = getIRowNodes();

    if (!nodes) {
      return;
    }
    const { nodesToRemove, nodesToAdd } =
      getProductsToUpdate(nodes, selectedProducts, selectedProductsIds);

    nodesToRemove.forEach((node) => {
      node.setExpanded(false);
    });
    gridRef?.current?.api?.applyTransaction({ remove: nodesToRemove });
    gridRef?.current?.api?.applyTransaction(
      { addIndex: 0, add: createProductRows(nodesToAdd) }
    );
  };

  const columnDefs = useMemo(() => {
    return useGrid.getColumnDefs().columnDefs;
  }, [ period ]);

  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const node = params.node;
    const isRecognized: boolean = node.data?.isRecognized;
    const options = [];
    params.node.setSelected(true);

    options.push('copy');
    if (!isGroupColumn(params.column)) {
      options.push('separator');
      if (isRecognized) {
        options.push({
          name: t('context-menu.mark-as-undone'),
          action: async () => {
            await deleteRecognition([ node ]);
            gridRef.current?.api.clearRangeSelection();
          }
        });
      } else {
        options.push({
          name: t('context-menu.mark-as-done'),
          action: async () => {
            await submitSelectedRecognition(false);
            gridRef.current?.api.clearRangeSelection();
          }
        });
      }
    } else {
      const selectedNodes = params.api.getSelectedNodes();
      if (node.data.type === RowType.INVOICE) {
        options.push('separator');
        selectedNodes.length === 1 && options.push({
          name: t('context-menu.view-invoice'),
          action: () => setInvoiceTransaction(node),
        });
      }
      options.push({
        name: t('context-menu.mark-as-done'),
        action: async () => {
          await submitSelectedRecognition(true);
        }
      });
      options.push({
        name: t('context-menu.mark-as-undone'),
        action: async () => {
          await deleteRecognition(selectedNodes);
        }
      });
    }

    return options;
  };

  const originalTransactionCellRenderer = params => {
    const isRecognized = params.data.isRecognized;
    const tooltipText = t('tooltip.original-transaction', { originalValue: params.data.amount });
    return <Tooltip title={ isRecognized ? tooltipText : null }>
      <div>
        { params.valueFormatted }
      </div>
    </Tooltip>;
  };

  const isOriginalInvoiceCell = (params: CellClassParams | ICellRendererParams) => {
    const field = params.colDef.field;
    const transactionDate = params.data.transactionDate;
    const transactionInBeginningOfMonth =
      dayjs(transactionDate).startOf('month').format('YYYY-MM-DD') + 'T00:00:00';
    return field === transactionInBeginningOfMonth;
  };

  const defaultColDef = useMemo((): ColDef => (
    {
      aggFunc: null,
      cellClass: styles.cell,
      width: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        const value = params.value;
        return value ? separateThousands(Math.round(value)) : null;
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        if (params.data && isOriginalInvoiceCell(params)) {
          return { component: originalTransactionCellRenderer };
        }
        return undefined;
      },
      cellClassRules: {
        valueCell: (params: CellClassParams): boolean => {
          if (params.data) {
            const isRecognized = params.data.isRecognized;
            return isNumber(params.value) && !isRecognized;
          }
          return false;

        },
        originalTransactionCell: (params: CellClassParams): boolean => {
          if (params.data) {
            const isRecognized = params.data.isRecognized;
            const isOriginalValueCell = isOriginalInvoiceCell(params);
            return isRecognized && isOriginalValueCell;
          }
          return false;

        },
        recognized: (params: CellClassParams): boolean => {
          if (params.data && params.data.type !== RowType.PRODUCT) {
            const isNumeric = isNumber(params.value);
            const isRecognized = params.data?.isRecognized;
            const isOriginalValueCell = isOriginalInvoiceCell(params);

            if (isOriginalValueCell && !params.data.isOriginalTransactionOverlapping) {
              return false;
            }
            return isNumeric && isRecognized;
          }
          return false;
        },
      },
    }
  ), []);

  const columnTypes = useMemo(() => ({
    valueColumn: {
      aggFunc: null,
      valueParser: (props: ValueParserParams) => {
        const number = parseInt(props.newValue);
        return Number.isInteger(number) ? number : null;
      }
    },
  }), []);

  const assignTransactions = (nodes: IRowNode[], productId: string, isAssigningOnPill = false) => {
    const filteredNodes = nodes.filter(node => {
      return node.parent.id !== productId &&
        node.id !== productId &&
        node.data.type === RowType.INVOICE;
    });
    if (!filteredNodes.length) {
      return;
    }

    const productsSelected = cloneDeep(selectedProducts);
    if (isAssigningOnPill && !selectedProductsIds.includes(+productId)) {
      const productToSelect = allProducts.find(p => p.id === +productId);
      dispatch(addSelectProduct(productToSelect));
      productsSelected.unshift(productToSelect);
    }
    const nodeIds = filteredNodes.map(node => +node.id);

    if (+productId === UNASSIGNED_ROW_ID) {
      organizationsService.clearTransactionsProduct({ transactionLines: nodeIds })
        .then()
        .catch(() => {
          notifyError(
            t('notifications.unexpected-error.message',
              { ns: 'common' })
          );
        })
        .finally(() => {
          getRows(productsSelected);
        });
    } else {
      organizationsService.addProductToTransaction({
        product: productId,
        transactionLines: nodeIds
      }).catch(() => {
        notifyError(
          t('notifications.unexpected-error.message',
            { ns: 'common' })
        );
      }).finally(() => {
        getRows(productsSelected);
      });

    }
    nodes.forEach((node) => {
      node.setSelected(false);
    });
  };

  const isProxyChild = (node: IRowNode) => {
    const key = node.parent.key;
    return typeof key === 'string';
  };

  const doesExternalFilterPass = (node: IRowNode) => {

    const filePathRootItem = node.data?.filePath[ 0 ];

    if (isUnassignedNode(node)) {
      return true;
    }

    if (isUnassignedNode(node.parent)) {
      return filterInvoiceTable(node, filterProduct.index);
    }

    if (isProxyChild(node)) {
      if (selectedProductsIds.includes(+filePathRootItem) || isUnassignedNode(node.parent.parent))
        return filterInvoiceTable(node, filterProduct.index);
      return false;
    }

    if (node.data?.type === RowType.PRODUCT) {
      return selectedProductsIds.includes(+filePathRootItem);
    }

    if (!selectedProductsIds.includes(+filePathRootItem)) {
      return false;
    }

    return filterInvoiceTable(node, filterProduct.index);
  };

  const onRowDragEnd = (event: RowDragEndEvent) => {
    gridDragStyles.clearHoveredRow();
    let nodesToAssign = event.nodes;
    if (event.node.group) {
      nodesToAssign = event.node.allLeafChildren;
    }
    const overNode = event.overNode;
    if (!overNode) {
      return;
    }
    const overNodeId = overNode.data.type === RowType.INVOICE ? overNode.parent.id : overNode.id;
    assignTransactions(nodesToAssign, overNodeId);

    overNode.setExpanded(true);

    gridRef.current.api?.refreshCells();
  };

  const onRowDragMove = useCallback((e: RowDragMoveEvent) => {
    gridRef.current?.api.clearRangeSelection();
    gridDragStyles.onRowDragMove(e);
  }, []);

  const onRowDragLeave = useCallback(() => {
    gridDragStyles.clearHoveredRow();
  }, []);

  const noRowsOverlayComponent = () => (
    <div className={ styles.emptyTableMessage } style={ { fontSize:'40px' } }>
      <ContainerIcon className={ styles.icon } />
      <span className={ styles.subtitle }>
        { t('no-data-message') }
      </span>
    </div>
  );

  const onCellClicked = (params) => {
    if (params.data &&
      (params.column.colId === 'ag-Grid-AutoColumn' || params.column.pinned === 'left')) {
      const fieldName = transactionColumnField(params.data);
      gridRef.current?.api.ensureColumnVisible(fieldName, 'start');
    }
  };

  const onFirstDataRendered = useCallback(() => {
    const rowNodes = getIRowNodes();
    const mappedData = rowNodes
      .filter(node => node.data?.type !== RowType.PRODUCT)
      .map((node) => node.data);
    const transactionDate = mappedData
      .filter(el => el != null)
      .map(el => Object.keys(el).find(date => dayjs(date).isValid()));

    const theEarliestDate = transactionDate.length > 0 ? transactionDate.sort()[ 0 ] : null;
    if (theEarliestDate) {
      const transaction =
        mappedData[ transactionDate.findIndex(el => el.includes(theEarliestDate)) ];
      const fieldName = transactionColumnField(transaction);
      gridRef.current?.api.ensureColumnVisible(fieldName, 'start');
    }
  }, [ gridRef ]);

  const isInvoiceVisible = useMemo(() => invoiceTransaction != null, [ invoiceTransaction ]);

  return (
    <>
      <Card className={ styles.invoicesTable }>
        <Loader isActive={ isLoading }/>
        <TopBar
          header=''
          description=''
          className={ styles.topBar }/>
        <div className={ `ag-theme-alpine ${ styles.table }` }>
          { dimensions.length && <AgGridReact
            ref={ gridRef }
            animateRows={ true }
            columnDefs={ columnDefs }
            suppressMovableColumns={ true }
            treeData={ true }
            getDataPath={ row => row.filePath }
            enableRangeSelection={ true }
            suppressMultiRangeSelection={ true }
            onFirstDataRendered={ onFirstDataRendered }
            onCellClicked={ onCellClicked }
            getContextMenuItems={ getContextMenuItems }
            noRowsOverlayComponent={ noRowsOverlayComponent }
            onRangeSelectionChanged={ params => {
              const selectedRange = gridRef.current?.api.getCellRanges()[ 0 ];
              if (!selectedRange) {
                return;
              }
              const rowNode = gridRef.current?.api.getDisplayedRowAtIndex(
                selectedRange.startRow.rowIndex
              );
              if (
                rowNode.data?.type === RowType.PRODUCT ||
              selectedRange.startRow.rowIndex != selectedRange.endRow.rowIndex ||
              selectedRange.columns.some(c => isGroupColumn(c))
              ) {
                params.api.clearRangeSelection();
              }
            } }
            suppressCellFocus={ true }
            getRowId={ row => row.data.id }
            defaultColDef={ defaultColDef }
            columnTypes={ columnTypes }
            rowHeight={ 45 }
            headerHeight={ 34 }
            autoGroupColumnDef={ { ...autoGroupColumnDef(null, styles),
              cellRenderer: 'agGroupCellRenderer',
              cellRendererParams: {
                suppressCount: true,
                suppressEnterExpand: true,
                innerRenderer: CustomCellRenderer
              },
              rowDragText:(params) => params?.rowNode?.data?.memo || params?.rowNode?.key,
              sort:'asc',
              rowDrag: (params: RowDragCallbackParams) => {
                if (params.node.data) {
                  return params.node.data?.type !== RowType.PRODUCT;
                }
                return true;
              },
            } }
            groupDisplayType='singleColumn'
            icons={ {
              groupContracted: `<img src='${ ChevronRight }'  alt='Arrow'/>`,
              rowDrag: `<img style="width: 17px" src='${ Drag }'  alt='Drag'/>`,
            } }
            rowSelection='multiple'
            isRowSelectable={ node => node.data?.type === RowType.INVOICE }
            rowDragMultiRow={ true }
            onRowDragMove={ onRowDragMove }
            onRowDragEnd={ onRowDragEnd }
            onRowDragLeave={ onRowDragLeave }
            isExternalFilterPresent={ () => true }
            excludeChildrenWhenTreeDataFiltering={ true }
            doesExternalFilterPass={ doesExternalFilterPass }
          /> }
        </div>
      </Card>
      <InvoiceModal
        isVisible={ isInvoiceVisible }
        onClose={ () => setInvoiceTransaction(null) }
        transaction={ invoiceTransaction?.data }
      />
    </>
  );
};

export default InvoicesTable;

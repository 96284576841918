import React, { PropsWithChildren, ReactNode } from 'react';

import styles from './SettingsSection.module.scss';

interface Props {
  title: ReactNode;
}

const SettingsSection = ({ title, children }: PropsWithChildren<Props>) => {
  return <div className={ styles.section }>
    <div className={ styles.sectionTitle }>
      { title }
    </div>
    <div className={ styles.options }>
      { children }
    </div>
  </div>;
};

export default SettingsSection;

import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { IRowNode } from 'ag-grid-community';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useAppSelector } from 'store/hooks/hooks';
import { useForm, useWatch } from 'react-hook-form';
import { Button, Form } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import AddFieldDropdown from './addFieldDropdown/AddFieldDropdown';
import FieldProxy from './editors/FieldProxy';
import useFieldsDefinitions, { durationMap } from './useFieldDefinitions';
import type { DimensionItem } from 'types/filterTable.types';
import dayjs, { QUnitType } from 'dayjs';
import { useTranslation, } from 'react-i18next';

import { useGetInputsQuery } from 'store/api/inputs.api';
import { Cadence } from 'types/form.types';
import {
  COLUMN_DATE_FORMAT,
  createUpdates,
  fieldToIconMap,
  getDefaultsForMultiple,
  setPlaceholdersForDimensions,
} from './budgetFields.utils';

import { isBudgetItemType } from '../../../../../types/budget.types';
import { getActiveOrganizationIdCookie } from 'utils/auth.utils';
import { FormStepSections } from 'components/financials/panels/overviewPanel/useBudgetItemForm';

import styles from './OverviewFormSections.module.scss';
import InstancesCalendar from './instancesCalendar/InstancesCalendar';
import { FrequencyUnit } from 'types/financials.types';
import { Duration } from 'types/contracts.types';

import { cloneDeep } from 'lodash';

interface LSFields {
  added: string[];
  removed: string[];
}

const showCalendar = false;

const BUDGET_ITEM_FIELDS_LS_KEY = (
  organizationId: string, budgetItemTypeId: number, step: number
) => `edit-budget-item-fields-${ organizationId }-${ budgetItemTypeId }-${ step }`;

// const CLOSE_KEY_BINDING = { code: 'Escape' };

interface Props {
  activeStep: number;
  gridApi: AgGridReact['api'];
  selectedBudgetItemType: number;
  changeBudgetItemType: (id: number) => void;
  entriesList: { node: IRowNode; column: string }[];
  cadence: Cadence;
  formSections: FormStepSections[ 'steps' ][ number ][ 'sections' ];
  setHasChanges?: (hasChanges: boolean) => void;
}

const OverviewFormSections = (
  {
    activeStep,
    gridApi,
    selectedBudgetItemType,
    entriesList,
    setHasChanges,
    changeBudgetItemType,
    formSections,
    cadence = Cadence.month
  }: Props) => {
  const { t } = useTranslation('financials');

  const containerRef = useRef<HTMLDivElement>(null);

  const placeholderMap = useRef(new Map<string, string>());

  const dimensionsMap = useAppSelector(state => state.breakdowns.dimensionMap);
  const dimensionsItemsMap = useAppSelector(state => state.breakdowns.dimensionItemMap);

  const { data: inputsResponseData } = useGetInputsQuery({});
  const inputs = inputsResponseData?.results || [];

  const { fieldsMap, groups } = useFieldsDefinitions();

  const salesBudgetItem = useAppSelector(state => state.budget.budgetItemTypes)
    .find(item => item.name?.[ 'en' ] === 'Sales');

  const {
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultsForMultiple(
      entriesList,
      salesBudgetItem,
      placeholderMap.current
    ) 
  });

  const [ savedFields, setSavedFields ] = useLocalStorage<LSFields>(
    BUDGET_ITEM_FIELDS_LS_KEY(getActiveOrganizationIdCookie(), selectedBudgetItemType, activeStep),
    {
      added: [],
      removed: []
    }
  );

  useLayoutEffect(() => {
    if (setHasChanges) {
      setHasChanges(formState.isDirty);
    }
  }, [ formState.isDirty ]);

  const visibleFields = useMemo(() => {
    const visibleSections = cloneDeep(formSections);

    let otherSection = visibleSections.find(section => section.name === 'OTHER');

    if (!otherSection) {
      otherSection = { name: 'OTHER', fields: [] };
      visibleSections.push(otherSection);
    }
    
    if (savedFields.added.length) {
      savedFields.added.forEach(field => {
        if (!otherSection.fields.some(f => f.name === field)) {
          otherSection.fields.push({ name: field, isMandatory: false });
        }
      });
    }

    const dimensionsFromFields = Array.from(
      new Set(
        visibleSections.flatMap(section => section.fields.map(field => field.name))
          .filter(name => name.startsWith('dimension'))
      )
    );

    const dimensions = Array.from(
      new Set(
        entriesList.flatMap(({ node }) => node?.data?.rowData?.dimensionItems
          .map((dimensionItem: DimensionItem) => dimensionsMap[ dimensionItem.dimensionId ])
          .filter(dimension => dimension != null && dimension.customName !== 'Counterparty')
          .map((dimension) => `dimension.${ dimension.id }`)
          .filter(dimension => !dimensionsFromFields?.includes(dimension))
        )
      )
    );

    otherSection.fields = [
      ...otherSection.fields,
      ...dimensions.map(dimension => ({ name: dimension }))
    ];

    if (savedFields.removed.length) {
      visibleSections.forEach(section => {
        section.fields = section.fields.filter(field => {
          return !savedFields.removed.find(removedField => {
            return removedField === field.name;
          });
        });
      });
    }

    setPlaceholdersForDimensions(
      placeholderMap.current,
      entriesList.map(({ node }) => node),
      [ ...dimensions, ...dimensionsFromFields ],
    );

    return visibleSections.filter(section => section.fields.length > 0);
  }, [ savedFields, entriesList, formSections ]);

  const onFieldSelected = useCallback((id: string) => {
    if (visibleFields.find(section => section.fields.find(field => field.name === id))) return;

    const isInRemoved = savedFields.removed.includes(id);

    if (isInRemoved) {
      setSavedFields({
        ...savedFields,
        removed: savedFields.removed.filter(f => f !== id)
      });
    } else {
      setSavedFields({
        ...savedFields,
        added: [ ...savedFields.added, id ]
      });
    }

    const values = getValues();
    placeholderMap.current.clear();
    const defaults = getDefaultsForMultiple(
      entriesList, salesBudgetItem, placeholderMap.current
    );
    reset({ ...values, [ id ]: defaults[ id ] });
  }, [ visibleFields ]);

  const onFieldRemove = useCallback((id: string) => {
    const isInAdded = savedFields.added.includes(id);

    if (isInAdded) {
      setSavedFields({
        ...savedFields,
        added: savedFields.added.filter(f => f !== id)
      });
    } else {
      setSavedFields({
        ...savedFields,
        removed: [ ...savedFields.removed, id ]
      });
    }
  }, [ visibleFields ]);

  const restoreDefaultFields = useCallback(() => {
    setSavedFields({
      added: [],
      removed: []
    });

    const defaults = getDefaultsForMultiple(
      entriesList, salesBudgetItem, placeholderMap.current
    );
    reset(defaults);
  }, [ entriesList ]);

  // const closeOnEnter = useCallback(() => onClose(), [ onClose ]);
  // useKeyPressListener({ keyBinding: CLOSE_KEY_BINDING, cb: closeOnEnter });

  const getUpdateData = (data) => {
    // ? Don't apply undefined in multiple entries edit as it can override multiple values fields
    if (entriesList.length > 1) {
      for (const item in data) {
        if (data[ item ] == null) {
          delete data[ item ];
        }
      }
    }

    // ? for column placement
    const startDate = dayjs(data.startDate ?? null).startOf(cadence).format(COLUMN_DATE_FORMAT);

    const updates = createUpdates(
      data,
      dimensionsItemsMap,
      dimensionsMap,
      startDate,
      entriesList
    );

    return updates;
  };

  const submit = async (data) => {
    const isValid = await trigger();
    if (!isValid) return;

    const updates = getUpdateData(data);

    gridApi.applyTransaction({ update: updates });
  };

  const onFieldChange = async (field: string, value: unknown) => {
    if (field === 'budgetItemType' && isBudgetItemType(value)) {
      changeBudgetItemType(value?.id);
      if (value.defaultAccounts.primary) {
        setValue('accounts', value.defaultAccounts);
      }
    }

    handleSubmit(submit)();
  };

  const endDate = useWatch({ control, name: 'endDate' });

  const formConfig = useMemo(() => {
    if (endDate) {
      return {
        duration: {
          disabled: true,
          message: t('inline-edit.warnings.duration-disclaimer'),
          messageType: 'warning'
        } as const
      };
    } else {
      return {};
    }

  }, [ endDate ]);

  useEffect(() => {
    placeholderMap.current.clear();
    reset(getDefaultsForMultiple(entriesList, salesBudgetItem, placeholderMap.current));
  }, [ entriesList ]);

  const frequency = watch('invoicingFrequency');
  const duration = watch('duration');
  const startDate = watch('startDate');

  const instances = useMemo(() => getAllInstances(
    entriesList,
    startDate,
    frequency,
    endDate,
    duration
  ), [ frequency, duration, startDate, endDate ]);

  return (
    <div ref={ containerRef } className={ styles.container }>

      <Form aria-label='form' className={ styles.form }>
        <div className={ styles.sectionsContainer }>

          { visibleFields.map(section => {
            return <Fragment key={ section.name }>
              <div className={ styles.section }>
                <div className={ styles.sectionName }>
                  { section.name }
                </div>
                <div className={ styles.sectionFields }>
                  { section.fields.map(({ name, isMandatory }) => {
                    return <FieldProxy
                      key={ name }
                      formConfig={ formConfig }
                      inputs={ inputs }
                      prefixIcon={ fieldToIconMap[ name ] }
                      control={ control }
                      isMandatory={ isMandatory }
                      onChange={ onFieldChange }
                      remove={ () => onFieldRemove(name) }
                      fieldName={ name }
                      errors={ formState.errors }
                      getValues={ getValues }
                      multipleSelected={ entriesList.length > 1 }
                      field={ fieldsMap.get(name) }
                      placeholder={ placeholderMap.current.get(name) }
                    />;
                  }) }
                </div>
              </div>

              {
                showCalendar && section.name === 'RECURRENCE' &&
                <div key='INSTANCES' className={ styles.section }>
                  <div className={ styles.sectionName }>
                    { t('inline-edit.sections.instances') }
                    { '  ' }
                    { instances.length }
                  </div>
                  <InstancesCalendar instances={ instances } />
                </div>
              }
            </Fragment>;
          }) }
          <div className={ styles.restoreDefaultSection }>
            <Button type='link' onClick={ restoreDefaultFields }>
              { t('inline-edit.fields.restoreDefaults') }
            </Button>
          </div>

          <div className={ styles.addFieldContainer }>
            <AddFieldDropdown fields={ groups } onSelect={ onFieldSelected }/>
            
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OverviewFormSections;

function getEntryInstances(
  entry: { node: IRowNode; column: string },
  formStartDate: string | null,
  formInvoicingFrequency: { amount: number; unit: FrequencyUnit } | null,
  formEndDate: string | null,
  formDuration: { id: Duration } | null
) {
  const {
    startDate: entryStartDate,
    endDate: entryEndDate,
    invoicingFrequency: entryInvoicingFrequency,
    duration: entryDuration
  } = entry.node.data.rowData;

  const startDate = formStartDate ?? entryStartDate;
  const invoicingFrequency = formInvoicingFrequency ?? entryInvoicingFrequency;
  const endDate = formEndDate ?? entryEndDate;
  const duration = formDuration ?? entryDuration;

  const start = dayjs(startDate ?? entryStartDate);
  let end = null;

  if (endDate) {
    end = dayjs(endDate);
  } else if (duration?.id) {
    end = dayjs(start).add(durationMap[ duration.id ] - 1, 'month');
  }

  const instances = [ { date: start.format(COLUMN_DATE_FORMAT) } ];

  if (!invoicingFrequency || invoicingFrequency?.unit === 'one_time') {
    return instances;
  }

  if (end) {
    let current = start;
    while (current.isBefore(end)) {
      instances.push({ date: current.format(COLUMN_DATE_FORMAT) });
      current = current.add(invoicingFrequency.amount, invoicingFrequency.unit as QUnitType);
    }
  } else {
    let current = start;
    // TODO: Limit to 36 months ? get from budget settings
    while (current.isBefore(dayjs(start).add(36, 'months'))) {
      instances.push({ date: current.format(COLUMN_DATE_FORMAT) });
      current = current.add(invoicingFrequency.amount, invoicingFrequency.unit as QUnitType);
    }
  }

  return instances;
}

function getAllInstances (
  entriesList: { node: IRowNode; column: string }[],
  formStartDate: string,
  formInvoicingFrequency: { amount: number; unit: FrequencyUnit },
  formEndDate: string,
  formDuration: { id: Duration }
) {
  const instances = entriesList.reduce<{ date: string }[]>((acc, entry) => {
    return [ ...acc, ...getEntryInstances(
      entry,
      formStartDate,
      formInvoicingFrequency,
      formEndDate,
      formDuration
    ) ];
  }, []);

  return instances;
}

import { WSBudgetEvent, WSMessage, WSTypes } from '../types/events.types';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { endBudgetGeneration, updateBudgetGeneration } from '../../store/budget.slice';
import { notifyBudgetGenerationErrors } from '../../utils/notifications.utils';

const useBudgetMessage = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.auth.user.pk);
  const activeBudgetId = useAppSelector(state => state.budget.activeBudgetId);

  const processMessage = useCallback((message: WSMessage) => {
    if (message.type !== WSTypes.BUDGET) return;
    if (message.invoker_id !== userId) return;

    if (message.budget_id !== activeBudgetId) return;

    switch (message.event) {
      case WSBudgetEvent.GENERATION_START:
        return dispatch(updateBudgetGeneration({
          status: 'started',
          totalSteps: message[ 'total_steps' ],
          step: 0,
        }));
      case WSBudgetEvent.GENERATION_STEP:
        return dispatch(updateBudgetGeneration({
          status: 'started',
          totalSteps: message[ 'total_steps' ],
          step: message.step,
        }));
      case WSBudgetEvent.GENERATION_STEP_FAILED:
        notifyBudgetGenerationErrors();
        return dispatch(updateBudgetGeneration({
          status: 'started',
          totalSteps: message[ 'total_steps' ],
          step: message.step,
          error: true,
        }));
      case WSBudgetEvent.GENERATION_END:
        return dispatch(endBudgetGeneration());
    }
  }, [ activeBudgetId, userId ]);

  return {
    processMessage
  };
};

export default useBudgetMessage;

import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { noop } from 'lodash';
import { Dropdown, Form } from 'antd';
import { FieldArrayWithId, useFieldArray, useForm, useWatch } from 'react-hook-form';

import type { Transaction } from 'types/statutory.types';
import { numberFormatter } from 'utils/common.utils';
import Button from 'components/elements/button/Button';
import organizationsService from 'services/organizations.service';
import Modal from 'components/elements/modal/Modal';

import { ReactComponent as SplitIcon } from 'assets/icons/arrow-split.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

import styles from './DimensionSplitModal.module.scss';
import DimensionSplit from './dimensionSplit/DimensionSplit';
import { notifyBackendValidationErrors } from 'utils/notifications.utils';
import validationSchema from './validation';
import type { FormType } from './dimensionSplit.types';
import type { BudgetItem } from 'types/budget.types';
import {
  getDimensionSplits,
  getExistingSplits,
  getLabel,
  getUnassignableBudgetItemSplitsForDelete,
  isAnyNaN,
  isTransaction
} from './split.utils';
import SplitItemLabel from './splitItemLabel/SplitItemLabel';
import { useAppSelector } from 'store/hooks/hooks';
import useSplitItemDefault from './useSplitDefault';
import axios from 'axios';
import InfoBox from 'components/elements/infoBox/InfoBox';
import { emptyDimension, SplitCloseMessages, SplitMessages } from './splits.constants';
import {
  isTransactionLineRequestParams,
  TransactionLineRequestParams
} from '../../../../services/statutory.service';
import { TransactionLineBulkActionFilters } from '../../../../types/templates.types';

interface DimensionSplitModalProps {
  splitItems: (TransactionLineRequestParams | Transaction | BudgetItem)[];
  onClose: (message?: SplitCloseMessages, data?: { updated: BudgetItem[] }) => void;
  onMessage: (message: SplitMessages, data?: { updated: BudgetItem[] }) => void;
  overrideItemCount: number;
  totalItemCount: number;
}

export const areTransactionLineRequestParams = (
  items: (Transaction | BudgetItem | TransactionLineRequestParams)[]):
  items is TransactionLineRequestParams[] => {

  return items.every(isTransactionLineRequestParams);
};

const ModalContent = (
  {
    splitItems,
    onClose,
    onMessage,
    overrideItemCount,
    totalItemCount,
  }: DimensionSplitModalProps) => {
  const [ t ] = useTranslation('financials');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);

  // ? Only for transactions, for keeping track of splits that will be deleted with footer button
  // ? as we don't want to refresh underlining ssrm transaction grid
  const [ permaDeleted, setPermaDeleted ] = useState<number[]>([]);

  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);
  const dimensionItemMap = useAppSelector(state => state.breakdowns.dimensionItemMap);

  const saveSplits = async (splits: { dimensionItem: number; percentage: number }[]) => {

    const firstItem = splitItems.at(0);

    if (isTransactionLineRequestParams(firstItem)) {
      await organizationsService.assignTransactionLines({
        filters: splitItems,
        dimensionSplit: splits,
        unassignDimensionIds: []
      });

      onClose('split:created');
      return ;
    }

    try {
      if (isTransaction(firstItem)) {
        if (splits.length > 0) {
          await organizationsService.addDimensionToTransaction({
            transactionLines: (splitItems as unknown as Transaction[]).map(tl => tl.id),
            split: splits
          });
        }

        onClose('split:created');
      } else {
        const fullDimensionItems = (splitItems as BudgetItem[])
          .map(item => item.dimensionSplit?.filter(d => {
            const isFull = Number(d.percentage) === 100;
            // If we are creating new split and there is already full dimension item assignment,
            // we should remove it.
            const isOverridden = splits.some(s =>
              dimensionItemMap[ s.dimensionItem ].dimensionId ===
              dimensionItemMap[ d.dimensionItem ].dimensionId);
            return isFull && !isOverridden;
          }));

        const oldSplits = (splitItems as BudgetItem[])
          .map(item => item.dimensionSplit?.filter(d => Number(d.percentage) !== 100));

        const updated = (splitItems as BudgetItem[]).map((b, index) => ({
          ...b,
          dimensionSplit: [
            ...splits,
            ...(oldSplits?.[ index ] ?? []),
            ...(fullDimensionItems?.[ index ] ?? [])
          ]
            .filter((split, i, self) => {
              return self.findIndex(s => s.dimensionItem === split.dimensionItem) === i;
            })
        }));

        updated.forEach(item => {
          item.dimensionItemIds = [
            ...item.dimensionItemIds,
            ...(item.dimensionSplit?.map(d => d.dimensionItem) ?? [])
          ].filter((id, index, self) => self.indexOf(id) === index);
        });

        updated.forEach(item => {
          // TODO: Type verification needed
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          item.dimensionItems = item.dimensionItemIds.map(id => dimensionItemMap[ id ]) as any;
        });

        onClose('split:created', { updated });
      }

    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status === 400) {
        notifyBackendValidationErrors(e);
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createSplit = async (data: FormType) => {
    const split: { dimensionItem: number; percentage: number; draft: boolean }[] = [];
    data.dimensions
      .filter(dimension => dimension.dimension !== null)
      .filter(
        dimension => !dimension.labels.every(label => typeof label.dimensionItemId === 'string')
      )
      .forEach(dimension => {
        dimension.labels
          .filter(label => label.dimensionItemId != null)
          .forEach(label => {
            split.push({
              dimensionItem: label.dimensionItemId as number,
              percentage: label.percentage,
              draft: label.draft
            });
          });
      });

    setIsLoading(true);

    await saveSplits(split);
  };

  const onDelete = async () => {
    if (areTransactionLineRequestParams(splitItems)) {
      await deleteSplits(splitItems);
    } else {
      const existingSplits = getExistingSplits(splitItems as (Transaction | BudgetItem)[]);

      if (existingSplits.length === 0) {
        onClose();
      } else {
        await deleteSplits(splitItems);
      }
    }

  };

  const deleteSplits = async (
    splits: (BudgetItem | Transaction | TransactionLineRequestParams)[]) => {

    const firstItem = splits.at(0);
    if (isTransaction(firstItem) || isTransactionLineRequestParams(firstItem)) {

      const dimensions = fields.map(
        (field) => field.dimension
      );

      let filters: TransactionLineBulkActionFilters[];

      if (isTransaction(firstItem)) {
        filters = [ { transactionLines: splitItems.map((item: Transaction) => item.id) } ];
      } else if (areTransactionLineRequestParams(splitItems)) {
        filters = splitItems;
      }

      try {
        await organizationsService.assignTransactionLines({
          filters,
          dimensionSplit: [],
          unassignDimensionIds: dimensions
        });

        await triggerValidation();
        onClose('split:deleted');

      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.status === 400) {
          notifyBackendValidationErrors(e);
        }
        throw e;
      } finally {
        setIsDeleting(false);
      }
    } else {
      const fullDimensionItems = (splitItems as BudgetItem[])
        .map(item => item.dimensionSplit?.filter(d => Number(d.percentage) === 100));

      const updated = (splitItems as BudgetItem[]).map((b, index) => ({
        ...b,
        dimensionSplit: [
          ...(fullDimensionItems?.[ index ] ?? []),
          ...(getUnassignableBudgetItemSplitsForDelete(b, dimensionItemMap, dimensionMap) ?? [])
        ]
      }));

      onClose('split:deleted', { updated });
    }
  };

  const getDefaultValues = useSplitItemDefault();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
    trigger: triggerValidation
  } = useForm<FormType>({
    resolver: async(...args) => await yupResolver(validationSchema())(...args),
    mode: 'all',
    defaultValues: async () => {
      setTimeout(focusFirstInput, 400);
      setIsLoading(true);
      const defaults = await getDefaultValues(splitItems, getNominalValue(splitItems));
      setIsLoading(false);
      return defaults;
    }
  });

  const { fields, append, remove } = useFieldArray({
    name: 'dimensions',
    control
  });

  const dimensions = useWatch({ control, name: 'dimensions' });

  const addDimension = () => {
    append(emptyDimension);
    triggerValidation();
  };

  const availableItems = splitItems.map((item) => ({
    label: <SplitItemLabel splitItem={ item } />,
    key: isTransactionLineRequestParams(item) ? 'todo' : item.id
  }));

  const getSelectedItemsLabel = (
    items: (Transaction | BudgetItem | TransactionLineRequestParams)[]) => {
    if (items.length === 1) {
      return getLabel(items[ 0 ]);
    } else {
      return t('dimension-split.modal.multiple');
    }
  };

  const nominalValue = getNominalValue(splitItems);

  const focusFirstInput = () => {
    const values = getValues();
    if (values.dimensions.length === 1 && values.dimensions.at(0).dimension == null) {
      (document.querySelector('input#dimension_0') as HTMLInputElement)?.focus();
    }
  };

  const total = getSplitItemsSum(splitItems);

  const splitsWillBeDeleted = useMemo(() => {
    if (areTransactionLineRequestParams(splitItems)) {
      return overrideItemCount * fields.length;
    } else {
      return getDimensionSplits(
        splitItems as (Transaction | BudgetItem)[] ).length - permaDeleted.length;
    }
  }, [ splitItems, permaDeleted, overrideItemCount, fields ]);

  const removeDraft = async (index: number) => {
    remove(index);
    await triggerValidation();
  };

  const deleteDimension = async (
    field: FieldArrayWithId<FormType, 'dimensions', 'id'>,
    index: number
  ) => {
    if (!dimensionMap[ Number(field.dimension) ]) throw new Error('Dimension not found');

    const firstItem = splitItems.at(0);

    if (isTransaction(firstItem) || isTransactionLineRequestParams(firstItem)) {
      const dimension = field.dimension;
      let filters: TransactionLineBulkActionFilters[];
      if (isTransaction(firstItem)) {
        filters = [ { transactionLines: splitItems.map((item: Transaction) => item.id) } ];
      } else if (areTransactionLineRequestParams(splitItems)) {
        filters = splitItems;
      }

      try {
        await organizationsService.assignTransactionLines({
          filters,
          dimensionSplit: [],
          unassignDimensionIds: [ field.dimension ]
        });

        remove(index);
        await triggerValidation();

        setPermaDeleted(perv => [ ...perv, dimension ]);
        onMessage('split:dimensionRemoved');
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.status === 400) {
          notifyBackendValidationErrors(e);
        }
        throw e;
      } finally {
        setIsDeleting(false);
      }

    } else {
      const updated = (splitItems as BudgetItem[]).map((b) => {
        let dimensionUpdate = [];

        if (!dimensionMap[ Number(field.dimension) ].canBeUnassigned) {
          dimensionUpdate = (
            getUnassignableBudgetItemSplitsForDelete(b, dimensionItemMap, dimensionMap) ?? []
          )
            .filter(d => d.dimension === field.dimension);
        }

        return {
          ...b,
          dimensionSplit: [
            ...b.dimensionSplit
              .filter(d => dimensionItemMap[ d.dimensionItem ].dimensionId !== field.dimension),
            ...dimensionUpdate
          ]
        };
      });

      onMessage('split:dimensionRemoved', { updated });

      remove(index);
      triggerValidation();
    }
  };

  const splitItemsType = useMemo(() => {

    const firstItem = splitItems.at(0);
    if (isTransactionLineRequestParams(firstItem)) {
      return t('mixed-label');
    }

    if (isTransaction(firstItem)) {
      if ((splitItems as Transaction[]).some(item => item.budgetItem)) {
        return t('mixed-label');
      }
      return t('actual-label');
    } else {
      return t('budget-label');
    }
  }, [ splitItems ]);

  const getSelectedCount = () => {
    if (areTransactionLineRequestParams(splitItems)) {
      return overrideItemCount;
    }
    return splitItems.length;
  };

  return <Form className={ styles.form } onFinish={ handleSubmit(createSplit) }>

    <div className={ styles.formContent }>
      <div>
        <label className={ styles.label }>
          <span>
            { t('dimension-split.modal.selected') }
            { '  ' }
            <span className={ styles.splitsCount }>
              { getSelectedCount() }
              { totalItemCount && ` ${ t('common:words.of') } ${ totalItemCount }` }
            </span>
          </span>
          <span className={ styles.total }>
            { total }
          </span>
        </label>

        <div>
          <Dropdown
            className={ styles.tlsDropdown }
            disabled={ availableItems.length === 0 }
            overlayClassName={ styles.tlsDropdownOverlay }
            getPopupContainer={ (trigger) => trigger }
            menu={ { items: availableItems } }>
            <div className={ styles.selectedTLsLabel }>

              <span className={ styles.description }>
                <div className={ styles.tlsBadge }>
                  { splitItemsType }
                </div>
                { getSelectedItemsLabel(splitItems) }
              </span>

              <span className={ styles.splitsDropdownIcon }>
                <ChevronDown />
              </span>

            </div>
          </Dropdown>
        </div>
      </div>

      <div className={ styles.dimensions }>
        {
          fields.map((field, index) => {
            return <DimensionSplit
              key={ field.id }
              index={ index }
              errors={ errors }
              isDraft={ (field).draft }
              nominalValue={ nominalValue }
              control={ control }
              selectedDimensions={ dimensions.map(d => d.dimension) }
              reset={ reset }
              getValues={ getValues }
              deleteDimension={
                (isDraft) =>
                  isDraft ?
                    removeDraft(index) :
                    deleteDimension(field, index)
              }
              setValue={ setValue }
            />;
          })
        }

      </div>
    </div>

    <div className={ styles.bottomActions }>
      <Button
        onClick={ addDimension }
        type='default'
        size='large'
        disabled={ isLoading }
        className={ styles.addDimension }
        icon={ <AddIcon /> }
      >
        { t('dimension-split.modal.add-dimension') }
      </Button>

      { (errors?.dimensions as unknown as Record<string, string>)?.message && (
        <InfoBox type='warning'>
          { (errors?.dimensions as unknown as Record<string, string>)?.message }
        </InfoBox>
      ) }
    </div>

    <div className={ styles.footerButtons }>
      { splitsWillBeDeleted > 0 ? <Button
        onClick={ onDelete }
        disabled={ isLoading }
        loading={ isDeleting }
        danger
        type='link'
      >
        { t('dimension-split.modal.deleteSplit') }
        { '  ' }
        (
        { splitsWillBeDeleted }
        )
      </Button> : <span></span>
      }
      <Button
        className={ styles.submitButton }
        type='primary'
        htmlType='submit'
        disabled={ isDeleting }
        loading={ isLoading }
        size='large'
      >
        { t('common:form.apply') }
      </Button>
    </div>
  </Form>;
};

interface Props extends DimensionSplitModalProps {
  isOpen: boolean;
  onMessage: (message: SplitMessages, data?: { updated: BudgetItem[] }) => void;
  overrideItemCount: number;
}

const DimensionSplitModal = (
  { 
    isOpen, 
    onClose, 
    onMessage: passMessage, 
    splitItems, 
    overrideItemCount,
    totalItemCount,
  }: Props) => {
  const [ t ] = useTranslation('financials');

  const [ requireRefresh, setRequireRefresh ] = useState(false);

  const onMessage = (message: SplitMessages, data?: { updated: BudgetItem[] }) => {
    if (message === 'split:dimensionRemoved' && data?.updated) {
      passMessage(message, data);
      return;
    }

    if (message === 'split:dimensionRemoved') {
      setRequireRefresh(true);
    }
  };

  const close = (message?: SplitCloseMessages, data?: { updated: BudgetItem[] }) => {
    if (message || data?.updated) {
      onClose(message, data);
    } else if (requireRefresh) {
      onClose('split:deleted');
    } else {
      onClose();
    }
    setRequireRefresh(false);
  };

  return <Modal
    isVisible={ isOpen }
    onConfirm={ noop }
    disableButtons
    destroyOnClose
    confirmDisabled
    className={ styles.modal }
    title={ <>
      <SplitIcon />
      <p>
        { t('dimension-split.modal.title') }
      </p>
    </> }
    onClose={ () => close() }
  >
    <>
      { isOpen ?
        <ModalContent
          splitItems={ splitItems }
          onClose={ close }
          onMessage={ onMessage }
          overrideItemCount={ overrideItemCount }
          totalItemCount={ totalItemCount }
        /> :
        null
      }
    </>
  </Modal>;
};

export default DimensionSplitModal;

function getAmount(splitItem: Transaction | BudgetItem | TransactionLineRequestParams) {
  if (isTransactionLineRequestParams(splitItem)) {
    return 0;
  } else {
    return isTransaction(splitItem) ? Number(splitItem.amount) : splitItem.amountFormula;
  }
}

function getNominalValue (
  splitItems: (TransactionLineRequestParams | Transaction | BudgetItem)[]): number | null {

  if (areTransactionLineRequestParams(splitItems)) {
    return null;
  }
  if (splitItems.length === 1) {
    const amount = Number(getAmount(splitItems.at(0)));
    return isNaN(amount) ? null : amount;
  } else {
    return null;
  }
}

function getSplitItemsSum(
  splitItems: (Transaction | BudgetItem | TransactionLineRequestParams)[]): string | null {

  if (isAnyNaN(splitItems.map(getAmount))) return null;

  const sum = numberFormatter(
    splitItems.reduce((acc, item) => acc + (getAmount(item) as number), 0),
    true
  );

  if (sum.includes('NaN')) {
    return null;
  }

  return sum;
}

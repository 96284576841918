import clsx from 'clsx';
import Tooltip from 'components/elements/tooltip/Tooltip';
import { PropsWithChildren, ReactNode } from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import styles from '../Form.module.scss';

interface LabelProps {
  label: ReactNode;
  name?: string;
  className?: string;
  labelTooltip?: string;
  tooltipIcon?: ReactNode | 'default';
}

const Label = ({
  children,
  label,
  labelTooltip,
  tooltipIcon,
  name,
  className
}: PropsWithChildren<LabelProps>) => (
  <label className={ clsx(styles.label, className) } htmlFor={ name }>
    <span className={ styles.labelInfo }>
      <span className={ styles.labelText }>{ label }</span>
      { labelTooltip && (
        <Tooltip title={ labelTooltip }>
          { tooltipIcon === 'default' ? <InfoIcon className={ styles.infoIcon } /> : tooltipIcon }
        </Tooltip>
      ) }
    </span>
    { children }
  </label>
);

export default Label;

import { Budget } from 'types/budget.types';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import SearchInput from 'components/elements/searchBar/searchInput/SearchInput';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContextMenu from 'components/elements/contextMenu/ContextMenu';
import { ContextMenuItem } from 'components/elements/contextMenu/contextMenu.types';

import styles from './BudgetsList.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import Modal from 'components/elements/modal/Modal';
import Button from 'components/elements/button/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { useDeleteBudgetMutation, useDuplicateBudgetMutation } from 'store/api/planning.api';
import { setActiveBudget } from 'store/budget.slice';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-bin.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate-file.svg';
import dayjs from 'dayjs';
import { handleError } from 'components/budget/utils/budget.utils';

const useDebouncedValue = (value: string, delay = 300) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [ value, delay ]);

  return debouncedValue;
};

interface Props {
  budgets: Budget[];
  onBudgetSelect: (id: number | null) => void;
}

const BudgetsList = ({ budgets, onBudgetSelect }: Props) => {
  const [ t ] = useTranslation('common');

  const [ search, setSearch ] = useState('');
  const activeBudgetId = useAppSelector(state => state.budget.activeBudgetId);

  const debouncedSearch = useDebouncedValue(search);

  const filteredBudgets = budgets
    .filter(budget => budget.name.toLowerCase().includes(debouncedSearch.toLowerCase()));

  const groupedByYear = filteredBudgets.reduce<Record<string, Budget[]>>((acc, budget) => {
    const year = dayjs(budget.startDate).year();
    if (!year) {
      return acc;
    }

    if (!acc[ year ]) {
      acc[ year ] = [];
    }

    acc[ year ].push(budget);

    return acc;
  }, {});

  return <div className={ styles.budgetsList }>
    <div className={ styles.search }>
      <SearchInput
        value={ search }
        onClear={ () => setSearch('') }
        onChange={ (e) => setSearch(e.target.value) }
        placeholder={ t('search.placeholder') }
      />
    </div>
    <div className={ styles.addBudget }>
      <Button
        type='default'
        icon={ <PlusIcon /> }
        onClick={ () => onBudgetSelect(0) }
      >
        { t('budget:panel.newBudgetButton') }
      </Button>
    </div>
    <div className={ styles.list }>
      {
        groupedByYear && Object.keys(groupedByYear)
          .sort((a, b) => Number(b) - Number(a))
          .map(year => (
            <div key={ year }>
              <div className={ styles.year }>{ year }</div>
              {
                groupedByYear[ year ].map(budget => (
                  <BudgetElement
                    key={ budget.id }
                    onBudgetSelect={ onBudgetSelect }
                    budget={ budget }
                    active={ activeBudgetId === budget.id }
                  />
                ))
              }
            </div>
          ))
      }
    </div>
  </div>;
};

export default BudgetsList;

interface BudgetProps {
  budget: Budget;
  active: boolean;
  onBudgetSelect: (id: number) => void;
}

const BudgetElement = ({ budget, active, onBudgetSelect }: BudgetProps) => {
  const [ t ] = useTranslation('budget');
  const [ deleteBudgetModalVisible, setDeleteBudgetModalVisible ] = useState(false);
  const appDispatch = useAppDispatch();

  const [ duplicateBudget ] = useDuplicateBudgetMutation();
  const [ deleteBudget ] = useDeleteBudgetMutation();

  const setBudgetAsActive = () => {
    appDispatch(setActiveBudget(budget));
  };

  const createBudgetDuplicate = async () => {
    try {
      const result = await duplicateBudget(budget.id).unwrap();

      onBudgetSelect(result.id);
    } catch (error) {
      handleError(error);
    }
      
  };

  const onBudgetDelete = async () => {
    try {
      await deleteBudget(budget.id);
    } catch (error) {
      handleError(error);
    }
  };

  const items: ContextMenuItem[] = [
    {
      name: t('panel.setAsActive'),
      action: setBudgetAsActive,
      icon: <CheckIcon />,
      disabled: active
    },
    {
      name: t('common:delete'),
      icon: <DeleteIcon />,
      action: () => {
        setDeleteBudgetModalVisible(true);
      },
      disabled: active
    },
    {
      name: t('common:form.duplicate'),
      icon: <DuplicateIcon />,
      action: createBudgetDuplicate,
    }
  ];
  
  return (<>
    <ContextMenu items={ items }>
      <div className={ styles.element } onClick={ () => onBudgetSelect(budget.id) }>
        <span className={ styles.name }>
          { budget.name }
          { active && <div className={ styles.dot } /> }
        </span>
        <ChevronRight />
      </div>
    </ContextMenu>
    <Modal
      isVisible={ deleteBudgetModalVisible }
      isDangerous
      onClose={ () => setDeleteBudgetModalVisible(false) }
      title={ t('deleteBudgetModal.title') }
      description={ t('deleteBudgetModal.description', { name: budget.name }) as string }
      onConfirm={ onBudgetDelete }
    />
  </>);
};

import { useMemo } from 'react';
import enGB from 'antd/lib/locale/en_GB';
import fiFI from 'antd/lib/locale/fi_FI';
import 'dayjs/locale/en-gb';
import { useTranslation } from 'react-i18next';

const useDatePickerLocale = () => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => {
    const languageLocale = {
      'en': enGB.DatePicker,
      'fi': fiFI.DatePicker
    };
    return languageLocale[ i18n.language ];
  }, [ i18n.language ]);

  return locale;
};

export default useDatePickerLocale;

import { Dayjs } from 'dayjs';
import { DimensionItemWithDimension } from './filterTable.types';
import { Counterparty } from './statutory.types';

export type CounterpartyLabelingRule = {
  id: number;
  counterparty: number;
  dimensionItem: number;
  startDate: string;
  endDate: string;
};

export type CounterpartyLabelingRuleCreate = Omit<CounterpartyLabelingRule, 'id'>;

export type CounterpartyLabelingRuleRemove = {
  counterparty: number;
  dimension: number;
};

export interface CounterpartyLabelingRuleElement {
  id: number;
  counterparty: Counterparty;
  dimensionItem: DimensionItemWithDimension;
  applied: number;
  startDate: string;
  endDate: string;
  lastApplied: string;
  ruleType: string;
}

export enum LabelingStatus {
  ACTIVE = 'Active',
  ENDED = 'Ended',
}

export enum LabelingPage {
  LABEL = 'label',
  CREATE_RULE = 'createRule',
  CONFIRM = 'confirm'
}

export interface TData {
  id: number;
  source: string;
  sourceDimension: string;
  relation: 'arrow';
  target: string;
  targetDimension: string;
  applied: number;
  lastApplied: string;
  ruleType: string;
  startDate: Dayjs;
  endDate: Dayjs;
  status: LabelingStatus;
}

export interface DimensionToLabel {
  id: number;
  dimension: string;
  dimensionItem: string;
}

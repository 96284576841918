import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, ColumnGroup, EditableCallbackParams, GridApi } from 'ag-grid-community';
import i18n from 'locales/i18n';
import dayjs, { Dayjs, QUnitType } from 'dayjs';

import { Cadence } from 'types/form.types';
import { Entries, ImportedStyles } from 'types/app.types';
import {
  CadenceColumnsSettings,
  ColumnHeaderOptions,
  GridColDef,
  GridColumns, Period,
  TableColumns,
} from 'types/financials.types';

import 'styles/agGridTheme.scss';
import { handleNodes } from './processNodes';
import {
  addColumn,
  addDynamicColumn,
  BVA_COLUMNS,
  DynamicColumn,
  ensureDifferentDates,
  getColumnWidth,
  getDynamicColumnDates,
  isNonTotalColumn,
  placeDynamicColumnInReport,
} from 'utils/grid.utils';
import { ReportType, UserReport } from 'types/templates.types';
import { Tooltip } from 'antd';

import { cloneDeep } from 'lodash';
import {
  getActualPeriodRange,
  getBudgetPeriodRange,
  getOverlappingRange,
  PeriodRange,
} from 'utils/period.utils';
import useKeyListener from 'hooks/useKeyListener';
import { DurationUnitType } from 'dayjs/plugin/duration';
import { useAppDispatch } from 'store/hooks/hooks';

import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import clsx from 'clsx';

interface GetColumnsParams {
  columns: TableColumns;
  columnOption: ColumnHeaderOptions;
  dynamicColumns: DynamicColumn[];
  reportType: ReportType;
  startDate: Dayjs;
  endDate: Dayjs;
  conditionalFormatting: boolean;
  editable: boolean | ((params: EditableCallbackParams) => boolean);
}

type DisplayedHeaders = {
  [key in ReportType]: string[];
};

const { t } = i18n;

const defaultFormatField = (field: string, reportType: ReportType,) => {
  return `${ reportType }__${ field }`;
};

interface GridSettings {
  showDynamicColumns: boolean;
  period: Period;
  templateId?: number;
  formatField?: typeof defaultFormatField;
  getHeaderId?: GetHeaderId;
  getHeaderName?: GetHeaderName;
}

type GetGroupParams = {
  date: Dayjs;
  cadence: Cadence;
  reportType: ReportType;
};

type GetHeaderId = (params: GetGroupParams) => string | null;
type GetHeaderName = (params: GetGroupParams) => string | null;

const useGrid = (
  {
    showDynamicColumns,
    period,
    templateId = 0,
    formatField = defaultFormatField,
    getHeaderId,
    getHeaderName,
  }: GridSettings,
  styles: ImportedStyles
) => {

  const columnHeadersOptions: ColumnHeaderOptions[] = [
    {
      cadence: Cadence.week,
      name: (date: Dayjs, reportType: ReportType) =>
        (getHeaderName && getHeaderName({ date, cadence: Cadence.week, reportType })) ||
        `${ t('time.week-abbr', { ns: 'common' }) }${ date.isoWeek() }`,
      nextCadence: Cadence.year
    },
    {
      cadence: Cadence.month,
      groupId: (date: Dayjs, reportType: ReportType) => (getHeaderId && getHeaderId({
        date,
        cadence: Cadence.month,
        reportType
      })) || date.format('MMM-YYYY'),
      name: (date: Dayjs, reportType: ReportType) => (getHeaderName && getHeaderName({
        date,
        cadence: Cadence.month,
        reportType
      })) || date.format('MMM'),
      nextCadence: Cadence.year
    },
    {
      cadence: Cadence.quarter,
      groupId: (date: Dayjs, reportType: ReportType) => (getHeaderId && getHeaderId({
        date,
        cadence: Cadence.quarter,
        reportType
      })) || `Q${ date.format('Q-YYYY') }`,
      name: (date: Dayjs, reportType: ReportType) => (getHeaderName && getHeaderName({
        date,
        cadence: Cadence.quarter,
        reportType
      })) || `${ t('time.quarter-abbr', { ns: 'common' }) }${ date.format('Q') }`,
      nextCadence: Cadence.year
    },
    {
      cadence: Cadence.year,
      groupId: (date: Dayjs, reportType: ReportType) => (getHeaderId && getHeaderId({
        date,
        cadence: Cadence.year,
        reportType
      })) || `Y${ date.format('YYYY') }`,
      name: (date: Dayjs, reportType: ReportType) => (getHeaderName && getHeaderName({
        date,
        cadence: Cadence.year,
        reportType
      })) || date.format('YYYY'),
      nextCadence: null,
    },
  ];

  const dispatch = useAppDispatch();

  const isLineVisible = useMemo(() => period.startDate && period.startDatePlan, [ period ]);

  const clearSelection = useCallback((api: GridApi) => {
    api.deselectAll();
    api.clearRangeSelection();
    api.clearFocusedCell();
  }, []);

  const selectColumnCellRanges = useCallback((api: GridApi, colId: string) => {
    const firstDisplayedRow = api.getFirstDisplayedRowIndex();
    const lastDisplayedRow = api.getLastDisplayedRowIndex();
    api.addCellRange({
      rowStartIndex: firstDisplayedRow,
      rowEndIndex: lastDisplayedRow,
      columns: [ colId ]
    });
  }, []);

  const headerSummaryRenderer = (props: ({
    displayName: string;
    columnGroup: ColumnGroup;
    cadence: string;
    column: Column;
    api: GridApi;
    setSort: (sort, multiSort?) => void;
    progressSort: (multiSort?) => void;
  })) => {
    const [ sortDirection, setSortDirection ] = useState<'asc' | 'desc' | null>(null);

    const { keyPressed: shiftPressed } = useKeyListener('Shift');
    const subtitle = props.column.getColDef().headerComponentParams.subtitle;
    const onClick = () => {
      if (shiftPressed) {
        selectColumnCellRanges(props.api, props.column.getColId());
      } else {
        clearSelection(props.api);
        selectColumnCellRanges(props.api, props.column.getColId());

        props.progressSort();
      }
    };

    useEffect(() => {
      const handler = () => setSortDirection(props.column.getSort());

      props.column.addEventListener('sortChanged', handler);

      () => {
        props.column.removeEventListener('sortChanged', handler);
      };
    });

    return (
      <Tooltip title={ subtitle } trigger={ [ 'hover' ] }>
        <div
          style={ { minWidth: '50px' } }
          className={ styles.headerCell }
          onClick={ onClick }
        >
          <span className={ styles.headerTitle }>
            <span>
              { props.displayName }
            </span>
            { sortDirection && (
              <ChevronDown className={ clsx(
                styles.sort,
                sortDirection === 'asc' ? styles.sortAsc : styles.sortDesc
              ) }
              />
            ) }
          </span>
        </div>
      </Tooltip>
    );
  };

  const headerGroupRenderer = (props: ({
    displayName: string;
    columnGroup: ColumnGroup;
    cadence: string;
    api: GridApi;
    column;
    data;
  })) => {
    const isBold = period.cadence === 'week' && props.cadence === 'month';
    const { keyPressed: shiftPressed } = useKeyListener('Shift');

    const getColId = () => {
      if (props.column) {
        return props.column.getColId();
      }
      const columnGroup = props.columnGroup;
      const groupId = columnGroup.getChildren() as Column[];
      return groupId[ 0 ].getColId();
    };
    const isGroupHeader = props.columnGroup;

    const onClick = () => {
      if (shiftPressed) {
        selectColumnCellRanges(props.api, getColId());
      } else {
        clearSelection(props.api);
        selectColumnCellRanges(props.api, getColId());
      }
    };

    return props.displayName == null ? null : (
      <div
        style={ isGroupHeader ? { width: `${ getColumnWidth(props.columnGroup) }px` } : {} }
        className={ isGroupHeader ? styles.columnGroupHeader : styles.headerCell }
        onClick={ onClick }
      >
        <span className={ `${ styles.headerTitle } ${ isBold ? styles.isBold : '' } ` }>
          { props.displayName }
        </span>
      </div>
    );
  };

  const getHeaderGroup = (
    parents: Entries<TableColumns>,
    columnOption: ColumnHeaderOptions,
    displayedHeaders: DisplayedHeaders,
    groupByReportType: boolean,
  ) => {
    const object = {};
    parents.forEach(([ key, val ]) => {
      const reportType = 'reportType' in val ? val.reportType : undefined;
      const date = dayjs(key).startOf(columnOption.cadence as QUnitType);
      const objKey = groupByReportType ? `${ reportType }__${ date.toString() }` : date.toString();

      const isDynamicColumn = 'headerComponentParams' in val &&
        val.headerComponentParams.isDynamicColumn;

      const headerName = columnOption.name(date, reportType);
      const isHeaderDisplayed = displayedHeaders[ reportType ].includes(headerName) ||
        (
          reportType === ReportType.PLAN &&
          displayedHeaders[ ReportType.ACTUAL ].at(-1) === headerName &&
          displayedHeaders[ ReportType.PLAN ].length === 0
        );
      const displayHeaderName = groupByReportType ?
        (!isDynamicColumn && !isHeaderDisplayed) : !isDynamicColumn;
      if (displayHeaderName) {
        displayedHeaders[ reportType ].push(headerName);
      }

      if (object[ objKey ]) {
        object[ objKey ].children.push(val);
      } else
        object[ objKey ] = {
          groupId: columnOption.groupId(date, reportType),
          headerName: displayHeaderName ? headerName : null,
          children: [ val ],
          type: 'valueColumn',
          headerGroupComponentParams: {
            cadence: columnOption.cadence,
          },
          headerGroupComponent: headerGroupRenderer,
        };
    });
    return object;
  };

  const getColumns = (
    {
      columns,
      dynamicColumns,
      columnOption,
      reportType,
      startDate,
      endDate,
      conditionalFormatting,
      editable
    }: GetColumnsParams): TableColumns => {
    const columnsClone = cloneDeep(columns);
    const dynamicColumnsClone = cloneDeep(dynamicColumns);
    const isPlanColumn = reportType === ReportType.PLAN;

    const offset = ReportType.PLAN === reportType ? 1 : 0;

    // Locales differ in the way they handle the start of the week, so here we adjust the start date
    let start = columnOption.cadence === 'week' ?
      startDate.isoWeekday(0) : startDate.startOf(columnOption.cadence);

    if (columnOption.cadence === 'week') {
      start = start.add(1, 'd');
    }

    let isFirst = true;
    while (start.diff(endDate) <= 0) {
      const nextStart = start.add(1, columnOption.cadence as DurationUnitType);
      const dynamicColumn = dynamicColumnsClone[ 0 ];
      const year = `${ start.format('YYYY') }`;
      const startWithOffset = start.add(offset, 'h');
      const dynamicColumnStartWithOffset = dynamicColumn?.date?.add(offset, 's');

      const placeDynamicColumn = !dynamicColumn?.date ? false :
        startWithOffset.isAfter(dynamicColumnStartWithOffset);

      const index = !placeDynamicColumn ? startWithOffset.toString() :
        dynamicColumnStartWithOffset.toString();
      const field = !placeDynamicColumn ?
        formatField(`${ start.format('YYYY-MM-DD') }T00:00:00`, reportType) : dynamicColumn.field;

      addColumn({
        columns: columnsClone,
        index,
        isFirst,
        subtitle: dynamicColumn?.subtitle,
        field,
        editable,
        year,
        isDynamicColumn: placeDynamicColumn,
        headerName: !placeDynamicColumn ? columnOption.name(start, reportType) : dynamicColumn.name,
        headerClass: !isPlanColumn
          ? [ styles.actualsHeader ]
          : isPlanColumn && isFirst && isLineVisible
            ? [ styles.planHeaderFirst ]
            : [],
        cadence: columnOption.cadence,
        headerComponent: !placeDynamicColumn ? headerGroupRenderer : headerSummaryRenderer,
        reportType,
        conditionalFormatting
      });
      if (placeDynamicColumn) {
        dynamicColumnsClone.shift();
      } else {
        start = nextStart;
      }
      isFirst = false;
    }

    dynamicColumnsClone.forEach((date) => {
      addDynamicColumn({
        columns: columnsClone,
        column: date,
        cadence: columnOption.cadence,
        reportType,
        headerComponent: headerSummaryRenderer,
        headerClass: !isPlanColumn ? [ styles.actualsHeader ] : [],
        offset,
        year: start.format('YYYY'),
        conditionalFormatting,
        dispatch
      });
    });
    return columnsClone;
  };

  const getOverlappingColumns = (
    {
      columns,
      columnOption,
      dynamicColumns,
      startDate,
      endDate,
      conditionalFormatting,
      editable
    }: GetColumnsParams): TableColumns => {
    let start = cloneDeep(startDate);
    let columnsClone = cloneDeep(columns);
    const columnsToPlaceInOverlappingPeriod = dynamicColumns.filter(isNonTotalColumn);

    while (start.diff(endDate) <= 0) {
      const nextStart = start.add(1, columnOption.cadence as DurationUnitType);
      const _endDate = nextStart.subtract(1, 'd');

      const dynamicColumnsInRange = columnsToPlaceInOverlappingPeriod.filter((col) =>
        col.date.isBetween(start, nextStart, 'h', '[]')
      );

      columnsClone = getColumns({
        columns: columnsClone,
        columnOption,
        dynamicColumns: dynamicColumnsInRange.filter((col) => col.reportType === ReportType.PLAN),
        reportType: ReportType.PLAN,
        startDate: start,
        endDate: _endDate,
        conditionalFormatting,
        editable
      });

      columnsClone = getColumns({
        columns: columnsClone,
        columnOption,
        dynamicColumns: dynamicColumnsInRange.filter((col) => col.reportType === ReportType.ACTUAL),
        reportType: ReportType.ACTUAL,
        startDate: start,
        endDate: _endDate,
        conditionalFormatting,
        editable
      });

      start = nextStart;
    }
    const remainDynamicColumns = dynamicColumns.filter(
      (col) => !columnsToPlaceInOverlappingPeriod.includes(col)
    );
    const dynamicColumnsToEndOfPeriod = remainDynamicColumns.map((col) => ({
      ...col,
      date: endDate.subtract(1, 'd')
    }));
    const differentDatesColumn = ensureDifferentDates(dynamicColumnsToEndOfPeriod);

    differentDatesColumn.forEach((column) => {
      addDynamicColumn({
        columns: columnsClone,
        column,
        cadence: columnOption.cadence,
        reportType: column.reportType,
        headerComponent: headerSummaryRenderer,
        headerClass: [],
        offset: 0,
        year: start.format('YYYY'),
        conditionalFormatting,
        dispatch
      });
    });

    return columnsClone;
  };

  const getColumnDefs = (
    settings: CadenceColumnsSettings,
    actualsOpen: boolean,
    planOpen: boolean,
    editable: boolean | ((params: EditableCallbackParams) => boolean),
    conditionalFormatting = false,
  ): GridColumns => {
    let columns: TableColumns;
    const columnFields = [];
    const config = settings ? settings[ period.cadence ] : null;
    const dynamicDates = showDynamicColumns ?
      getDynamicColumnDates(config, {
        cadence: period.cadence,
        startDate: actualsOpen ? period.startDate : null,
        endDate: actualsOpen ? period.endDate : null,
        startDatePlan: planOpen ? period.startDatePlan : null,
        endDatePlan: planOpen ? period.endDatePlan : null,
        isManuallySet: false,
      }) : [];
    let columnOption = columnHeadersOptions.find(item => item.cadence === period.cadence);
    const displayedHeaders = {
      [ ReportType.ACTUAL ]: [],
      [ ReportType.PLAN ]: [],
    };
    const isBvaColumnSelected = dynamicDates.some(col => BVA_COLUMNS.includes(col.type));
    const overlappingRange = isBvaColumnSelected && getOverlappingRange(period);
    const isBudgetOverlappingActual = !!overlappingRange;

    do {
      if (columns) {
        columns = getHeaderGroup(
          Object.entries(columns),
          columnOption,
          displayedHeaders,
          !isBudgetOverlappingActual
        );
      } else {
        columns = {};
        const isBothReportsOpen = actualsOpen && planOpen;

        const actualRange = getActualPeriodRange(period, overlappingRange);
        const budgetRange = getBudgetPeriodRange(period, overlappingRange);
        const getDynamicColumns = (range: PeriodRange, reportTypes: ReportType[]) => {
          const _startDate = dayjs.unix(range.start);
          const _endDate = dayjs.unix(range.end);

          return dynamicDates.filter((dynamicColumn) =>
            placeDynamicColumnInReport(dynamicColumn.type, reportTypes, isBothReportsOpen) &&
            reportTypes.includes(dynamicColumn.reportType) &&
            dynamicColumn.date.isBetween(_startDate, _endDate, 's', '[]')
          );
        };

        const setColumns = (
          range: PeriodRange,
          reportType: ReportType,
          setter: (props: GetColumnsParams) => TableColumns,
          dynamicColumns: DynamicColumn[]
        ) => {
          const _startDate = dayjs.unix(range.start);
          const _endDate = dayjs.unix(range.end);

          columns = setter({
            columns,
            columnOption,
            dynamicColumns,
            reportType,
            startDate: _startDate,
            endDate: _endDate,
            conditionalFormatting,
            editable
          });
        };
        const columnsSetters: Function[] = [];
        if (actualsOpen && actualRange) {
          const dynamicColumns = getDynamicColumns(actualRange, [ ReportType.ACTUAL ]);
          columnsSetters.push(() => setColumns(
            actualRange,
            ReportType.ACTUAL,
            getColumns,
            dynamicColumns
          ));
        }
        if (planOpen && budgetRange) {
          const dynamicColumns = getDynamicColumns(budgetRange, [ ReportType.PLAN ]);
          columnsSetters.push(() => setColumns(
            budgetRange,
            ReportType.PLAN,
            getColumns,
            dynamicColumns
          ));
        }

        if (isBudgetOverlappingActual) {
          const setOverlappedColumns = () => {
            setColumns(
              overlappingRange,
              ReportType.PLAN,
              getOverlappingColumns,
              getDynamicColumns(overlappingRange, [ ReportType.ACTUAL, ReportType.PLAN ])
            );
          };
          columnsSetters.push(setOverlappedColumns);
        }

        columnsSetters.forEach(setter => setter());
      }
      columnOption = columnHeadersOptions.find(item => item.cadence === columnOption.nextCadence);
    } while (columnOption != null);

    return {
      columnDefs: Object.values(columns) as GridColDef[],
      columnFields: columnFields
    };
  };

  const getRows = (data: UserReport) => {
    const rows = handleNodes(data, templateId);
    let maxDepth = 0;
    for (const row of rows) {
      if (maxDepth < row.filePath.length)
        maxDepth = row.filePath.length;
    }

    return {
      maxDepth: maxDepth - 1,
      rows: rows
    };
  };

  return {
    getColumnDefs,
    getRows,
  };
};

export default useGrid;

import clsx from 'clsx';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import { MouseEvent, useMemo } from 'react';
import { setActiveBudget } from 'store/budget.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import useBudgets from '../utils/budgets.hooks';
import { appSlice } from 'store/app.slice';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import styles from './BudgetDropdown.module.scss';
import Button from 'components/elements/button/Button';
import { useTranslation } from 'react-i18next';

const BudgetDropdown = () => {
  const [ t ] = useTranslation('budget');

  const activeBudgetId = useAppSelector(state => state.budget?.activeBudgetId);
  const appDispatch = useAppDispatch();

  const { budgets } = useBudgets();

  const onBudgetChange = (e: number) => {
    if (e === 0) {
      return;
    }
    const budget = budgets.find((b) => b.id === e);
    if (!budget) return;
    appDispatch(setActiveBudget(budget));
  };

  const editBudget = (e: number) => {
    appDispatch(appSlice.actions.setLeftPanel('budget'));
    appDispatch(appSlice.actions.setLeftPanelData({ budgetId: e }));
  };

  const addNewBudget = (e: MouseEvent) => {
    e.stopPropagation();
    appDispatch(appSlice.actions.setLeftPanel('budget'));
    appDispatch(appSlice.actions.setLeftPanelData({ budgetId: 0 }));
  };

  const options = useMemo(() => {
    const budgetList = budgets.slice();
    const activeBudget = budgetList.splice(budgets.findIndex(b => b.id === activeBudgetId), 1);

    return [ ...activeBudget, ...budgetList ]
      .map(budget => ({
        label: <div className={ styles.budgetLabel } role='option'>
          <div>{ budget.name }</div>
          <div className={ styles.icons } aria-label='Edit' onClick={ (e) => {
            e.stopPropagation();
            e.preventDefault();
            editBudget(budget.id);
          } }>
            <CheckIcon className={ styles.check } />
            <EditIcon className={ styles.edit } />
          </div>
        </div>,
        disabled: false,
        name: budget.name,
        value: budget.id
      }))
      .concat({
        label: <>
          <Button onClick={ addNewBudget } type='default' icon={ <PlusIcon /> }>
            { t('dropdown.newBudget') }
          </Button>
        </>,
        disabled: true,
        name: 'Add budget',
        value: 0
      });
  }, [ budgets, activeBudgetId ]);

  if (budgets.length === 0) {
    return null;
  }

  return (
    <SearchableSelect
      className={ clsx(styles.select, 'withBorder') }
      options={ options }
      searchBy={ (e) => e.name }
      popupClassName={ styles.popup }
      hideSearch={ options.length < 10 }
      value={ activeBudgetId }
      onChange={ onBudgetChange }
    /> 
  );
};

export default BudgetDropdown;

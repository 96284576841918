import { BudgetItem } from 'types/budget.types';
import * as yup from 'yup';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { BudgetItemTableRequestFilters } from 'services/planning.service';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';

const accountsSchema = yup.object().shape({
  primary: yup.number().required(),
  counter: yup.number().nullable(),
});
const validationSchema = yup.object().shape({
  accounts: accountsSchema.required(),
  amountFormula: yup.string().required(),
  memo: yup.string().required(),
  frequencyUnit: yup.string().required(),
  frequencyAmount: yup.number().required(),
  startDate: yup.string().required(),
  itemType: yup.number().required(),
});

export const isBudgetItemValid = (budgetItem: BudgetItem): boolean => {
  return validationSchema.isValidSync(budgetItem);
};

/**
 * Convert TransactionLineRequestParams to BudgetItemTableRequestFilters by camelizing the keys
 * and changing `plan_start_date` and `plan_end_date` to `start_date` and `end_date`.
 */
export const convertRequestParams =
  (params: TransactionLineRequestParams): BudgetItemTableRequestFilters => ({
    templateRowId: params[ 'template_row_id' ],
    dimensionItemIds: params[ 'dimension_item_ids' ],
    transactionLines: params[ 'transaction_lines' ],
    unassignedDimensionIds: params[ 'unassigned_dimension_ids' ],
    contractId: params[ 'contract_id' ],
    // planStartDate and planEndDate are unused
    startDate: params[ 'plan_start_date' ],
    endDate: params[ 'plan_end_date' ],
    cadence: params[ 'cadence' ],
    userTemplateId: params[ 'user_template_id' ],
    templateCategory: params[ 'template_category' ],
    breakdownIds: params[ 'breakdown_ids' ],
    excludedUnassignedDimensionIds: params[ 'excluded_unassigned_dimension_ids' ],
    excludedDimensionItemIds: params[ 'excluded_dimension_item_ids' ],
    formulaIds: params[ 'formula_ids' ],
  });

export function handleError(error: unknown) {
  if (errorHasData(error)) {
    const key = Object.keys(error?.data).at(0);
    if (key) {
      const values = Object.values(error?.data[ key ]);
      if (values) {
        notifyError(values.at(0) as unknown as string);
      }
    } else if (error?.data[ 'nonFieldErrors' ]) {
      notifyError(error?.data[ 'nonFieldErrors' ]);
    } else {
      notifyUnexpectedError(new Error());
    }
  } else {
    notifyUnexpectedError(error as Error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function errorHasData(error: unknown): error is { data: Record<string, any> } {
  return (error as { data: unknown }).data !== undefined;
}
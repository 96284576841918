import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { IRowNode } from 'ag-grid-community';

import type {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import { GroupSettings } from 'components/elements/tableWrapper/types/table.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { clearLeftPanel, setLeftPanel } from 'store/financials.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import PanelWrapper from 'components/panels/wrappers/PanelWrapper';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './FinancialsLeftPanel.module.scss';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import SortSection from './sortSection/SortSection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import FiltersSection from './filterSection/FilterSection';
import { isProductProxy } from 'utils/financials.utils';
import { sideTabs } from '../../../panels/panel.constants';
import RegisterPanel from 'components/panels/registerPanel/RegisterPanel';
import { usePanelControl } from 'components/panels/panelsContext';
import LayoutControl from '../layout/LayoutControl';
import { useCommandBar } from 'components/commandBar/context/CommandBarContext';
import LayoutSidePanelHeader from 'components/panels/layoutSidePanelHeader/LayoutSidePanelHeader';
import TableSettingsPanel from '../settingsPanel/SettingsPanel';
import BudgetsPanel from 'components/budget/panels/budgetsPanel/BudgetsPanel';

interface Props {
  nodes: IRowNode[] | null;
  gridRef: MutableRefObject<AgGridReact>;
  assignLabels: AssignFunction;
  unassignLabels: UnassignFunction;
  disableTabs?: string[];
  includeTabs?: string[];
}
const FinancialsLeftPanel = ({
  nodes,
  gridRef,
  assignLabels,
  unassignLabels,
  disableTabs = [],
  includeTabs = []
}: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const dispatch = useAppDispatch();
  const panelType = useAppSelector(state => state.app.leftPanel);
  const activeItem = useAppSelector(state => state.financials.active);
  const { state: { utils } } = useCommandBar();

  const { panels, setLeftActivePanel } = usePanelControl();

  const node = getSortNode(nodes);

  const isActive = useMemo(() => {
    const availableInCommandBar = utils.filter(u => !u.hidden && !u.disabled)
      .map((u) => u.key) as ReadonlyArray<string>;

    const activatedPanel = panels
      .filter(el => availableInCommandBar.includes(el.id))
      .filter(el => !disableTabs.includes(el.id))
      .concat(panels.filter(el => includeTabs.includes(el.id)))
      .find(_ => _.id === panelType);

    if (activatedPanel?.type === 'link') return false;

    return !!activatedPanel && templateId === activeItem?.templateId;
  }, [ templateId, panelType, activeItem?.templateId, panels ]);

  useEffect(() => {
    setLeftActivePanel(panelType);
  }, [ panelType ]);

  const onChange = useCallback((key: string | null) => {
    if (key == null) clearLeftPanel();

    dispatch(setLeftPanel({ ...(activeItem ?? {}), panelType: key }));
  }, [ templateId ]);

  const onClose = () => {
    dispatch(clearLeftPanel());
  };

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  return <PanelWrapper orientation='left' isActive={ isActive } >
    <LayoutSidePanelHeader
      side='left'
      onChange={ onChange }
      onClose={ onClose }
      includeTabs={ includeTabs }
    />

    <RegisterPanel { ...sideTabs[ 'layout' ] } >
      <LayoutControl />
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'budget' ] } >
      <BudgetsPanel />
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'label' ] }>
      <FiltersSection
        gridRef={ gridRef }
        panelType='label'
        assignLabels={ assignLabels }
        unassignLabels={ unassignLabels }
      />
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'filter' ] }>
      <FiltersSection
        gridRef={ gridRef }
        panelType='filter'
        assignLabels={ assignLabels }
        unassignLabels={ unassignLabels }
      />
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'sort' ] }>
      <div className={ clsx(panelStyles.panelBody, styles.sort) }>
        <SortSection
          node={ node }
          gridRef={ gridRef }
        />
      </div>
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'group' ] }>
      <div className={ clsx(panelStyles.panelBody, styles.sort) }>
        <GroupSelection
          gridRef={ gridRef }
          onSelect={ onGroupSelect }
        />
      </div>
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'settings' ] }>
      <TableSettingsPanel />
    </RegisterPanel>

  </PanelWrapper>;
};

const ProviderWrapper = (props: Props) => {
  return <FinancialsLeftPanel { ...props } />;
};

export default ProviderWrapper;

function getSortNode (nodes: IRowNode[] | null): IRowNode | null | undefined {
  if (!nodes || nodes.length === 0) return null;
  if (nodes.length === 1) return nodes.at(0);

  if (
    nodes.at(0)?.parent && isProductProxy(nodes.at(0)?.parent)
    && nodes.at(0)?.parent?.isSelected()
  ) {
    return nodes.at(0)?.parent;
  }

  return nodes?.at(0) ?? null;
}

import { createSlice } from '@reduxjs/toolkit';

export const SHOW_LABEL_CONFIRMATION_MODAL = 'show_labelConfirmationModal';
export const DELAY_LABELING_REFRESH = 'delay_labeling_refresh';
export const AUTO_CREATE_RULE = 'auto_create_rule';

export interface LabelingStore {
  settings: {
    skipConfirmation: boolean;
    delayRefresh: boolean;
  };
}

const initialState: LabelingStore = {
  settings: {
    skipConfirmation: false,
    delayRefresh: true,
  }
};

const getInitialState = (): LabelingStore => {
  const skipConfirmation = localStorage.getItem(SHOW_LABEL_CONFIRMATION_MODAL);
  const delayRefresh = localStorage.getItem(DELAY_LABELING_REFRESH);

  const defaults = initialState.settings;

  return {
    ...initialState,
    settings: {
      skipConfirmation: skipConfirmation ? skipConfirmation === 'true' : defaults.skipConfirmation,
      delayRefresh: delayRefresh ? delayRefresh === 'true' : defaults.delayRefresh,
    },
  };
};

export const labelingSlice = createSlice({
  name: 'labeling',
  initialState: getInitialState(),
  reducers: {

    setSkipConfirmation: (state, action) => {
      state.settings.skipConfirmation = action.payload;
      localStorage.setItem(SHOW_LABEL_CONFIRMATION_MODAL, action.payload.toString());
    },
    setDelayRefresh: (state, action) => {
      state.settings.delayRefresh = action.payload;
      localStorage.setItem(DELAY_LABELING_REFRESH, action.payload.toString());
    },
  }
});

export const labelingReducer = labelingSlice.reducer;

import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedResponse } from 'types/app.types';
import { Budget } from 'types/budget.types';
import { endpoints } from 'utils/config.utils';
import { httpBaseQuery } from './api.utils';

export interface Section {
  name: string;
  fields: {
    input: number | null;
    dimension: number | null;
    budgetItemFieldName: string | null;
  }[];
}

export interface BudgetItemForm {
  id: number;
  budgetItemType: number;
  steps: { sections: Section[] }[];
}

export interface CreateBudget {
  name: string;
  startDate: string;
  durationMonths: number;
  status: 'draft' | 'approved';
}

export const planningApi = createApi({
  reducerPath: 'planningApi',
  baseQuery: httpBaseQuery(),
  tagTypes: [ 'Budget', 'Planning' ],
  endpoints: (builder) => ({
    getForms: builder.query<BudgetItemForm[], { budgetItemId?: number }>({
      query: ({ budgetItemId }) => {
        return {
          url: endpoints.planning.budgetItems.forms.replace('{id}', budgetItemId?.toString()),
          method: 'GET',
          providesTags: [ 'Budget' ]
        };
      },
      providesTags: [ 'Budget' ],
    }),
    getBudgets: builder.query<PaginatedResponse<Budget>, void>({
      query: () => ({
        url: endpoints.planning.budgets.list,
        method: 'GET',
        providesTags: [ 'Budget' ],
      }),
      providesTags: [ 'Budget' ],
    }),
    createBudget: builder.mutation<Budget, CreateBudget>({
      query: (payload) => ({
        url: endpoints.planning.budgets.list,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: [ 'Budget' ],
    }),
    deleteBudget: builder.mutation<void, number>({
      query: (id) => ({
        url: endpoints.planning.budgets.detail.replace('{id}', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: [ 'Budget' ],
    }),
    patchBudget: builder.mutation<void, { id: number; data: Partial<CreateBudget> }>({
      query: ({ id, data }) => ({
        url: endpoints.planning.budgets.detail.replace('{id}', id.toString()),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [ 'Budget' ],
    }),
    duplicateBudget: builder.mutation<Budget, number>({
      query: (id) => ({
        url: endpoints.planning.budgets.clone.replace('{id}', id.toString()),
        method: 'POST',
      }),
      invalidatesTags: [ 'Budget' ],
    }),
  }),
});

export const {
  useGetFormsQuery,
  useGetBudgetsQuery,
  useCreateBudgetMutation,
  useDeleteBudgetMutation,
  usePatchBudgetMutation,
  useDuplicateBudgetMutation
} = planningApi;

import { useCallback } from 'react';
import {
  FormulaItem,
  FormulaItemType,
  isDimensionItemNode,
  isFinancialsNode,
  isUnassignedNode,
  TemplateNode,
  isUnassignedDimensionItemNode
} from 'types/templates.types';
import { cloneDeep } from 'lodash';
import { getDisplayName, isDisplayNameEmpty } from 'utils/common.utils';
import { getUUID } from 'utils/templates.utils';
import {
  DECIMAL_SEPARATOR,
  operators,
  OperatorsType,
} from '../utils/formulaBuilder.utils';
import {
  FormulaInsertion,
} from 'components/templates/customTemplate/formulaBuilder/types/formula.types';
import { getFormulaItemTypeFromNode } from 'utils/template.utils';
import { RowType } from 'types/financials.types';
import { getUnassignedNodeName } from 'utils/financials.utils';
import { selectBreakdowns } from 'store/breakdowns.slice';
import { useAppSelector } from 'store/hooks/hooks';

const BRACKET_OPEN = operators.find((el) => el.value === '(');
const BRACKET_CLOSE = operators.find((el) => el.value === ')');
const AND_OPERATOR = operators.find((el) => el.value === '&');
const OR_OPERATOR = operators.find((el) => el.value === '|');

type NewFormulaItem = Omit<FormulaItem, 'key' | 'prev' | 'next'>;

const useFormula = () => {
  const breakdowns = useAppSelector(selectBreakdowns);

  const updateFormulaRelations = useCallback((list: FormulaItem[]) => {
    const formulaCopy = cloneDeep(list);
    formulaCopy.forEach((element, index) => {
      if (index === 0) {
        element.prev = null;
      } else {
        element.prev = formulaCopy[ index - 1 ].type;
      }
      if (index === formulaCopy.length - 1) {
        element.next = null;
      } else {
        element.next = formulaCopy[ index + 1 ].type;
      }
    });
    return formulaCopy;
  }, []);

  const getPreviousElement = useCallback((formula: FormulaItem[], position: number) => {
    if (position === 0) {
      return null;
    }
    return formula[ position - 1 ]?.type;
  }, []);

  const connectWithBrackets = useCallback((
    formula: FormulaItem[],
    start: number,
    end: number
  ): FormulaInsertion => {
    if (start === end) {
      return {
        formula,
        position: end,
      };
    }
    const formulaCopy = [ ...formula ];
    const { formula: _formula } = addOperator(BRACKET_OPEN, formulaCopy, start);
    const { formula: __formula } = addOperator(BRACKET_CLOSE, _formula, end + 1);

    return {
      formula: __formula,
      position: end + 1,
    };
  }, []);

  const addItemOnPosition = useCallback((
    item: NewFormulaItem,
    formula: FormulaItem[],
    position: number
  ): FormulaInsertion => {
    const newItem: FormulaItem = {
      ...item,
      key: getUUID(),
      next: null,
      prev: null,
    };
    let copyFormula = [ ...formula ];
    copyFormula.splice(position, 0, newItem);
    copyFormula = updateFormulaRelations(copyFormula);

    return {
      formula: copyFormula,
      position: position + 1,
    };
  }, []);

  const addTemplateElements = useCallback((
    items: TemplateNode[],
    type: FormulaItemType,
    formula: FormulaItem[],
    position: number,
  ): FormulaInsertion => {
    let positionToInsert = position ?? formula.length;
    let formulaCopy = [ ...formula ];

    items.forEach((item, index) => {
      const {
        formula: _formula,
        position: _position
      } = addTemplateElement(item, type, formulaCopy, positionToInsert);
      formulaCopy = _formula;
      positionToInsert = _position;

      const hasNextSibling = index < items.length - 1;
      if (hasNextSibling) {
        const {
          formula: __formula,
          position: __position
        } = addOperator(OR_OPERATOR, formulaCopy, positionToInsert);
        formulaCopy = __formula;
        positionToInsert = __position;
      }
    });

    return connectWithBrackets(formulaCopy, position, positionToInsert);
  }, []);

  const addTemplateElement = useCallback((
    item: TemplateNode,
    type: FormulaItemType,
    formula: FormulaItem[],
    position: number,
  ): FormulaInsertion => {
    let formulaCopy = [ ...formula ];
    const startPosition = position ?? formula.length;
    let positionToInsert = startPosition;
    const hasChildren = item?.childrenNodes?.length > 0;
    const formulaItemType = getFormulaItemTypeFromNode(item);
    if (!hasChildren) {
      return addElement(item, formulaItemType, formulaCopy, positionToInsert);
    }

    const {
      formula: formulaWithElement,
      position: positionWithElement
    } = addElement(item, formulaItemType, formulaCopy, positionToInsert);
    formulaCopy = formulaWithElement;
    positionToInsert = positionWithElement;

    const {
      formula: formulaWithAndOperator,
      position: positionWithAndOperator
    } = addOperator(AND_OPERATOR, formulaCopy, positionToInsert);
    formulaCopy = formulaWithAndOperator;
    positionToInsert = positionWithAndOperator;

    const childrenNodes = item.childrenNodes;
    const {
      formula: formulaWithChildren,
      position: positionWithChildren
    } = addTemplateElements(childrenNodes, type, formulaCopy, positionToInsert);
    formulaCopy = formulaWithChildren;
    positionToInsert = positionWithChildren;

    const hasManyChildren = item.childrenNodes.length > 1;
    if (hasManyChildren) {
      const withBrackets = connectWithBrackets(formulaCopy, startPosition, positionToInsert);
      return {
        formula: updateFormulaRelations(withBrackets.formula),
        position: withBrackets.position,
      };
    }

    if (item.childrenNodes.length === 0) {
      return {
        formula: updateFormulaRelations(formulaCopy),
        position: positionToInsert,
      };
    } else {
      const allWithBrackets = connectWithBrackets(
        formulaCopy, startPosition, positionToInsert);

      return {
        formula: allWithBrackets.formula,
        position: allWithBrackets.position
      };

    }

  }, []);

  const addElement = useCallback((
    item: TemplateNode,
    type: FormulaItemType,
    formula: FormulaItem[],
    position?: number,
  ): FormulaInsertion => {
    if (isUnassignedNode(item)) {
      const newElement: NewFormulaItem = {
        id: item.rowData.id,
        value: getUnassignedNodeName(item.rowData),
        type: FormulaItemType.UNASSIGNED,
        offset: null,
      };
      return addItemOnPosition(newElement, formula, position || formula.length);
    }

    if (isUnassignedDimensionItemNode(item)) {
      const dimension = breakdowns.dimensionMap[ item.rowData.dimensionId ];
      const newElement: NewFormulaItem = {
        id: item.rowData.dimensionId,
        value: getUnassignedNodeName(dimension),
        type: FormulaItemType.UNASSIGNED,
        offset: null,
      };
      return addItemOnPosition(newElement, formula, position || formula.length);
    }

    if (type === FormulaItemType.TEMPLATE) {
      return addTemplateElement(item, type, formula, position);
    }
    const id = isDimensionItemNode(item) || item.type === RowType.FORMULA ?
      item.rowData.id : item?.id;
    const name = isFinancialsNode(item) && !isDisplayNameEmpty(item?.rowData.shortName) ?
      item?.rowData.shortName : item?.rowData?.name;
    const newElement: NewFormulaItem = {
      id,
      value: getDisplayName(name),
      type: type,
      offset: null,
    };

    const positionToInsert = position ?? formula.length;
    return addItemOnPosition(newElement, formula, positionToInsert);
  }, []);

  const addOperator = useCallback((
    el: OperatorsType,
    formula: FormulaItem[],
    position?: number,
  ): FormulaInsertion => {
    const positionToInsert = position ?? formula.length;
    const newOperator: NewFormulaItem = {
      value: el.value,
      type: el.type
    };

    return addItemOnPosition(newOperator, formula, positionToInsert);
  }, []);

  const addConstant = useCallback((
    value: string,
    formula: FormulaItem[],
    position: number,
  ): FormulaInsertion => {
    const formulaCopy = cloneDeep(formula);
    const prev = getPreviousElement(formulaCopy, position);

    if (prev === FormulaItemType.CONSTANT) {
      const isNumericValue = !isNaN(Number(value));
      const isComma = value === DECIMAL_SEPARATOR;
      const previousValue = formulaCopy[ position - 1 ].value;
      const previousValueTrimmed = previousValue.at(-1) === DECIMAL_SEPARATOR ?
        previousValue.slice(0, -1) : previousValue;
      const isPreviousNumeric = !isNaN(Number(previousValueTrimmed));

      if ((isNumericValue || isComma) && isPreviousNumeric) {
        formulaCopy[ position - 1 ].value += value;
        return {
          formula: formulaCopy,
          position: position,
        };
      }
    }

    const newElement: NewFormulaItem = {
      value,
      type: FormulaItemType.CONSTANT
    };

    return addItemOnPosition(newElement, formulaCopy, position);
  }, []);

  const remove = useCallback((formula: FormulaItem[], item: FormulaItem): FormulaInsertion => {
    if (!item) {
      return {
        formula,
        position: undefined,
      };
    }
    const copyFormula = cloneDeep(formula);
    const index = copyFormula.findIndex((el) => el.key === item.key);

    if (item.type === FormulaItemType.CONSTANT && item.value.length > 1) {
      copyFormula[ index ] = { ...item, value: item.value.slice(0, -1) };
      return {
        formula: copyFormula,
        position: index + 1,
      };
    }

    copyFormula.splice(index, 1);
    return {
      formula: updateFormulaRelations(copyFormula),
      position: index,
    };
  }, []);

  return {
    addElement,
    addOperator,
    addConstant,
    remove
  };
};

export default useFormula;

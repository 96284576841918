import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { dashboardSlice, selectMode, selectSelectedElements } from 'store/dashboard.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DashboardModeType } from 'types/dashboard.types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/toast-close.svg';
import styles from './DashboardPanel.module.scss';
import { PanelMode } from './panel.utils';
import AddCardsPage from './addCardsPage/AddCardsPage';
import CardSettingsPage from './cardSettingsPage/CardSettingsPage';
import Tabs from 'components/elements/tabs/Tabs';
import PanelWrapper from 'components/panels/wrappers/PanelWrapper';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { DASHBOARD_PANEL_ID } from 'utils/dashboard.utils';

const DashboardPanel = () => {
  const mode = useAppSelector(selectMode);
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('dashboard');
  const [ panelMode, setPanelMode ] = useState(PanelMode.ADD_CARDS);
  const selectedElements = useAppSelector(selectSelectedElements);

  const onClose = useCallback(() => {
    dispatch(dashboardSlice.actions.setMode(DashboardModeType.VIEW));
  }, []);

  useEffect(() => {
    const newMode = selectedElements.length > 0 ? PanelMode.CARD_SETTINGS : PanelMode.ADD_CARDS;
    setPanelMode(newMode);
  }, [ selectedElements ]);

  const page = useMemo(() => {
    if (panelMode === PanelMode.ADD_CARDS) {
      return <DynamicScrollableContainer>
        <AddCardsPage />
      </DynamicScrollableContainer>;
    }
    if (panelMode === PanelMode.CARD_SETTINGS) {
      return <CardSettingsPage />;
    }
  }, [ panelMode ]);

  const tabItems = useMemo(() => {
    return Object.values(PanelMode).map((m) => ({
      id: m.toString(),
      // t('panel.switcher.add-cards')
      // t('panel.switcher.card-settings')
      label: t(`panel.switcher.${ m }`),
      key: m,
      disabled: m === PanelMode.CARD_SETTINGS && !selectedElements.length
    }));
  }, [ selectedElements ]);

  return (
    <PanelWrapper
      customPortalId={ DASHBOARD_PANEL_ID }
      isActive={ mode === DashboardModeType.EDIT }>
      <div className={ styles.panel }>
        <div className={ styles.title }>
          <h3>
            { t('panel.title') }
          </h3>
          <GhostIconButton onClick={ onClose }>
            <CloseIcon height={ 24 } width={ 24 } />
          </GhostIconButton>
        </div>
        <Tabs
          activeKey={ panelMode }
          onChange={ (m) => setPanelMode(m as PanelMode) }
          items={ tabItems }
        />
        { page }
      </div>
    </PanelWrapper>
  );
};

export default DashboardPanel;

import React, { Fragment, MutableRefObject, useEffect, useState } from 'react';
import { IRowNode } from 'ag-grid-community';
import Button from 'components/elements/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';

import styles from './OverviewPanel.module.scss';
import OverviewSteps from './overviewSteps/OverviewSteps';
import SelectEntries from './selectEntries/SelectEntries';
import useBudgetItemForm from './useBudgetItemForm';
import { TableCoordinates } from './overviewPanel.types';
import ConfirmRowsDelete from 'components/financials/modals/confirmCellsDelete/ConfirmCellsDelete';
import { ReportType } from 'types/templates.types';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import { AgGridCommon } from 'ag-grid-community/dist/types/core/interfaces/iCommon';

interface Props {
  gridRef: MutableRefObject<AgGridCommon<unknown, unknown>>;
  cells: TableCoordinates[] | null;
}

const OverviewPanel = ({ gridRef, cells }: Props) => {
  const [ t ] = useTranslation('financials');

  const [ , setHasChanges ] = useState(false);

  const [ budgetItemType, setBudgetItemType ] = useState<number | null>(
    cells?.at(0)?.node?.data?.rowData?.budgetItemType?.id ?? null
  );

  const form = useBudgetItemForm(budgetItemType);

  const cadence = useAppSelector(
    state => state.financials.tables[ state.financials.active.templateId ]?.period.cadence
  );

  const [ rowsForDelete, setRowsForDelete ] = useState<TableCoordinates[]>([]);

  const cancelDelete = () => {
    setRowsForDelete([]);
  };

  const removeRow = () => {
    onRowDelete?.(selectedEntries);
  };

  const onRowDelete = (deleteNodes: TableCoordinates[]) => {
    setRowsForDelete(deleteNodes);
  };

  const confirmDelete = () => {
    rowsForDelete.forEach(({ node, column }) => {
      const newValues = node.data[ ReportType.PLAN ];
      delete newValues[ column.split('__').at(-1) ];
      delete node.data.rowData.entries[ column.split('__').at(-1) ];
      gridRef.current?.api?.applyTransaction({ update: [
        { ...node.data, [ ReportType.PLAN ]: newValues }
      ] });
    });
  };

  const [
    selectedEntries, onEntriesSelected
  ] = useState<{ node: IRowNode; column: string }[]>([]);

  useEffect(() => {
    onEntriesSelected(cells.at(0) ? [ cells.at(0) ] : []);
  }, [ cells ]);

  useEffect(() => {
    setBudgetItemType(selectedEntries.at(0)?.node?.data?.rowData?.budgetItemType?.id ?? null);
  }, [ selectedEntries ]);

  return <Fragment>
    { selectedEntries.length === 0 ? <div className={ styles.emptySelection }>
      { t('inline-edit.emptySelection') }
    </div> : <Fragment>
      <SelectEntries
        entriesList={ cells }
        selectedEntries={ selectedEntries }
        onEntriesSelected={ onEntriesSelected }
      />
      <DynamicScrollableContainer>
        <OverviewSteps
          key={ selectedEntries?.map(({ node, column }) => `${ node.id }${ column }`).join('_') }
          entriesList={ selectedEntries }
          steps={ form?.steps }
          gridRef={ gridRef }
          cadence={ cadence }
          budgetItemType={ budgetItemType }
          setHasChanges={ setHasChanges }
          setBudgetItemType={ setBudgetItemType }
        />
      </DynamicScrollableContainer>
      <div className={ styles.footer }>
        <Button onClick={ removeRow } type='link' danger>
          { t('common:delete') }
          {
            selectedEntries.length > 1 && <span>
              (
              { getEntriesCount(selectedEntries) }
              )
            </span>
          }
        </Button>
      </div>
    </Fragment> }

    {
      !!rowsForDelete.length && <ConfirmRowsDelete
        nodesToRemove={ rowsForDelete }
        isVisible onConfirm={ confirmDelete }
        onClose={ cancelDelete } />
    }

  </Fragment>;
};

export default OverviewPanel;

function getEntriesCount(entries: TableCoordinates[]) {
  if (entries.length <= 1) return entries.length;

  return entries.length;
}

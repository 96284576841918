import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Redirect, TableHookProps } from 'components/elements/tableWrapper/types/table.types';
import { Transaction } from 'types/statutory.types';
import { AgGridReact } from 'ag-grid-react';
import {
  GridColumnsChangedEvent,
  IRowNode,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  SelectionChangedEvent,
} from 'ag-grid-community';
import useTransactionsTable
  from 'components/financials/detailedView/transactionsTable/hooks/useTransactionsTable';
import CostLabelerModal from 'components/financials/costLabelerModal/CostLabelerModal';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { FilterList } from 'types/filterTable.types';
import { Period } from 'types/financials.types';
import useMasterDetailSplits
  from 'components/financials/detailedView/transactionsTable/hooks/useMasterDetailSplits';
import { getDisplayName } from '../../../../../utils/common.utils';
import { getUUID } from '../../../../../utils/templates.utils';
import dayjs from 'dayjs';
import organizationsService, { SSRMParams } from '../../../../../services/organizations.service';
import { useAppSelector } from '../../../../../store/hooks/hooks';
import { GatheredLabels } from '../../../../../hooks/useLabeler';
import { t } from 'i18next';
import { FilterModel } from 'ag-grid-enterprise';

interface Props {
  isGridReady: boolean;
  gridRef: RefObject<AgGridReact>;
  templateId: number;
  params: TransactionLineRequestParams[];
  filter: FilterList;
  search: string;
  period: Period;
  sectionKey: string;
  setRedirect: React.Dispatch<React.SetStateAction<Redirect>>;
  setIsAnySelected?: MutableRefObject<(areAllLoaded?: boolean) => void>;
}

interface TransactionTableProps {
  costLabeler: React.ReactNode;
  totalRowsCount: number | null;
}

const useTransactionsTableProps = (
  {
    isGridReady,
    gridRef,
    templateId,
    params,
    filter,
    search,
    period,
    sectionKey,
    setRedirect,
    setIsAnySelected
  }: Props): TableHookProps<Transaction, TransactionTableProps> => {
  const [ selectedRows, setSelectedRows ] = useState<IRowNode[]>([]);
  const [ gatheredLabels, setGatheredLabels ] = useState<GatheredLabels>();
  const [ selectAllActive, setSelectAllActive ] = useState(false);
  const [ currentRequestParams, setCurrentRequestParams ] = useState<SSRMParams>({});
  const [ toggledTransactionIds, setToggledTransactionIds ] = useState<number[]>([]);
  const masterDetailProps = useMasterDetailSplits({ search });
  const {
    params: detailedViewParams,
  } = useAppSelector(state => state.financials.detailedView);

  const [ totalRowsCount, setTotalRowsCount ] = useState<number | null>(null);

  const { dimensionItemMap } = useAppSelector(state => state.breakdowns);

  const tableKey = 'detailed-view-transactions';

  const baseRequestParams = useMemo(() => {
    // Params determined from what is passed to detail view from financial table.
    //  It depends on the structure of the table and the date of selected range.
    const param = detailedViewParams?.actual;
    if (!param) {
      return {};
    }
    const startDate = dayjs.unix(period.startDate);
    const endDate = dayjs.unix(period.endDate);
    const startDatePlan = dayjs.unix(period.startDatePlan);
    const endDatePlan = dayjs.unix(period.endDatePlan);

    const dateParams = {};
    const dateFormat = 'YYYY-MM-DD';

    if (period.actualsOpen) {
      dateParams[ 'start_date' ] = startDate.format(dateFormat);
      dateParams[ 'end_date' ] = endDate.format(dateFormat);
    }
    if (period.planOpen) {
      dateParams[ 'plan_start_date' ] = startDatePlan.format(dateFormat);
      dateParams[ 'plan_end_date' ] = endDatePlan.format(dateFormat);
    }

    return {
      filterModel: param.map(p => ({
        ...p,
        ...dateParams,
        search,
      }))
    };
  }, [ search, detailedViewParams, period ]);

  useEffect(() => {
    if (!currentRequestParams || !(currentRequestParams.filterModel as FilterModel)?.length) {
      return;
    }
    organizationsService.getTransactionLineSSRMSum(currentRequestParams).then(({ data }) => {
      setTotalRowsCount(data.count);
      gridRef.current?.api?.setGridOption('pinnedBottomRowData', [ { 'amount': data[ 'total' ] } ]);
    });
  }, [ currentRequestParams ]);

  const onShowCostLabelerModal = useCallback((
    nodes: IRowNode[],
    labels: GatheredLabels,
  ) => {
    setSelectedRows(nodes);
    setGatheredLabels(labels);
  }, []);

  const onCloseCostLabelerModal = useCallback(() => {
    setSelectedRows([]);
    setGatheredLabels(null);
  }, []);

  const {
    columnDefs: transactionColumnDefs,
    panels,
    groupOptions,
    fetchTransactions,
    loading,
  } = useTransactionsTable({
    search,
    isGridReady,
    gridRef,
    onShowCostLabelerModal,
    filter,
    params,
    period,
    templateId,
    setRedirect,
    sectionKey,
    tableKey,
    baseRequestParams,
  });

  const onGridColumnsChanged = useCallback((e: GridColumnsChangedEvent) => {
    gridRef.current.api.setGridOption('pinnedBottomRowData', undefined);

    const colIds = transactionColumnDefs.map(col => col.field);
    e.api.setColumnsVisible(colIds, true);
  }, [ transactionColumnDefs ]);

  const costLabeler = useMemo(() => {
    if (!templateId) {
      return null;
    }
    return gatheredLabels && <CostLabelerModal
      isVisible={ selectedRows.length > 0 }
      onClose={ onCloseCostLabelerModal }
      onConfirm={ async () => {
        onCloseCostLabelerModal();
        await fetchTransactions({ filter, params });
      } }
      selectedRows={ selectedRows }
      useServerSide={ selectAllActive }
      serverSideRequestParams={ currentRequestParams }
      excludeTransactionIds={ toggledTransactionIds }
      gatheredLabels={ gatheredLabels }
      templateId={ templateId }
    />;
  }, [
    templateId,
    params,
    filter,
    selectedRows,
    gatheredLabels
  ]);

  const getRows = useCallback(async (serverSideParams: IServerSideGetRowsParams) => {

    const request = serverSideParams.request;
    if (!baseRequestParams) {
      serverSideParams.success({ rowData: [] });
      return;
    }
    const combinedRowData = [];

    const getSuccessResponse = (response) => {
      if (response) {
        const groupCol = request.groupKeys.length ?
          request.rowGroupCols.at(-1) : request.rowGroupCols.at(0);

        const isDimension = groupCol?.id.includes('dimension');

        const groupRows = response.data[ 'groupRows' ]
          .filter(
            (el: string) => {
              // Handle first-letter filter that can be applied for groupings
              if (request.filterModel[ groupCol.id ]) {
                const groupFilter = request.filterModel[ groupCol.id ];
                // Dimension items are stored in redux and only id is provided as a group,
                // so we need to get the name to compare with filter.
                if (isDimension) {
                  const displayName = getDisplayName(dimensionItemMap[ el ]?.name);
                  if (displayName) {
                    return displayName.startsWith(groupFilter.filter);
                  }
                }
                return el.startsWith(groupFilter.filter);
              }
              return true;
            }
          ).map((el: string) => {
            return {
              id: getUUID(),
              [ groupCol.id ]: el,
            };
          });
        const transactionLines = response.data[ 'transactionLines' ];

        return groupRows.length > 0 ? groupRows : transactionLines;
      }
    };

    if (baseRequestParams.filterModel) {
      const SSRMRequestParams = {
        ...request,
        filterModel: baseRequestParams.filterModel.map(
          filterModel => ({
            ...filterModel,
            ...request.filterModel,
          })
        ),
      };
      setCurrentRequestParams(SSRMRequestParams);
      if (!SSRMRequestParams.filterModel.length) {
        serverSideParams.success({ rowData: [] });
        return;
      }

      const response = await organizationsService.getTransactionLinesSSRM(
        SSRMRequestParams, null);
      combinedRowData.push(getSuccessResponse(response));

      serverSideParams.success(
        {
          rowData: combinedRowData.flat(),
        }
      );
    }

    if (gridRef.current?.props?.rowModelType === 'serverSide') {
      // focus first cell
      setTimeout(() => {
        gridRef.current?.api?.ensureIndexVisible(0);
        const firstColumn = gridRef.current?.api.getColumns()?.at(0);
        gridRef.current?.api?.setFocusedCell(0, firstColumn);
      });
    }

  }, [ baseRequestParams ]);

  const serverSideDatasource = useMemo(() => {
    return {
      getRows
    };
  }, [ getRows ]);

  const table = useMemo(() => ({
    columnDefs: transactionColumnDefs,
    rowModelType: 'serverSide',
    serverSideDatasource,
    onGridColumnsChanged,
    groupByOptions: groupOptions,
    panels,
    onSelectionChanged(event: SelectionChangedEvent) {
      const serverSideSelectionState = event.api.getServerSideSelectionState();
      const isSelectAll = !!serverSideSelectionState[ 'selectAll' ];
      setSelectAllActive(isSelectAll);
      const toggledTransactionLineIds = serverSideSelectionState[ 'toggledNodes' ].map(
        nodeId => +event.api.getRowNode(nodeId)?.data?.id
      );
      setToggledTransactionIds(toggledTransactionLineIds);

      const count = event.api.getDisplayedRowCount();
      setIsAnySelected?.current?.(totalRowsCount <= count);
    },
    isLoading: loading,
    rowDragMultiRow: true,
    rowDragEntireRow: true,
    rowDragText: () => {
      const selection = gridRef.current.api
        .getServerSideSelectionState() as IServerSideSelectionState;

      if (selection.selectAll) {
        if (selection.toggledNodes.length === 0) {
          return t('tableWrapper:detailed-view.drag.allRowsSelected');
        } else {
          return t('tableWrapper:detailed-view.drag.selectedRows');
        }
      }

      return t('tableWrapper:detailed-view.drag.rows', { count: selection.toggledNodes.length });
    },
    onModelUpdated: event => {
      const count = event.api.getDisplayedRowCount();
      setIsAnySelected?.current?.(totalRowsCount <= count);
    },
    tableKey,
    ...masterDetailProps
  }), [ transactionColumnDefs, groupOptions, panels, totalRowsCount, serverSideDatasource ]);

  const other = useMemo(() => ({
    costLabeler,
    toggledTransactionIds,
    fetchTransactions,
    totalRowsCount
  }), [
    costLabeler,
    transactionColumnDefs,
    groupOptions,
    panels,
    loading,
    totalRowsCount
  ]);

  return { table, other } as TableHookProps<Transaction, TransactionTableProps>;
};

export default useTransactionsTableProps;

import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import { Card } from 'antd';

import {
  GroupOption, GroupSettings
} from 'components/elements/tableWrapper/types/table.types';
import { appSlice } from 'store/app.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import SearchBar from 'components/elements/searchBar/SearchBar';
import ColumnSelection from 'components/panels/columnSelection/ColumnSelection';
import PanelWrapper from 'components/panels/wrappers/PanelWrapper';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './InputsLeftPanel.module.scss';
import DimensionsView
  from '../../elements/dimensionLeftPanel/listView/dimensionsView/DimensionsView';
import type { FilterList } from '../../../types/filterTable.types';
import FinancialFooter from '../../elements/dimensionLeftPanel/footer/FinancialFooter';
import { clearDimensionFilter, inputsSlice } from '../../../store/inputs.slice';
import { INPUT_TEMPLATE_ID } from '../utils/inputs.utils';
import SortSelection,
{ convertColumnsStateToSortForm } from 'components/panels/sortSelection/SortSelection';
import GroupSelection from 'components/panels/groupSelection/GroupSelection';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import { useTableContext } from 'context/TableContext';
import RegisterPanel from 'components/panels/registerPanel/RegisterPanel';
import { sideTabs } from 'components/panels/panel.constants';
import { usePanelControl } from 'components/panels/panelsContext';
import LayoutControl from 'components/financials/panels/layout/LayoutControl';
import LayoutSidePanelHeader from 'components/panels/layoutSidePanelHeader/LayoutSidePanelHeader';

interface Props {
  gridRef: React.MutableRefObject<AgGridReact>;
  groupOptions?: GroupOption[];
}
const InputsLeftPanel = ({ gridRef, groupOptions = [] }: Props) => {

  const dispatch = useAppDispatch();
  const activePanelType = useAppSelector(store => store.app.leftPanel);
  const filters = useAppSelector(store => store.inputs.filters);
  const [ stagingFilter, setStagingFilter ] = useState<FilterList>([]);

  const { defaultSorting, sortState } = useTableContext();
  const { panels, setLeftActivePanel } = usePanelControl();

  const isActive = panels.map(_ => _.id).includes(activePanelType);
  
  useEffect(() => {
    if (filters) {
      setStagingFilter(filters);
    }
  }, [ filters ]);

  const onChange = (key: string | null) => {
    dispatch(appSlice.actions.setLeftPanel(key));
  };

  const onClose = () => {
    dispatch(appSlice.actions.setLeftPanel(null));
  };

  useEffect(() => {
    setLeftActivePanel(activePanelType);
  }, [ activePanelType ]);

  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  if (!gridRef.current) {
    return null;
  }

  const setFilters = (newFilters: FilterList) => {
    setStagingFilter(newFilters);
    dispatch(inputsSlice.actions.setFilters(newFilters));
  };

  return <PanelWrapper
    orientation='left'
    isActive={ isActive }
  >
    <Card bordered={ false } className={ clsx(panelStyles.card, styles.card) }>
      <LayoutSidePanelHeader
        side='left'
        onChange={ onChange }
        onClose={ onClose }
      />

      <RegisterPanel { ...sideTabs[ 'columns' ] } >
        <SearchBar
          className={ panelStyles.searchBox }
          showSeparator={ false }
          showResults={ false }
        />
        <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
          <ColumnSelection
            isGridReady
            gridRef={ gridRef }
            tableKey={ INPUT_TEMPLATE_ID.toString() }
          />
        </div>
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'sort' ] } >
        <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
          <SortSelection
            gridRef={ gridRef }
            defaults={ convertColumnsStateToSortForm(defaultSorting) }
            sortState={ convertColumnsStateToSortForm(sortState) }
          />
        </div>
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'group' ] } >
        <div className={ clsx(panelStyles.panelBody, styles.panelBody) }>
          <GroupSelection
            onSelect={ onGroupSelect }
            gridRef={ gridRef }
            options={ groupOptions }
          />
        </div>
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'filter' ] } >
        <DimensionsView
          mode='filter'
          templateId={ null }
          filters={ filters }
          stagingFilter={ stagingFilter }
          setStagingFilter={ setStagingFilter }
          classContainer={ styles.dimensionsList }
          gridRef={ gridRef }
          onClearFilters={ (event: MouseEvent, dimensionId) => {
            event.stopPropagation();
            if (dimensionId) {
              dispatch(clearDimensionFilter(dimensionId));
            } else {
              setFilters([]);
            }

          } }
          defaultUnassignNodesCallback={ () => null }
          assignLabels={ () => null }
        />
        <FinancialFooter
          filters={ filters }
          stagingFilter={ stagingFilter }
          onApplyFilters={ () => setFilters(stagingFilter) }
          onClearFilters={ () => {
            setFilters([]);
          } }
        />
      </RegisterPanel>

      <RegisterPanel { ...sideTabs[ 'layout' ] } >
        <LayoutControl />
      </RegisterPanel>

    </Card>
  </PanelWrapper>;
};

const ProviderWrapper = (props: Props) => {
  return <InputsLeftPanel { ...props } />;
};

export default ProviderWrapper;

import { GroupSettings } from 'components/elements/tableWrapper/types/table.types';
import { RefObject } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef } from 'ag-grid-community';

export const ROW_HEIGHT = 35;
export const HEADER_HEIGHT = 50;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const valueIsString = (value: any): value is string => {
  return typeof value === 'string';
};

const isGroupedColumn = (col : ColDef | ColGroupDef): col is ColGroupDef => {
  return 'children' in col;
};

const getGroupedColumnDefs = (group: GroupSettings, columnDefs: (ColDef | ColGroupDef)[]) => {
  return columnDefs.map((column) => {
    if (isGroupedColumn(column)) {
      return {
        ...column,
        children: getGroupedColumnDefs(group, column.children)
      };
    } else {
      if (
        group.primary?.field && (
          column.field === group.primary?.field ||
          column.field === group.secondary?.field
        )
      ) {
        return { ...column, rowGroup: true };
      }
      return { ...column, rowGroup: false };
    }
  });
};

export const onSelectGrouping = (group: GroupSettings, gridRef: RefObject<AgGridReact>) => {
  const columnDefs = gridRef.current.api.getColumnDefs();
  const groupColumnDefs = getGroupedColumnDefs(group, columnDefs);

  gridRef.current.api.setGridOption('columnDefs', groupColumnDefs);
  gridRef.current.api.setFilterModel(null);
  gridRef.current.api.onFilterChanged();
};

import React from 'react';
import { IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { SortIndex } from 'components/elements/tableWrapper/types/table.types';
import SortSelection,
{ ColumnSortSettings, FormType } from 'components/panels/sortSelection/SortSelection';
import { useFinancialTable } from 'context/FinancialTableContext';
import { isEqual, isEmpty } from 'lodash';
import { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { financialsSlice, SortingSettingPayload } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  isTransactionLevelSorting,
  gridSortOptionsValues,
  AG_ROOT_NODE_ID
} from 'utils/financials.utils';
import { SortingType } from 'utils/sorting.constants';
import { NodeSortingSetting, getRootSortSettings } from 'utils/sorting.utils';
import Modal from 'components/elements/modal/Modal';
import { LoadingStatus, RowType } from '../../../../../types/financials.types';
import useLazyLoadingRow from '../../../financialTable/hooks/useLazyLoadingRow';

interface Props {
  node: IRowNode | null | undefined;
  gridRef: MutableRefObject<AgGridReact>;
}

const SortSection = ({ node, gridRef }: Props) => {
  const [ t ] = useTranslation('financials');
  const { state: { templateId } } = useFinancialTable();
  const dispatch = useAppDispatch();

  const tablesOptions = useAppSelector((state) => state.financials.options);

  const customSorting = useAppSelector((state) => state.financials.tables[ templateId ]?.sorting);
  const { addTransactionsToNode } = useLazyLoadingRow(gridRef, templateId);

  const [
    showSortChangeConfirmation,
    setShowSortChangeConfirmation
  ] = useState<Record<string, SortingSettingPayload> | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const defaultSortSettings: Partial<FormType> = useMemo(() => {
    return getDefaultRootSortSettings(node, customSorting);
  }, [ node, customSorting ]);

  const sortState: Partial<FormType> = useMemo(() => {
    return getSortFormValues(customSorting, node);
  }, [ node, customSorting ]);

  const sortOptions: { label: string; value: number | string }[] = useMemo(() => {
    if (!node || !node.parent) return [];

    const isToSort = isTransactionLevelSorting(node.parent);
    return gridSortOptionsValues(isToSort);
  }, [ node ]);

  const onSort = useCallback((sortSetting: Partial<FormType> | null) => {
    if (!node || !node.parent || !node.parent.id) return;

    const newSettings = getNewSettingsFromForm(sortSetting);

    if (isEqual(newSettings, customSorting[ node.parent.id ])) return;

    // show confirmation popup for sort change on root node
    if (node.parent.id === AG_ROOT_NODE_ID && !isEmpty(newSettings)) {
      setShowSortChangeConfirmation({
        newSettings: {
          templateId,
          nodeId: node.parent.id,
          settings: { ...newSettings }
        },
        oldSettings: {
          templateId,
          nodeId: node.parent.id,
          settings: { ...customSorting[ node.parent.id ] }
        }
      });
      return;
    }

    // check if it is a lazy loading row
    if (node.parent.data?.asyncStatus != null) {

      // Check if maybe we already loaded all the rows - then we can sort locally
      // We can check that by finding a load more row
      const existingLoadMoreRow = node.parent.childrenAfterGroup.find(
        (child) => child.data?.type === RowType.LOAD_MORE
      );

      if (existingLoadMoreRow && tablesOptions.loadInvoiceRowsInBatches) {

        // This seems to be unfortunately necessary, as the grid does not remove the rows inside
        //  further groups and instead creates filler groups with no data.
        //  NOTE: We assume here that there is just 2 levels below the parent node.
        const toRemove = node.parent.childrenAfterFilter.flatMap(
          child => [ child, ...child.childrenAfterFilter ]
        );
        gridRef.current.api?.applyTransaction({
          remove: toRemove
        });
        gridRef?.current?.api?.redrawRows();
        dispatch(financialsSlice.actions.setLazyLoadingStatus({
          nodeId: node.parent.id,
          status: LoadingStatus.LOADING,
          templateId
        }));
        addTransactionsToNode(
          {
            nodeToAppend: node.parent,
            loadCount: 20,
            nodeSorting: newSettings
          }
        );
      }
    }

    dispatch(financialsSlice.actions.setSorting({
      templateId,
      nodeId: node.parent.id,
      settings: newSettings
    }));

  }, [ node, customSorting, templateId, dispatch, addTransactionsToNode ]);
  
  return (
    <>
      <SortSelection
        sortOptions={ sortOptions }
        defaults={ defaultSortSettings }
        sortState={ sortState }
        gridRef={ gridRef }
        customSortHandler={ onSort }
        onSortReset={ () => onSort(null) }
      />

      <div ref={ ref } />

      <Modal
        title={ t('common:warnings.confirm-close-title') }
        getContainer={ () => ref.current || document.body }
        isVisible={ !!showSortChangeConfirmation }
        closeOnConfirm={ false }
        onConfirm={ () => {
          dispatch(financialsSlice.actions.setSorting(
            (showSortChangeConfirmation as Record<string, SortingSettingPayload>).newSettings
          ));
          setShowSortChangeConfirmation(null);
        } }
        onCancel={ () => {
          dispatch(financialsSlice.actions.setSorting(
            (showSortChangeConfirmation as Record<string, SortingSettingPayload>).oldSettings
          ));
          setShowSortChangeConfirmation(null);
        } }
        onClose={ () => {
          dispatch(financialsSlice.actions.setSorting(
            (showSortChangeConfirmation as Record<string, SortingSettingPayload>).oldSettings
          ));
          setShowSortChangeConfirmation(null);
        } }
        okText={ t('common:form.apply') as string }
        bodyStyle={ { overflow: 'unset' } }
        cancelText={ t('common:form.cancel') }
      >
        <div>
          <p>{ t('table.root-sort-change-modal.description-part-1') }</p>
          <p>{ t('table.root-sort-change-modal.description-part-2') }</p>
        </div>
      </Modal>
    </>
  );
};

type SortingSettings = { isDescending: boolean; type: SortingType };
type ColumnState = { colId: string | number; sort: 'asc' | 'desc'; sortIndex: SortIndex };

function convertToColumnState(index: SortIndex, sortSetting: SortingSettings): ColumnState;
function convertToColumnState(
  index: SortIndex, sortSetting?: SortingSettings
): ColumnState | undefined {
  if (!sortSetting) {
    return undefined;
  }

  return {
    colId: sortSetting.type as unknown as number,
    sort: (sortSetting.isDescending ? 'desc' : 'asc'),
    sortIndex: index
  };
}

function convertToRowSorting(
  sortSetting: ColumnSortSettings
) {
  return {
    type: sortSetting.colId as unknown as SortingType,
    isDescending: sortSetting.sort === 'desc'
  };
}

function getNewSettingsFromForm(
  sortSetting: Partial<FormType> | null,
) {
  return {
    ...(sortSetting?.primary ? {
      primary: convertToRowSorting(sortSetting.primary)
    } : {}),
    ...(sortSetting?.secondary ? {
      secondary: convertToRowSorting(sortSetting.secondary)
    } : {})
  };
}

function getSortFormValues (
  sorting: Record<string, NodeSortingSetting>, node: IRowNode | null | undefined
): Partial<FormType> {
  const sortSettings: Partial<FormType> = {};

  if (!node || !node.parent || !node.parent.id || !sorting) return sortSettings;

  if (sorting[ node.parent.id ]?.primary) {
    sortSettings[ SortIndex.PRIMARY ] = convertToColumnState(
      SortIndex.PRIMARY, sorting[ node.parent.id ].primary as SortingSettings
    );

    if (sorting[ node.parent.id ]?.secondary) {
      sortSettings[ SortIndex.SECONDARY ] = convertToColumnState(
        SortIndex.SECONDARY, sorting[ node.parent.id ].secondary as SortingSettings
      );
    }
  } else {
    return getDefaultRootSortSettings(node, sorting);
  }

  return sortSettings;
}

function getDefaultRootSortSettings (
  node: IRowNode | null | undefined,
  sorting: Record<string, NodeSortingSetting>
): Partial<FormType> {
  const sortSettings: Partial<FormType> = { };

  if (!node ||
    !node.parent ||
    !node.parent.id ||
    node.parent?.id === AG_ROOT_NODE_ID
  ) return sortSettings;

  const rootSortSettings = getRootSortSettings(node, sorting);

  if (rootSortSettings) {
    sortSettings[ SortIndex.PRIMARY ] = convertToColumnState(
      SortIndex.PRIMARY, rootSortSettings.primary
    );
    sortSettings[ SortIndex.SECONDARY ] = convertToColumnState(
      SortIndex.SECONDARY, rootSortSettings.secondary
    );
  }

  return sortSettings;
}

export default SortSection;

import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import dayjs from 'dayjs';
import { t } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-len
export function createValidationRules(field: TableColDef, getValues?: () => any, multipleSelected?: boolean) {
  const validate = getValidationFunction(field, getValues, multipleSelected);

  if (!field.cellEditorParams) return {};

  return {
    required: (field.cellEditorParams.required && !multipleSelected) ?
      t('common:validation.requiredField', { field: field.headerName })
      : false,
    pattern: field.cellEditorParams.pattern ? {
      value: field.cellEditorParams.pattern,
      message: t('common:validation.invalidField', { field: field.headerName })
    } : undefined,
    validate: validate ? validate : undefined,
    maxLength: field.cellEditorParams.maxLength ? {
      value: field.cellEditorParams.maxLength,
      message: t('common:validation.maxLength', {
        field: field.headerName,
        length: field.cellEditorParams.maxLength
      })
    } : undefined,
    minLength: field.cellEditorParams.minLength ? {
      value: field.cellEditorParams.minLength,
      message: t('common:validation.minLength', {
        field: field.headerName,
        length: field.cellEditorParams.maxLength
      })
    } : undefined,
    min: field.cellEditorParams.min ? {
      value: field.cellEditorParams.min,
      message: t('common:validation.outOfRangeField', {
        field: field.headerName,
        min: field.cellEditorParams.min,
        max: field.cellEditorParams.max
      })
    } : undefined,
    max: field.cellEditorParams.max ? {
      value: field.cellEditorParams.max,
      message: t('common:validation.outOfRangeField', {
        field: field.headerName,
        min: field.cellEditorParams.min,
        max: field.cellEditorParams.max
      })
    } : undefined,
  };
}

function getValidationFunction(
  field: TableColDef,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues?: () => any,
  multipleSelected?: boolean
) {
  const params = field.cellEditorParams;

  if (!params) return undefined;

  if (params.disableDecimals) {
    return value => {
      return /^[0-9]+$/.test(value.toString()) ? true : t('common:validation.integer');
    };
  }

  if (params.disallowZero && !multipleSelected) {
    return value => {
      return +value !== 0 ? true : t('common:validation.zeroField', { field: field.headerName });
    };
  }

  if (params.comparison && params.type) {
    return (value) => {
      const comparisonValue = getValues?.()?.[ params.comparison ];
      if (!value || !comparisonValue) {
        return true;
      }

      if (params.type === 'after' && dayjs(value).isBefore(dayjs(comparisonValue))) {
        return t('common:validation.date.after', { field: params.comparison });
      }
      if (params.type === 'before' && dayjs(value).isAfter(dayjs(comparisonValue))) {
        return t('common:validation.date.before', { field: params.comparison });
      }
    };

  }

  return undefined;
}

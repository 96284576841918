import React, { RefObject, useCallback, useMemo } from 'react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip } from 'antd';

import { type FinancialNode, RowData, RowType } from 'types/financials.types';
import { useAppSelector } from 'store/hooks/hooks';
import { getDisplayName } from 'utils/common.utils';
import { getBreakdownName } from 'utils/template.utils';
import { isDimensionItemNode } from 'types/templates.types';
import {
  UNASSIGNED_ROW_NAME
} from '../../../singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import {
  getFinancialItemTooltipContent,
  getUnassignedNodeName,
  isLazyLoadingRow,
  isLoadMoreRow,
  isProductProxy,
  transactionLineTooltipContent,
} from 'utils/financials.utils';
import LazyLoadingRow from '../../lazyLoadingRow/LazyLoadingRow';
import RowCounterpartyLogo from './RowCounterpartyLogo';

import { ReactComponent as SigmaIcon } from 'assets/icons/sigma.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/warning.svg';

import styles from '../styles/FinancialTable.module.scss';
import rowStyles from './RowTitleRenderer.module.scss';
import useLazyLoadingRow, { BATCH_LOADING_DISABLED_ROWS } from '../hooks/useLazyLoadingRow';

const NO_TITLE_NODE = [ RowType.SPACER, RowType.HALF_SPACER ];

interface Props extends ICellRendererParams {
  gridRef: RefObject<AgGridReact>;
  templateId: number;
}

const BATCH_LOADING_COUNT = 20;

const RowTitleRenderer = ({ gridRef, templateId, ...renderParams }: Props) => {
  const [ t ] = useTranslation('financials');
  const showCounterpartyLogos = useAppSelector(state => state.financials.options.counterpartyLogos);
  const accountsNumber = useAppSelector(state => state.financials.options.accountsNumber);
  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);
  const { addTransactionsToNode } = useLazyLoadingRow(gridRef, templateId);
  const tablesOptions = useAppSelector(state => state.financials.options);

  const getRowDisplayName = useCallback(

    // Some transaction lines we receive from backend should be translated on frontend.
    // This includes adjustment transactions for VAT and retained earnings.

    // t('<retained-earnings-tl-memo>', { ns: 'financials' } )
    // t('<vat-adjustment-tl-memo>', { ns: 'financials' } )
    // t('<vat-statement-created-tl-memo>', { ns: 'financials' } )
    // t('<vat-adjustment-product-proxy>', { ns: 'financials' } )

    (params: { value: string; data: FinancialNode; node: IRowNode }): string => {
      let displayName;
      const rowData = params.data?.rowData;

      if (NO_TITLE_NODE.includes(params?.data?.type)) return '';

      if (isProductProxy(node)) {
        return getDisplayName(rowData?.name);
      }

      if (params.value == null) return null;
      displayName = getDisplayName(rowData?.name) || params.value;

      if (params?.data?.type === RowType.COUNTERPARTY_TYPE) {
        displayName = capitalize(getDisplayName(rowData?.name).toLowerCase());
      }
      if (params?.data?.type === RowType.UNASSIGNED) {
        displayName = getUnassignedNodeName(rowData);
      }

      if (params?.data?.type === RowType.NEW_BUDGET_ITEM) {
        displayName = params.data.rowData?.memo;
      }

      if (params?.data?.type === RowType.DIMENSION_ITEM) {
        const dimensionItem =
          dimensionMap[ rowData.dimensionId ]?.items?.find(item => item.id === rowData.id);
        displayName = getBreakdownName(rowData, dimensionItem);
        if (rowData.relation === 'ACCOUNT' && accountsNumber) {
          displayName = `${ rowData.account.number } ${ displayName }`;
        }
        if (displayName === 'Unassigned') {
          const count = +(params.data.actual?.count || 0) + +(params.data.plan?.count || 0);
          displayName = `${ displayName } \u00B7 ${ count }`;
        }
      }

      if (params?.data?.type === RowType.BREAKDOWN) {
        const dimension = dimensionMap[ rowData.id ];
        return getBreakdownName(rowData, dimension);
      }
      return t(displayName);
    }, [ accountsNumber, dimensionMap ]);

  const getTooltipText = useCallback((node: IRowNode, rowData: RowData): string => {
    if (node?.data?.type === RowType.TRANSACTION) {
      return transactionLineTooltipContent(rowData.description);
    }
    if (node?.data?.rowData?.tooltip) {
      return getFinancialItemTooltipContent(node);
    }
    return '';
  }, []);

  const showCounterpartyLogo = useCallback((node: IRowNode): boolean => {
    if (node.data && isDimensionItemNode(node.data)) {
      return showCounterpartyLogos &&
        node.data.rowData.counterparty &&
        node.key !== UNASSIGNED_ROW_NAME;
    }
    return false;
  }, [ showCounterpartyLogos ]);

  const shouldShowSigma = useCallback((node: IRowNode): boolean => {
    return [
      RowType.SUM_UP,
      RowType.SUBTOTAL,
      RowType.TOTAL,
      RowType.FORMULA
    ].includes(node.data?.type);
  }, []);

  const shouldShowErrorIcon = useCallback((rowNode: IRowNode): boolean => {
    return rowNode.data?.isFailed;
  }, []);

  const node = renderParams.node;
  const data = renderParams.data;
  const value = renderParams.value;
  const tooltipText = getTooltipText(node, data?.rowData);
  const displayName = getRowDisplayName({ node, data, value });

  const loadMoreCount = useMemo(() => {
    if (tablesOptions.loadInvoiceRowsInBatches) {
      return BATCH_LOADING_COUNT;
    } else {
      return BATCH_LOADING_DISABLED_ROWS;
    }
  }, [ tablesOptions ]);

  if (isLazyLoadingRow(node)) {
    return <LazyLoadingRow
      data={ data }
      node={ node }
      value={ displayName }
      gridRef={ gridRef }
      tooltip={ tooltipText }
      templateId={ templateId }
    />;
  }

  if (isLoadMoreRow(node)) {
    return <div className={ rowStyles.loadMoreRow }>
      <div
        className={ rowStyles.loadNext }
        onClick={ () => {
          addTransactionsToNode({ nodeToAppend: node.parent, loadCount:loadMoreCount });
        } }
      >
        { t('financials:table.load-more', { count: loadMoreCount }) }
      </div>
      <div className={ rowStyles.separator }></div>
      <div
        className={ rowStyles.showAll }
        onClick={ () => {
          addTransactionsToNode({ nodeToAppend: node.parent, loadCount: 9999 });
        } }
      >
        { t('financials:table.show-all') }
      </div>

    </div>;
  }

  const showLogo = showCounterpartyLogo(node);
  return <Tooltip title={ tooltipText || null } mouseEnterDelay={ .8 }>
    <div className={ `${ styles.text } ${ showLogo ? styles.renderLogoExpandRow : '' } ` }> 
      { showLogo && <RowCounterpartyLogo row={ node }/> }
      <span>{ displayName }</span>
      { shouldShowSigma(node) ? <SigmaIcon className={ styles.sigma }/> : null }
      {
        shouldShowErrorIcon(node) ?
          <Tooltip title={ t('financials:table.row-loading-error') }>
            <ErrorIcon className={ styles.errorIcon }/>
          </Tooltip> :
          null
      }
    </div>
  </Tooltip>;
};

export default RowTitleRenderer;

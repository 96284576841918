import { getTransactionQuery, isProductProxy, isTransactionRow, } from '../utils/financials.utils';
import { AxiosResponse } from 'axios';
import { notifyError } from '../utils/notifications.utils';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../types/statutory.types';
import {
  TransactionLineFilterParams,
  TransactionLinePayloadParams
} from '../services/statutory.service';
import { IRowNode } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../store/hooks/hooks';
import { forceRefresh } from 'store/dashboard.slice';
import { financialsSlice } from '../store/financials.slice';
import organizationsService from '../services/organizations.service';
import { useCallback } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { SHOW_LABEL_CONFIRMATION_MODAL } from '../utils/storage.utils';

export interface GatheredLabels {
  dimensionItems?: number[];
  unassignedDimensions?: number[];
}

export const useShouldSkipLabelingModal = () => {

  const [ skipConfirmation ] = useLocalStorage(SHOW_LABEL_CONFIRMATION_MODAL, false);

  return useCallback((event: MouseEvent) => {
    return event.altKey || skipConfirmation;
  }, [ skipConfirmation ]);

};

const useLabeler = (templateId: number) => {
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const period = useAppSelector(state => state.financials.tables[ templateId ]?.period);
  const setLabelingTimestamp = () => {
    dispatch(financialsSlice.actions.setLabelingTimestamp({ templateId, value: Date.now() }));
  };

  const getLabelingPayloads = (nodes: IRowNode[]): TransactionLineFilterParams[][] => {
    const payloads: TransactionLinePayloadParams[][] = [];
    const groupNodes: IRowNode[] = [];
    const transactionNodes: IRowNode[] = [];
    // We don't want to label each child, so we just keep track of them and skip them.
    const children = new Set<string>();
    let transactionIds: number[] = [];
    for (const node of nodes) {
      if (children.has(node.id)) {
        continue;
      } else {
        for (const child of node.allLeafChildren) {
          children.add(child.id.toString());
        }
      }
      if (isTransactionRow(node) || isProductProxy(node)) {
        transactionNodes.push(node);
      } else {
        groupNodes.push(node);
      }
    }

    if (transactionNodes.length) {
      transactionIds = isProductProxy(transactionNodes[ 0 ])
        ? transactionNodes.map(n => n.allLeafChildren
          .filter(a => isTransactionRow(a))
          .map(a => a.key)).flat(1)
        : transactionNodes.map(n => n.data.rowData.id);
    }

    if (transactionIds.length) {
      payloads.push([ { transactionLines: transactionIds } ]);
    }

    for (const node of groupNodes) {
      const query = getTransactionQuery([ node ], templateId, period);
      payloads.push(query);
    }
    return payloads;
  };

  const resolveLabelingPromises = async (promises: Promise<AxiosResponse>[]) => {
    try {
      await Promise.all(promises);
    } catch (e) {
      if (e.response?.data.nonFieldErrors)
        notifyError(e.response.data.nonFieldErrors);
      else {
        notifyError(
          t('notifications.unexpected-error.message',
            { ns: 'common' }
          )
        );
        throw e;
      }

      throw e;
    } finally {
      dispatch(forceRefresh());
      setLabelingTimestamp();
    }
  };
  /**
   * Assigns nodes to multiple dimension items and/or unassigns them from multiple dimensions.
   */
  const labelNodes = async (
    nodes: IRowNode[],
    breakdowns: GatheredLabels,
    createRule = false,
  ) => {
    const filteringPayloads = getLabelingPayloads(nodes);
    const promises = [];
    for (const payload of filteringPayloads) {
      promises.push(unifiedLabelingRequest(
        payload, breakdowns.dimensionItems, breakdowns.unassignedDimensions, createRule)());
    }
    await resolveLabelingPromises(promises);

  };

  const labelTransactions = async (
    transactionLines: Transaction[],
    breakdowns: GatheredLabels,
    createRules?: boolean,
    ruleStartDate?: string,
    ruleEndDate?: string
  )=> {
    const filteringPayload = [ {
      // eslint-disable-next-line camelcase
      transaction_lines: transactionLines.map(transaction => transaction.id)
    } ];
    const promise = unifiedLabelingRequest(
      filteringPayload,
      breakdowns.dimensionItems,
      breakdowns.unassignedDimensions,
      createRules,
      ruleStartDate,
      ruleEndDate
    )();
    await resolveLabelingPromises([ promise ]);
  };

  const unifiedLabelingRequest = (
    filteringPayload: TransactionLineFilterParams[],
    dimensionItemIds: number[],
    unassignDimensionIds: number[],
    createRules?: boolean,
    ruleStartDate?: string,
    ruleEndDate?: string
  ) => {
    return organizationsService.assignTransactionLines.bind(this, {
      filters: filteringPayload,
      dimensionSplit: dimensionItemIds?.map(dimensionItem => ({ dimensionItem, percentage: 100 })),
      unassignDimensionIds: unassignDimensionIds,
      createRules: createRules,
      ruleStartDate: ruleStartDate,
      ruleEndDate: ruleEndDate
    });
  };

  return {
    labelNodes,
    labelTransactions
  };

};

export default useLabeler;

import { MutableRefObject } from 'react';
import { IRowNode, CellRange } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import useChatbotCommandBar from 'components/collaboration/chat/hooks/useChatbotCommandBar';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';
import useCellCommandBar from '../financialTable/hooks/commandBar/useCellCommandBar';
import useGenerateTable from '../financialTable/hooks/commandBar/useGenerateTable';
import useGridCommandBar from '../financialTable/hooks/commandBar/useGridCommandBar';
import useNodeCommandBar from '../financialTable/hooks/commandBar/useNodeCommandBar';
import useFinancialsCommandBar from '../hooks/useFinancialsCommandBar';
import { TableCoordinates } from '../panels/overviewPanel/overviewPanel.types';

interface Props {
  templateId: number;
  gridRef: MutableRefObject<AgGridReact>;
  node: IRowNode;
  gridReady: boolean;
  tableIsInEdit: boolean;
  setInvoiceTransaction: (node: IRowNode) => void;
  lastSelectedCellRange: CellRange[];
  lastNodesSelected: IRowNode[];
  isTableEdited: object | null;
  addRow: (node: IRowNode) => void;
  setNodesForRemove: (nodes: IRowNode[] | TableCoordinates[]) => void;
}

const FinancialCommandBar = ({
  templateId,
  gridReady,
  tableIsInEdit,
  gridRef,
  node,
  setInvoiceTransaction,
  lastSelectedCellRange,
  lastNodesSelected,
  isTableEdited,
  addRow,
  setNodesForRemove
}: Props) => {
  useCommandBarDefaults({
    keys: [
      'filter', 'label', 'sort', 'layout',
      'budget',
      'divider.1',
      'pdf',
      'export',
      'settings',
      'divider.2',
      'addRow',
      'budgetDropdown',
      'removeSelected',
      'overviewDetails',
      'detailed-view',
      'divider.3',
      'chat',
      'save'
    ],
    mode: 'show' as const,
    showCapsulesFilter: true
  });
  useChatbotCommandBar({ });
  useGridCommandBar({ templateId, gridRef });
  useNodeCommandBar({ templateId, setInvoiceTransaction, node });
  useGenerateTable({ templateId, gridRef, isTableEdited });
  useCellCommandBar({
    gridReady,
    templateId,
    gridRef,
    tableIsInEdit,
    cellRange: lastSelectedCellRange,
    selectedNodes: lastNodesSelected,
    addRow,
    setNodesForRemove
  });
  useFinancialsCommandBar({ tableIsInEdit, gridReady });

  return null;
};

export default FinancialCommandBar;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Table from '../../../elements/tableWrapper/table/Table';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { clearDetailedViewSettings, updateDetailedViewSettings } from 'store/financials.slice';
import {
  ActiveSection,
  HeaderTool,
  Redirect,
  TableColDef,
  TableSection,
} from '../../../elements/tableWrapper/types/table.types';
import { Transaction } from 'types/statutory.types';
import { DetailedViewType, Period } from 'types/financials.types';
import { ReportType } from 'types/templates.types';
import useBudgetItemTableProps
  from 'components/budget/budgetItemTable/hooks/useBudgetItemTableProps';
import useTransactionsTableProps
  from 'components/financials/detailedView/transactionsTable/hooks/useTransactionsTableProps';
import { InternalBudgetItem } from 'components/budget/budgetItemTable/hooks/useBudgetItems';
import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import useKeyPressListener from 'hooks/useKeyPressListener';
import useBudgetingSectionProps from '../transactionsTable/hooks/useBudgetingSectionProps';
import { getDisplayName } from '../../../../utils/common.utils';
import { BudgetItem } from '../../../../types/budget.types';
import { DisplayName } from '../../../../types/app.types';
import Split from 'components/financials/dimensionSplit/Split';
import { useWarnOnUnsaved } from '../../financialTable/hooks/useWarnOnUnsaved/useWarnOnUnsaved';

const CLOSE_KEY_BINDING = { code: 'Escape' };

interface Props {
  onClose: () => void;
  headerTools: HeaderTool[];
  isInModal?: boolean;
}

const DetailedViewTable = ({ onClose, headerTools, isInModal = false }: Props) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('tableWrapper');
  const gridRef = useRef<AgGridReact>();

  const [ isGridReady, setIsGridReady ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ redirect, setRedirect ] = useState<Redirect>(null);
  const {
    budgetItemType,
    period,
    filter,
    templateId,
    sectionKey,
    params: detailedViewParams,
    type
  } = useAppSelector(state => state.financials.detailedView);

  // ! HACK: To avoid re-renders of the table when the header tools change
  const setIsAnySelected = useRef<(areAllLoaded?: boolean) => void>(null);

  const {
    budgetItemCategories,
    budgetItemCategoryInputs,
    budgetItemTypesMap,
  } = useAppSelector(state => state.budget);

  /**
   * Callback we pass to budget item table props that allows for changing the active section of
   * the table when new item is added. We select section that matches the category of budget item
   * type
   */
  const onAddBudgetItem = (budgetItem: BudgetItem) => {
    const itemType = budgetItemTypesMap[ budgetItem.itemType ];
    if (itemType && itemType.category) {
      dispatch(updateDetailedViewSettings({ sectionKey: getDisplayName(itemType.category) }));
    }
  };

  const {
    table: budgetTableProps,
    other: budgetOtherProps,
  } = useBudgetItemTableProps({
    gridRef,
    period,
    search,
    budgetItemType,
    setIsAnySelected,
    setRedirect,
    onAddBudgetItem,
    onBudgetItemsUpdated: () => {
      // HACK: Just invoking it is not working, so we need to wait a bit.
      setTimeout( () => setIsAnySelected.current?.(), 200);
    }
  });

  const { warnModal, continueWithCheck } = useWarnOnUnsaved<void>(
    {
      onConfirm: () => {
        onExitTable();
      },
      isUnsaved: budgetOtherProps.canApply
    }
  );

  const {
    table: transactionTableProps,
    other: transactionOtherProps,
  } = useTransactionsTableProps({
    isGridReady,
    gridRef,
    templateId,
    search,
    filter,
    params: detailedViewParams ? detailedViewParams[ ReportType.ACTUAL ] : null,
    period,
    setRedirect,
    setIsAnySelected,
    sectionKey
  });

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [ period ]);

  useEffect(() => {
    dispatch(updateDetailedViewSettings({
      data: {
        budgetItems: budgetTableProps.rowData,
      }
    }));
  }, [ budgetTableProps.rowData ]);

  const onSplitsUpdated = (data?: { updated: BudgetItem[] }) => {
    gridRef?.current?.api?.onFilterChanged();

    if (data?.updated) {
      data.updated.forEach(element => {
        budgetOtherProps.update(element);
      });

      gridRef.current.api.applyTransaction({ update: data.updated });
      setIsAnySelected.current();
    }
  };

  const headerToolsWithSplit = useMemo(() => [
    ...headerTools,
    {
      key: 'split',
      button: <Split
        onSplitsUpdated={ onSplitsUpdated }
        currentTransactionRequestParams={
          detailedViewParams ? detailedViewParams[ ReportType.ACTUAL ] : null
        }
        gridRef={ gridRef }
        selectionChanged={ setIsAnySelected }
        totalItemCount={ transactionOtherProps.totalRowsCount }
      />,
      buttonPosition: 1,
    },
  ], [ headerTools, transactionOtherProps ]);

  const budgetSectionProps = useBudgetingSectionProps({
    gridRef,
    isGridReady,
    headerTools: headerToolsWithSplit,
    budgetTableProps,
    type,
    sectionKey
  });

  const getColumnDefsForCategory = (category: DisplayName, defs: TableColDef[]) => {
    const inputsToShow = budgetItemCategoryInputs[ category[ 'en' ] ];
    if (!inputsToShow) {
      return defs;
    }
    const colsToShow = inputsToShow.map(input => 'input_' + input);
    const columnsBeforeInputs = [];
    let inputColumns = [];
    const columnsAfterInputs = [];

    const inputsSeen = false; // input columns are always grouped together
    for (const def of defs) {
      const isInputColumn = def.field.startsWith('input_');
      if (isInputColumn) {
        inputColumns.push(def);
      } else if (inputsSeen) {
        columnsAfterInputs.push(def);
      } else {
        columnsBeforeInputs.push(def);
      }
    }
    inputColumns = inputColumns.sort((a, b) => {
      const aIndex = colsToShow.indexOf(a.field);
      const bIndex = colsToShow.indexOf(b.field);
      return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
    }).map(def => {
      if (colsToShow.includes(def.field)) {
        return { ...def, hide: false };
      }
      return { ...def, hide: true };
    });

    return [
      ...columnsBeforeInputs,
      ...inputColumns,
      ...columnsAfterInputs
    ];
  };

  const sections: TableSection[] = useMemo(() => {
    return [
      {
        key: sectionKeys.budgeting,
        label: t('detailed-view.sections.all'),
      },
      // each category has by default displayed only columns with inputs that are assigned
      // to the category and only displays budget items from that category.
      ...budgetItemCategories.map(
        category => ({
          key: getDisplayName(category),
          label: getDisplayName(category),
        })
      ),
      {
        key: sectionKeys.transactions,
        label: t('detailed-view.sections.transactions'),
      }
    ];
  }, [
    headerToolsWithSplit,
    sectionKey,
  ]);

  const getSectionProps = (forSectionKey) => {

    const budgetSectionsProps = {};

    for (const category of budgetItemCategories) {
      const categoryKey = getDisplayName(category);
      budgetSectionsProps[ categoryKey ] = {
        ...budgetSectionProps,
        columnDefs: getColumnDefsForCategory(category, budgetSectionProps.columnDefs),
        rowData: budgetTableProps.rowData.filter(
          (item: InternalBudgetItem) => {
            const itemType = budgetItemTypesMap[ item.itemType ];
            if (itemType && itemType.category) {
              return getDisplayName(itemType.category) === getDisplayName(category);
            }
            return false;
          }
        )
      };
    }

    const keyToProps = {
      [ sectionKeys.budgeting ]: budgetSectionProps,
      [ sectionKeys.transactions ]: {
        ...transactionTableProps,
        headerTools: headerToolsWithSplit
      },
      ...budgetSectionsProps
    };
    return keyToProps[ forSectionKey ];
  };

  const onSectionChange = useCallback((_sectionKey: string) => {
    dispatch(updateDetailedViewSettings({ sectionKey: _sectionKey }));
  }, []);

  const changeSection = useCallback((_sectionKey: string) => {
    const section = sections.find(s => s.key === _sectionKey);
    setActiveSection({
      ...section,
      tableProps: getSectionProps(_sectionKey)
    });
  }, [ sections, transactionTableProps, budgetTableProps ]);

  const [ activeSection, setActiveSection ] =
    useState<ActiveSection<Transaction | BudgetItem> | null>(null);

  const onPeriodChange = useCallback((_period: Period) => {
    dispatch(updateDetailedViewSettings({ period: _period }));
  }, []);

  const onExitTable = useCallback(() => {
    onClose();
    budgetOtherProps.onClose();
    dispatch(clearDetailedViewSettings());
  }, [ onClose, budgetOtherProps.onClose ]);

  const onTableClose = useCallback(() => {
    continueWithCheck();
  }, [ budgetOtherProps.canApply, onExitTable ]);

  useEffect(() => {
    if (sectionKey) {
      changeSection(sectionKey);
    } else if (budgetItemType) {
      changeSection(sectionKeys.budgeting);
    } else {
      changeSection(sectionKeys.transactions);
    }

  }, [ budgetItemType, sectionKey, changeSection ]);

  const onGridReady = useCallback(() => {
    setIsGridReady(true);
  }, [ setIsGridReady ]);

  useKeyPressListener({ keyBinding: CLOSE_KEY_BINDING, cb: onTableClose, enabled: isInModal });

  return (
    <>
      <Table
        name={ t('transactions-table.title') }
        tableKey='detailed-view'
        gridRef={ gridRef }
        sections={ sections }
        changeSection={ onSectionChange }
        activeSection={ activeSection }
        cacheBlockSize={ 100 }
        headerHeight={ 58 }
        rowBuffer={ 50 }
        onSearch={ setSearch }
        showSearch={ true }
        period={ period }
        onPeriodChange={ onPeriodChange }
        columnDefs={ [] }
        isFloatingPanel={ type === DetailedViewType.FLOATING_PANEL }
        redirect={ redirect }
        onGridReady={ onGridReady }
        onClose={ onTableClose }
      />
      { warnModal }

      { transactionOtherProps.costLabeler }
    </>
  );
};

export default DetailedViewTable;

import React, { MouseEvent, RefObject, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useSearchParams } from 'react-router-dom';
import {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import type { FilterList } from 'types/filterTable.types';
import DimensionDetails from '../dimensionDetails/DimensionDetails';
import DimensionsListView from '../dimensionsListView/DimensionsListView';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks';
import { useFinancialTable } from '../../../../../context/FinancialTableContext';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import { clearFilters, removeDimensionFromFilters } from '../../../../../store/financials.slice';
import styles from './DimensionView.module.scss';

interface Props {
  mode: 'label' | 'filter';
  classContainer?: string;
  filters?: FilterList;
  stagingFilter: FilterList | undefined;
  setStagingFilter: (value: FilterList | undefined) => void;
  templateId: number;
  gridRef: RefObject<AgGridReact>;
  defaultUnassignNodesCallback: UnassignFunction;
  onClearFilters?: (event: MouseEvent, dimensionId?: number) => void;
  assignLabels: AssignFunction;
  config?: {
    hideLazyLabelSetting?: boolean;
  };
}

// TODO: Currently this component is by default tied to the template by the templateId props.
//  This is making it a bit less flexible. We should consider making it more generic by passing
//  required data via props so that it can more easily be used in other contexts.
const DimensionsView = ({
  mode,
  classContainer,
  templateId,
  filters,
  stagingFilter,
  setStagingFilter,
  gridRef,
  defaultUnassignNodesCallback,
  onClearFilters,
  assignLabels
}: Props) => {
  const [ dimensionDetails, setDimensionDetails ] = useState<number | null>(null);
  const { state: { customSettings } } = useFinancialTable();
  let dimensions = useAppSelector(state => state.breakdowns.dimensions);
  if (customSettings.panelSettings.onlyDimensionIds.length > 0) {
    dimensions = dimensions.filter(
      dimension => customSettings.panelSettings.onlyDimensionIds.includes(dimension.id));
  }

  const dispatch = useAppDispatch();

  const [ filerSearch, setFilterSearch ] = useSearchParams();

  useEffect(() => {
    if (filerSearch.get('filter') == null) return;
    const filter = Number.parseInt(filerSearch.get('filter') as string);
    if (isNaN(filter) || filter == null) return;

    setDimensionDetails(filter);
  }, [ filerSearch, setFilterSearch ]);

  const _onClearFilters = (event: MouseEvent, dimensionId?: number) => {
    if (onClearFilters) {
      return onClearFilters(event, dimensionId);
    }
    event.stopPropagation();
    if (!templateId) return;

    if (dimensionId == null) {
      dispatch(clearFilters(templateId));
    } else {
      dispatch(removeDimensionFromFilters({ templateId, dimensionId: dimensionId }));
    }
  };

  return (
    <>
      <div className={ `${ styles.container } ${ classContainer }` }>
        {
          dimensionDetails ? (
            <>
              <DimensionDetails
                templateId={ templateId }
                outerFilters={ filters }
                stagingFilter={ stagingFilter }
                dimensionId={ dimensionDetails }
                setDimensionDetails={ setDimensionDetails }
                setStagingFilter={ setStagingFilter }
                gridRef={ gridRef }
                defaultUnassignNodesCallback={ defaultUnassignNodesCallback }
                mode={ mode }
                assignLabels={ assignLabels }
              />
            </>
          ) : (
            <DynamicScrollableContainer>
              <DimensionsListView
                gridRef={ gridRef }
                outerFilters={ filters }
                onClearFilters={ _onClearFilters }
                stagingFilter={ stagingFilter }
                templateId={ templateId }
                setDimensionDetails={ setDimensionDetails }
                setStagingFilter={ setStagingFilter }
                dimensions={ dimensions }
                mode={ mode }
                assignLabels={ assignLabels }
              />
            </DynamicScrollableContainer>
          )
        }
      </div>
    </>
  );
};

export default DimensionsView;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ChatOptions.module.scss';
import BurgerDropdown, { BurgerOption } from 'components/elements/burgerDropdown/BurgerDropdown';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { chatSlice } from 'store/chat.slice';
import organizationsService from 'services/organizations.service';

const CharOptions = () => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('collaboration');
  const hasConsent = useAppSelector(state => state.chat.isConsentGiven);

  const options = useMemo((): BurgerOption[] => {
    const _options: BurgerOption[] = [
      {
        title: t('chat.utils.clear-history'),
        onClick: () => dispatch(chatSlice.actions.clearHistory())
      },

    ];
    if (hasConsent) {
      _options.push({
        title: t('chat.utils.revokeConsent'),
        onClick: () => {
          organizationsService.chatbotConsent(false);
          dispatch(chatSlice.actions.setConsent(false));
        }
      });
    }
    return _options;
  }, [ hasConsent ]);

  return <div className={ styles.optionsContainer }>
    <div className={ styles.utils }>
      <BurgerDropdown
        options={ options }
        placement='bottom'
      />
    </div>
  </div>;
};

export default CharOptions;

import { appSlice } from './app.slice';
import { chatReducer } from './chat.slice';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import undoable from 'redux-undo';

import { authenticationReducer } from 'store/auth.slice';
import { appReducer } from 'store/app.slice';
import { accountMappingReducer } from './accountMapping.slice';
import { financialsReducer } from './financials.slice';
import { templatesReducer } from './templates.slice';
import { templateReducer } from './template.slice';
import { revenueRecognitionReducer } from './revenueRecognition.slice';
import { eventsReducer } from './events.slice';
import { breakdownsReducer } from './breakdowns.slice';
import { dashboardReducer } from './dashboard.slice';
import { budgetReducer } from './budget.slice';
import { topBarReducer } from './topBar.slice';
import { reportsApi } from './api/reports.api';
import { Config, createStateSyncMiddleware } from 'redux-state-sync';
import { formulaReducer } from 'store/formula.slice';
import { getActiveOrganizationIdCookie } from 'utils/auth.utils';
import { inputsApi } from './api/inputs.api';
import { inputsReducer } from './inputs.slice';
import { templatesApi } from './api/templates.api';
import { labelingReducer } from './labeling.slice';
import { planningApi } from './api/planning.api';

const disableDifferentOrganizationSync = () => {
  const organization = store.getState().auth.organization;
  const savedOrganization = getActiveOrganizationIdCookie();
  return organization && organization.id !== +savedOrganization;
};

const differentOrganizationWarn = (action: Action) => {
  const isSameAction = action.type === appSlice.actions.setOrganizationChangeWarning.type;
  if (!isSameAction) {
    store.dispatch(appSlice.actions.setOrganizationChangeWarning(true));
  }
};

const stateSyncConfig: Config = {
  predicate: (action: Action) => {
    if (disableDifferentOrganizationSync()) {
      differentOrganizationWarn(action);
      return false;
    }

    const sliceWhitelist = [
      'budget',
      'breakdowns',
      'templates'
    ];
    return sliceWhitelist.some(slice => action.type.startsWith(slice));
  },
};

const undoableTemplateReducer = undoable(templateReducer, {
  debug: false,
});

export const store = configureStore({
  reducer: {
    auth: authenticationReducer,
    app: appReducer,
    accountsMapping: accountMappingReducer,
    financials: financialsReducer,
    templates: templatesReducer,
    template: undoableTemplateReducer,
    revenueRecognition: revenueRecognitionReducer,
    events: eventsReducer,
    breakdowns: breakdownsReducer,
    dashboard: dashboardReducer,
    budget: budgetReducer,
    topBar: topBarReducer,
    labeling: labelingReducer,
    chat: chatReducer,
    formula: formulaReducer,
    inputs: inputsReducer,
    [ reportsApi.reducerPath ]: reportsApi.reducer,
    [ inputsApi.reducerPath ]: inputsApi.reducer,
    [ templatesApi.reducerPath ]: templatesApi.reducer,
    [ planningApi.reducerPath ]: planningApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      createStateSyncMiddleware(stateSyncConfig),
      reportsApi.middleware,
      templatesApi.middleware,
      inputsApi.middleware,
      planningApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;

import { ActiveCardType, DynamicColumnType, FinancialRow } from '../types/financials.types';
import {
  Dimension,
  DimensionItem,
  EXTERNAL_BREAKDOWN_TYPES,
  FilterList,
} from 'types/filterTable.types';
import {
  isBreakdownNode,
  isDimensionItemNode,
  isFinancialsNode,
  isGroupTypeNode,
  isReportNode,
  isUnassignedNode,
  ReportDataValues,
  ReportNode,
  ReportType,
  TemplateNode,
  TemplateResponse,
  UserReport,
  UUID,
} from 'types/templates.types';
import { Period, RowType } from 'types/financials.types';
import { IRowNode, RowHeightParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { isTransactionNode, Transaction } from 'types/statutory.types';
import i18n from '../locales/i18n';
import { getDisplayName } from './common.utils';
import { TransactionLineRequestParams, } from 'services/statutory.service';
import { isFinancialType } from './template.utils';
import { capitalize } from 'lodash';
import { t } from 'i18next';
import {
  UNASSIGNED_ROW_NAME,
} from 'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import { DATE_FORMATS } from './date.utils';
import { compute } from 'compute-scroll-into-view';
import { PanelType } from 'types/app.types';
import { SortingType } from './sorting.constants';

export const AG_ROOT_NODE_ID = 'ROOT_NODE_ID';
const DIFFERENT_NODE_CHILDREN_INDEX = 1;

export const AUTO_COLUMN_WIDTH_LS_KEY = (templateId: number) => `autoColumnWidth-${ templateId }`; 

// These panels don't require any table to be displayed
export const LEFT_PANEL_SAFE_TABS: PanelType[] = [ 'layout' ];

const dynamicColumnKeys = Object.values(DynamicColumnType);

export const getTransactionName = (memo: string, date: string) => {
  if (!memo) {
    // eslint-disable-next-line max-len
    return `${ i18n.t('transaction-line.name', { ns: 'financials' }) } \u2022 ${ dayjs(date).format(DATE_FORMATS[ 0 ]) }`;
  }
  return memo.split(',')[ 0 ];
};

export const sumNodeData = (data: ReportDataValues | number) => {
  return Object.values(data).reduce((acc: number, value) => {
    if (typeof value === 'number') {
      return acc + value;
    }
    return acc + sumNodeData(value);
  }, 0);
};

export const createLoadMoreRow = (parentNode: IRowNode, startRow) => {
  return {
    id: `${ parentNode.id }_load_more`,
    filePath: [ ...parentNode.data.filePath, 'load_more' ],
    rowData: {
      loading: false,
      startRow,
    },
    type: RowType.LOAD_MORE,
    localRow: true,
    hasData: false,
    hasTransactions: false,
    negativeMultiplier: false,
    isFailed: false,
    actual: {},
    plan: {},
    uuid: null,
    asyncStatus: null,
  };
};

export const createTransactions = (transactions: Transaction[], parentNode: IRowNode) => {
  return transactions.map((transaction) => ({
    id: [ ...parentNode.data.filePath, transaction.id ].join('_'),
    filePath: [ ...parentNode.data.filePath, transaction.id ],
    rowData: {
      ...transaction,
      name: getTransactionName(transaction.memo, transaction.transactionDate),
      date: transaction.transactionDate,
      description: transaction.memo,
      isLeaf: true,
    },
    type: RowType.TRANSACTION,
    localRow: true,
    hasData: !isDataEmpty(transaction.data.actual) && !isDataEmpty(transaction.data.plan),
    hasTransactions: false,
    negativeMultiplier: false,
    isFailed: false,
    actual: {
      ...transaction.data.actual,
      total: sumNodeData(transaction.data.actual),
    },
    plan: {
      ...transaction.data.plan,
      total: sumNodeData(transaction.data.plan),
    },
    uuid: null,
    asyncStatus: null,
  }));
};

export const groupTransactionsByCounterparty = (transactions: Transaction[]) => {
  const groupedTransactions = transactions?.sort((a, b) => {
    if (a.counterparty?.name == null) {
      return 1;
    }
    if (b.counterparty?.name == null) {
      return -1;
    }
    return a.counterparty?.name.localeCompare(b.counterparty?.name);
  })
    .reduce((group, transaction) => {
      const counterparty = transaction.counterparty;
      let counterpartyName = counterparty?.name;
      if (!counterpartyName) {
        counterpartyName = i18n.t('cost-labeler.unassigned', { ns: 'financials' });
      }
      if (!group[ counterpartyName ]) {
        group[ counterpartyName ] = [];
      }
      group[ counterpartyName ].push(transaction);

      return group;
    }, {});

  return groupedTransactions || {};
};

export const roundTransactionAmount = (amount: number) => {
  return Math.round(amount);
};

export const sumTransactions = (transactions: Transaction[]) => {
  return roundTransactionAmount(transactions.reduce((acc, transaction) => {
    return acc + transaction.amount;
  }, 0));
};

export const removeTimezone = (dateWithTimeZone: string) => {
  return dateWithTimeZone?.split('T')[ 0 ];
};

export const formatDateWithTimezone = (dateWithTimeZone: string) => {
  const dateWithoutTimeZone = removeTimezone(dateWithTimeZone);
  return dateWithoutTimeZone?.split('-').reverse().join('.');
};

export const isTransactionRow = (row: IRowNode) => row?.data?.type === RowType.TRANSACTION ||
  row?.data?.transaction != null;

export const isProductProxy = (row: IRowNode | undefined | null) => {
  return row?.data?.type === RowType.PRODUCT_PROXY;
};

export const isTransactionLevelSorting = (row: IRowNode) => {
  return isTransactionRow(row.childrenAfterSort[ 0 ]) || isDimensionItemNode(row.data) ||
    isProductProxy(row.childrenAfterSort[ 0 ]);
};

export const isLazyLoadingRow = (row: IRowNode) => {
  return row.data?.asyncStatus != null && row.data.asyncStatus !== 'loaded';
};

export const isLoadMoreRow = (row: IRowNode) => row.data?.type === RowType.LOAD_MORE;

const getGreatParent = (row: IRowNode): IRowNode => {
  let greatParent = row;
  while (greatParent?.parent && greatParent?.parent?.id !== AG_ROOT_NODE_ID) {
    greatParent = greatParent?.parent;
  }
  if (isGroupTypeNode(greatParent.data)) {
    return greatParent.allLeafChildren[ DIFFERENT_NODE_CHILDREN_INDEX ];
  }
  return greatParent;
};

export const canBeAssignedDimension = (row: IRowNode<ReportNode> | IRowNode<Transaction>) => {
  // There are different rows that can end up here:
  // 1. Financial rows (ReportNode)
  // 2. Transaction rows from detail view

  if (isReportNode(row)) {
    const greatParent = getGreatParent(row);
    const isFlatTable = !row.allLeafChildren;
    const editable = isDimensionItemNode(greatParent.data) ||
      isUnassignedNode(greatParent.data) ||
      isBreakdownNode(greatParent.data) ||
      isFinancialsNode(greatParent.data) ||
      isFlatTable;

    return editable && (
      isTransactionRow(row) ||
      (isLastBreakdown(row) && !isFinancialType(row.data)) ||
      hasBreakdown(row) ||
      isDimensionItemNode(row.data) ||
      !row.data
    );
  } else if (isTransactionNode(row)) {
    return true;
  }

};

export const canOpenOverview = (row: IRowNode, column: string) => {
  return row.data?.type === RowType.NEW_BUDGET_ITEM &&
    row.data[ ReportType.PLAN ][ column.split('__').at(-1) ];
};

export const canDimensionItemBeChanged = (row: IRowNode, dimensionItem: DimensionItem) => {
  if (dimensionItem.relation === 'COUNTERPARTY') {
    const rowDimensionItems = row.data?.rowData?.dimensionItems;
    if (!rowDimensionItems) return true;

    if (row?.data?.rowData?.counterparty?.isDefaultCcSupplier) return true;

    const dimensionItemAssociation = rowDimensionItems.find(
      it => it.dimension === dimensionItem.dimensionId
    );

    if (dimensionItemAssociation) {
      return dimensionItemAssociation.source === 'USER';
    }
  }
  return true;
};

const isLastBreakdown = (row: IRowNode): boolean => {
  return !row.allLeafChildren.slice(1).some(n => n.data?.type === RowType.DIMENSION_ITEM);
};

const hasBreakdown = (row: IRowNode): boolean => {
  const firstChild = row.allLeafChildren.at(1);
  return firstChild && firstChild.data?.type === RowType.BREAKDOWN &&
    row.parent?.data?.type === RowType.BREAKDOWN;
};

export const separateThousands = (value: number | string, separator = ' '): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const transactionLineTooltipContent = (description: string) => {
  if (!description) {
    return i18n.t('transaction-line.no-data', { ns: 'financials' });
  }
  return capitalize(description);
};

export const getFinancialItemTooltipContent = (node: IRowNode) => {
  return getDisplayName(node.data.rowData.tooltip);
};

export const gridSortOptionsValues = (isTransactionSort: boolean) => ([
  {
    label: t('type.amount', { ns: 'sorting' }),
    value: SortingType.AMOUNT,
  },
  {
    label: t('type.alphabetical', { ns: 'sorting' }),
    value: SortingType.ALPHABETICAL,
  },
  ...(isTransactionSort ? [ {
    label: t('type.date', { ns: 'sorting' }),
    value: SortingType.DATE,
  } ] : [])
]);

export const getHtmlRows = (rowId: string): NodeList => {
  return document.querySelectorAll(`[row-id="${ rowId }"]`);
};

const getNodesJoinedIds = (nodes: IRowNode[]): string => {
  return nodes.map(node => node.data.rowData.id).join(',');
};

const getTransactionsIds = (nodes: IRowNode[]): number[] => {
  const transactions = [];
  nodes.forEach(node => {
    if (isProductProxy(node)) {
      transactions.push(...node.allLeafChildren);
    } else {
      transactions.push(node);
    }
  });

  return transactions.map(node => node.data.rowData.id);
};

/* eslint-disable camelcase */
export const getTransactionQuery = (
  nodesForQuery: IRowNode<FinancialRow>[],
  templateId: number,
  period?: Period
): TransactionLineRequestParams[] => {
  const params: TransactionLineRequestParams = {
    dimension_item_ids: [],
    user_template_id: templateId,
    negative_multiplier: false,
    unassigned_dimension_ids: [],
    transaction_lines: [],
    breakdown_ids: [],
    formulaIds: [],
  };
  let breakdownItem = nodesForQuery[ 0 ];
  let isInitialNode = true;

  // Get all children nodes and if these are dimension items - create an OR group
  const childrenDimensionItemGroup = new Set<number>();
  const unassignedDimensionNodes = new Set<number>();

  const startDate = dayjs.unix(period.startDate).utcOffset(0, true);
  const endDate = dayjs.unix(period.endDate).utcOffset(0, true);
  const planStartDate = dayjs.unix(period.startDatePlan).utcOffset(0, true);
  const planEndDate = dayjs.unix(period.endDatePlan).utcOffset(0, true);

  const filteredNodes = nodesForQuery.filter(
    n => {
      const actualData = n.data.actual ?
        Object.keys(n.data.actual).map(k => {
          return dayjs(k);
        }).filter(d => d.isBetween(startDate, endDate, 'day', '[]')).some(d => d)
        : null;

      const planData = n.data.plan ?
        Object.keys(n.data.plan).map(k => {
          return dayjs(k);
        }).filter(d => d.isBetween(planStartDate, planEndDate, 'day', '[]')).some(d => d)
        : null;

      return actualData || planData;
    });

  if (filteredNodes.length === 0) {
    return [];
  }

  for (const node of filteredNodes) {
    for (const child of node.allLeafChildren) {
      // TODO: Add check for TRANSACTIONS IN PERIOD only to avoid unnecessary checks and queries.
      if (child.id !== node.id && child.data.hasTransactions) {
        if (child.data?.type === RowType.DIMENSION_ITEM && !child.data?.localRow) {
          const id = child.data.rowData.id;
          if (id !== 'Unassigned') {
            childrenDimensionItemGroup.add(child.data.rowData.id);
          } else {
            unassignedDimensionNodes.add(child.data.rowData.dimensionId);
          }
        }
      }
    }
  }

  while (breakdownItem?.level >= 0) {

    if (breakdownItem?.id === AG_ROOT_NODE_ID) {
      break;
    }
    const isTransactionProxy = isProductProxy(breakdownItem);
    const type = breakdownItem?.data?.type;

    if (type === RowType.TRANSACTION || (isTransactionProxy && !params.transaction_lines.length)) {
      const transactionNodes = filteredNodes.filter(
        node => node.data?.type === RowType.TRANSACTION || !node.data
      );
      params.transaction_lines = isInitialNode ?
        getTransactionsIds(transactionNodes) : [ breakdownItem.data?.rowData?.id ];
    } else if (type === RowType.FORMULA) {
      params.formulaIds = [ breakdownItem.data.rowData.id ];

    } else if (type === RowType.FINANCIALS && !params.template_row_id) {
      const financialNodes = filteredNodes.filter(node => node.data?.type === RowType.FINANCIALS);
      params.template_row_id = isInitialNode ?
        getNodesJoinedIds(financialNodes) : breakdownItem.data.rowData.id;
    } else if (type === RowType.DIMENSION_ITEM) {
      if (breakdownItem.data.rowData.id > 0) {
        const dimensionItemNodes = filteredNodes.filter(
          node => node.data?.type === RowType.DIMENSION_ITEM
        );
        const id = isInitialNode ?
          getNodesJoinedIds(dimensionItemNodes) : breakdownItem.data.rowData.id;
        params.dimension_item_ids.unshift(id);
      } else {
        const unassignedDimensionItemNodes = filteredNodes.filter(
          node => node.data?.type === RowType.DIMENSION_ITEM &&
            node.data.rowData.id === UNASSIGNED_ROW_NAME
        );
        const ids = isInitialNode ? unassignedDimensionItemNodes.map(
          node => node.data.rowData.dimensionId
        ) : [ breakdownItem.data.rowData.dimensionId ];

        params.unassigned_dimension_ids = params.unassigned_dimension_ids.concat(ids);
      }
    } else if (type === RowType.BREAKDOWN) {
      const breakdowns = filteredNodes.filter(node => node.data?.type === RowType.BREAKDOWN);
      params.breakdown_ids = breakdowns.map(node => node.data.rowData.id);
    } else if (type === RowType.UNASSIGNED) {
      params.unassigned_dimension_ids = params.unassigned_dimension_ids.concat(
        breakdownItem.data.rowData.id);
    }
    breakdownItem = breakdownItem.parent;
    isInitialNode = false;
  }

  // Negative multiplier can only be set on root template nodes.
  params.negative_multiplier = breakdownItem?.data?.negativeMultiplier;

  const paramsList = [ JSON.parse(JSON.stringify(params)) ];

  const childrenGroupJoined = [ ...childrenDimensionItemGroup ].join(',');
  if (childrenGroupJoined) {
    paramsList[ 0 ].dimension_item_ids.push(childrenGroupJoined);
  }
  if (unassignedDimensionNodes.size) {
    paramsList.push(JSON.parse(JSON.stringify(params)));
    paramsList[ 1 ].unassigned_dimension_ids = [ ...unassignedDimensionNodes ];
  }

  if (period) {
    return paramsList.map(param => ({
      ...param,
      start_date: period.startDate ?
        dayjs.unix(period.startDate).utcOffset(0, true).format() : undefined,
      end_date: period.endDate ?
        dayjs.unix(period.endDate).utcOffset(0, true).format() : undefined,
      plan_start_date: period.startDatePlan ?
        dayjs.unix(period.startDatePlan).utcOffset(0, true).format() : undefined,
      plan_end_date: period.endDatePlan ?
        dayjs.unix(period.endDatePlan).utcOffset(0, true).format() : undefined,
      cadence: period.cadence,
    }));
  }
  return paramsList;

};

export const getHeightForRowType = (params: RowHeightParams): number => {
  if (params.node.data?.type === RowType.HALF_SPACER) {
    return 15;
  }
  return 33;
};

export const getTransactionNodesFromProxies = (proxyNodes: IRowNode[]): IRowNode[] => {
  return proxyNodes.reduce((prev, curr) => {
    return prev.concat(curr.allLeafChildren);
  }, []);
};

export const isStickyHeaderActive = (viewId: number): boolean => {
  return viewId !== null;
};

interface IGetStartAndEndDate {
  startDate: number;
  endDate: number;
  startDatePlan: number;
  endDatePlan: number;
}

export const getStartAndEndDate = (
  {
    startDate,
    endDate,
    startDatePlan,
    endDatePlan
  }: IGetStartAndEndDate) => {
  if (!startDatePlan || !endDatePlan || !startDate || !endDate) {
    return {
      start: startDate || startDatePlan,
      end: endDate || endDatePlan
    };
  }

  return {
    start: dayjs.unix(startDate).isBefore(dayjs.unix(startDatePlan)) ? startDate : startDatePlan,
    end: dayjs.unix(endDate).isAfter(dayjs.unix(endDatePlan)) ? endDate : endDatePlan
  };
};

const isDynamicColumnKey = (key: string): boolean => {
  return dynamicColumnKeys.some(dynamicKey => key.includes(dynamicKey));
};

export const isDataEmpty = (data: ReportDataValues | number): boolean => {
  return data === null || !Object.keys(data).some(key => {
    return (isNaN(+key) && (dayjs(key).isValid()) || isDynamicColumnKey(key));
  });
};

const isNodeDataEmpty = (node: ReportNode): boolean => {
  if (!node.data) {
    return true;
  }
  return isDataEmpty(node.data[ ReportType.ACTUAL ]) && isDataEmpty(node.data[ ReportType.PLAN ]);
};

export const isReportEmpty = (nodes: ReportNode[]): boolean => {
  return !nodes || nodes.every(node => isNodeDataEmpty(node));
};

export const getChartBreakdown = (
  report: UserReport | TemplateResponse,
  nodeUuid: UUID
): TemplateNode & { type: RowType.BREAKDOWN | RowType.DIMENSION_ITEM } => {
  const node = report.nodes.find(root => root.uuid === nodeUuid);
  if (node?.children?.length === 0) {
    return null;
  }
  const breakdownNode = report.nodes.find(n => n.id === node?.children[ 0 ]);
  if (breakdownNode?.type === RowType.BREAKDOWN || breakdownNode?.type === RowType.DIMENSION_ITEM) {
    return breakdownNode;
  }
  return null;
};

export const getDimensionDisplayName = (dimension: Dimension | DimensionItem): string => {
  if (!dimension) {
    return '';
  }
  return dimension?.customName ?
    getDisplayName(dimension?.customName) : getDisplayName(dimension?.name);
};

export const getUnassignedNodeName = (dimension: Dimension): string => {
  return `${ t('common:unassigned') } (${ getDimensionDisplayName(dimension) })`;
};

export const getDimensionViewName = (dimension: Dimension | DimensionItem) => {
  if (!dimension) {
    return '';
  }
  if ('items' in dimension) {
    const suffix = EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type) ?
      ` [${ dimension.type?.at(0).toUpperCase() }]` : '';
    return `${ getDimensionDisplayName(dimension) }${ suffix }`;
  }
  return getDimensionDisplayName(dimension);
};

export const defaultFilter: FilterList = [];

const areFiltersEmpty = (filters: FilterList): boolean => {
  return !filters.some((f) => f?.excludedItems?.length !== 0 || f.excludeUnassigned !== false);
};
export const areFiltersActive = (filters: FilterList): boolean => {
  return filters && !areFiltersEmpty(filters);
};

export function scrollViewItemIntoView(type: ActiveCardType, templateId?: number | null) {
  const viewItemId = `item__${ type }__${ templateId }`;
  const element = document.querySelector(`[data-view-item-id="${ viewItemId }"]`);
  const parent = document.querySelector('#scrollContainer');

  if (parent && element) {
    const actions = compute(element, { boundary: parent, block: 'start' });
    actions.forEach(({ top }) => {
      parent?.scroll({ top, behavior: 'smooth' });
    });
  }
}

export function isAnyParentOnList(node: IRowNode, list: PropertyKey[]): boolean {
  if (!node.parent) {
    return false;
  }

  if (list.includes(node.parent.id)) {
    return true;
  }

  return isAnyParentOnList(node.parent, list);
}

export function shouldMarkAsLazyLabeled(node: IRowNode, labeledNodes: string[]): boolean {
  if (!labeledNodes?.length || !node) {
    return false;
  }

  return labeledNodes?.includes(node.id) ||
  isAnyParentOnList(node, labeledNodes) ||
  (
    isProductProxy(node) &&
    node.childrenAfterFilter.every((child) => labeledNodes.includes(child.id))
  );
}

// t('common:frequency.every_count_months')
// t('common:frequency.every_count_weeks')
// t('common:frequency.every_count_quarters')
export const frequencyUnitTranslationMap = {
  'month': 'common:frequency.every_count_months',
  'week': 'common:frequency.every_count_weeks',
  'quarter': 'common:frequency.every_count_quarters',
};

export const frequencyPresets = [
  {
    name: 'one_time',
    label: t('common:frequency.one_time'),
    unit: 'one_time',
    amount: 1
  },
  {
    name: 'weekly',
    label: t('common:frequency.weekly'),
    unit: 'week',
    amount: 1
  },
  {
    name: 'monthly',
    label: t('common:frequency.monthly'),
    unit: 'month',
    amount: 1
  },
  {
    name: 'bi_monthly',
    label: t('common:frequency.bi_monthly'),
    unit: 'week',
    amount: 2 
  },
  {
    name: 'every_2_months',
    label: t('common:frequency.every_2_months'),
    unit: 'month',
    amount: 2
  },
  {
    name: 'every_3_months',
    label: t('common:frequency.every_3_months'),
    unit: 'month',
    amount: 3
  },
  {
    name: 'every_4_months',
    label: t('common:frequency.every_4_months'),
    unit: 'month',
    amount: 4
  },
  {
    name: 'every_6_months',
    label: t('common:frequency.every_6_months'),
    unit: 'month',
    amount: 6
  },
  {
    name: 'every_12_months',
    label: t('common:frequency.every_12_months'),
    unit: 'month',
    amount: 12
  },
  {
    name: 'quarterly',
    label: t('common:frequency.quarterly'),
    unit: 'quarter',
    amount: 1
  },
] as const;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getIntegrationByOrganization } from 'services/integration.service';
import { AppDispatch, RootState } from 'store/store';
import { ActionNotification, AppStore, CustomViewSection } from 'types/app.types';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';
import { SyncState } from '../types/settings.types';

const initialState: AppStore = {
  actionNotifications: [],
  navigationList: [],
  anyModalOpened: false,
  navbarOpen: false,
  integrations: [],
  initialIntegrationsFetched: false,
  organizationChangeWarning: false,
  integrationsCode: '',
  accountsSynchronized: false,
  leftPanelData: {},
  leftPanel: null,
  rightPanel: null,
  websocketConnected: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addActionNotification: (state, action: PayloadAction<ActionNotification>) => {
      return {
        ...state,
        actionNotifications: [ ...state.actionNotifications, action.payload ]
      };
    },
    setActionNotifications: (state, action: PayloadAction<ActionNotification[]>) => {
      return {
        ...state,
        actionNotifications: action.payload
      };
    },
    setNavigationList: (state, action: PayloadAction<CustomViewSection[]>) => {
      return {
        ...state,
        navigationList: action.payload
      };
    },
    setAnyModalOpened: (sliceState, action: PayloadAction<boolean>) => {
      sliceState.anyModalOpened = action.payload;
    },
    setNavbarOpen: (sliceState, action: PayloadAction<boolean>) => {
      sliceState.navbarOpen = action.payload;
    },
    setIntegrations: (sliceState, action: PayloadAction<AppStore['integrations']>) => {
      sliceState.integrations = action.payload;
      sliceState.initialIntegrationsFetched = true;
    },
    setProcountorCode: (sliceState, action: PayloadAction<string>) => {
      sliceState.integrationsCode = action.payload;
    },
    setAccountsSynchronized: (sliceState) => {
      sliceState.accountsSynchronized = true;
    },
    setIntegrationInitialSyncState: (sliceState, action: PayloadAction<SyncState>) => {
      if (sliceState.integrations.length > 0) {
        sliceState.integrations[ 0 ].initialSyncState = action.payload;
      }
    },
    setIntegrationSyncInProgress: (sliceState, action: PayloadAction<boolean>) => {
      if (sliceState.integrations.length > 0) {
        sliceState.integrations[ 0 ].syncInProgress = action.payload;
      }
    },
    setOrganizationChangeWarning: (
      sliceState,
      action: PayloadAction<AppStore['organizationChangeWarning']>
    ) => {
      sliceState.organizationChangeWarning = action.payload;
    },
    setLeftPanelData: (sliceState, action: PayloadAction<Record<string, unknown>>) => {
      sliceState.leftPanelData = action.payload;
    },
    setLeftPanel: (sliceState, action: PayloadAction<string | null>) => {
      sliceState.leftPanel = action.payload;
    },
    setRightPanel: (sliceState, action: PayloadAction<string | null>) => {
      sliceState.rightPanel = action.payload;
    },
    setWebsocketConnected: (sliceState, action: PayloadAction<boolean>) => {
      sliceState.websocketConnected = action.payload;
    }
  }
});

export const loadNavigationList = (data: CustomViewSection[]) =>
  async (dispatch: AppDispatch) => {
    dispatch(appSlice.actions.setNavigationList(data));
  };

export const fetchAndSetIntegrations = (organizationId: number) =>
  async (dispatch: AppDispatch) => {
    getIntegrationByOrganization(organizationId)
      .then(({ data }) => {
        dispatch(appSlice.actions.setIntegrations(data));
      })
      .catch((e) => {
        dispatch(appSlice.actions.setIntegrations([]));
        if (e.response.data.detail) {
          notifyError(e.response.data.detail);
        } else {
          notifyUnexpectedError(e);
        }
      });
  };

export const selectApp = (state: RootState) => state.app;
export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;

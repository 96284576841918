import React, { useCallback, useEffect, useMemo, useState } from 'react';

import RangePickerModal from 'components/elements/rangePicker/RangePickerModal';
import styles from './TopBar.module.scss';
import { DetailedViewType, Period } from 'types/financials.types';
import ChartsButtonActive from 'components/charts/chartsButton/ChartsButton';
import { setPeriod as setLSPeriod } from 'store/financials.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import DynamicColumnDropdown from '../dynamicColumns/dynamicColumnDropdown/DynamicColumnDropdown';
import CadenceSwitcher from '../cadenceSwitcher/CadenceSwitcher';
import { Cadence } from 'types/form.types';
import BudgetItemTypeDropdown from '../../budget/budgetItemTypeDropdown/BudgetItemTypeDropdown';
import dayjs from 'dayjs';
import { getPickerEndDate, getPickerStartDate } from 'utils/date.utils';
import ExternalClose from 'components/financials/topBar/buttons/ExternalClose';

import { ReactComponent as LockOpen } from 'assets/icons/lock-open.svg';
import { ReactComponent as LockClose } from 'assets/icons/lock-closed.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

import { setReportLocked } from 'utils/period.utils';
import { Tooltip } from 'antd';
import { DASHBOARD_PERIOD_ID } from 'utils/dashboard.utils';
import useSelectDetailedView from 'components/financials/detailedView/hooks/useSelectDetailedView';
import SpinningLoader from '../../elements/spinning-loader/SpinningLoader';
import { useTranslation } from 'react-i18next';
import { ClearFiltersIcon } from '../clearFiltersIcon/ClearFiltersIcon';
import { isEqual } from 'lodash';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { useWarnOnUnsaved } from '../financialTable/hooks/useWarnOnUnsaved/useWarnOnUnsaved';

type Props = {
  header: string;
  description: string;
  setIsPeriodLocked?: React.Dispatch<React.SetStateAction<boolean>>;
  isPeriodLocked?: boolean;
  defaultPeriod: Period;
};

const TopBar = ({
  header,
  description,
  isPeriodLocked,
  setIsPeriodLocked,
  defaultPeriod,
}: Props) => {
  const { state: {
    templateId,
    onClose,
    customSettings: { detailedViewSettings }
  } } = useFinancialTable();
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const [ period, setPeriod ] = useState<Period>(defaultPeriod);
  const { planOpen, actualsOpen } = period;
  const pagePeriod = useAppSelector((state) => state.topBar.viewPeriod);
  const { viewId } = useAppSelector((state) => state.topBar);
  const tableLoaded = useAppSelector((state) => state.financials.tablesLoadingState[ templateId ]);
  const filters = useAppSelector((state) => state.financials.tables[ templateId ].filter);

  const hasAnyFiltersApplied = useMemo(() => {
    return filters.some((filter) => filter.excludedItems.length > 0 || filter.excludeUnassigned);
  }, [ filters ]);

  const { onSelect } = useSelectDetailedView({
    templateId,
    type: detailedViewSettings?.floatingPanelDisabled ?
      DetailedViewType.MODAL : DetailedViewType.FLOATING_PANEL
  });

  const isDetailedViewOpen = useAppSelector((state) => state.financials.detailedView.type !== null);

  const activeTable = useAppSelector((state) => state.financials.active?.templateId);
  const activeTableHasNewCells = useAppSelector((state) =>
    state.financials.tables[ activeTable ]?.hasNewCells);

  const { warnModal, continueWithCheck } =
    useWarnOnUnsaved<{ newPeriod: Period; isDefaultRange: boolean}>(
      {
        onConfirm: ({ newPeriod, isDefaultRange }) => {
          dispatchRangeChange(newPeriod, isDefaultRange);
          setPeriod(newPeriod);
        },
        isUnsaved: activeTableHasNewCells
      }
    );

  useEffect(() => {
    if (isPeriodLocked) {
      if (defaultPeriod.isManuallySet) {
        setPeriod(defaultPeriod);
      }
    } else {
      setPeriod(pagePeriod);
    }

  }, [ defaultPeriod, pagePeriod ]);

  const onRangeChange = (newPeriod: Period, isDefaultRange: boolean) => {
    continueWithCheck({ newPeriod, isDefaultRange });
  };

  const dispatchRangeChange = useCallback((newPeriod: Period, isDefaultRange: boolean) => {
    dispatch(setLSPeriod({
      templateId,
      period: newPeriod,
      isDefaultRange
    }));
    setIsPeriodLocked(true);
    setReportLocked(templateId, true);
  }, [ templateId ]);

  const onPeriodLockChange = useCallback(() => {
    setIsPeriodLocked(prev => !prev);
    setReportLocked(templateId, !isPeriodLocked);
  }, [ isPeriodLocked ]);

  const handleSetCadence = useCallback((cadence: Cadence) => {
    const newPeriod: Period = {
      ...period,
      startDate: period.startDate ?
        getPickerStartDate(dayjs.unix(period.startDate), cadence).unix() : undefined,
      endDate: period.endDate ?
        getPickerEndDate(dayjs.unix(period.endDate), cadence).unix() : undefined,
      startDatePlan: period.startDatePlan ?
        getPickerStartDate(dayjs.unix(period.startDatePlan), cadence).unix() : undefined,
      endDatePlan: period.endDatePlan ?
        getPickerEndDate(dayjs.unix(period.endDatePlan), cadence).unix() : undefined,
      cadence,
      isManuallySet: false,
    };

    continueWithCheck({ newPeriod, isDefaultRange: false });

  }, [ templateId, period, pagePeriod, continueWithCheck ]);

  const isDateChanged = useMemo(() => !isEqual(pagePeriod, period), [ pagePeriod, period ]);

  return (
    <div className={ `ag-top-bar ${ styles.top }` }>
      <div className={ styles.headerTitle }>
        <h2 className={ styles.title }>
          <span>{ header }</span>
          { !tableLoaded && <SpinningLoader size={ 15 } className={ styles.loader }/> }
          { hasAnyFiltersApplied && tableLoaded &&
            <ClearFiltersIcon templateId={ templateId } />
          }
        </h2>
        <span className={ styles.text }>{ description }</span>
      </div>
      <div className={ styles.rightSection }>
        <CadenceSwitcher
          value={ period.cadence }
          onChange={ handleSetCadence }
          className={ styles.cadenceSwitcher }
        />
        <div className={ styles.datepicker }>
          {
            viewId !== DASHBOARD_PERIOD_ID && isDateChanged && (
              <Tooltip title={
                isPeriodLocked ? t('top-bar.date-range.unlock') : t('top-bar.date-range.lock')
              }>
                <button onClick={ onPeriodLockChange } >
                  { isPeriodLocked ? <LockClose /> : <LockOpen /> }
                </button>
              </Tooltip>
            )
          }
          <RangePickerModal
            onSubmit={ onRangeChange }
            defaultPeriod={ period }
            cadence={ period.cadence }
            actualsIsOpen={ actualsOpen }
            planIsOpen={ planOpen }
            templateId={ templateId }
            disabled={ viewId !== DASHBOARD_PERIOD_ID ? isPeriodLocked : false }
          >
            {
              !isDateChanged ? (
                <GhostIconButton>
                  <Calendar className={ styles.calendarIcon } />
                </GhostIconButton>
              ) : null
            }
          </RangePickerModal>
        </div>
        <div className={ styles.buttonsContainer }>
          {
            !detailedViewSettings.disabled ? (
              <BudgetItemTypeDropdown onSelect={ onSelect } disabled={ isDetailedViewOpen } />
            ) : null
          }
          <ChartsButtonActive />
          <DynamicColumnDropdown />
        </div>

        { onClose ? <ExternalClose onClose={ onClose } /> : null }
      </div>

      { warnModal }
    </div>
  );
};

export default TopBar;

import React, { ReactNode } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import DatePickerCellEditor from './DatePickerCellEditor';
import { DimensionCellEditor } from './DimensionCellEditor';
import NumericCellEditor from './NumericCellEditor';
import TextCellEditor from './TextCellEditor';
import SelectCellEditor from './SelectCellEditor';

import styles from './Editors.module.scss';
import clsx from 'clsx';
import AccountCellEditor from './AccountCellEditor';
import { Account } from '../../../../../../types/statutory.types';
import { InputRow } from 'components/inputs/types/inputs.types';
import { getDisplayName } from '../../../../../../utils/common.utils';

import BudgetItemTypeEditor from './BudgetItemTypeEditor';
import InputGroupWithIcon from './InputGroupWithIcon';
import { createValidationRules } from './validation.utils';
import FrequencyCellEditor from './FrequencyCellEditor';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import Tooltip from 'components/elements/tooltip/Tooltip';

import { ReactComponent as RemoveIcon } from 'assets/icons/close.svg';
import FormMessage from 'components/elements/form/formMessage/FormMessage';
import { ErrorMessage } from '@hookform/error-message';

interface FormConfig {
  disabled?: boolean;
  message?: string;
  messageType?: 'warning' | 'error' | 'info';
}

interface Props {
  field: TableColDef;
  fieldName: string;
  inputs: InputRow[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  onChange?: (fieldName: string, value: unknown) => void;
  formConfig: Record<string, FormConfig>;
  remove: () => void;
  prefixIcon?: ReactNode;
  placeholder?: string;
  errors?: FieldErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues?: () => any;
  multipleSelected?: boolean;
  isMandatory?: boolean;
  onBlur?: () => void;
}

const FieldProxy = ({
  field,
  fieldName,
  inputs,
  control,
  onChange: externalOnChange,
  prefixIcon,
  remove,
  formConfig,
  placeholder,
  errors,
  getValues,
  multipleSelected,
  isMandatory,
  onBlur,
}: Props) => {
  const [ t ] = useTranslation();

  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);

  const getEditor = () => {
    switch (field?.cellEditor?.displayName) {
      case 'SelectCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <SelectCellEditor
                field={ fieldName }
                value={ value }
                onBlur={ onBlur }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                setValue={ (e) => {
                  onChange(e);
                  externalOnChange?.(fieldName, e);
                } }
                values={ field?.cellEditorParams?.values ?? [] }
              />
            </InputGroupWithIcon> }
        />;
      case 'FrequencyCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <FrequencyCellEditor
                field={ fieldName }
                value={ value }
                onBlur={ onBlur }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                formatValue={ (val: string) => {
                  if (!value) return '';
      
                  if (!isNaN(parseInt(val))) {
                    return t('contracts:frequency.every_count_months', { count: parseInt(val) });
                  }
              
                  return t(`contracts:frequency.${ val }`);
                } }
                setValue={ e => {
                  onChange(e);
                  externalOnChange?.(fieldName, e);
                } }
                values={ field?.cellEditorParams?.values.map(_ => _.id) ?? [] }
              />
            </InputGroupWithIcon>
          }
        />;
      case 'AccountCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <AccountCellEditor
                field={ fieldName }
                value={ value }
                errors={ errors }
                onBlur={ onBlur }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                setValue={ e => {
                  onChange(e);
                  externalOnChange?.(fieldName, e);
                } }
                formatValue={ (account: Account) => {
                  return `${ account.number } ${ getDisplayName(account.name) }`;
                } }
                values={ field?.cellEditorParams?.values ?? [] } />
            </InputGroupWithIcon>
          }
        />;
      case 'DatePickerCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <DatePickerCellEditor
                value={ value }
                disabled={ formConfig[ fieldName ]?.disabled }
                onBlur={ onBlur }
                setValue={ e => {
                  onChange(e);
                  externalOnChange?.(fieldName, e);
                } }
                errors={ errors }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
              />
            </InputGroupWithIcon>
          }
        />;
      case 'NumericCellEditor': {
        let defaultValuePlaceholder = field?.cellEditorParams?.placeholder;
        
        if (fieldName.startsWith('input')) {
          const [ , inputKey ] = fieldName.split('_');
          const fieldInput = inputs.find(input => input.id === +inputKey);
          if (fieldInput?.defaultValue != null) {
            defaultValuePlaceholder = fieldInput?.defaultValue;
          }
        }

        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <NumericCellEditor
              field={ fieldName }
              setValue={ e => {
                onChange(e);
                externalOnChange?.(fieldName, e);
              } }
              onBlur={ onBlur }
              value={ +value }
              errors={ errors }
              placeholder={ placeholder ?? defaultValuePlaceholder }
              disabled={ formConfig[ fieldName ]?.disabled }
              headerName=''
            />
          }
        />;
      }
      case 'BudgetItemTypeEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) => <>
            <BudgetItemTypeEditor value={ value } onBlur={ onBlur } onChange={
              (val) => {
                onChange(val);
                externalOnChange?.(fieldName, val);
              }
            } />
          </>
          }
        />;
      case 'DimensionCellEditor':
      case 'TextCellEditor':
      default:
        if (field.group === 'Dimensions') {
          const dimensionId = field.field.split('.').at(-1);
          const dimension = dimensionMap[ parseInt(dimensionId) ];

          return <Controller
            name={ fieldName }
            control={ control }
            rules={ createValidationRules(field, getValues, multipleSelected) }
            render={ ({ field: { value, onChange } }) =>
              <DimensionCellEditor
                props={ {
                  value: value ?? null,
                  field: fieldName,
                  errors: errors,
                  disabled: formConfig[ fieldName ]?.disabled,
                  ...field.cellEditorParams(),
                  placeholder: placeholder ?? field?.cellEditorParams?.placeholder
                } }
                onBlur={ onBlur }
                onChangeDimensionItem={ e => {
                  onChange(e);
                  externalOnChange?.(fieldName, e);
                } }
                canBeUnassigned={ !!dimension?.canBeUnassigned }
              />
            }
          />;
        }

        return <Controller
          name={ fieldName }
          control={ control }
          rules={ createValidationRules(field, getValues, multipleSelected) }
          render={ ({ field: { value, onChange } }) =>
            <TextCellEditor
              field={ fieldName }
              value={ value }
              onBlur={ onBlur }
              className={ clsx({ [ styles.formula ]: fieldName === 'amountFormula' }) }
              errors={ errors }
              placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
              disabled={ formConfig[ fieldName ]?.disabled }
              headerName={ field?.headerName } 
              setValue={ e =>{ 
                onChange(e);
                externalOnChange?.(fieldName, e);
              } }
            />
          }
        />;

    }
  };

  if (!field) {
    return null;
  }

  return <>
    <div
      aria-label={ `Field ${ field?.headerName }` }
      className={ styles.fieldRow }>
      <Label
        headerName={ field?.headerName }
        field={ fieldName }
        onRemove={ remove }
        canRemove={ !isMandatory }
      >
        { getEditor() }
      </Label>
      <FormMessage type={ formConfig[ fieldName ]?.messageType }>
        { formConfig[ fieldName ]?.message }
      </FormMessage>

      <FormMessage>
        <ErrorMessage errors={ errors } name={ fieldName } />
      </FormMessage>
    </div>
  </>;
};

export default FieldProxy;

interface LabelProps {
  children: ReactNode;
  headerName?: string;
  field: string;
  canRemove?: boolean;
  onRemove?: () => void;
}

const Label = ({ children, headerName, canRemove, onRemove }: LabelProps) => {
  const [ t ] = useTranslation('financials');

  return <label className={ styles.label }>
    <div className={ styles.leftGroup }>
      <span className={ styles.labelText }>
        { headerName }
        { !canRemove && <span className={ styles.mandatory }>*</span> }
      </span>
      {
        canRemove &&
        <Tooltip title={ t('inline-edit.removeField') }>
          <div aria-label='removeField' className={ styles.removeField } onClick={ onRemove }>
            <RemoveIcon />
          </div>
        </Tooltip>
      }
    </div>
    <div className={ styles.editor }>
      { children }
    </div>
  </label>;
};

import { defaultFields }
  from 'components/financials/panels/overviewPanel/overviewFormSections/useFieldDefinitions';
import { Section, useGetFormsQuery } from 'store/api/planning.api';

export interface FormStepSections {
  id: number;
  budgetItemType: number;
  steps: {
    sections: {
      name: string;
      fields: {
        name: string;
        isMandatory?: boolean;
        shownByDefault?: boolean;
      }[];
    }[];
  }[];
}

function getFieldName(field: Section['fields'][number]) {
  if (field.budgetItemFieldName) return snakeToCamel(field.budgetItemFieldName);

  if (field.input) return `input_${ field.input }`;

  if (field.dimension) return `dimension.${ field.dimension }`;

  return null;
}

const useBudgetItemForm = (budgetItemId: number) => {
  const { data } = useGetFormsQuery({ budgetItemId }, { skip: !budgetItemId });

  const firstFrom = data?.at(0);

  if (!firstFrom) return defaultFields;

  const form = {
    ...firstFrom,
    steps: firstFrom.steps.map(step => ({
      ...step,
      sections: step.sections.map(section => ({
        ...section,
        fields: section.fields.map(field => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { input, dimension, budgetItemFieldName, ...rest } = field;

          return {
            ...rest,
            name: getFieldName(field),
          };
        })
      }))
    }))
  };

  return form;
};

export default useBudgetItemForm;

function snakeToCamel(str: string) {
  return str
    .toLowerCase()
    .replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
}

import { useCallback, useEffect, useMemo } from 'react';
import { UUID } from 'types/templates.types';
import { INPUT_NONE_GROUP_ID, isInputValid } from '../../utils/inputs.utils';
import { getUUID } from 'utils/templates.utils';
import { useImmer } from 'use-immer';
import { InputSource } from '../../types/inputs.types';
import { InputRowWithValues } from 'components/inputs/types/inputs.types';
import { Period } from 'types/financials.types';
import dayjs from 'dayjs';
import { Cadence } from 'types/form.types';
import useTrackChanges from 'hooks/useTrackChanges';
import useAutoSaveEffect from 'hooks/useAutoSaveEffect';
import {
  useBulkUpdateMutation,
  useGetCategoriesQuery,
  useGetInputsWithValuesQuery
} from 'store/api/inputs.api';

type InternalInputRow = InputRowWithValues & { internalId?: UUID };

interface Props {
  period: Period;
}

const useInputs = ({ period }: Props) => {
  const [ inputs, setInputs ] = useImmer<InternalInputRow[]>([]);
  const {
    changes,
    clear: clearChanges,
    trackChanges
  } = useTrackChanges<InternalInputRow>({
    items: inputs,
    isValid: isInputValid,
  });

  const { data } = useGetInputsWithValuesQuery({ period });
  const { data: categoriesResponse, isFetching: isFetchingCategories } = useGetCategoriesQuery({});
  const categories = categoriesResponse?.results || [];

  const [ bulkUpdate ] = useBulkUpdateMutation();

  useEffect(() => {
    if (categories && data) {
      setInputs(data);
    }
  }, [ data, categories ]);

  const updateInput = (newInput: InternalInputRow) => {
    setInputs(draft => {
      const inputIndex = draft.findIndex(input => {
        if (newInput.internalId) {
          return input.internalId === newInput.internalId;
        }
        return input.id === newInput.id;
      });
      if (inputIndex !== -1) {
        draft[ inputIndex ] = newInput;
      } else {
        draft.push(newInput);
      }
    });
  };

  const update = (newInputs: InternalInputRow[] | InternalInputRow, track = true) => {
    if (newInputs instanceof Array) {
      newInputs.forEach(newInput => {
        updateInput(newInput);
      });
    } else {
      updateInput(newInputs);
    }
    if (track) {
      trackChanges(newInputs instanceof Array ? newInputs : [ newInputs ]);
      clearAutoSave();
    }
  };

  const clearInternalRowsOnUpdate = (created: InternalInputRow[]) => {
    setInputs(draft => {
      draft.forEach((input, index) => {
        if (
          input.internalId &&
          created.some(createdInput => createdInput.internalId === input.internalId)
        ) {
          draft.splice(index, 1);
        }
      });
    });
  };

  const onUpdateInputs = useCallback(async (items: InternalInputRow[]) => {
    const res = await bulkUpdate({
      data: {
        startDate: dayjs.unix(period?.startDate).utcOffset(0, true).format('YYYY-MM-DD'),
        endDate: dayjs.unix(period?.endDate).utcOffset(0, true).format('YYYY-MM-DD'),
        planStartDate: dayjs.unix(period?.startDatePlan).utcOffset(0, true).format('YYYY-MM-DD'),
        planEndDate: dayjs.unix(period?.endDatePlan).utcOffset(0, true).format('YYYY-MM-DD'),
        cadence: Cadence.month,
        items
      }
    });
    if (res[ 'error' ]) {
      throw new Error(res[ 'error' ]);
    }
    clearInternalRowsOnUpdate(items);
    clearChanges();
  }, [ period ]);

  const { isAutoSaved, clearAutoSave } = useAutoSaveEffect<InternalInputRow>({
    items: changes,
    onSave: onUpdateInputs,
    timeout: 1_000
  });

  const onAddInput = () => {
    setInputs(draft => {
      draft.unshift({
        internalId: getUUID(),
        id: undefined,
        name: '',
        alias: '',
        canOverride: false,
        isVatPercentage: false,
        category: INPUT_NONE_GROUP_ID,
        actualValues: [],
        planValues: [],
        dataType: null,
        cadence: Cadence.month,
        source: InputSource.USER,
        defaultValue: null,
        dimensionItemIds: [],
        dimensionItems: [],
      });
    });

  };

  const duplicate = (input: InternalInputRow) => {
    const newInput = {
      ...input,
      source: InputSource.USER,
      internalId: getUUID(),
      id: undefined,
    };
    update(newInput);
  };
  const hasNewInputs = inputs.some(input => !input.id);
  return useMemo(() => ({
    inputs,
    categories,
    loading: isFetchingCategories,
    update,
    canUpdate: changes.length > 0 || hasNewInputs,
    onAddInput,
    isAutoSaved,
    duplicate
  }), [
    inputs,
    categories,
    isFetchingCategories,
    changes,
    period,
    isAutoSaved
  ]);
};

export default useInputs;

export const KEY_BINDINGS = {
  detailedView: { primary: { key: 'Enter' } },
  addRow: {
    primary: { key: '+' },
    alternative: { code: 'Equal', withShift: true }
  },
  removeSelected: {
    primary: { key: 'Delete' },
    alternative: { key: 'Backspace' }
  },
  overviewDetails: { primary: { key: 'Enter', withShift: true } }
};

import dayjs, { OpUnitType } from 'dayjs';
import { groupBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ChartReport, ChartSettings, ChartStyle, ChartValues } from 'types/chart.types';
import { Period } from 'types/financials.types';
import { ReportType } from 'types/templates.types';
import { getStartAndEndDate } from 'utils/financials.utils';
import { numberFormatter } from 'utils/common.utils';
import useChartColors from 'components/charts/hooks/useChartColors';
import useChartData from 'components/charts/hooks/useChartData';
import colors from 'utils/colors.utils';
import { isChartStacked } from 'components/charts/chart/utils/chart.utils';
import { graphic } from 'echarts';
import { getSeriesFormat } from '../chart/utils/chart.utils';
import { isLineChart } from 'utils/chart.utils';
import { DurationUnitType } from 'dayjs/plugin/duration';

interface Props {
  period: Period;
  settings: ChartSettings;
  templateId: number;
  cumulative: boolean;
}

const useChartSeries = ({ period, settings, templateId, cumulative }: Props) => {
  const { data } = useChartData({ settings, templateId, period, cumulative });
  const { getColor } = useChartColors({ settings });
  const { planOpen, actualsOpen, cadence } = period;
  const values = useMemo(() => {
    const primaryValues = data[ 0 ]?.map(o => {
      if (o?.formatting === 'PERCENTAGE') {
        return o.value * 100;
      }
      return o.value;
    }) || [];
    const secondaryValues = data[ 1 ]?.map(o => {
      if (o?.formatting === 'PERCENTAGE') {
        return o.value * 100;
      }
      return o.value;
    }) || [];
    return [ ...primaryValues, ...secondaryValues ];
  }, [ data ]);

  const dates = useMemo(() => {
    const { start, end } = getStartAndEndDate({
      startDate: actualsOpen ? period.startDate : null,
      endDate: actualsOpen ? period.endDate : null,
      startDatePlan: planOpen ? period.startDatePlan : null,
      endDatePlan: planOpen ? period.endDatePlan : null
    });
    const _dates = [];
    let startDate = dayjs.unix(start);
    while (startDate.isBefore(dayjs.unix(end))) {
      _dates.push(startDate.startOf(cadence as OpUnitType).utcOffset(0, true).format());
      startDate = startDate.add(1, cadence as DurationUnitType);
    }
    return _dates.sort((a, b) => dayjs(a).diff(dayjs(b)));
  }, [ period ]);

  const fillDates = useCallback((
    chartValues: ChartValues[],
    type: string,
    reportType: ReportType,
  ): ChartValues[] => {
    const formatting = chartValues[ 0 ].formatting;
    const filledDates = dates.map(date => {
      const value = chartValues.find(v => dayjs(date).isSame(v.date, cadence as OpUnitType));
      return value || { date, value: null, type, reportType, formatting };
    });
    return filledDates.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
  }, [ dates ]);

  const showLabels = useCallback((datasetIndex: number) => {
    if (isChartStacked(settings.datasets[ datasetIndex ].style)) {
      return settings.other.dataLabels.stacked;
    }
    return settings.other.dataLabels.bar;
  }, [ settings ]);

  const getLabelPosition = useCallback((stack, reverseValues, value) => {
    return stack ? 'inside' :
      (reverseValues ? (value > 0 ? 'bottom' : 'top') :
        (value > 0 ? 'top' : 'bottom'));
  }, []);

  const getBarStyleProps = useCallback((datasetIndex: number) => {
    const dataset = settings.datasets[ datasetIndex ];
    const shouldHaveRadius = dataset.style == 'bar' || dataset.style == 'grouped';

    let fontSize = 16;

    if (!shouldHaveRadius) {
      fontSize = 18;
    }

    return {
      fontSize,
    };
  }, [ settings, values ]);

  const getSeriesSettings = useCallback((
    chartValues: ChartValues[],
    keys: string[],
    datasetIndex: number,
    key: string,
    reportType: ReportType
  ) => {
    const stack = isChartStacked(settings.datasets[ datasetIndex ].style);
    const style = settings.datasets[ datasetIndex ].style;
    let areaColor;

    const { fontSize } = getBarStyleProps(datasetIndex);

    const reverseValues = settings.datasets[ datasetIndex ].reverseValues;

    return {
      name: `${ reportType }__${ datasetIndex }__${ key }`,
      type: style.startsWith('line') ? 'line' : 'bar',
      data: chartValues.map(({ value, formatting }, index) => {
        const color = getColor({
          datasetIndex,
          value,
          keys,
          key,
          reportType,
          dataIndex: index,
        });
        areaColor = color;
        let _value = value;
        if (_value && formatting === 'PERCENTAGE') {
          _value = value * 100;
        }
        return {
          value: (reverseValues ? -_value : _value),
          label: {
            position: getLabelPosition(stack, reverseValues, value),
          },
          itemStyle: {
            color,
          },
          emphasis: {
            itemColor: {
              color,
            }
          },
        };
      }),
      lineStyle: style.startsWith('line') ? {
        color: areaColor,
      } : null,
      itemStyle: style.startsWith('line') ? {
        color: areaColor,
      } : null,
      areaStyle: style === ChartStyle.LINE_WITH_BACKGROUND ? {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0.0052,
            color: areaColor,
          },
          {
            offset: 0.7364,
            color: 'rgba(255, 255, 255, 0)'
          }
        ])
      } : null,
      blur: {
        itemStyle: {
          opacity: 1,
        }
      },
      clip: false,
      barMaxWidth: 300,
      barMinHeight: 8,
      barCategoryGap: '25%',
      label: {
        show: showLabels(datasetIndex),
        fontStyle: 'Roboto',
        fontWeight: 'normal',
        fontSize: fontSize,
        color: stack ? 'white' : colors[ 'indigoGray700' ],
        formatter: ({ value }) => {
          if (!value || Math.round(value) === 0) return '';
          if (getSeriesFormat(settings, datasetIndex) === 'percentage') {
            return `${ value.toFixed(1) } %`;
          }
          return numberFormatter(value);
        }
      },
      ...(stack ? { stack } : {}),
    };
  }, [ settings, values, getBarStyleProps, getColor ]);

  const getKeys = useCallback((index: number) => {
    const typeData = data[ index ];
    const dataByType = groupBy(typeData, 'type');
    return Object.keys(dataByType).sort((a, b) => {
      const aSum = dataByType[ a ].reduce((acc, curr) => acc + curr.value, 0);
      const bSum = dataByType[ b ].reduce((acc, curr) => acc + curr.value, 0);
      return bSum - aSum;
    });
  }, [ data ]);

  const mapValuesToPercentage = useCallback((_values: ChartValues[]): ChartValues[] => {
    const dataByDate = groupBy(_values, 'date');
    const returnValues: ChartValues[] = [];
    Object.values(dataByDate).forEach((_valueByDate) => {
      const sum = _valueByDate.reduce((acc, curr) => acc + curr.value, 0);

      _valueByDate.forEach((value) => {
        returnValues.push({
          ...value,
          value: value.value / sum,
        });
      });
    });

    return returnValues;
  }, []);

  const mapValuesToStyle = useCallback((
    _values: ChartReport,
    index: number
  ): ChartValues[] => {
    if (settings.datasets[ index ].style === ChartStyle.PERCENTAGE) {
      return mapValuesToPercentage(_values[ index ]);
    }
    return _values[ index ];
  }, [ settings ]);

  const connectPlanWithActuals = useCallback((
    connectValue: ChartValues,
    plan: ChartValues[]
  ): ChartValues[] => {
    return plan.map((val) => {
      const toConnect = dayjs(val.date).isSame(dayjs(connectValue.date), cadence as OpUnitType) ?
        connectValue : null;
      return {
        ...val,
        value: toConnect ? toConnect.value : val.value,
        hideValue: !!toConnect,
      };
    });
  }, []);

  const getSeries = useCallback((datasetIndex: number) => {
    const active = settings.datasets[ datasetIndex ]?.active;
    if (!active) return [];
    const typeData = mapValuesToStyle(data, datasetIndex);
    const dataByType = groupBy(typeData, 'type');
    const sortedKeys = getKeys(datasetIndex);
    const series = [];

    // We are connecting plan values with actuals if they are side by side. These values can be in
    // different datasets. We are checking if they are "side by side" by comparing the last date of
    // the plan values with the first date of the actual values.
    const otherActuals = data.map(
      datasetData => datasetData.filter(({ reportType }) => reportType === ReportType.ACTUAL)
    );
    sortedKeys.forEach((key) => {
      const chartValues = dataByType[ key ];
      const actualValues = chartValues.filter(({ reportType }) => reportType === ReportType.ACTUAL);
      const planValues = chartValues.filter(({ reportType }) => reportType === ReportType.PLAN);
      if (actualValues.length > 0) {
        const filledActuals = fillDates(actualValues, key, ReportType.ACTUAL);
        series.push(
          getSeriesSettings(filledActuals, sortedKeys, datasetIndex, key, ReportType.ACTUAL)
        );
      }
      if (planValues.length > 0) {
        const filledPlan = fillDates(planValues, key, ReportType.PLAN);
        const cadenceUnit = cadence as DurationUnitType;
        let areValuesSideBySide = false;
        let actualsToConnect = [];
        for (const actual of otherActuals) {
          const isTheSameType = actual[ 0 ]?.type === planValues[ 0 ]?.type;
          if (!isTheSameType) continue;
          areValuesSideBySide = dayjs(actual.at(-1)?.date)
            .add(1, cadenceUnit)
            .utcOffset(0, true)
            .isSame(dayjs(planValues.at(0)?.date).startOf(cadenceUnit).utcOffset(
              0, true));
          if (areValuesSideBySide && actual.length > 0) {
            actualsToConnect = actual;
            break;
          }
        }

        const shouldConnect = isLineChart(settings.datasets[ datasetIndex ].style) &&
          areValuesSideBySide;

        if (shouldConnect) {
          const connectedPlan = connectPlanWithActuals(actualsToConnect.at(-1), filledPlan);
          series.push(
            getSeriesSettings(connectedPlan, sortedKeys, datasetIndex, key, ReportType.PLAN)
          );
        } else {
          series.push(
            getSeriesSettings(filledPlan, sortedKeys, datasetIndex, key, ReportType.PLAN)
          );
        }
      }
    });

    return series;
  }, [ settings, data, dates, showLabels ]);

  const barStacks = useMemo(() => {
    const getStacks = (datasetIndex) => {
      return getSeries(datasetIndex).reduce((acc, curr, index) => {
        const accumulator = [ ...acc ];

        if (index === 0) {
          curr.data.forEach((el) => {
            accumulator.push([ { ...el, series: curr.name } ]);
          });
        } else {
          curr.data.forEach((el, i) => {
            accumulator[ i ].push({ ...el, series: curr.name });
          });
        }
        return accumulator;
      }, []);
    };
    return settings.datasets.map(
      (dataset, index) => getStacks(index)
    );
    // return {
    //   primary: getStacks(0),
    //   secondary: getStacks(1),
    // };
  }, [ getSeries, settings, data ]);

  return {
    getSeries,
    values,
    dates,
    barStacks,
  };
};

export default useChartSeries;

import { Switch, Tooltip } from 'antd';
import React from 'react';
import styles from './CalculateDynamicTotalsToggle.module.scss';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

const CalculateDynamicTotalsToggle = ({ value, onChange, labelText, helpText = '' }) => {
  
  return (
    <div className={ styles.container }>
      <span className={ styles.text }>
        { labelText }
      </span>
      <div className={ styles.actionContainer }>
        { helpText && <Tooltip title={ helpText }>
          <InfoIcon className={ styles.infoIcon }></InfoIcon>
        </Tooltip> }
        <Switch
          size='small'
          checked={ value }
          onChange={ onChange }
        />
      </div>

    </div>
  );
};

export default CalculateDynamicTotalsToggle;

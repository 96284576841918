import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, Dropdown } from 'antd';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

import { ReactComponent as CloseIcon } from 'assets/icons/toast-close.svg';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down.svg';

import styles from './SidePanelHeader.module.scss';

interface BaseTab {
  id: string;
  title: string;
  icon?: React.ReactNode;
}

export interface SideHeaderProps {
  onChange: (section: BaseTab['id'] | null) => void;
  onClose: () => void;
  activeTabId: string | null;
  tabs: BaseTab[];
  showOnlyTabs?: BaseTab['id'][];
  hideTabs?: BaseTab['id'][];
}

export const SidePanelHeader = ({
  onChange,
  onClose,
  activeTabId,
  tabs = [],
  showOnlyTabs,
  hideTabs
}: SideHeaderProps) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const tabsAfterFilter = useMemo(() => {
    let tabsToFilter = tabs;

    if (showOnlyTabs) {
      tabsToFilter = tabsToFilter.filter(el => showOnlyTabs.includes(el.id));
    }

    if (hideTabs) {
      tabsToFilter = tabsToFilter.filter(el => !hideTabs.includes(el.id));
    }

    return tabsToFilter;
  }, [ tabs, showOnlyTabs, hideTabs ]);

  const onLabelClick = (key: BaseTab[ 'title' ] | null) => {
    setIsOpen(false);
    onChange(key ?? null);
  };

  const items = useMemo(() => tabsAfterFilter.map(el => ({
    key: el.id,
    label: <div
      onClick={ () => onLabelClick(el.id) }
      className={
        clsx(styles.navigationItem, {
          [ styles.specialButton ]: [ 'overviewDetails' ].includes(el.id) 
        })
      }
    >
      { el.icon }
      <span>{ el.title }</span>
    </div>
  })), [ tabsAfterFilter, onLabelClick ]);

  const activeTab = tabsAfterFilter.find(el => el.id === activeTabId);

  return (
    <div className={ styles.headerWrapper }>
      <Dropdown
        open={ isOpen }
        trigger={ [ 'click' ] }
        className={ styles.dropdown }
        onOpenChange={ setIsOpen }
        overlayClassName={ styles.dropdownOverlay }
        menu={ { items } }>
        <Button
          type='link'
          className={ clsx(
            styles.dropdownTrigger,
            {
              [ styles.specialSvgButton ]: [ 'overviewDetails' ].includes(activeTab?.id)
            } 
          ) }
        >
          { activeTab?.icon }
          { activeTab?.title }
          { ' ' }
          <ChevronIcon
            className={ clsx(styles.arrowIcon, { [ styles.activeDropdown ]: isOpen }) }
          />
        </Button>
      </Dropdown>
      <GhostIconButton
        aria-label='close'
        onClick={ onClose }
        className={ styles.closeButton }
      >
        <CloseIcon width={ 24 } height={ 24 }/>
      </GhostIconButton>
    </div>
  );
};

export default SidePanelHeader;

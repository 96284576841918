import DimensionCapsule from 'components/elements/dimensionCapsule/DimensionCapsule';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'types/statutory.types';
import { getDisplayName } from 'utils/common.utils';
import CounterpartyGroup from './CounterpartyGroup';
import styles from './LabelPage.module.scss';
import { Popover } from 'antd';
import clsx from 'clsx';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as AllAndUpcomingIcon } from 'assets/icons/rules/all-and-upcoming.svg';
import { ReactComponent as StartingFromIcon } from 'assets/icons/rules/starting-from.svg';
import LabelCard from '../labelCard/LabelCard';
import InlineStatement from '../../../elements/inlineStatement/InlineStatement';
import { GatheredLabels } from '../../../../hooks/useLabeler';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { Dimension, DimensionItem } from '../../../../types/filterTable.types';

export type RuleType = 'none' | 'all' | 'startingFrom' | 'range';

type RemovedLabel = {
  dimension: Dimension;
  dimensionItem: DimensionItem | null;
};

interface Props {
  counterparties: {
    [ key: string ]: Transaction[];
  };
  onRemove: (id: number) => void;
  labels: GatheredLabels;
  onRuleTypeChange: (ruleType: RuleType) => void;
  ruleType?: RuleType;
  transactionsCount: number;
  onLabelRemoved?: (label: RemovedLabel) => void;
}

const LabelPage = (
  {
    counterparties,
    onRemove,
    labels,
    onRuleTypeChange,
    ruleType = 'none',
    transactionsCount,
    onLabelRemoved,
  }: Props) => {
  const counterpartyKeys = useMemo(() => Object.keys(counterparties), [ counterparties ]);
  const [ t ] = useTranslation('labeling');

  const { dimensionItemMap, dimensionMap } = useAppSelector(state => state.breakdowns);

  const labelsCount = useMemo(() => {
    return labels?.dimensionItems?.length + labels?.unassignedDimensions?.length;
  }, [ labels ]);

  const ruleTypeButtons: { label: string; ruleType: RuleType; icon: ReactElement }[] = [
    {
      label: t('rule-selection.option-none-info'),
      ruleType: 'none',
      icon: null,
    },
    {
      label: t('rule-selection.option.all'),
      ruleType: 'all',
      icon: <AllAndUpcomingIcon/>,
    },
    {
      label: t('rule-selection.option.starting-from'),
      ruleType: 'startingFrom',
      icon: <StartingFromIcon/>,
    },
  ];

  const infoTextBullets = t('label.info-points', { returnObjects: true });

  const popoverContent = useMemo(() => (
    <InlineStatement type='info' className={ styles.labelInfo }>
      <div>
        <span className={ styles.infoText }>
          { t('label.info') }
        </span>
        <ul className={ styles.infoTextBullets }>
          { Array.isArray(infoTextBullets) && infoTextBullets.map((point, index) => (
            <li key={ index }>{ point }</li>
          )) }
        </ul>

      </div>
    </InlineStatement>
  ), [ t ]);

  const allowForTargetRemoval = useMemo(() => {
    return (labels.dimensionItems?.length + labels.unassignedDimensions?.length )> 1;
  }, [ labels ]);

  return <div className={ styles.labelPage }>
    <div className={ styles.labelerBody }>
      <div className={ styles.labeler }>
        <LabelCard title={ t('create-rule.source') } count={ transactionsCount }
          style={ { maxHeight: 550, overflow: 'hidden' } }
        >
          <div className={ styles.label }>
            {
              counterpartyKeys.map(counterparty => (
                <div className={ styles.counterpartyGroup }
                  key={ `counterparty__${ counterparty }` }>
                  <CounterpartyGroup
                    counterpartyName={ counterparty }
                    nodes={ counterparties[ counterparty ] }
                    onRemove={ onRemove }
                  />
                </div>
              ))
            }
          </div>

        </LabelCard>

        <LabelCard title={ t('create-rule.target') } count={ labelsCount }>
          <div className={ styles.dimensionTarget }>
            <div className={ styles.targetCapsules }>
              {
                labels.dimensionItems?.map(
                  dimensionItemId => {
                    const dimension = dimensionMap[
                      dimensionItemMap[ dimensionItemId ].dimensionId ];
                    const dimensionItem = dimensionItemMap[ dimensionItemId ];

                    return <DimensionCapsule
                      key={ dimensionItemMap[ dimensionItemId ].id }
                      dimension={ getDisplayName(dimension.customName) }
                      dimensionItem={ getDisplayName(dimensionItem.customName) }
                      showRemoveIcon={ allowForTargetRemoval }
                      onRemove={ () => onLabelRemoved?.({
                        dimension: dimension,
                        dimensionItem: dimensionItem,
                      })
                      }
                    />;
                  }
                )
              }
              {
                labels.unassignedDimensions?.map(
                  dimensionId => {
                    const dimension = dimensionMap[ dimensionId ];
                    return <DimensionCapsule
                      key={ dimension.id }
                      dimension={ getDisplayName(dimension.customName) }
                      dimensionItem={ t('cost-labeler.unassigned', { ns: 'financials' }) }
                      showRemoveIcon={ allowForTargetRemoval }
                      onRemove={ () => onLabelRemoved?.({
                        dimension: dimension,
                        dimensionItem: null,
                      })
                      }
                    />;
                  }
                )
              }
            </div>

          </div>

        </LabelCard>

      </div>
    </div>

    <div className={ styles.ruleSection }>
      <div className={ styles.ruleSectionTitle }>
        <span>{ t('rule-selection.title') }</span>

        <Popover
          className={ styles.popover }
          content={ popoverContent }
          placement='right'
          trigger='click'
          color='#f4f5f8'
        >
          <InfoIcon className={ styles.infoIcon }/>
        </Popover>
      </div>
      <div className={ styles.ruleTypeButtonContainer }>
        {
          ruleTypeButtons.map((ruleTypeButton, index) => (
            <button
              key={ index }
              className={ clsx(
                { [ styles.active ]: ruleType === ruleTypeButton.ruleType }, styles.labelMethod)
              }
              onClick={ () => onRuleTypeChange(ruleTypeButton.ruleType) }
            >
              { ruleTypeButton.label }
              { ruleTypeButton.icon && <div className={ styles.methodIconContainer }>
                { ruleTypeButton.icon }
              </div> }

            </button>
          ))
        }
      </div>

    </div>

  </div>;
};

export default LabelPage;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Table from 'components/elements/tableWrapper/table/Table';
import { useTranslation } from 'react-i18next';
import useInputsTableProps from 'components/inputs/table/hooks/useInputsTableProps';
import { AgGridReact } from 'ag-grid-react';
import { InputRowWithValues } from 'components/inputs/types/inputs.types';
import Card from 'components/elements/card/Card';
import styles from './InputsTable.module.scss';
import dayjs from 'dayjs';
import {
  CellKeyDownEvent,
  ColumnState,
  FillOperationParams,
  InitialGroupOrderComparatorParams,
  IRowNode
} from 'ag-grid-community';
import {
  INPUT_NONE_GROUP_NAME,
  INPUT_TEMPLATE_ID,
  isInputValid
} from 'components/inputs/utils/inputs.utils';
import { FinancialTableContextProvider } from 'context/FinancialTableContext';
import InputsLeftPanel from '../panels/InputsLeftPanel';
import useInputsCommandBar from '../hooks/useInputsCommandBar';
import clsx from 'clsx';
import { UUID } from 'types/templates.types';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';
import { useAppSelector } from '../../../store/hooks/hooks';
import DataLossPrevention from 'components/financials/dataLossPrevention/DataLossPrevention';
import { TableContextProvider } from 'context/TableContext';
import PanelProvider from 'components/panels/panelsContext';
import AddInputAliasModal from './modals/AddInputAliasModal';
import { useSetInputAliasMutation } from '../../../store/api/inputs.api';

interface InputsTableProps {
  isActive?: boolean;
  className?: string;
}

const InputsTable = ({ isActive, className = '' }: InputsTableProps) => {
  const gridRef = useRef<AgGridReact<InputRowWithValues>>();
  const [ t ] = useTranslation('inputs');
  const [ search, setSearch ] = useState('');
  const filters = useAppSelector(store => store.inputs.filters);

  const [ inputToAddAlias, setInputToAddAlias ] =
    useState<IRowNode<InputRowWithValues> | null>(null);
  const [ showAddAliasModal, setShowAddAliasModal ] = useState(false);

  const [ setAlias ] = useSetInputAliasMutation();

  const onAliasCellEdit = useCallback((node: IRowNode<InputRowWithValues>) => {
    if (node.data.alias || !node.data.id) {
      return;
    }
    setShowAddAliasModal(true);
    setInputToAddAlias(node);
  }, []);

  const onAddAlias = useCallback((node: IRowNode<InputRowWithValues>, alias: string) => {
    setAlias({ id: node.data.id, alias });
  }, []);

  const {
    otherTableProps: { table: { groupByOptions, ...tableProps }, other: { canUpdate } },
    columnProperties,
    duplicate,
    onAddInput
  } = useInputsTableProps({ search, onAliasCellEdit });
  const { dimensionItemMap } = useAppSelector(store => store.breakdowns);

  const fillOperation = useCallback((params: FillOperationParams) => {
    const isValueColumn = dayjs(params.column.getColId()).isValid();
    if (!isValueColumn) {
      return params.currentCellValue;
    }
    const value = params.values.at(0);
    if (!isNaN(+value)) {
      return value;
    }

    return params.currentCellValue;
  }, []);

  const initialGroupOrderComparator = useCallback(
    (params: InitialGroupOrderComparatorParams<InputRowWithValues>) => {
      const keyA = params.nodeA.key;
      const keyB = params.nodeB.key;
      if (keyA === INPUT_NONE_GROUP_NAME || keyB === INPUT_NONE_GROUP_NAME) {
        return keyA === INPUT_NONE_GROUP_NAME ? -1 : 1;
      }
      return keyA?.localeCompare(keyB);
    }, []);

  const doesExternalFilterPass = useCallback((node: IRowNode<InputRowWithValues>) => {
    const nodeAssignedDimensions = new Set(
      node.data.dimensionItemIds.map(id => dimensionItemMap[ id ]?.dimensionId));
    for (const filter of filters) {
      const excludedSet = new Set(filter.excludedItems);
      if (excludedSet.size && node.data.dimensionItemIds.some(id => excludedSet.has(id))) {
        return false;
      }
      if (filter.excludeUnassigned && !nodeAssignedDimensions.has(filter.dimension)) {
        return false;
      }
    }
    return true;
  }, [ filters ]);

  useEffect(() => {
    if (filters) {
      gridRef.current?.api.onFilterChanged();
    }
  }, [ filters ]);

  const defaultSorting: ColumnState[] = useMemo(() => [
    {
      colId: 'input_id',
      sort: 'desc',
      sortIndex: 0
    }
  ], []);

  return (
    <TableContextProvider defaultSorting={ defaultSorting }>
      <FinancialTableContextProvider templateId={ INPUT_TEMPLATE_ID }>
        <PanelProvider>
          { isActive && <InputsCommandBar
            gridRef={ gridRef }
            duplicate={ duplicate }
            onAddInput={ () => {
              onAddInput();
              gridRef.current?.api.applyColumnState({
                state: defaultSorting
              });
            } }
          />
          }

          <div data-view-item-id='item__inputs__null' className={ styles.viewWrapper }>
            { isActive && <InputsLeftPanel
              gridRef={ gridRef }
              groupOptions={ groupByOptions }
            /> }
            <Card className={ clsx(styles.tableCard, className) }>
              <Table
                tableKey={ INPUT_TEMPLATE_ID.toString() }
                onCellKeyDown={ (event: CellKeyDownEvent ) => {
                  if (event.event[ 'key' ] === 'Enter' && event.column.getColId() === 'alias') {
                    onAliasCellEdit(event.node);
                    event.event.preventDefault();
                    event.event.stopPropagation();
                  }
                } }
                gridRef={ gridRef }
                name={ t('table.title') }
                showSearch={ true }
                onSearch={ setSearch }
                className={ styles.table }
                enableFillHandle={ true }
                enableRangeSelection={ true }
                fillHandleDirection='x'
                fillOperation={ fillOperation }
                periodSections='multiple'
                initialGroupOrderComparator={ initialGroupOrderComparator }
                isRowValid={ isInputValid }
                defaultSort={ defaultSorting }
                columnDefs={ columnProperties.columnDefs }
                defaultColDef={ columnProperties.defaultColDef }
                { ...tableProps }
                isExternalFilterPresent={ () => !!filters }
                doesExternalFilterPass={ doesExternalFilterPass }
              />
            </Card>
          </div>
          <DataLossPrevention willLooseData={ canUpdate } />
        </PanelProvider>
      </FinancialTableContextProvider>
      <AddInputAliasModal
        node={ inputToAddAlias }
        open={ showAddAliasModal }
        onConfirm={ onAddAlias }
        disabled={ !!inputToAddAlias?.data.alias }
        onClose={
          () => {
            setInputToAddAlias(null);
            setShowAddAliasModal(false);
          }
        } />
    </TableContextProvider>
  );
};

export default InputsTable;

interface InputsCommandBarProps {
  gridRef: React.MutableRefObject<AgGridReact<InputRowWithValues> | undefined>;
  duplicate: (input: InputRowWithValues & { internalId?: UUID }) => void;
  onAddInput: () => void;
}

const InputsCommandBar = ({ gridRef, duplicate, onAddInput }: InputsCommandBarProps) => {
  useCommandBarDefaults({ keys: [ 'sort', 'columns' ], mode: 'show', showCapsulesFilter: true });
  useInputsCommandBar(true, gridRef, duplicate, onAddInput);

  return null;
};

import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { clearLeftPanel } from 'store/financials.slice';
import PanelWrapper from 'components/panels/wrappers/PanelWrapper';
import LayoutControl from '../layout/LayoutControl';
import { appSlice } from 'store/app.slice';
import RegisterPanel from '../../../panels/registerPanel/RegisterPanel';
import { sideTabs } from '../../../panels/panel.constants';
import { usePanelControl } from 'components/panels/panelsContext';
import LayoutSidePanelHeader from 'components/panels/layoutSidePanelHeader/LayoutSidePanelHeader';

const ItemsLayoutPanel = () => {
  const dispatch = useAppDispatch();
  const panelType = useAppSelector(state => state.app.leftPanel);
  const { setLeftActivePanel } = usePanelControl();

  const onClose = () => {
    dispatch(clearLeftPanel());
  };

  useEffect(() => {
    setLeftActivePanel(panelType);
  }, [ panelType ]);

  const onChange = (key: string | null) => {
    dispatch(appSlice.actions.setLeftPanel(key));
  };

  return <PanelWrapper orientation='left' isActive={ panelType === 'layout' } >
    <LayoutSidePanelHeader
      side='left'
      onChange={ onChange }
      onClose={ onClose }
    />

    <RegisterPanel { ...sideTabs[ 'layout' ] } >
      <LayoutControl />
    </RegisterPanel>
  </PanelWrapper>;
};

const ProviderWrapper = () => {
  return <ItemsLayoutPanel />;
};

export default ProviderWrapper;

import React from 'react';
import { IRowNode } from 'ag-grid-community';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { BudgetItemType, BudgetItem } from 'types/budget.types';
import { DimensionItem, Dimension } from 'types/filterTable.types';
import { FinancialNode } from 'types/financials.types';

import { ReactComponent as StartPointIcon } from 'assets/icons/start-point.svg';
import { ReactComponent as EndPointIcon } from 'assets/icons/end-point.svg';
import { ReactComponent as RecurringIcon } from 'assets/icons/recurring.svg';
import { ReactComponent as ClockOnwardIcon } from 'assets/icons/clock-onward.svg';
import { getDisplayName } from '../../../../../utils/common.utils';
import { Entry, TableCoordinates } from '../overviewPanel.types';
import { UNASSIGNED_ROW_ID }
  from 'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';

export const COLUMN_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const fieldToIconMap = {
  'startDate': <StartPointIcon />,
  'endDate': <EndPointIcon />,
  'duration': <ClockOnwardIcon />,
  'invoicingFrequency': <RecurringIcon />
};

export const defaultFields = [
  'budgetItemType',
  'counterparty',
  'memo',
  'amountFormula',
  'startDate',
  'duration',
  'endDate',
  'invoicingFrequency'
];

export function getBudgetItemDefaults (budgetItemType: BudgetItemType) {
  const itemTypeRelatedSettings: Partial<BudgetItem> = budgetItemType != null ? {
    memo: getDisplayName(budgetItemType.memo),
    accounts: budgetItemType.defaultAccounts,
  }: {};

  return {
    ...itemTypeRelatedSettings,
    itemType: budgetItemType?.id,
    dimensionItems: [],
    dimensionItemIds: [],
  };
}

export function getDefaults (
  sourceNode: IRowNode<FinancialNode>,
  sourceColumn: string,
  defaultBudgetItem: BudgetItemType
) {
  const budgetItemDefaults = getBudgetItemDefaults(defaultBudgetItem);

  const rowData = sourceNode?.data?.rowData;

  const dimension = ((rowData?.dimensionItems ?? []) as DimensionItem[])
    .reduce<Record<Dimension['id'], DimensionItem['id']>>((acc, DI) => {
    acc[ DI.dimensionId ] = DI.id;
    return acc;
  }, {});

  const dateColumn = sourceColumn.split('__').at(1);
  const dateFallback = dateColumn ? dayjs(dateColumn)
    .startOf('month').format('YYYY-MM-DD') : '';

  return {
    ...budgetItemDefaults,
    ...rowData,
    memo: rowData?.memo,
    budgetItemType: rowData?.budgetItemType,
    accounts: {
      primary: rowData?.account ?? rowData.accounts?.primary ??
        budgetItemDefaults?.accounts?.primary,
      counter: rowData.accounts?.counter
    },
    amountFormula: rowData.entries?.[ dateColumn ]?.amountFormula ??
      Object.values(sourceNode.data.plan).at(0) ?? 0,
    startDate: rowData.entries?.[ dateColumn ]?.startDate ??
      Object.keys(sourceNode.data.plan).at(0) ??
      dateFallback,
    endDate: rowData?.endDate,
    invoicingFrequency: {
      unit: rowData.frequencyUnit ?? 'one_time',
      amount: rowData.frequencyAmount ?? 1,
    },
    duration: rowData.duration ?? { id: 0 },
    dimension,
    counterparty: rowData?.counterparty
  };
}

export function getDefaultsForMultiple(
  entries: { node: IRowNode<FinancialNode>; column: string }[],
  defaultBudgetItem: BudgetItemType,
  placeholderMap?: Map<string, string>,
) {

  const defaults = entries
    .map(({ node, column }) => getDefaults(node, column, defaultBudgetItem));

  const def = defaults.reduce((acc, item) => {
    if (acc == null) return { ...item };

    for (const key in item) {
      if (key !== 'dimensionItems' && Array.isArray(item[ key ])) continue;

      if (typeof item[ key ] === 'object') {

        if (key === 'dimensionItems') {
          acc[ key ].forEach((accDimensionItem) => {
            item[ key ].forEach((thisDimensionItem) => {
              if (accDimensionItem.dimensionId !== thisDimensionItem.dimensionId) {
                acc[ `dimension.${ thisDimensionItem.dimensionId }` ] = null;
                if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
              }
            });
          });
          continue;
        } else if (key === 'accounts') {
          if (acc[ key ]?.primary !== item[ key ]?.primary) {
            acc[ key ] = null;
            if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
          } else {
            continue;
          }
        } else if (key === 'invoicingFrequency') {
          if (
            acc[ key ]?.unit !== item[ key ]?.unit ||
            acc[ key ]?.amount !== item[ key ]?.amount
          ) {
            acc[ key ] = null;
            if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
          } else {
            continue;
          }
        } else {
          if (acc[ key ]?.id !== item[ key ]?.id) {
            acc[ key ] = null;
            if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
          } else {
            continue;
          }
        }

      }

      if (acc[ key ] !== item[ key ]) {
        acc[ key ] = null;
        if (placeholderMap) placeholderMap.set(key, t('common:form.multiple'));
      }
    }

    return acc;

  }, null);

  for (const key in def) {
    if (def[ key ] == null && !placeholderMap.has(key) && !key.startsWith('input')) {
      placeholderMap.set(key, t('common:none'));
    }
  }

  return def;
}

const notNull = (val: unknown) => val != null;

export function createUpdates (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any,
  dimensionsItemsMap: Record<number, DimensionItem>,
  dimensionsMap: Record<number, Dimension>,
  startDate: string,
  entriesList: TableCoordinates[]
) {
  
  const dimensionItems = Object.values(formData.dimension)
    .filter(notNull)
    .map((id: number) => {
      if (id === UNASSIGNED_ROW_ID) {
        return null;
      }

      return dimensionsItemsMap[ id ];
    })
    .filter((dimensionItem) => {
      const dimensionName = dimensionsMap[ dimensionItem?.dimensionId ]?.relation;
      if (dimensionName === 'COUNTERPARTY' || dimensionName === 'ACCOUNT') {
        return false;
      }
      return dimensionItem;
    });

  const groupByNodes = entriesList.reduce((acc, { node, column }) => {
    if (!acc[ node.id ]) {
      acc[ node.id ] = { node, columns: [] };
    }
    acc[ node.id ].columns.push(column);
    return acc;
  }, {} as Record<number, { node: IRowNode<FinancialNode>; columns: string[] }>);

  return Object.values(groupByNodes).map(({ node, columns }) => {
    const entries = {
      ...(node.data.rowData.entries ?? {})
    };
    const plan = {
      ...(node.data.plan ?? {})
    };

    // Remove cells that was empty before
    for (const entry in entries) {
      if (!entries[ entry ].amountFormula) {
        delete entries[ entry ];
        delete plan[ entry ];
      }
    }

    columns.forEach((column) => {

      const [ , date ] = column.split('__');
  
      if (node.data.rowData.entries?.[ column.split('__').at(1) ] == null) {
        return;
      }
  
      const value = plan[ date ];
      delete plan[ date ];
      delete entries[ date ];
  
      entries[ formData.startDate ? startDate : date ] = {
        ...node.data.rowData.entries[ date ],
        ...(formData.amountFormula ? { amountFormula: formData.amountFormula } : {}),
        ...(formData.startDate ? { startDate: formData.startDate } : {}),
      };
      plan[ formData.startDate ? startDate : date ] = formData.amountFormula ?
        formData.amountFormula : value;
    });

    const rowData = node.data.rowData;
      
    return {
      ...node.data,
      plan,
      rowData: {
        ...rowData,
        accounts: formData.budgetItemType.defaultAccounts,
        ...formData,
        frequencyUnit: formData.invoicingFrequency?.unit ?? rowData.invoicingFrequency?.unit,
        frequencyAmount: formData.invoicingFrequency?.amount ?? rowData.invoicingFrequency?.amount,
        entries,
        dimensionItemIds: dimensionItems.map(item => item.id),
        dimensionItems,
      }
    };
  });
}

export function getEntries (nodes: IRowNode<FinancialNode>[], columns: string[]) {
  return nodes.flatMap((node) => Object.entries(node.data?.rowData?.entries ?? {})
    .filter(([ key, value ]: [ string, Entry ]) => {
      return columns.includes(`plan__${ key }`) && value.amountFormula;
    })
    .flatMap(([ key, value ]: [ string, object ]) => ({
      key: `${ key }__${ node.id }`,
      ...node.data.rowData,
      ...value,
    }))) as Entry[];
}

export function setPlaceholdersForDimensions(
  placeholderMap: Map<string, string>,
  nodes: IRowNode[],
  dimensionsFields: string[]
) {
  if (!placeholderMap) return;

  dimensionsFields.forEach((dimensionField) => {
    const dimensionId = dimensionField.split('.').at(1);
    const dimensionItems = nodes.flatMap(node => node.data.rowData.dimensionItems)
      .filter((item) => item.dimensionId === dimensionId);

    if (dimensionItems.length > 1) {
      placeholderMap.set(dimensionField, t('common:form.multiple'));
    } else if (dimensionItems.length === 0) {
      placeholderMap.set(dimensionField, t('common:none'));
    }
  });
}

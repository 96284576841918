import React, { useEffect, useLayoutEffect, useState } from 'react';
import { IRowNode, RowDropZoneParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAppSelector } from 'store/hooks/hooks';
import { selectFilteredTableReady } from 'store/financials.slice';
import styles from '../DimensionItem/DimensionItem.module.scss';
import { useShouldSkipLabelingModal } from '../../../../../../../hooks/useLabeler';

interface Props {
  dropId: string;
  gridRef: React.RefObject<AgGridReact>;
  onAssign: (nodes: IRowNode[], showModal: boolean) => void;
  canDrop?: boolean;
  isDisabled?: (nodes: IRowNode[]) => boolean;
}

const ANIMATION_TIME = 2000;

const useDropZoneEffect = ({
  dropId,
  gridRef,
  onAssign,
  canDrop = true,
  isDisabled = () => false
}: Props) => {
  if (!gridRef?.current) return;

  const getLabelingSkipConfirmation = useShouldSkipLabelingModal();

  const filteredTableReady = useAppSelector(selectFilteredTableReady);
  const [ element, setElement ] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    if (!element) {
      setElement(document.getElementById(dropId) as HTMLElement);
    }
  }, []);

  const addCompleteAnimation = (el: HTMLElement) => {
    el.classList.add(styles.dimensionItemComplete);

    setTimeout(() => {
      el.classList.remove(styles.dimensionItemComplete);
    }, ANIMATION_TIME);
  };

  const callbackWithAnimation = (callback: Function, showModal: boolean) => {
    if (!canDrop) return;
    callback();
    if (!showModal) {
      addCompleteAnimation(element);
    }
  };

  useEffect( () => {
    if (!element) return;
    const dropZoneParams: RowDropZoneParams = {
      getContainer: () => element,
      onDragStop: (params) => {
        element.classList.remove(styles.dimensionItemHover);
        element.classList.remove(styles.disabled);
        if (!canDrop) return;

        const skipModal = getLabelingSkipConfirmation(params.event);
        callbackWithAnimation(() => {
          onAssign(params.nodes, !skipModal);
        }, !skipModal);
      },
      onDragEnter: (params) => {
        if (canDrop) {
          element.classList.toggle(styles.disabled, isDisabled(params.nodes));
          element.classList.toggle(styles.dimensionItemHover, !isDisabled(params.nodes));
        } else {
          element.classList.add(styles.disabled);
        }
      },
      onDragLeave: () => {
        element.classList.remove(styles.dimensionItemHover);
        element.classList.remove(styles.disabled);
      }
    };

    gridRef?.current?.api?.addRowDropZone(dropZoneParams);
    return () => gridRef?.current?.api?.removeRowDropZone(dropZoneParams);
  }, [
    filteredTableReady,
    onAssign,
    gridRef?.current,
    canDrop, element,
    getLabelingSkipConfirmation,
    isDisabled
  ]);

  return undefined;
};

export default useDropZoneEffect;

import { useGetBudgetsQuery } from 'store/api/planning.api';
import { appSlice } from 'store/app.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { Budget } from 'types/budget.types';
import BudgetDetails from './budgetDetails/BudgetDetails';
import BudgetsList from './budgetsList/BudgetsList';

const newBudget: Budget = {
  id: 0,
  name: 'New Budget',
  startDate: undefined as unknown as string,
  durationMonths: 1,
  status: 'draft'
};

const BudgetsPanel = () => {
  const selectedBudgetId = useAppSelector(state => state.app.leftPanelData.budgetId);
  const appDispatch = useAppDispatch();

  const { data } = useGetBudgetsQuery();

  const budgets = data?.results; 

  if (!budgets) {
    return null;
  }

  const onBudgetIdSelect = (budgetId: number | null) => {
    appDispatch(appSlice.actions.setLeftPanelData({ budgetId }));
  };

  const selectedBudget = budgets?.find(budget => budget?.id === selectedBudgetId);

  const isNewBudgetItem = selectedBudgetId === 0;

  return <>
    {
      selectedBudgetId != null ?
        <BudgetDetails
          budgets={ budgets }
          budget={ isNewBudgetItem ? { ...newBudget } : selectedBudget }
          onBudgetSelect={ onBudgetIdSelect }
        /> :
        <BudgetsList
          budgets={ budgets }
          onBudgetSelect={ onBudgetIdSelect }
        />
    }
  </>;
};

export default BudgetsPanel;

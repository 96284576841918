import { RefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialRow, LoadingStatus, RowType } from 'types/financials.types';
import { Transaction } from 'types/statutory.types';
import { notifyError } from 'utils/notifications.utils';
import { createLoadMoreRow, createTransactions, getTransactionQuery } from 'utils/financials.utils';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { financialsSlice } from 'store/financials.slice';
import { IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import useAbort from 'hooks/useAbort';
import { createDisplayName } from '../../../../utils/common.utils';
import organizationsService, { SSRMParams } from '../../../../services/organizations.service';
import { SortingType } from '../../../../utils/sorting.constants';
import { NodeSortingSetting } from '../../../../utils/sorting.utils';

// Even with batch loading disabled, we still need some reasonable limit, so we set it here.
export const BATCH_LOADING_DISABLED_ROWS = 1000;
const INITIAL_BATCH_LOAD_COUNT = 10;

type AddTransactionsToNodeParams = {
  nodeToAppend: IRowNode;
  childrenToExpand?: string[];
  loadCount?: number;
  nodeSorting?: NodeSortingSetting;
};

const useLazyLoadingRow = (gridRef: RefObject<AgGridReact>, templateId: number) => {
  const period = useAppSelector(state => state.financials.tables[ templateId ].period);
  const { controller, signal } = useAbort();
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(state => state.financials.tables[ templateId ].sorting);
  const tablesOptions = useAppSelector(state => state.financials.options);

  const changeLoadingStatus = useCallback((status: LoadingStatus, nodeId: string) => {
    dispatch(financialsSlice
      .actions
      .setLazyLoadingStatus({
        nodeId,
        status,
        templateId
      }));
  }, [ templateId ]);

  const addTransactionsToNode = useCallback((
    {
      nodeToAppend,
      childrenToExpand = [],
      loadCount = INITIAL_BATCH_LOAD_COUNT,
      nodeSorting = null,
    }: AddTransactionsToNodeParams
  ) => {
    if (!nodeToAppend.data.hasTransactions) return;
    const totalTransactionCount = (nodeToAppend.data.actual?.count || 0) +
      (nodeToAppend.data.plan?.count || 0);
    const parentSorting = nodeSorting || sorting[ nodeToAppend.id ] || {
      primary: {
        type: SortingType.DATE,
        isDescending: false,
      },
      secondary: {
        type: SortingType.ALPHABETICAL,
        isDescending: false
      }
    };
    const sortingTypeToColId = {
      [ SortingType.ALPHABETICAL ]: 'memo',
      [ SortingType.DATE ]: 'transaction.transaction_date',
      [ SortingType.AMOUNT ]: 'amount',
    };
    let startRow = 0;
    const existingLoadMoreRow = nodeToAppend.childrenAfterGroup.find(
      (child) => child.data?.type === RowType.LOAD_MORE
    );
    if (existingLoadMoreRow) {
      gridRef?.current?.api?.applyTransactionAsync(
        {
          update: [ { ...existingLoadMoreRow.data, rowData: {
            ...existingLoadMoreRow.data.rowData, loading: true,
          } } ]
        },
        () => {
          gridRef?.current?.api?.redrawRows({ rowNodes: [ existingLoadMoreRow ] });
        }
      );
    }

    if (existingLoadMoreRow) {
      startRow = existingLoadMoreRow.data.rowData.startRow;
    }

    changeLoadingStatus(LoadingStatus.LOADING, nodeToAppend.id);
    const transactionQuery = getTransactionQuery([ nodeToAppend ], templateId, period);

    const getEndRow = () => {
      if (existingLoadMoreRow) {
        return startRow + loadCount;
      } else if (tablesOptions.loadInvoiceRowsInBatches) {
        return startRow + INITIAL_BATCH_LOAD_COUNT;
      } else {
        return startRow + BATCH_LOADING_DISABLED_ROWS;
      }
    };

    const ssrmParams: SSRMParams = {
      filterModel: transactionQuery,
      startRow: startRow ,
      endRow: getEndRow(),
      cadence: period.cadence,
    };

    if (parentSorting && parentSorting.primary) {
      ssrmParams.sortModel = [ {
        colId: sortingTypeToColId[ parentSorting.primary.type ],
        sort: parentSorting.primary.isDescending ? 'desc' : 'asc',
      } ];
    }
    const request = organizationsService.getTransactionLinesSSRM(ssrmParams, signal);
    request
      .then((res) => {
        const transactions: Transaction[] = res.data.transactionLines;
        if (transactions.length === 0) {
          // Don't update the table if for some reason we got nothing
          return changeLoadingStatus(LoadingStatus.LOADED, nodeToAppend.id);
        }
        const newGroupData = createTransactions(transactions, nodeToAppend);
        const newRows = mapTransactions(newGroupData);

        const transaction = {

        };
        if (totalTransactionCount > getEndRow()) {
          const nextStartRow = startRow + (existingLoadMoreRow ?
            loadCount : INITIAL_BATCH_LOAD_COUNT);
          const loadMoreRow = createLoadMoreRow(nodeToAppend, nextStartRow);
          newRows.push(loadMoreRow);
        } else {
          transaction[ 'remove' ] = [ existingLoadMoreRow ];
        }
        transaction[ 'add' ] = newRows;

        gridRef?.current?.api?.applyTransactionAsync(
          transaction,
          () => {
            gridRef?.current?.api?.redrawRows({ rowNodes: [ nodeToAppend ] });

            nodeToAppend.childrenAfterGroup.forEach(child => {
              if (childrenToExpand.includes(child.key)) {
                child.setExpanded(true);
              }
            });
          }
        );

        nodeToAppend.setExpanded(true);
        gridRef?.current?.api?.applyTransaction({
          update: [ { ...nodeToAppend.data, asyncStatus: 'loaded' } ]
        });
        changeLoadingStatus(LoadingStatus.LOADED, nodeToAppend.id);
      })
      .catch((e) => {
        changeLoadingStatus(LoadingStatus.TO_LOAD, nodeToAppend.id);
        gridRef?.current?.api?.applyTransaction({
          update: [ { ...nodeToAppend.data, asyncStatus: 'not_loaded' } ]
        });

        if (e.message !== 'canceled') {
          notifyError(t('notifications.unexpected-error.message', { ns: 'common' }));
          throw e;
        }
      });
  }, [ changeLoadingStatus, period ]);

  const createFilePath = useMemo(
    () => (
      transaction,
      defaultPath,
      groupByProxy = false,
    ) => {
      const unassignedPath = [ ...defaultPath ];

      if (groupByProxy) {
        const path = unassignedPath;
        path.splice(unassignedPath.length - 1, 0, transaction.rowData.productProxy);

        return path;
      }
      return [ ...unassignedPath ];
    },
    []
  );

  const createProductProxyRow = (transactionRow: FinancialRow): FinancialRow => {
    return {
      id: transactionRow.id + '_proxy',
      type: RowType.PRODUCT_PROXY,
      localRow: true,
      hasData: true,
      hasTransactions: true,
      negativeMultiplier: false,
      isFailed: false,
      asyncStatus: null,
      uuid: null,
      rowData: {
        name: createDisplayName(transactionRow.rowData.productProxy),
      },
      filePath: [ ...transactionRow.filePath.slice(0, -1), transactionRow.rowData.productProxy ],
    };
  };

  const mapTransactions = useCallback(
    (transactions: FinancialRow[]) => {
      const counts = {};

      transactions
        .filter((el) => el.rowData?.productProxy)
        .forEach((tr) => {
          counts[ tr.rowData?.productProxy ] = counts[ tr.rowData?.productProxy ]
            ? counts[ tr.rowData?.productProxy ] + 1
            : 1;
        });

      const proxyRows = [];
      const createdProxies = new Set();

      const transactionRows = transactions.map((transaction) => {
        const productProxy = transaction.rowData.productProxy;
        const groupByProxy = counts[ productProxy ] > 1;

        if (groupByProxy && !createdProxies.has(productProxy)) {
          proxyRows.push(createProductProxyRow(transaction));
          createdProxies.add(productProxy);
        }

        return {
          ...transaction,
          filePath: createFilePath(transaction, transaction.filePath, groupByProxy),
        };
      });

      return [ ...proxyRows, ...transactionRows ];
    },
    []
  );

  return {
    addTransactionsToNode,
    controller,
  };
};

export default useLazyLoadingRow;

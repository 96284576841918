import React, { useCallback } from 'react';
import { ColDef, ColDefField, ICellRendererParams, IRowNode } from 'ag-grid-community';
import HighlightedText from '../../highlightedText/HighlightedText';
import { ReactComponent as Bin } from 'assets/icons/bin.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow-rounded.svg';
import { useTranslation } from 'react-i18next';
import AmountBadge from 'components/elements/badge/amount/AmountBadge';
import isEmpty from 'lodash/isEmpty';
import ValueBadge from 'components/elements/badge/value/ValueBadge';
import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import SourceBadge from 'components/elements/sourceBadge/SourceBadge';
import styles from '../table/Table.module.scss';
import { getColumnWidthLS } from '../utils/columns.utils';

interface Props {
  search: string;
}

const isBottomRowPinned = (params: ICellRendererParams) => params.node.rowPinned === 'bottom';
const isGroupNode = (params: ICellRendererParams) => params.node.group;
const isSubtotalGroup = (params: ICellRendererParams) =>
  isGroupNode(params) && params.node.level === 1;

const useTableRenderer = ({ search }: Props) => {
  const [ t ] = useTranslation('tableWrapper');

  const getCellText = (params: ICellRendererParams) => {
    const isMemoColumn = params.column.getColId() === 'memo';
    if (!isMemoColumn) {
      return params.formatValue ? params.formatValue(params.value) : params.value;
    }
    if (isBottomRowPinned(params)) {
      return t('transactions-table.grand-total');
    }
    if (isSubtotalGroup(params)) {
      return t('transactions-table.subtotal');
    }
    if (isGroupNode(params)) {
      return t('transactions-table.total');
    }
    return params.formatValue ? params.formatValue(params.value) : params.value;
  };

  const cellRenderer = useCallback((params: ICellRendererParams) => {
    const text = getCellText(params);
    const colDef = params.colDef as TableColDef;
    const showSourceBadge = colDef.sourceBadge &&
      !isBottomRowPinned(params) &&
      !isGroupNode(params);

    // TODO: Source badge should NOT be here. It is a general cell renderer, and here we have
    //  element that is very specific to the budget item rows.
    //  That should be provided from the outside.
    const inputId = params?.colDef?.field.split('input_')[ 1 ];
    const isValueInData = params?.data && params.data.inputs &&
      params.data.inputs[ inputId ] != null;

    return <div className={ styles.cellRenderer }>
      {
        showSourceBadge ?
          <SourceBadge
            className={ styles.sourceBadge }
            source={ isValueInData ? 'custom' : 'default' }
          /> : null
      }
      <HighlightedText
        text={ text }
        highlight={ search }
      />
    </div>;
  }, [ search ]);

  const renderField = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T = any, D = any>(
      field: ColDefField<T, D>,
      title?: string,
      defaultWidth = 120
    ): ColDef<T, D> => {
      let columnWidth;
      try {
        columnWidth = getColumnWidthLS(field) ?? defaultWidth;
      } catch {
        columnWidth = defaultWidth;
      }

      return {
        field: field,
        width: columnWidth,
        headerName: title,
        cellRenderer: cellRenderer,
        flex: 1,
      } as ColDef<T, D>;
    },
    [ cellRenderer ]
  );

  const defaultCellRenderer = useCallback(({ value }: ICellRendererParams) => {
    return <div className={ styles.cell }>
      { value }
    </div>;
  }, []);

  const deleteCellRenderer = useCallback((
    params: ICellRendererParams,
    onClick: (node: IRowNode) => void
  ) => {
    return (
      <div className={ styles.actions }>
        {
          (params.node.rowPinned !== 'bottom' && !params.node.group) ?
            <Bin
              height={ 25 }
              className={ styles.grayIcon }
              onClick={ () => onClick(params.node) }
            /> : null
        }
      </div>
    );
  }, []);

  const redirectCellRenderer = useCallback((
    params: ICellRendererParams,
    onClick: (node: IRowNode) => void
  ) => {
    const value = params.value;
    const isValueEmpty = typeof value === 'object' ? isEmpty(value) : !value;
    if (isValueEmpty) {
      return null;
    }
    return <div onClick={ () => onClick(params.node) } className={ styles.redirectCell }>
      <Arrow/>
    </div>;
  }, []);

  const amountRenderer = useCallback((params: ICellRendererParams) => {
    if (params.value == null) {
      return null;
    }

    if (/^-?\d+(\.\d+)?$/.test(params.value)) {
      return <AmountBadge className={ styles.amountCell } amount={ params.value }/>;
    }

    return params.value;
  }, []);

  const valueRenderer = useCallback((params: ICellRendererParams) => {
    const sign = params.value < 0 ? 'negative' : 'positive';
    if (params.value == undefined) {
      return null;
    }

    return <ValueBadge
      className={ styles.amountCell }
      size='extra-small'
      value={ params.formatValue ? params.formatValue(params.value) : params.value }
      sign={ sign }
    />;
  }, []);

  return {
    cellRenderer,
    renderField,
    defaultCellRenderer,
    deleteCellRenderer,
    redirectCellRenderer,
    amountRenderer,
    valueRenderer
  };
};
export default useTableRenderer;

import React, { createContext, useState, useCallback, useContext, PropsWithChildren } from 'react';

export type PanelConfigProps = {
  id: string;
  title: string;
  type?: string;
  side: 'left' | 'right';
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

type PanelContextType = {
  activeLeftPanel: string | null;
  activeRightPanel: string | null;
  panels: Map<string, PanelConfigProps>;
  registerPanel: (id: PanelConfigProps) => void;
  unregisterPanel: (id: string) => void;
  setLeftActivePanel: (id: string | null) => void;
  setRightActivePanel: (id: string | null) => void;
};

// Context
const PanelContext = createContext<PanelContextType | null>(null);

// Context Provider
const PanelProvider = ({ children }: PropsWithChildren<{}>) => {
  const [ activeLeftPanel, setLeftActivePanel ] = useState<string | null>(null);
  const [ activeRightPanel, setRightActivePanel ] = useState<string | null>(null);
  const [ panels, setPanels ] = useState<Map<string, PanelConfigProps>>(new Map());

  const registerPanel = useCallback((panel: PanelConfigProps) => {
    setPanels(prev => {
      const newPanels = new Map<string, PanelConfigProps>(prev);
      newPanels.set(panel.id, panel);
      return newPanels;
    });
  }, []);

  const unregisterPanel = useCallback((id: string) => {
    setPanels(prev => {
      const newPanels = new Map(prev);
      newPanels.delete(id);
      return newPanels;
    });
  }, []);

  return (
    <PanelContext.Provider 
      value={ { 
        activeLeftPanel, 
        activeRightPanel, 
        panels, 
        registerPanel, 
        unregisterPanel, 
        setLeftActivePanel,
        setRightActivePanel 
      } }
    >
      { children }
    </PanelContext.Provider>
  );
};

export default PanelProvider;

// Hook for external control
export const usePanelControl = () => {
  const context = useContext(PanelContext);
  if (!context) throw new Error('usePanelControl must be used within PanelProvider');
  
  return {
    setLeftActivePanel: context.setLeftActivePanel,
    setRightActivePanel: context.setRightActivePanel,
    activeLeftPanel: context.activeLeftPanel,
    activeRightPanel: context.activeRightPanel,
    panels: Array.from(context.panels.values())
  };
};

export const useRegistryControl = () => {
  const context = useContext(PanelContext);
  if (!context) throw new Error('usePanelControl must be used within PanelProvider');
  
  return {
    registerPanel: context.registerPanel,
    unregisterPanel: context.unregisterPanel,
    setLeftActivePanel: context.setLeftActivePanel,
    setRightActivePanel: context.setRightActivePanel,
    panels: Array.from(context.panels.values()),
    activeLeftPanel: context.activeLeftPanel,
    activeRightPanel: context.activeRightPanel,
  };
};

import { ReactComponent as EndPointIcon } from 'assets/icons/end-point.svg';
import { ReactComponent as ClockOnwardIcon } from 'assets/icons/clock-onward.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import styles from './BudgetDropdown.module.scss';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useAppSelector } from 'store/hooks/hooks';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { Duration } from 'types/contracts.types';
import { useTranslation } from 'react-i18next';
import OptionPopover from './optionPopover/OptionPopover';
import BudgetOption from './budgetSelectedOption/BudgetSelectedOption';
import FrequencyOptions from './frequencyOptions/FrequencyOptions';
import { DATE_FORMATS } from 'utils/date.utils';
import { getBudgetingOptionKey } from 'components/financials/utils/addRow.utils';
import useDatePickerLocale from 'hooks/datePickerLocale';
import clsx from 'clsx';
import InfoBox from 'components/elements/infoBox/InfoBox';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const BudgetDropdown = () => {
  const { t } = useTranslation('financials');

  const locale = useDatePickerLocale();

  const templateId = useAppSelector(state => state.financials.active.templateId);

  const endDateKey = getBudgetingOptionKey('endDate', templateId);
  const durationKey = getBudgetingOptionKey('duration', templateId);

  const [ endDate, setEndDate ] = useLocalStorage(endDateKey, null);
  const [ duration, setDuration ] = useLocalStorage(durationKey, null);

  const durationFormatter = (value: Duration) => {
    if (!value) return '';

    return t(`financials:duration.${ value }`);
  };

  return <div className={ styles.budgetDropdown }>
    <FrequencyOptions />
    <OptionPopover
      contentClassName={ duration ? styles.crossValidationWarning : null }
      content={
        duration ?
          <InfoBox type='warning'>
            { t('inline-edit.warnings.endDateDurationValidationMessage') }
          </InfoBox> :
          <DatePicker
            className={ styles.datePicker }
            getPopupContainer={ target => target }
            format={ (val) => val.format(DATE_FORMATS.at(1)) }
            locale={ locale }
            open={ true }
            showToday={ false }
            bordered={ false }
            value={ endDate ? dayjs(endDate, DATE_FORMATS.at(1)) : undefined }
            onChange={ (date) => {
              setEndDate(date?.format(DATE_FORMATS.at(1)));
            } }
            size='small'
          />
      }
    >
      <BudgetOption
        name={ t('inline-edit.budgetOptions.endDateLabel') }
        disabled={ duration }
        icon={ <EndPointIcon /> }
        value={ endDate }
      />
    </OptionPopover>
    <OptionPopover
      contentClassName={ endDate ? styles.crossValidationWarning : null }
      content={ 
        endDate ?
          <InfoBox type='warning'>
            { t('inline-edit.warnings.endDateDurationValidationMessage') }
          </InfoBox> :
          <>
            {
              Object.values(Duration).map((item) => {
                return <div
                  key={ item }
                  className={ clsx(
                    styles.optionItem,
                    { [ styles.selectedOption ]: duration === item }
                  ) }
                  onClick={ () => setDuration(item) }
                >
                  { durationFormatter(item) }
                  { duration === item ? <CheckIcon className={ styles.selectedIcon } /> : '' }
                </div>;
              })
            }
            {
              duration && <div
                className={ clsx(styles.optionItem, styles.clearOption) }
                onClick={ () => setDuration(null) }
              >
                { t('common:clear') }
              </div>
            }
          
          </>
      }
    >
      <BudgetOption
        name={ t('inline-edit.fields.duration') }
        disabled={ endDate }
        icon={ <ClockOnwardIcon /> }
        value={ durationFormatter(duration) }
      />
    </OptionPopover>
  </div>;
};

export default BudgetDropdown;

import useBudgetItemTable from 'components/budget/budgetItemTable/hooks/useBudgetItemTable';
import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import { FormStepSections } from 'components/financials/panels/overviewPanel/useBudgetItemForm';
import { capitalize, noop } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { Duration } from 'types/contracts.types';
import { getDisplayName } from 'utils/common.utils';

// ? Those have dedicated editors 
const excludedFields = [ 'itemType', 'split', 'budget' ];

export const durationMap = {
  [ Duration.FOR_1_MONTH ]: 1,
  [ Duration.FOR_3_MONTHS ]: 3,
  [ Duration.FOR_6_MONTHS ]: 6,
  [ Duration.FOR_18_MONTHS ]: 18,
  [ Duration.FOR_12_MONTHS ]: 12,
  [ Duration.FOR_24_MONTHS ]: 24,
};

const useFieldsDefinitions = () => {
  const [ t ] = useTranslation('financials');

  const {
    budgetColumnDefs,
    budgetSettingsColumnDefs,
    dimensionColumnDefs,
    redirectColDef
  } = useBudgetItemTable({ search: null, updateBudgetItem: noop, setRedirect: noop });

  // We are filtering out filters that are used in budget item table, but not in the popover
  const filteredBudgetColumnDefs = useMemo(
    () => budgetColumnDefs.filter(col => !excludedFields.includes(col.field)),
    [ budgetColumnDefs ]);

  const durationOptions = useMemo(() => {
    const items = Object.values(Duration)
      .map((type) => {
        return {
          id: type,
          name: capitalize(t('financials:duration.' + type)),
        };
      });

    return [
      {
        id: 0,
        name: t('financials:inline-edit.fields.duration-infinity'),
      },
      ...items
    ];
  }
  , [ t ]);

  const customFields = useMemo( () => ({
    'budgetItemType': {
      field: 'budgetItemType',
      headerName: t('inline-edit.fields.budget-item-type'),
      cellEditor: {
        displayName: 'BudgetItemTypeEditor',
      },
      cellEditorParams: {}
    },
    'duration': {
      field: 'duration',
      headerName: t('inline-edit.fields.duration'),
      cellEditor: {
        displayName: 'SelectCellEditor',
      },
      cellEditorParams: {
        placeholder: t('inline-edit.fields.duration'),
      }
    },
  }), [ t ]);

  const budgetItemTypes = useAppSelector(state => state.budget.budgetItemTypes);

  const fieldsMap = useMemo(() => {
    const frequency = filteredBudgetColumnDefs.find(col => col.field === 'invoicingFrequency');

    const fieldsTable = [
      ...filteredBudgetColumnDefs.map(col => [ col.field, col ]),
      ...budgetSettingsColumnDefs.map(col => [ col.field, col ]),
      ...dimensionColumnDefs.map(col => [ col.field, col ]),
      [ redirectColDef.field, redirectColDef ],
      [ 'budgetItemType', {
        ...customFields[ 'budgetItemType' ],
        cellEditorParams: {
          ...customFields[ 'budgetItemType' ].cellEditorParams,
          values: budgetItemTypes.map(type => ({ id: type.id, name: getDisplayName(type.name) }))
        }
      } ],
      [
        'duration', {
          ...customFields[ 'duration' ],
          cellEditorParams: {
            ...customFields[ 'duration' ].cellEditorParams,
            values: durationOptions,
          }
        },
      ],
      [
        'invoicingFrequency', {
          ...frequency,
          cellEditor: {
            ...frequency.cellEditor,
            displayName: 'FrequencyCellEditor',
          },
          cellEditorParams: {
            ...frequency.cellEditorParams,
            values: frequency.cellEditorParams?.values.map((type) => ({
              id: type,
              name: capitalize(t('contracts:frequency.' + type)),
            }))
          }
        },
      ],
    ] as [string, TableColDef][];

    return new Map<string, TableColDef>(fieldsTable);
  }, [
    filteredBudgetColumnDefs,
    budgetSettingsColumnDefs,
    dimensionColumnDefs,
    redirectColDef,
    budgetItemTypes
  ]);

  const sections = [
    {
      key: 'default',
      label: t('common:source.default'),
      items: filteredBudgetColumnDefs.map(createEntry).concat([
        {
          id: customFields[ 'budgetItemType' ].field,
          title: customFields[ 'budgetItemType' ].headerName,
          key: customFields[ 'budgetItemType' ].field,
          editor: customFields[ 'budgetItemType' ].cellEditor.displayName
        },
        {
          id: customFields[ 'duration' ].field,
          title: customFields[ 'duration' ].headerName,
          key: customFields[ 'duration' ].field,
          editor: customFields[ 'duration' ].cellEditor.displayName
        }
      ])
    },
    {
      key: 'settings',
      label: t('inline-edit.sections.settings'),
      items: budgetSettingsColumnDefs.map(createEntry)
    },
    {
      key: 'dimension',
      label: t('inline-edit.sections.dimension'),
      items: dimensionColumnDefs.map(createEntry),
    },
  ];

  const groups = [
    {
      key: 'all',
      label: t('inline-edit.sections.all'),
      sections: sections,
      items: []
    },
    {
      key: 'default',
      label: t('common:source.default'),
      sections: sections.filter(i => i.key === 'default'),
      items: []
    },
    {
      key: 'settings',
      label: t('inline-edit.sections.settings'),
      sections: sections.filter(i => i.key === 'settings'),
      items: []
    },
    {
      key: 'dimension',
      label: t('inline-edit.sections.dimension'),
      sections: sections.filter(i => i.key === 'dimension'),
      items: []
    },
  ];

  return { fieldsMap, groups };
};

export default useFieldsDefinitions;

function createEntry(item: TableColDef) {
  return {
    id: item.field,
    title: item.headerName,
    key: item.field,
    editor: item.cellEditor,
  };
}

export const defaultFields: FormStepSections = {
  'id': 1,
  'budgetItemType': null,
  'steps': [
    {
      'sections': [
        {
          'name': 'GENERAL',
          'fields': [
            { 'name': 'budgetItemType', isMandatory: true, shownByDefault: true },
            { 'name': 'accounts', isMandatory: true, shownByDefault: true },
            { 'name': 'counterparty', isMandatory: false, shownByDefault: true },
            { 'name': 'amountFormula', isMandatory: true, shownByDefault: true },
            { 'name': 'memo', isMandatory: false, shownByDefault: true }
          ]
        },
        {
          'name': 'RECURRENCE',
          'fields': [
            { 'name': 'invoicingFrequency', isMandatory: true, shownByDefault: true },
            { 'name': 'startDate', isMandatory: true, shownByDefault: true },
            { 'name': 'duration', isMandatory: false, shownByDefault: false }
          ]
        },
        {
          'name': 'OTHER',
          fields: []
        }
      ]
    }
  ]
};

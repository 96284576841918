import React from 'react';
import LabelSettings from './labelSettings/LabelSettings';

import styles from './SettingsPanel.module.scss';

import TableSettings from './tableSettings/TableSettings';

const SettingsPanel = () => {
  return <>
    <div className={ styles.container }>
      <TableSettings />
      <LabelSettings />
    </div>
  </>;
};

export default SettingsPanel;

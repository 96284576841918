import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PanelType } from 'types/app.types';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarUtil, UtilKey } from 'components/commandBar/types/commandBar.types';
import { clearLeftPanel, setLeftPanel, setRightPanel, clearRightPanel }
  from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { areFiltersActive } from 'utils/financials.utils';
import BudgetDropdown from '../financialTable/common/budgetDropdown/BudgetDropdown';

import { ReactComponent as FilterActiveIcon } from 'assets/icons/filters-active.svg';
import { KEY_BINDINGS } from '../utils/keyBindings';

const budgetDropdownButton = defaults.find((button) => button.key === 'budgetDropdown');
const overviewDetailsButton = defaults.find((button) => button.key === 'overviewDetails');

interface Props {
  disabled?: boolean;
  disabledCommands?: UtilKey[];
  tableIsInEdit?: boolean;
  gridReady?: boolean;
}

const useFinancialsCommandBar = ({
  disabled = false,
  disabledCommands = [],
  tableIsInEdit,
  gridReady,
}: Props) => {
  const [ t, i18n ] = useTranslation('financials');
  const appDispatch = useAppDispatch();
  const activeCard = useAppSelector(state => state.financials.active?.templateId);
  const isTableActive = useAppSelector(state => state.financials.active?.type === 'table');
  const filters = useAppSelector(state => state.financials.tables[ activeCard ]?.filter);
  
  const leftPanel = useAppSelector(state => state.app.leftPanel);
  const rightPanel = useAppSelector(state => state.app.rightPanel);
  const anyModalOpened = useAppSelector(state => state.app.anyModalOpened);

  const budgetingOptions = useMemo(() => {
    if (activeCard == null || !isTableActive) {
      return null;
    }

    return {
      key: 'budgetDropdown',
      type: 'button-custom',
      icon: budgetDropdownButton.icon,
      className: budgetDropdownButton.className,
      hidden: false,
      disabled,
      placement: 'left',
      tooltip: budgetDropdownButton.tooltip,
      render: () => {
        return <BudgetDropdown />;
      }
    } as const;
  }, [ isTableActive, activeCard, disabled ]);

  const isKeyboardDisabled =
    anyModalOpened ||
    tableIsInEdit;

  const tableElement = useMemo(() => 
    document.querySelector(`[data-view-item-id="item__table__${ activeCard }"]`) as HTMLElement,
  [ activeCard, gridReady ]);

  const overviewDetails = useMemo(() => {
    return {
      key: 'overviewDetails',
      type: 'button',
      keyBinding: {
        ...KEY_BINDINGS.overviewDetails,
        keyBindingNode: { current: tableElement },
        disabled: isKeyboardDisabled
      },
      disabled: !isTableActive,
      onClick: () => {
        if (rightPanel === 'overviewDetails') {
          appDispatch(clearRightPanel());
        } else {
          appDispatch(
            setRightPanel({ panelType: 'overviewDetails', templateId: activeCard, type: 'table' })
          );
        }
      },
      hidden: false,
      className: overviewDetailsButton.className,
      icon: overviewDetailsButton.icon,
      tooltip: overviewDetailsButton.tooltip,
      active: rightPanel === 'overviewDetails',
      placement: overviewDetailsButton.placement

    } as const;
  }, [ tableElement, isKeyboardDisabled, isTableActive, activeCard, rightPanel ]);

  const getPanelButton = useCallback((panelType: PanelType): CommandBarUtil => {
    if (activeCard == null && panelType !== 'layout') {
      return null;
    }
    const defaultValue = defaults.find(button => button.key === panelType);
    const active = leftPanel === panelType;
    return {
      ...defaultValue,
      tooltip: t(`common:command-bar.tooltip.${ panelType }`),
      icon: areFiltersActive(filters) && panelType === 'filter' ?
        <FilterActiveIcon/> : defaultValue.icon,
      disabled,
      hidden: false,
      active,
      type: 'button',
      onClick: () => {
        if (active) {
          appDispatch(clearLeftPanel());
        } else {
          appDispatch(setLeftPanel({ panelType, templateId: activeCard, type: 'table' }));
        }
      }
    };
  }, [ leftPanel, activeCard, disabled, filters, i18n.language ]);

  const filterButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('filter');
  }, [ getPanelButton ]);

  const settingsButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('settings');
  }, [ getPanelButton ]);

  const labelButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('label');
  }, [ getPanelButton ]);

  const budgetButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('budget');
  }, [ getPanelButton ]);

  const sortButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('sort');
  }, [ getPanelButton ]);

  const layoutButton: CommandBarUtil = useMemo(() => {
    return getPanelButton('layout');
  }, [ getPanelButton ]);

  const utilsToUpdate: CommandBarUtil[] = useMemo(() => {
    return [
      overviewDetails,
      budgetingOptions,
      settingsButton,
      filterButton,
      labelButton,
      budgetButton,
      sortButton,
      layoutButton
    ]
      .filter(el => el)
      .filter(el => !disabledCommands?.includes(el.key));
  }, [
    overviewDetails,
    budgetingOptions,
    settingsButton,
    filterButton,
    budgetButton,
    settingsButton,
    sortButton,
    layoutButton,
  ]);
  
  useCommandBarEffect({ utils: utilsToUpdate, filterCapsules: filters });
  return undefined;
};

export default useFinancialsCommandBar;

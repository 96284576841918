import { FilterList } from 'types/filterTable.types';
import { ReportNode, UserReport, UUID } from '../../types/templates.types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CadenceColumnsSettings, Period } from 'types/financials.types';
import { endpoints } from 'utils/config.utils';
import { reportsService } from '../../services/reports.services';
import { financialsSlice } from '../financials.slice';
import { httpBaseQuery } from './api.utils';
import dayjs from 'dayjs';
import { RootState } from '../store';

export const getReportCacheTag = (id: number, period: Period, templateNodeUuids?: UUID[]) => {
  if (!period) {
    return null;
  }
  const keyParts = [
    id.toString(),
    period.cadence?.toString(),
    period.startDate?.toString(),
    period.endDate?.toString(),
    period.startDatePlan?.toString(),
    period.endDatePlan?.toString(),
    templateNodeUuids?.join('__'),
  ];
  return `reports__${ keyParts.join('__') }`;
};

type GetReportParams = {
  id: number;
  period: Period;
  templateNodeUuids?: UUID[];
};

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: httpBaseQuery(),
  tagTypes: [ 'Report', 'StreamReport' ],
  endpoints: (builder) => ({
    getReport: builder.query<UserReport, GetReportParams>({
      query: ({ id, period, templateNodeUuids }) => ({
        url: endpoints.reports.data.replace('{id}', id.toString()),
        params: {
          cadence: period?.cadence,
          startDate: period?.startDate ?
            dayjs.unix(period.startDate).utcOffset(0, true).format() : undefined,
          endDate: period?.endDate ?
            dayjs.unix(period.endDate).utcOffset(0, true).format() : undefined,
          planStartDate: period?.startDatePlan ?
            dayjs.unix(period.startDatePlan).utcOffset(0, true).format() : undefined,
          planEndDate: period?.endDatePlan ?
            dayjs.unix(period.endDatePlan).utcOffset(0, true).format() : undefined,
          templateNodeUuids: templateNodeUuids
        },
        method: 'GET',
        providesTags: [ getReportCacheTag(id, period, templateNodeUuids) ]
      }),
      providesTags: (data) => [ { type: 'Report', id: data?.id } ],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(financialsSlice.actions.setReportLoaded({ id: arg.id, loaded: true }));
        } catch (e) {
          // Network error
        }
      }
    }),
    streamReport: builder.query<UserReport, { id: number; period: Period }>({
      // Get a base report structure. Nodes will be loaded with steaming update from the server
      queryFn: async (arg, { dispatch }) => {
        dispatch(financialsSlice.actions.setReportLoaded({ id: arg.id, loaded: false }));
        const templateResponse = await reportsService.getUserReport({
          reportId: arg.id,
          period: null,
        });
        return { data: templateResponse.data };
      },
      providesTags: (result, error, { id, period }) => [
        { type: 'StreamReport', id: getReportCacheTag(id, period) },
      ],
      async onQueryStarted(arg, { updateCachedData, queryFulfilled, dispatch, getState }) {
        dispatch(financialsSlice.actions.setReportLoaded({ id: arg.id, loaded: false }));
        const controller = new AbortController();
        const signal = controller.signal;
        const version = dayjs().unix();
        dispatch(financialsSlice.actions.setReportVersion({ id: arg.id, version }));

        await queryFulfilled;
        const response = await reportsService.streamUserReport({
          reportId: arg.id,
          period: arg.period,
          signal,
        });

        const reader = response.body.getReader();
        const abortHandler = () => {
          reader.cancel();
        };
        signal.addEventListener('abort', abortHandler);
        const chunks: {[ nodeId: number]: ReportNode } = {};
        let buffer = '';
        let currentVersion = version;
        // eslint-disable-next-line no-constant-condition
        while (currentVersion === version) {
          const store = getState() as RootState;
          currentVersion = store.financials.tablesVersions[ arg.id ];
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          if (value) {
            const decoded = new TextDecoder().decode(value);
            buffer += decoded;

            const bufferLines = buffer.split('\n');
            let unfinishedLine = false;

            for (const line of bufferLines) {
              if (line) {
                try {
                  const items = JSON.parse(line);
                  for (const item of items) {
                    chunks[ item.id ] = item;
                  }
                  updateCachedData((draft) => {
                    draft.nodes = draft.nodes.map(
                      (node: ReportNode) => ({ ...node, ...(chunks[ node.id ] || []) })
                    );
                  });
                  // Since we managed to process entire buffer, we can clear it.
                  buffer = '';
                } catch (e) {
                  // If we split buffer by lines and last line is not finished, we need to keep it
                  buffer = line;
                  unfinishedLine = true;
                  break;
                }
              }
            }

            if (unfinishedLine) {
              buffer = bufferLines[ bufferLines.length - 1 ];
            }
          }

        }
        if (currentVersion === version) {
          dispatch(financialsSlice.actions.setReportLoaded({ id: arg.id, loaded: true }));
        } else {
          controller.abort();
        }
        signal.removeEventListener('abort', abortHandler);
      },
    }),

    setColumnsSettings: builder.mutation<CadenceColumnsSettings, {
      id: number; data: CadenceColumnsSettings;
    }
    >({
      query: ({ id, data }) => ({
        url: endpoints.reports.columnsSettings.replace('{id}', id.toString()),
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [ { type: 'Report', id } ],
    }),
    setFilters: builder.mutation<FilterList, { id: number; data: FilterList }>({
      query: ({ id, data }) => ({
        url: endpoints.reports.reportFilters.index.replace('{id}', id.toString()),
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [ { type: 'Report', id } ],
    }),
    clearFilters: builder.mutation<FilterList, { id: number }>({
      query: ({ id }) => ({
        url: endpoints.reports.reportFilters.index.replace('{id}', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [ { type: 'Report', id } ],
    })
  }),
});

export const {
  useGetReportQuery,
  useStreamReportQuery,
  useSetColumnsSettingsMutation,
  useClearFiltersMutation,
  useSetFiltersMutation
} = reportsApi;

import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { CellRange } from 'ag-grid-community';

import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { clearRightPanel, setRightPanel } from 'store/financials.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import PanelWrapper from 'components/panels/wrappers/PanelWrapper';

import panelStyles from 'components/panels/Panels.module.scss';
import { sideTabs } from '../../../panels/panel.constants';
import RegisterPanel from 'components/panels/registerPanel/RegisterPanel';
import { usePanelControl } from 'components/panels/panelsContext';
import OverviewPanel from '../overviewPanel/OverviewPanel';
import Chat from 'components/collaboration/chat/Chat';
import { getCellsWithValueInSelection } from 'components/financials/utils/selection.utils';
import { ReportType } from 'types/templates.types';
import { RowType } from 'types/financials.types';
import { useCommandBar } from 'components/commandBar/context/CommandBarContext';
import LayoutSidePanelHeader from 'components/panels/layoutSidePanelHeader/LayoutSidePanelHeader';

interface Props {
  selection: CellRange[] | null;
  gridRef: MutableRefObject<AgGridReact>;
  disableTabs?: string[];
}
const FinancialsRightPanel = ({
  selection,
  gridRef,
  disableTabs = []
}: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const dispatch = useAppDispatch();
  const panelType = useAppSelector(state => state.app.rightPanel);
  const activeItem = useAppSelector(state => state.financials.active);
  const { state: { utils } } = useCommandBar();

  const newCells = useMemo(() => {
    if (!selection) return [];
    return getCellsWithValueInSelection(selection, gridRef.current, ReportType.PLAN)
      .filter(({ node }) => node.data.type === RowType.NEW_BUDGET_ITEM);
  }, [ selection ]);

  const { panels, setRightActivePanel } = usePanelControl();

  const isActive = useMemo(() => {

    const availableInCommandBar = utils.filter(u => !u.hidden && !u.disabled)
      .map((u) => u.key) as ReadonlyArray<string>;
    
    const activatedPanel = panels
      .filter(el => !disableTabs.includes(el.id))
      .filter(el => availableInCommandBar.includes(el.id))
      .find(_ => _.id === panelType);

    if (activatedPanel?.type === 'link') return false;

    return !!activatedPanel && templateId === activeItem?.templateId;
  }, [ templateId, panelType, activeItem?.templateId, panels, disableTabs ]);

  useEffect(() => {
    setRightActivePanel(panelType);
  }, [ panelType ]);

  const onChange = useCallback((key: string | null) => {
    if (key == null) clearRightPanel();

    dispatch(setRightPanel({ ...(activeItem ?? {}), panelType: key }));
  }, [ templateId ]);

  const onClose = () => {
    dispatch(clearRightPanel());
  };

  return <PanelWrapper orientation='right' isActive={ isActive } >
    <LayoutSidePanelHeader
      side='right'
      hideTabs={ disableTabs }
      onChange={ onChange }
      onClose={ onClose }
    />

    <RegisterPanel { ...sideTabs[ 'chat' ] }>
      <div className={ clsx(panelStyles.panelBody) }>
        <Chat />
      </div>
    </RegisterPanel>

    <RegisterPanel { ...sideTabs[ 'overviewDetails' ] }>
      <OverviewPanel
        cells={ newCells }
        gridRef={ gridRef }
      />
    </RegisterPanel>

  </PanelWrapper>;
};

const ProviderWrapper = (props: Props) => {
  return <FinancialsRightPanel { ...props } />;
};

export default ProviderWrapper;

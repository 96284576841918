import Modal from 'components/elements/modal/Modal';
import { useFinancialTable } from 'context/FinancialTableContext';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsComponent from './settingsComponent/SettingsComponent';
import styles from './DynamicColumnModal.module.scss';
import { ActionButtonOptions, DynamicSettings } from 'types/financials.types';
import ConfigComponent from './configComponent/ConfigComponent';
import { Cadence } from 'types/form.types';
import { optionListCadence } from '../config';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useForm, useWatch } from 'react-hook-form';
import { mapColumnSettings } from '../dynamicColumns.utils';
import { Switch } from 'antd';
import { selectPeriod, toggleActionsButton } from 'store/financials.slice';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import useUpdateColumnsSettings
  from 'components/financials/dynamicColumns/hooks/useUpdateColumnsSettings';
import { useWarnOnUnsaved } from '../../financialTable/hooks/useWarnOnUnsaved/useWarnOnUnsaved';

interface Props {
  templateId: number;
}

const DynamicColumnModal = ({ templateId }: Props) => {
  const dispatch = useAppDispatch();
  const period = useAppSelector(selectPeriod(templateId));
  const { data: report, refetch } = useReportQuery({ templateId, period });

  const activeTableHasNewCells = useAppSelector((state) =>
    state.financials.tables[ templateId ]?.hasNewCells);

  const [ showDynamicColumns, setShowDynamicColumns ] = useState(false);
  const { state: { showModalDynamicColumn } } = useFinancialTable();
  const { dispatch: dispatchContext } = useFinancialTable();
  const [ cadence, setCadence ] = useState<Cadence | 'all'>(Cadence.month);
  const [ t ] = useTranslation('dynamicColumns');
  const { setValue, reset, control } = useForm<DynamicSettings>({
    defaultValues: mapColumnSettings(report?.columnSettings, 'modal')
  });
  const { update, isLoading } = useUpdateColumnsSettings({ templateId });
  const initialRef = useRef(true);
  const applied = useRef(false);

  const values = useWatch({ control }) as DynamicSettings;
  const dynamicColumns = useAppSelector(state => {
    return state.financials.tables?.[ templateId ]?.state?.dynamicColumns;
  });

  useEffect(() => {
    setShowDynamicColumns(dynamicColumns);
  }, [ dynamicColumns ]);

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    reset(mapColumnSettings(report?.columnSettings, 'modal'));
  }, [ report?.columnSettings ]);

  useEffect(() => {
    if (showModalDynamicColumn) {
      applied.current = false;
      reset(mapColumnSettings(report?.columnSettings, 'modal'));
    }
  }, [ showModalDynamicColumn ]);

  const onCloseModal = () => dispatchContext({ type: 'setModalDynamicColumn' } );

  const onConfirmModal = async () => {
    update(values)
      .finally(() => {
        if (dynamicColumns !== showDynamicColumns) {
          dispatch(toggleActionsButton(templateId, ActionButtonOptions.DYNAMIC_COLUMNS));
        }
        initialRef.current = true;
        applied.current = true;
        onCloseModal();
        refetch();
      });
  };

  const { warnModal, continueWithCheck } = useWarnOnUnsaved<void>({
    onConfirm: onConfirmModal,
    isUnsaved: activeTableHasNewCells,
  });

  return (
    <Modal
      title={
        <div className={ styles.title }>
          { t('title') }
          <Switch
            checked={ showDynamicColumns }
            onChange={ setShowDynamicColumns }
          />
        </div>
      }
      bodyStyle={ { padding: 0 } }
      isVisible={ showModalDynamicColumn }
      onConfirm={ continueWithCheck }
      onClose={ onCloseModal }
      destroyOnClose={ true }
      okText={ t('common:form.apply') }
      width={ 745 }
      className={ styles.modal }
      closeOnConfirm={ false }
      confirmLoading={ isLoading }
      disableButtons={ false }
    >
      <div className={ styles.container }>
        <SettingsComponent
          title={ t('cadenceTitle') }
          width={ 120 }
          chooseOption={ cadence }
          optionList={ optionListCadence }
          setChooseOption={ setCadence }
          bold={ true }
        />
        <ConfigComponent
          setValue={ setValue }
          cadence={ cadence }
          values={ values }
          disableOptions={ !showDynamicColumns }
        />
        { warnModal }
      </div>
    </Modal>

  );
};

export default DynamicColumnModal;

import React from 'react';
import { useEffect } from 'react';
import { PanelConfigProps, useRegistryControl } from '../panelsContext';

const RegisterPanel = ({ id, title, icon, side, children }: PanelConfigProps) => {
  const {
    activeLeftPanel, activeRightPanel, registerPanel, unregisterPanel
  } = useRegistryControl();

  useEffect(() => {
    registerPanel({ id, title, icon, side, children });
    return () => unregisterPanel(id);
  }, [ id ]);

  if (side === 'right') {
    return <>{ activeRightPanel === id ? children : null }</>;
  }

  return <>{ activeLeftPanel === id ? children : null }</>;
};

export default RegisterPanel;

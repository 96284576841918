import React from 'react';
import { useTranslation } from 'react-i18next';
import { toggleTablesOption } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { TablesOptions } from 'types/financials.types';
import SettingsSection from '../common/settingsSection/SettingsSection';
import SwitchSetting from '../common/switchSetting/SwitchSetting';

const TableSettings = () => {
  const [ t ] = useTranslation('financials');
  const appDispatch = useAppDispatch();
  const activeItem = useAppSelector(state => state.financials.active);
  const hasNewCells = useAppSelector(state => {
    if (activeItem.templateId == null) return false;
    
    return state.financials.tables[ activeItem.templateId ]?.hasNewCells;
  });

  const tablesOptions = useAppSelector(state => state.financials.options);
  const toggleAction = (active: boolean, option: TablesOptions) => {
    if (active !== tablesOptions?.[ option ]) {
      appDispatch(toggleTablesOption(option));
    }
  };

  if (activeItem.templateId == null || activeItem.type !== 'table') {
    return null;
  }

  return <SettingsSection title={ t('actions.table-section-header') }>
    <SwitchSetting
      label={ t('actions.hide-dimension-labels') }
      active={ tablesOptions.dimensionLabels }
      onChange={ (active) => toggleAction(active, TablesOptions.DIMENSION_LABELS) }
      disabled={ hasNewCells }
    />
    <SwitchSetting
      label={ t('actions.hide-dimension-subtotals') }
      active={ tablesOptions.dimensionNumbers }
      onChange={ (active) => toggleAction(active, TablesOptions.DIMENSION_NUMBERS) }
    />
    <SwitchSetting
      label={ t('actions.hide-counterparty-logos') }
      active={ tablesOptions.counterpartyLogos }
      onChange={ (active) => toggleAction(active, TablesOptions.COUNTERPARTY_LOGOS) }
    />
    <SwitchSetting
      label={ t('actions.hide-accounts-number') }
      active={ tablesOptions.accountsNumber }
      onChange={ (active) => toggleAction(active, TablesOptions.ACCOUNTS_NUMBER) }
    />
    <SwitchSetting
      label={ t('actions.hide-accounts') }
      active={ tablesOptions.accounts }
      onChange={ (active) => toggleAction(active, TablesOptions.ACCOUNTS) }
    />
    <SwitchSetting
      label={ t('actions.load-invoice-rows') }
      active={ tablesOptions.loadInvoiceRows }
      onChange={ (active) => toggleAction(active, TablesOptions.LOAD_INVOICE_ROWS) }
    />
    <SwitchSetting
      label={ t('actions.load-invoice-rows-in-batches') }
      active={ tablesOptions.loadInvoiceRowsInBatches }
      disabled={ !tablesOptions.loadInvoiceRows }
      onChange={ (active) => toggleAction(active, TablesOptions.LOAD_INVOICE_ROWS_IN_BATCHES) }
    />
  </SettingsSection>;
};

export default TableSettings;

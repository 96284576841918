import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/elements/modal/Modal';
import {
  getTransactionNodesFromProxies,
  getTransactionQuery,
  groupTransactionsByCounterparty,
  isProductProxy,
} from 'utils/financials.utils';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as NewBrowser } from 'assets/icons/newBrowser.svg';

import styles from './CostLabelerModal.module.scss';
import { Transaction } from 'types/statutory.types';
import { AxiosResponse } from 'axios';
import { ReportSource, RowType } from 'types/financials.types';
import Loader from '../../elements/loader/Loader';
import dayjs, { Dayjs } from 'dayjs';
import useLabeler, { GatheredLabels } from '../../../hooks/useLabeler';
import statutoryService from '../../../services/statutory.service';
import { IRowNode } from 'ag-grid-community';
import { useAppSelector } from 'store/hooks/hooks';
import LabelPage, { RuleType } from './label/LabelPage';
import { handleError } from 'utils/error.utils';
import organizationsService, { SSRMParams } from '../../../services/organizations.service';
import { EXTERNAL_BREAKDOWN_TYPES } from 'types/filterTable.types';
import InlineStatement from 'components/elements/inlineStatement/InlineStatement';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import Button from '../../elements/button/Button';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { routes } from '../../../utils/config.utils';
import InlineDatePicker from '../../elements/inlineDatePicker/InlineDatePicker';
import { ReactComponent as StartPointIcon } from 'assets/icons/start-point.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedRows: IRowNode[];
  useServerSide?: boolean;
  serverSideRequestParams?: SSRMParams;
  excludeTransactionIds?: number[];
  gatheredLabels: GatheredLabels;
  templateId: number;
};

export const REMEMBERED_RULE_DATE = 'remembered_rule_date';

const CostLabelerModal = ({
  isVisible,
  onClose,
  onConfirm,
  selectedRows,
  useServerSide = false,
  serverSideRequestParams,
  excludeTransactionIds,
  gatheredLabels,
  templateId
}: Props) => {
  const [ t ] = useTranslation('labeling');
  const [ transactions, setTransactions ] = useState<Transaction[]>([]);
  const [ transactionsToLabel, setTransactionsToLabel ] = useState<Transaction[]>([]);
  const groupedTransactions = useMemo(
    () => groupTransactionsByCounterparty(transactionsToLabel), [ transactionsToLabel ]
  );
  const { dimensionMap } = useAppSelector(state => state.breakdowns);
  const dimensionItemMap = useAppSelector(state => state.breakdowns.dimensionItemMap);
  const period = useAppSelector(state => state.financials.tables[ templateId ].period);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ isCommitting, setIsCommitting ] = useState<boolean>(false);
  const [ startingDate, setStartingDate ] = useState<Dayjs | null>(null);
  const [ rememberedDate, setRememberedDate ] = useLocalStorage<string>(REMEMBERED_RULE_DATE, '');

  const [ ruleType, setRuleType ] = useState<RuleType>('none');

  const [ currentLabels, setCurrentLabels ] = useState<GatheredLabels>(gatheredLabels);

  const labeledDimensions = useMemo(() => {
    if (!currentLabels) {
      return [];
    }
    const { dimensionItems, unassignedDimensions } = currentLabels;

    return [
      ...(dimensionItems?.map(
        dimensionItemId => dimensionMap[ dimensionItemMap[ dimensionItemId ].dimensionId ]
      ) || []),
      ...(unassignedDimensions?.map(dimensionId => dimensionMap[ dimensionId ]) || [])
    ];
  }, [ dimensionMap, currentLabels ]);

  const {
    labelTransactions,
  } = useLabeler(templateId);

  const getLatestTransactionDate = (transactionList: Transaction[]): Dayjs => {
    return dayjs(transactionList.reduce((acc, curr) => {
      return curr.transactionDate > acc ? curr.transactionDate : acc;
    }, ''));
  };

  const setDatesForTransactions = (transactionList: Transaction[]) => {
    if (rememberedDate) {
      setStartingDate(dayjs(rememberedDate));
    } else {
      const highestDate = getLatestTransactionDate(transactionList);
      setStartingDate(highestDate.add(1, 'day'));
    }
  };

  useEffect(() => {
    if (ruleType === 'startingFrom') {
      setDatesForTransactions(transactions);
    } else {
      setStartingDate(null);
    }

  }, [ transactions, ruleType ]);

  useEffect(() => {
    setLoading(true);
    if (!isVisible) {
      setTransactions([]);
    } else {
      if (useServerSide) {
        const filterModel = {
          ...serverSideRequestParams,
          startRow: 0,
          endRow: 9999,
        };
        const response = organizationsService.getTransactionLinesSSRM(filterModel, null);
        response.then(res => {
          const excludeSet = new Set(excludeTransactionIds);
          setTransactions(res.data.transactionLines.filter(
            transaction => !excludeSet.has(transaction.id)
          ));
          setLoading(false);
        }).finally(() => setLoading(false));
      } else if (selectedRows) {
        const promises: Promise<AxiosResponse<Transaction[]>>[] = [];
        for (const r of selectedRows) {
          if (isProductProxy(r)) {
            const transactionsInProxies = getTransactionNodesFromProxies([ r ]);
            for (const transactionNode of transactionsInProxies) {
              setTransactions(prevState => [ ...prevState, transactionNode.data.rowData ]);
            }
          } else if (r?.data?.transaction) {
            setTransactions(prevState => [ ...prevState, r.data ]);
          } else if (r.data?.type !== RowType.TRANSACTION) {
            const parameters = getTransactionQuery([ r ], templateId, period);
            promises.push(statutoryService.getTransactionLines(parameters[ 0 ], null));
          } else if (r.data?.rowData) {
            setTransactions(prevState => [ ...prevState, r.data.rowData ]);
          }
        }
        Promise.all(promises).then(responses => {
          const allTransactions = [];
          for (const r of responses) {
            allTransactions.push(...r.data);
          }
          setTransactions(prev => [ ...prev, ...allTransactions ]);
        }).finally(() => setLoading(false));
      }
    }
  }, [ isVisible, excludeTransactionIds ]);

  const canTransactionBeLabeled = useCallback((transaction: Transaction) => {
    if (labeledDimensions.some(dimension => EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type))) {
      return transaction.transactionSource === ReportSource.SYSTEM;
    }
    return true;
  }, [ labeledDimensions ]);

  useEffect(() => {
    setTransactionsToLabel(transactions.filter(canTransactionBeLabeled));
  }, [ transactions, labeledDimensions ]);

  const removeTransaction = useCallback((id: number) => {
    setTransactions(prevState => prevState.filter(transaction => transaction.id !== id));
  }, []);

  const handleOnClose = useCallback(() => {
    setIsCommitting(false);
    setLoading(false);
    setStartingDate(null);
    onClose();
  }, [ onClose, onConfirm ]);

  const handleOnAssign = useCallback(async () => {
    setIsCommitting(true);
    await handleError(async () => {
      await labelTransactions(
        transactionsToLabel,
        currentLabels,
        ruleType !== 'none',
        ruleType === 'startingFrom' ? startingDate?.format('YYYY-MM-DD'): undefined
      );
    });
    setIsCommitting(false);
    handleOnClose();

  }, [ transactionsToLabel, currentLabels, ruleType, startingDate ]);

  const confirmDisabled = useMemo(() => {
    const noTransactionsLeft = transactionsToLabel.length === 0;
    const noTargetsLeft = currentLabels?.dimensionItems?.length === 0 &&
      currentLabels?.unassignedDimensions?.length === 0;
    return noTransactionsLeft || noTargetsLeft;
  }, [ ruleType, transactionsToLabel, currentLabels ]);

  const areTransactionsFiltered = useMemo(() => {
    return transactionsToLabel.length !== transactions.length;
  }, [ transactions, transactionsToLabel ]);

  const navigateToRules = useCallback(() => {
    window.open(`${ window.location.origin }${ routes.manage.labelingRules }`);
  }, []);

  return <Modal
    title={
      <div className={ styles.title }>
        <div className={ styles.titleLeft }>
          <LabelIcon className={ styles.labelIcon }/>
          { t('cost-labeler.title', { ns: 'financials' }) }
        </div>
        <Button type='default' onClick={ navigateToRules }>
          <NewBrowser className={ styles.newBrowserIcon }/>
          { t('cost-labeler.rules-button') }
        </Button>
      </div>
    }
    isVisible={ isVisible }
    onClose={ handleOnClose }
    onConfirm={ async () => {
      await handleOnAssign();
      onConfirm();
    } }
    closeOnConfirm={ false }
    confirmDisabled={ confirmDisabled }
    confirmLoading={ isCommitting }
    destroyOnClose={ true }
    className={ styles.costLabelerModal }
    width={ 850 }
    okText={ t('form.apply', { ns: 'common' }) }
    closeIcon={ <CloseIcon/> }
    okButtonType='primary'
  >
    <>
      <Loader isActive={ loading }/>
      <LabelPage
        labels={ currentLabels }
        counterparties={ groupedTransactions }
        onRemove={ removeTransaction }
        ruleType={ ruleType }
        transactionsCount={ transactionsToLabel.length }
        onRuleTypeChange={ (type) => {
          setRuleType(type);
          if (type === 'startingFrom') {
            if (!rememberedDate) {
              setRememberedDate(startingDate?.format());
            }
          }
        } }
        onLabelRemoved={ (removedLabel) => {
          if (removedLabel.dimensionItem === null) {
            setCurrentLabels({
              ...currentLabels,
              unassignedDimensions: currentLabels.unassignedDimensions?.filter(
                dimensionId => dimensionId !== removedLabel.dimension.id
              )
            });
          } else {
            setCurrentLabels({
              ...currentLabels,
              dimensionItems: currentLabels.dimensionItems?.filter(
                dimensionItemId => dimensionItemId !== removedLabel.dimensionItem.id
              )
            });
          }
        } }
      />

      <div className={ styles.labelSection }>

        { ruleType === 'startingFrom' && startingDate &&
        <div className={ styles.datepickerContainer }>
          <div className={ styles.datePickerInner }>
            <div className={ styles.label }>
              { t('create-rule.start-date') }
            </div>

            <InlineDatePicker
              height={ 40 }
              className={ styles.datePicker }
              value={ startingDate }
              prefixIcon={ <StartPointIcon /> }
              suffixIcon={ <CalendarIcon /> }
              onChange={ (date) => {
                setStartingDate(date);
                setRememberedDate(date.format());
              } }
              disabled={ false }
            />

          </div>
        </div> }

        {
          areTransactionsFiltered ?
            <InlineStatement type='info' className={ styles.filterInfo }>
              { t('modal.filter-info') }
            </InlineStatement> : null
        }

      </div>
    </>
  </Modal>;
};

export default CostLabelerModal;

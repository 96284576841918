import { useTranslation } from 'react-i18next';
import styles from './WebsocketConnectionLost.module.scss';
import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import ShimmerText from '../../../elements/shimmerText/ShimmerText';

const WebsocketConnectionLost = () => {

  const [ t ] = useTranslation('common');

  return (
    <div className={ styles.websocketConnectionLost }>
      <WarningIcon className={ styles.warningIcon }/>
      { t('common:websocket-connection-lost') }
      <span>&#183;</span>
      <ShimmerText text={ t('common:websocket-connection-reconnecting') }/>
    </div>
  );
};

export default WebsocketConnectionLost;

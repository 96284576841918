import { ActionButtonOptions, TablesOptions } from 'types/financials.types';

const getActionButtonName = (option: string, templateId?: number) => {
  if (templateId) return `show_${ option }_${ templateId }`;
  return `show_${ option }`;
};

export const toggleActionOption = (
  option: ActionButtonOptions | TablesOptions,
  templateId?: number
) => {
  const name = getActionButtonName(option, templateId);
  const value: string | null = localStorage.getItem(name);

  if (value === null) {
    localStorage.setItem(name, 'true');
  } else if (value === 'true') {
    localStorage.setItem(name, 'false');
  } else if (value === 'false')
    localStorage.setItem(name, 'true');
};

interface GetValueFromStorage {
  option: ActionButtonOptions | TablesOptions;
  defaultValue?: boolean;
  templateId?: number;
}

export const getValueFromStorage = ({
  option,
  defaultValue = false, 
  templateId
}: GetValueFromStorage) => {
  const name = getActionButtonName(option, templateId);
  const value: string | null = localStorage.getItem(name);

  if (value === null) {
    localStorage.setItem(name, defaultValue ? 'true' : 'false');
    return defaultValue;
  } else if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }
};

export interface ContextMenuAction {
  name: string;
} 

export interface ContextMenuItem {
  name: string;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  // eslint-disable-next-line no-undef
  render?: () => JSX.Element;
  action?: (item?: ContextMenuAction) => void;
  subMenu?: ContextMenuItem[];
  active?: boolean;
  disabled?: boolean;
  suppressHide?: boolean;
}

export enum ContextMenuType {
  FORMULA_ITEM,
  CAPSULE,
}

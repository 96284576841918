import React, { useCallback, useMemo, useState } from 'react';
import { FormulaItem, FormulaItemType, TemplateNode } from 'types/templates.types';
import {
  selectCustomFormula,
  selectCustomFormulaId,
} from 'store/formula.slice';
import { useSortable } from '@dnd-kit/sortable';
import { useDrop } from 'react-dnd';
import { getTemplateTagFormulaType } from 'utils/template.utils';
import { useAppSelector } from 'store/hooks/hooks';
import {
  canDropOnFormulaFromBottomPanel,
  canDropOnFormulaFromCanvas, isTemplateNodeFromCanvas,
  operators,
  OperatorsType,
} from '../utils/formulaBuilder.utils';
import { selectTemplate } from 'store/template.slice';
import useFormula
  from 'components/templates/customTemplate/formulaBuilder/hooks/useFormula';
import useNodeToTag
  from 'components/templates/customTemplate/formulaBuilder/hooks/useNodeToTag';
import styles from '../FormulaBuilder.module.scss';
import FormulaItemContextMenu from
  'components/templates/customTemplate/formulaItemContextMenu/FormulaItemContextMenu';
import { ReactComponent as VerticalMenu } from 'assets/icons/vertical-menu.svg';
import { useTemplatesContext } from 'context/TemplatesContext';
import { useTranslation } from 'react-i18next';

interface Props{
  element: FormulaItem;
  setCursor: React.Dispatch<number>;
  updateFormula: (formula: FormulaItem[]) => void;
  isSelected: boolean;
  setSelected: () => void;
}

const FormulaTag = ({ element, setCursor, updateFormula, isSelected, setSelected }: Props ) => {
  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaId = useAppSelector(selectCustomFormulaId);
  const template = useAppSelector(selectTemplate);
  const findOperator = operators.find(el => el.value === element.value);
  const { addOperator, addElement } = useFormula();
  const { getTemplateTagFromNode } = useNodeToTag();
  const [ isVisible, setIsVisible ] = useState(false);
  const tagPosition = useMemo(() => {
    return customFormula.findIndex((item) => item.key === element.key);
  }, [ customFormula, element ]);
  const { dispatch: templateContextDispatch } = useTemplatesContext();

  const [ t ] = useTranslation('financials');

  const {
    attributes,
    listeners,
    setNodeRef,
    transition,
  } = useSortable({ id: element.key });

  const style = {
    transition,
  };

  const [ { isOver: isOverTag, isOverCurrent: isOverCurrentTag }, dropElement ] = useDrop({
    accept: [ 'tag', 'node' ],
    drop: (item: TemplateNode) => {
      if (isOverCurrentTag) {
        const type = isTemplateNodeFromCanvas(item) ?
          FormulaItemType.TEMPLATE : getTemplateTagFormulaType(item);
        const {
          formula,
          position
        } = addElement(getTemplateTagFromNode(item), type, customFormula, tagPosition);
        updateFormula(formula);
        setCursor(position);
      }
    },
    canDrop:(item: TemplateNode) => {
      return isTemplateNodeFromCanvas(item) ?
        canDropOnFormulaFromCanvas(item, customFormulaId, template) :
        canDropOnFormulaFromBottomPanel(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [ { isOver: isOverOperator, isOverCurrent: isOverCurrentOperator }, dropOperator ] =
    useDrop({
      accept: [
        FormulaItemType.OPERATOR,
        FormulaItemType.BRACKET,
        FormulaItemType.LOGICAL_OPERATOR
      ],
      drop: (item: OperatorsType) => {
        if (isOverCurrentOperator) {
          const { formula, position } = addOperator(item, customFormula, tagPosition);
          updateFormula(formula);
          setCursor(position);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    });

  const customFormulaElement = customFormula.find(el => el.key === element.key);
  const activeElementOffset = customFormulaElement?.offset;
  const activeElementRollingAverage = customFormulaElement?.rollingAverage;
  const showOffsetIndicator = activeElementOffset !== null &&
    (
      element.type === FormulaItemType.STATUTORY ||
      element.type === FormulaItemType.TEMPLATE ||
      element.type === FormulaItemType.DIMENSION_ITEM ||
      element.type === FormulaItemType.UNASSIGNED
    );

  const formatPeriodKey = useCallback((key: string) => {
    return key.replaceAll('_', ' ');
  }, []);

  const formatRollingAverage = useCallback((rollingAverageMonths: number) => {
    return `${ t('templates.contextMenu.rolling-average-indicator', 
      { months: rollingAverageMonths }) }`;
  }, []);

  const onClick = () => {
    const position = customFormula.findIndex((item) => item.key === element.key);
    setCursor(position + 1);
    setSelected();
  };

  const renderOperator = () => {
    return (
      <FormulaItemContextMenu
        isVisible={ isVisible }
        setIsVisible={ setIsVisible }
        element={ element }
      >
        <div
          className={ styles.containerElement }
          ref={ (el) => {
            dropElement(el);
            dropOperator(el);
            setNodeRef(el);
          } }
          { ...listeners }
          { ...attributes }
          style={ { ...style } }
        >
          <div
            onClick={ onClick }
            className={ `
              ${ styles.formulaOperator }
              ${ isSelected ? styles.chooseElement : '' }
              ${ element.bold ? styles.withChildren : '' } ` }
          >
            <div
              ref={ (el) => {
                dropElement(el);
                dropOperator(el);
              } }
              className={ `${ styles.leftDrop } ${ styles.dropArea }` } />
            {
              showOffsetIndicator &&
                <div className={ styles.offsetIndicator }>
                  { formatPeriodKey( activeElementOffset) }
                </div>
            }
            <div className={ styles.formulaTagWrapper }>
              { findOperator.component }
            </div>
          </div>
        </div>
      </FormulaItemContextMenu>
    );
  };

  const renderElement = () => {
    return (
      <FormulaItemContextMenu
        isVisible={ isVisible }
        setIsVisible={ setIsVisible }
        element={ element }
      >
        <div
          className={ styles.containerElement }
          ref={ (el) => {
            dropElement(el);
            dropOperator(el);
            setNodeRef(el);
          } }
          { ...listeners }
          { ...attributes }
          style={ { ...style } }
        >
          <div
            onClick={ onClick }
            className={ `
            ${ styles.formulaElement }
            ${ isSelected ? styles.chooseElement : '' }
            ${ element.bold ? styles.withChildren : '' }
            ` }
          >
            <div
              ref={ (el) => {
                dropElement(el);
                dropOperator(el);
              } }
              className={ `${ styles.leftDrop } ${ styles.dropArea }` } />

            <div>
              { element.value }
            </div>
            {
              showOffsetIndicator &&
                <div className={ styles.offsetIndicator }>
                  { formatPeriodKey( activeElementOffset ) }
                </div>
            }
            {
              activeElementRollingAverage &&
                <div className={ styles.rollingAverageIndicator }>
                  { formatRollingAverage(activeElementRollingAverage) }
                </div>
            }
            <VerticalMenu
              className={ styles.verticalIcon }
              onClick={ (e) => {
                e.stopPropagation();
                setIsVisible(true);
                templateContextDispatch({ type: 'contexMenuFormulaElement', payload: true });
              } }
            />
          </div>
        </div>
      </FormulaItemContextMenu>
    );
  };

  return (
    <>
      { ( isOverTag || isOverOperator ) && <div className={ styles.goalDrop }></div> }
      {
        findOperator ? renderOperator() : renderElement()
      }
    </>
  );
};

export default FormulaTag;

import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Input as AntdInput, Tooltip } from 'antd';
import { ReactComponent as EyeOnIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/visibility-off.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import styles from './Input.module.scss';

type Props = {
  name: string;
  defaultValue?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  rules?: {};
  errors: FieldErrors;
  control: Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
  allowClear?: boolean;
  labelTooltip?: string;
  displayErrorMessage?: boolean;
};

const Input = ({
  name,
  defaultValue = '',
  placeholder,
  label,
  type,
  rules,
  errors,
  control,
  className = '',
  allowClear = true,
  labelTooltip = '',
  displayErrorMessage = false,
}: Props) => {
  const config = {
    allowClear,
    placeholder: placeholder,
    className: `${ styles.input } ${ className }`,
    size: 'large'
  };

  const getInput = ({ field }) => type === 'password' ? (
    <AntdInput.Password
      iconRender={ visible =>
        visible ? <EyeOnIcon className={ styles.icon } /> : <EyeOffIcon className={ styles.icon } />
      }
      { ...config }
      { ...field }
    />
  ) : (
    <AntdInput
      { ...config }
      { ...field }
    />
  );

  return (
    <div className={ `${ styles.inputWrapper } ${
      errors[ name ] ? styles.error : '' }`
    }>
      <label className={ styles.label } htmlFor={ name }>
        { label }
        { labelTooltip && (
          <Tooltip title={ labelTooltip }>
            <InfoIcon className={ styles.infoIcon } />
          </Tooltip>
        ) }
        { displayErrorMessage && <span className={ styles.errorSpan }>
          <>
            { errors[ name ]?.message }
          </>
        </span> }
      </label>
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        rules={ rules }
        render={ getInput }
      />
    </div>
  );
};

export default Input;

import { IRowNode } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/types/core/interfaces/iCommon';
import React, { MutableRefObject, useState } from 'react';
import { Cadence } from 'types/form.types';
import OverviewFormSections from '../overviewFormSections/OverviewFormSections';
import { FormStepSections } from '../useBudgetItemForm';

interface Props {
  gridRef: MutableRefObject<AgGridCommon<unknown, unknown>>;
  entriesList: { node: IRowNode; column: string }[];
  cadence: Cadence;
  steps: FormStepSections[ 'steps' ] | null;
  budgetItemType: number;
  setHasChanges: (hasChanges: boolean) => void;
  setBudgetItemType: (id: number) => void;
}

const OverviewSteps = ({
  steps,
  entriesList,
  cadence,
  gridRef,
  budgetItemType,
  setBudgetItemType,
  setHasChanges,
}: Props) => {
  const [ activeStep ] = useState(0);

  return <>
    <OverviewFormSections
      activeStep={ activeStep }
      formSections={ steps[ activeStep ].sections }
      selectedBudgetItemType={ budgetItemType }
      changeBudgetItemType={ setBudgetItemType }
      gridApi={ gridRef.current.api }
      entriesList={ entriesList }
      setHasChanges={ setHasChanges }
      cadence={ cadence }
    />
  </>;
};

export default OverviewSteps;
